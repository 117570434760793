import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _debounce from 'lodash/fp/debounce';
import {
  changePerPage,
  getSearchRequest,
  getPerPage,
  getCurrentPage,
  getSelectedCategories, getSelectedTags, getSelectedBudgetFrom, getSelectedBudgetTo,
} from '../../../ducks/rooms';
import { setInputValue } from '../../../ducks/searchInput';

// components
import StyledComponents from './style';
import { Select, Input, Button } from '../../Shared';

class TableFilters extends Component {
  static propTypes = {
    // from parent component
    openModal: PropTypes.func,
    // from connect
    entriesPerPage: PropTypes.number,
    page: PropTypes.number,
    searchFilterRequest: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchText: this.props.inputSearchValue || '',
      tableEntriesPerPage: [
        { value: 25, text: 25 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 },
      ],
    };

    this.onSearch = _debounce(300, this.onSearch);
  }

  onFormFieldsValueChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.setInputValue(this.state.searchText, 'searchRooms');
      }
    );
  };

  onEntriesPerPageChange = ({ target: { value } }) => {
    this.props.changePerPage(value);
  };

  onSearch = () => {
    const { getSearchRequest } = this.props;
    getSearchRequest({
      categories: this.props.selectedCategories,
      tags: this.state.selectedTags,
      from_budget: this.props.selectedBudgetFrom,
      to_budget: this.props.selectedBudgetTo,
      page: 1,
      per_page: this.props.entriesPerPage,
      q: this.state.searchText,
    });
  };

  filtersFirstWorldStyle = { marginRight: '7px' };

  filtersSecondWorldStyle = { marginLeft: '7px' };

  render() {
    const { entriesPerPage, openModal } = this.props;
    const { tableEntriesPerPage, searchText } = this.state;

    return (
      <StyledComponents>
        <h2 className="visually-hidden">Rooms table filters</h2>
        <div className="filters__item">
          <span className="filters__word" style={this.filtersFirstWorldStyle}>
            Show
          </span>
          <Select
            name="entriesPerPage"
            options={tableEntriesPerPage}
            value={entriesPerPage}
            onChange={this.onEntriesPerPageChange}
          />
          <span className="filters__word" style={this.filtersSecondWorldStyle}>
            entries
          </span>
        </div>
        <div className="filters__item full-width">
          <Input
            name="searchText"
            type="search"
            placeholder="Search in Rooms"
            value={searchText}
            onChange={this.onFormFieldsValueChange}
            onInput={this.onSearch}
          />
          <Button width={200} color="tertiary" size="large" uppercase onClick={openModal}>
            Filter
          </Button>
        </div>
      </StyledComponents>
    );
  }
}

// Enhance
const mapStateToProps = (state) => ({
  page: getCurrentPage(state),
  entriesPerPage: getPerPage(state),
  inputSearchValue: state.searchInputReducer.searchRooms,
  selectedCategories: getSelectedCategories(state),
  selectedTags: getSelectedTags(state),
  selectedBudgetFrom: getSelectedBudgetFrom(state),
  selectedBudgetTo: getSelectedBudgetTo(state),
});

const mapDispatchToProps = { changePerPage, setInputValue, getSearchRequest };

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(TableFilters);
