import styled from "styled-components"

export default styled.section`
  padding-left: 30px;
  display: flex;
  
  .merge-purge-all-items {
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 0;  
  }
  
  .merge-purge-all-items__title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .merge-purge-all-items__list {
    min-height: 617px;
    max-height: 562px;
    margin-bottom: 0;
    padding-left: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .merge-purge-all-items__list-item {
    display: block;
    width: 100%;
    padding: 5px 10px;
    list-style-type: none;
  }
  
  .merge-purge-all-items__list-item.hovered {
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
    
    &:hover,
    &:focus {
      background-color:#ccc;  
    }
    
    &:active {
      background-color:#7a7a7a;
    }
  }
  
  .merge-purge-all-items__list-item.hovered.current {
    color: #fff;
    background-color:#8a8a8a;
    border-bottom-color: #ccc;
  }
  
  .merge-purge-all-items__none {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
`
