import React, { Component } from "react"
import PropTypes from "prop-types"

class Checkbox extends Component {
  handleCheckboxChange = () => this.props.controlFunc(!this.props.value)

  render() {
    return (
      <label className={"checkbox"}>
        <input
          type={"checkbox"}
          checked={this.props.value}
          className={"checkbox__input"}
          onChange={this.handleCheckboxChange}
        />
        <span className={"checkbox__imitation"} />
        {this.props.title && <span className={"checkbox__label"}>{this.props.title}</span>}
      </label>
    )
  }
}

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  title: PropTypes.string,
  controlFunc: PropTypes.func.isRequired
}

export default Checkbox
