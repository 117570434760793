import React from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import _get from 'lodash/fp/get';
import {
  fetchFurnitureCategoriesAction,
  getLoadingSelector,
  getRoomTypeAction,
} from '../../../ducks/availableCategories';
// Routing
// Components
import { DesignTemplatesLayout } from '../index';
import CreateForm from './CreateForm';
import EditForm from './EditForm';

function Detail(props) {
  const isEditMode = props.match.params.id !== 'new';

  const getItem = () => {
    if (isEditMode) {
      props.getRoomType();
    } else {
      props.fetchFurnitureCategories();
    }
  };

  return (
    <DesignTemplatesLayout
      isLoading={props.isLoading}
      pageTitle="Categories"
      buttonTitle="Go back"
      getItem={getItem}
      button_clickHandler={props.buttonGoBackOnClick}
    >
      {isEditMode ? <EditForm /> : <CreateForm />}
    </DesignTemplatesLayout>
  );
}

Detail.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  buttonGoBackOnClick: PropTypes.func.isRequired,
  getRoomType: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isLoading: getLoadingSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  buttonGoBackOnClick: () => dispatch(goBack()),
  getRoomType: () => {
    const roomTypeId = _get('match.params.id', ownProps);
    dispatch(getRoomTypeAction(roomTypeId));
  },
  fetchFurnitureCategories: () => dispatch(fetchFurnitureCategoriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
