import React from 'react';
import PropTypes from 'prop-types';

// Misc

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { syncValidate } from '../../../Shared/reduxForm/sync-validate';
import { formName } from '../../../../constants/forms';

// Import Components
import DetailCreateFormStyle from './style';
import { ReduxFormFileUploader, ReduxFormInput } from '../../../Shared/reduxForm';
import { Button } from '../../../Shared';
import RoomInnerArray from './RoomInner';

DetailCreateForm.propTypes = {
  // from DetailFloorPlans Component
  onSubmit: PropTypes.func.isRequired,
  // from reduxForm
  handleSubmit: PropTypes.func,
  // from connect
  roomInner: PropTypes.array,
};

function DetailCreateForm({
  handleSubmit, onSubmit, pristine, submitting, invalid,
}) {
  return (
    <DetailCreateFormStyle>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="floor-plan__form"
      >
        <ReduxFormFileUploader
          name="image_url"
          formName={formName.availableFloorPlanCreateForm}
          width="400px"
          height="400px"
          validate={[
            syncValidate.images,
            syncValidate.required,
          ]}
        />
        <div className="floor-plan__form-column">
          <ReduxFormInput
            name="name"
            label="Name"
            validate={[
              syncValidate.required,
            ]}
          />
          <ReduxFormInput
            name="width"
            label="width"
            validate={[
              syncValidate.onlyNumbers,
              syncValidate.required,
            ]}
          />
          <ReduxFormInput
            name="height"
            label="Height"
            validate={[
              syncValidate.onlyNumbers,
              syncValidate.required,
            ]}
          />
          <FieldArray
            name="floorplan_rooms_attributes"
            component={RoomInnerArray}
          />
        </div>
        <div className="floor-plan__buttons">
          <Button
            type="submit"
            size="large"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </DetailCreateFormStyle>
  );
}

// Enhance
const mapStateToProps = null;

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName.availableFloorPlanCreateForm,
  })
);

export default enhance(DetailCreateForm);
