import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// Actions/Selectors
import { push } from 'react-router-redux';

import {
  getAllAvailableInventorySuppliersAction,
  getAllAvailableInventorySuppliersFilesAction,
  getAllAvailableInventorySuppliersSelector,
  getAvailableInventorySupplierAction,
  getIsLoadingSelector,
} from '../../../ducks/availableInventorySuppliers';

// Import Components
import { ETLLayout } from '../index';
import { InventoryRuleList } from '../index';
import { Preloader } from '../../Shared';

InventoryFiles.propTypes = {
  push: PropTypes.func,
  goBack: PropTypes.func,
  allInventorySuppliers: PropTypes.array,
  isLoading: PropTypes.bool,
  getAllAvailableInventorySuppliersAction: PropTypes.func,
  getAllAvailableInventorySuppliersFilesAction: PropTypes.func,
  getAllAvailableInventorySuppliersSelector: PropTypes.func,
  getAvailableInventorySupplierAction: PropTypes.func,
  getAvailableInventorySupplierSelector: PropTypes.func,
};

function InventoryFiles({
  push, goBack, getAllAvailableInventorySuppliersAction, isLoading, allInventorySuppliers,
}) {
  useEffect(() => {
    getAllAvailableInventorySuppliersAction();
  }, []);

  const buttonGoBack_clickHandler = function () {
    goBack();
  };

  const getItem = function () {

  };

  return (
    <Preloader isLoading={isLoading}>
      <ETLLayout
        isLoading={isLoading}
        pageTitle="inventory files"
        buttonTitle="Go back"
        getItem={getItem}
        button_clickHandler={buttonGoBack_clickHandler}
      >
        <InventoryRuleList
          type="inventory-files"
          items={allInventorySuppliers}
        />
      </ETLLayout>

    </Preloader>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  allInventorySuppliers: getAllAvailableInventorySuppliersSelector(state),
  isLoading: getIsLoadingSelector(state),
});

const mapDispatchToProps = {
  push,
  goBack,
  getAllAvailableInventorySuppliersAction,
  getAllAvailableInventorySuppliersFilesAction,
  getAvailableInventorySupplierAction,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(InventoryFiles);
