export const checkValidDeliveryAgent = (deliveryAgent) => (
  !deliveryAgent?.company_name
  || !deliveryAgent?.address
  || !deliveryAgent?.country
  || !deliveryAgent?.state_or_province
  || !deliveryAgent?.metropolitan_city
  || !deliveryAgent?.latitude
  || !deliveryAgent?.longitude
  || !deliveryAgent?.status
  || !deliveryAgent?.quality_rating);

export const checkUpdateDeliveryAgent = (previousData, newData) => (
  previousData?.company_name !== newData?.company_name
  && previousData?.website !== newData?.website
  && previousData?.address !== newData?.address
  && previousData?.admin_contact_name !== newData?.admin_contact_name
  && previousData?.admin_contact_email !== newData?.admin_contact_email
  && previousData?.admin_contact_number !== newData?.admin_contact_number
  && previousData?.warehouse_contact_name !== newData?.warehouse_contact_name
  && previousData?.warehouse_contact_email !== newData?.warehouse_contact_email
  && previousData?.warehouse_contact_phone_number !== newData?.warehouse_contact_phone_number
  && previousData?.status !== newData?.status
  && previousData?.invoices_sent_to !== newData?.invoices_sent_to
  && previousData?.inventory_check_method !== newData?.inventory_check_method
  && previousData?.warehouse_instructions !== newData?.warehouse_instructions
  && previousData?.quality_rating !== newData?.quality_rating
  && previousData?.notes !== newData?.notes
  && previousData?.truck_rate_per_truck !== newData?.truck_rate_per_truck
  && previousData?.storage_fee_per_month !== newData?.storage_fee_per_month
  && previousData?.receiving_cost_per_shipment !== newData?.receiving_cost_per_shipment
  && previousData?.disposal_fee !== newData?.disposal_fee
  && previousData?.fuel_charge_per_mile !== newData?.fuel_charge_per_mile);
