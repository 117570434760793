import React, { Component } from 'react';
import { connect } from 'react-redux';
import fileExtension from 'file-extension';
import { validate } from 'email-validator';

import Spinner from '../../Components/Shared/Spinner';
import Uploader from '../../Components/CreateMany/Uploader';
import LogTable from '../../Components/CreateMany/LogTable';

import {
  loadingExcelProductsSelector,
  uploadingExcelProductsSelector,
  excelProductsSelector,
} from '../../ducks/productMany';

import { getLogs, sendFile } from '../../ducks/productMany';

const formatsTitle = '*.csv, *.xls, *.xlsx';

const defaultState = {
  file: undefined,
  email: undefined,
  error: false,
};

class ImportProducts extends Component {
  state = {
    ...defaultState,
  };

  componentDidMount() {
    const { getLogs } = this.props;
    getLogs();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.loading && nextProps.uploaded) {
      this.setState({
        ...defaultState,
      });
    }
  }

  onChoose = (e) => {
    const file = e.target.files && e.target.files[0];

    this.setState((state) => ({ ...state, file }));
  };

  onEmailValidation = (email) => {
    const emailError = () => {
      this.setState({ error: true });
      return false;
    };

    if (!email) {
      return emailError();
    }

    if (!validate(email)) {
      return emailError();
    }

    return true;
  };

  onFileValidation = (file) => {
    if (!file) return false;

    const formats = ['csv', 'xls', 'xlsx'];

    const format = fileExtension(file.name);

    return formats.indexOf(format) !== -1;
  };

  onUpload = () => {
    const { file, email } = this.state;
    const { sendFile } = this.props;

    if (!this.onEmailValidation(email)) return;

    if (!this.onFileValidation(file)) return;

    sendFile(file, email);
  };

  onEmailEnter = (event) => {
    const { email } = this.state;

    const seed = 1;

    this.setState({ email: event.target.value });

    if (email && email.length > seed) this.setState({ error: false });
  };

  onCheckEmailForValid = () => {
    const { email } = this.state;

    if (this.onEmailValidation(email)) {
      this.setState({ error: false });
    }
  };

  render() {
    const { error, email, file } = this.state;
    const { loading, excelProducts } = this.props;

    const pageLoading = loading || excelProducts === null;

    if (pageLoading) {
      return (
        <div className="import-products">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="import-products">
        <div className="import-products-wrapper">
          <Uploader
            file={file}
            email={email}
            error={error}
            loading={loading}
            formatsTitle={formatsTitle}
            onChoose={this.onChoose}
            onUpload={this.onUpload}
            onEmailEnter={this.onEmailEnter}
            onCheckEmailForValid={this.onCheckEmailForValid}
          />
          <LogTable loading={loading} excelProducts={excelProducts} name="import" />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    excelProducts: excelProductsSelector(state),
    loading: loadingExcelProductsSelector(state),
    uploaded: uploadingExcelProductsSelector(state),
  }),
  {
    getLogs,
    sendFile,
  }
)(ImportProducts);
