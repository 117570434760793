import { put, call, all, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { appName, POSTFIX } from "../constants"
import Http from "../api/design-templates-textures"
import { replace } from "react-router-redux"

/**
 * Constants
 **/
export const moduleName = "availableTextures"
const prefix = `@@${appName}/${moduleName}`

const GET_TEXTURES_REQUEST = `${prefix}/GET_TEXTURES_REQUEST`
const GET_TEXTURE_BY_ID_REQUEST = `${prefix}/GET_TEXTURE_BY_ID_REQUEST`
const CREATE_TEXTURE_REQUEST = `${prefix}/CREATE_TEXTURE_REQUEST`
const UPDATE_TEXTURE_REQUEST = `${prefix}/UPDATE_TEXTURE_REQUEST`
const DELETE_TEXTURE_REQUEST = `${prefix}/DELETE_TEXTURE_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  textures: [],
  texture: {},
  isLoading: false,
  error: null
}

export default function(state = initialState, { type, payload, error }) {
  switch (type) {

    case GET_TEXTURES_REQUEST + POSTFIX.START:
    case GET_TEXTURE_BY_ID_REQUEST + POSTFIX.START:
    case CREATE_TEXTURE_REQUEST + POSTFIX.START:
    case UPDATE_TEXTURE_REQUEST + POSTFIX.START:
    case DELETE_TEXTURE_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_TEXTURES_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_TEXTURE_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        textures: payload.textures
      }

    case GET_TEXTURE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        texture: payload.texture
      }

    case GET_TEXTURES_REQUEST + POSTFIX.FAILURE:
    case GET_TEXTURE_BY_ID_REQUEST + POSTFIX.FAILURE:
    case CREATE_TEXTURE_REQUEST + POSTFIX.FAILURE:
    case UPDATE_TEXTURE_REQUEST + POSTFIX.FAILURE:
    case DELETE_TEXTURE_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableTexturesReducer
 **/
export const getAvailableTextures = function(state) {
  return state.availableTexturesReducer.textures
}

export const getAvailableTextureById = function(state) {
  return state.availableTexturesReducer.texture
}

export const getIsLoading = function(state) {
  return state.availableTexturesReducer.isLoading
}

/**
 * Action Creators
 **/
export function getAvailableTexturesRequest() {
  return { type: GET_TEXTURES_REQUEST }
}

export function getAvailableTextureByIdRequest(id) {
  return {
    type: GET_TEXTURE_BY_ID_REQUEST,
    payload: { id }
  }
}

export function updateAvailableTextureRequest(id, data) {
  return {
    type: UPDATE_TEXTURE_REQUEST,
    payload: { id, data }
  }
}

export function createAvailableTextureRequest(data) {
  return {
    type: CREATE_TEXTURE_REQUEST,
    payload: { data }
  }
}

export function deleteAvailableTexturesRequest(id) {
  return {
    type: DELETE_TEXTURE_REQUEST,
    payload: { id }
  }
}

/**
 * Sagas
 **/
function* getAvailableTexturesSaga() {
  yield put({ type: GET_TEXTURES_REQUEST + POSTFIX.START })
  try {
    const textures = yield call(() => Http.getTextures())
    yield put({
      type: GET_TEXTURES_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { textures }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_TEXTURES_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* getAvailableTextureByIdSaga({ payload: { id } }) {
  yield put({ type: GET_TEXTURE_BY_ID_REQUEST + POSTFIX.START })
  try {
    const texture = yield call(() => Http.getTextureById(id))
    yield put({
      type: GET_TEXTURE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { texture }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_TEXTURE_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* createAvailableTextureSaga({ payload: { data } }) {
  yield put({ type: CREATE_TEXTURE_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.addTexture(data))
    yield call(() => toastr.success("Successfully created"))
    yield put(replace("/design-templates/detail/textures"))
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: CREATE_TEXTURE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* updateAvailableTextureSaga({ payload: { id, data } }) {
  yield put({ type: UPDATE_TEXTURE_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.updateTexture(id, data))
    yield call(() => toastr.success("Successfully updated"))
    yield put(replace("/design-templates/detail/textures"))
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: UPDATE_TEXTURE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* deleteAvailableTextureSaga({ payload: { id } }) {
  yield put({ type: DELETE_TEXTURE_REQUEST + POSTFIX.START })
  try {
    const textures = yield call(() => Http.deleteTexture(id))
    yield call(() => toastr.success("Successfully deleted"))
    yield put({
      type: DELETE_TEXTURE_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { textures }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: DELETE_TEXTURE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_TEXTURES_REQUEST, getAvailableTexturesSaga),
    takeLatest(CREATE_TEXTURE_REQUEST, createAvailableTextureSaga),
    takeLatest(GET_TEXTURE_BY_ID_REQUEST, getAvailableTextureByIdSaga),
    takeLatest(UPDATE_TEXTURE_REQUEST, updateAvailableTextureSaga),
    takeLatest(DELETE_TEXTURE_REQUEST, deleteAvailableTextureSaga)
  ])
}
