import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _set from 'lodash/fp/set';
import _get from 'lodash/fp/get';
import { connect } from 'react-redux';
import {
  createRoomTypeAction,
  getFurnitureCategoriesSelector,
  makeAddFurnitureCategoriesSelector,
  fetchFurnitureCategoriesAction
} from '../../../ducks/roomType';

import { Button } from '../../Shared';
import { CreateFormStyle } from './style';
import Table from './Table';
import objectToFormData from '../../../utils/objectToFormData';

class CreateForm extends Component {
  static propTypes = {
    fetchFurnitureCategories: PropTypes.func.isRequired,
    furnitureCategories: PropTypes.array.isRequired,
    addFurnitureCategories: PropTypes.array,
  };

  state = {
    furnitureCategory: {
      value: '',
      error: null,
    },
    categories: {},
    removeCategoryIds: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addFurnitureCategories !== this.props.addFurnitureCategories) {
      this.initializeCategories();
    }
  }

  initializeCategories = () => {
    const { addFurnitureCategories } = this.props;

    const categories = addFurnitureCategories.reduce((acc, category) => {
      if (category.children.length) {
        category.children.forEach((item) => {
          acc[item.value] = {
            product_category_id: item.value,
            quantity: _get(`${[item.value]}.quantity`, this.state.categories) || 0,
          };
        });
      }

      return acc;
    }, {});

    this.setState({ categories });
  };

  onChangeCategories = (idCategory, event) => {
    const { target } = event;
    const { name, value } = target;

    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set(`${name}`, parseFloat(value) || 0, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeSelectCategories = (idCategory, selected) => {
    const { value } = selected;

    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set('variation', value, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeRemoveCategory = (event) => {
    const { target } = event;
    const { name, checked } = target;

    this.setState((prevState) => ({
      removeCategoryIds: {
        ...prevState.removeCategoryIds,
        [name]: checked,
      },
    }));
  };

  formOnSubmit = (event) => {
    event && event.preventDefault && event.preventDefault();
    const { furnitureCategory, categories } = this.state;

    if (!this.disabledSubmit()) {
      const data = {
        room_type: {
          name: furnitureCategory.value,
          room_types_default_categories_attributes: Object.values(categories).length ? [...Object.values(categories)] : [[]],
        },
      };

      this.props.createRoomType(objectToFormData(data));
    }
  };

  disabledSubmit = () => !this.state.furnitureCategory.value;

  inputOnChange = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        value: target.value,
      },
    }));
  };

  inputOnFocus = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        error: null,
      },
    }));
  };

  inputOnBlur = ({ target }) => {
    if (!target.value) {
      this.setState((prevState) => ({
        [target.name]: {
          ...prevState[target.name],
          error: 'This field is required',
        },
      }));
    }
  };

  render() {
    const { furnitureCategory: { value, error } } = this.state;
    const inputLabelClassNames = cn('form-control__label', { 'error': !!error });
    const inputClassNames = cn('form-control__input', { 'error': !!error });

    return (
      <CreateFormStyle>
        <form noValidate className="form" onSubmit={this.formOnSubmit}>
          <div className="form-control__wrapper">
            <label htmlFor="furnitureCategory-id" className={inputLabelClassNames}>Room type name</label>
            <div className="form-control__input-wrapper">
              <input
                className={inputClassNames}
                name="furnitureCategory"
                id="furnitureCategory-id"
                type="text"
                value={value}
                onChange={this.inputOnChange}
                onFocus={this.inputOnFocus}
                onBlur={this.inputOnBlur}
              />
              {error && <span className="form-control__error">{error}</span>}
            </div>
          </div>
          <div className="form__table">
            <Table
              data={this.props.furnitureCategories}
              onChangeCategory={this.onChangeRemoveCategory}
              onChangeCategories={this.onChangeCategories}
              onChangeSelectCategories={this.onChangeSelectCategories}
              categories={this.state.categories}
            />
          </div>
          <div className="form__button-wrapper">
            <Button
              type="submit"
              uppercase
              size="large"
              width={150}
              disabled={this.disabledSubmit()}
            >
              Save
            </Button>
          </div>
        </form>
      </CreateFormStyle>
    );
  }
}

const mapStateToProps = (state) => {
  const addFurnitureCategoriesSelector = makeAddFurnitureCategoriesSelector();

  return {
    furnitureCategories: getFurnitureCategoriesSelector(state),
    addFurnitureCategories: addFurnitureCategoriesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createRoomType: (roomData) => dispatch(createRoomTypeAction(roomData)),
  fetchFurnitureCategories: () => dispatch(fetchFurnitureCategoriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm);
