import axios from 'axios';

import { apiUrl } from './lt-api';

const usersApi = axios.create({
  baseURL: apiUrl,
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    withCredentials: true,
  },
});

export const getUsersApi = () => usersApi.get('users/admin');
