import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styled from 'styled-components';
import _get from 'lodash/fp/get';
import ReactTooltip from 'react-tooltip';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
import Checkbox from '../Checkbox';
import Spinner from '../Spinner';
import NotFound from '../NotFound';
import tableTitles from '../../../constants/tableHeaders';
import PaginationView from '../Pagination';
import ZoomImage from '../ZoomImage';

import { DOMAIN_FE } from '../../../constants';

import './index.scss';

// Style
const Stock = styled.div`
  text-align: center;
  cursor: pointer;
`;

// End style

const Table = ({
  type,
  onEditItem = () => {},
  onSortChange = () => {},
  selected,
  handleSelectItems,
  handleOpenModal,
  currentPage,
  totalEntries,
  perPage,
  renderColumns,
  tableLoaded,
  noResults,
  items,
  paginationChange,
  token = '',
  push: pushTo,
}) => {
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(document.documentElement.clientHeight);
  }, []);

  const titles = () => tableTitles[type];

  const handleSelect = (id, val) => {
    let selectedItems = selected;
    if (val) {
      selectedItems.push(id);
    } else {
      selectedItems = selectedItems.filter((user) => user !== id);
    }
    handleSelectItems(selectedItems);
  };

  const renderOneColumnHeader = (col, i) => col === 'checkbox' ? (
    <Checkbox key={i} controlFunc={selectAll} value={isSelectedAll} />
  ) : (
    <th key={i} className={renderClassNames(col)}>
      <div className="furnishr-table__header-cell-content-wrapper">
        <span>{titles()[col]}</span>

        {type !== 'dataStatus'
          && col !== 'action'
          && type !== 'deliveryAgentorders'
          && type !== 'deliveryAgentorders'
          && (
            <div className="furnishr-table__sorting-toggle-arrows">
              <FontAwesomeIcon
                onClick={() => onSortChange(col)}
                icon={faSortUp}
                alt="profile-options-button-image"
              />
              <FontAwesomeIcon
                onClick={() => onSortChange(`-${col}`)}
                icon={faSortDown}
                alt="profile-options-button-image"
              />
            </div>
          )}
      </div>
    </th>
  );

  const renderOneCell = (col, item) => {
    if (titles()[col] === 'Supplier Name') {
      const httpRegex = /^(http|https):\/\//i;

      return (item?.website
        ? (
          <a
            href={httpRegex.test(item.website) ? item.website : `http://${item.website}`}
            target="_blank"
          >
            {item[col]}
          </a>
        )
        : item[col]
      );
    }

    if (col === 'checkbox') {
      return (
        <div className="furnishr-table__checkbox-cell">
          <Checkbox
            controlFunc={(val) => handleSelect(item.id, val)}
            value={Boolean(selected.find((s) => s === item.id))}
          />
        </div>
      );
    } if (col === 'action') {
      return (
        <button className="furnishr-table__cell-actions" onClick={() => onEditItem(item.id)}>
          Edit
        </button>
      );
    } if (col === 'stock_availability') {
      return item.stock_availability ? 'Yes' : 'No';
    } if (col === 'error') {
      return ReactHtmlParser(item.error);
    } if (col === 'name') {
      return (
        <span className="furnishr-table__cell_name" aria-hidden onClick={() => onEditItem(item.id)}>
          {item[col]}
        </span>
      );
    } if (col === 'thumbnail_image') {
      if (item.default_image !== undefined) {
        return (
          <ZoomImage
            alt={item.name}
            srcThumb={item.default_image.thumb.url || item.url_default_image}
            srcPreview={item.default_image.large.url || item.url_default_image}
            screenHeight={screenHeight}
          />
        );
      }
    } else if (col === 'stock_number') {
      const date = _get('arrival_date', item);
      const formattedDate = new Date(date).toLocaleDateString();

      return (
        <>
          <Stock data-tip={date ? `ETA: ${formattedDate}` : 'unknown date'}>{item.stock_number}</Stock>
          <ReactTooltip />
        </>
      );
    } else if (col === 'last_updated_date') {
      const date = _get('last_updated_date', item);

      return new Date(date).toLocaleDateString();
    } if (col === 'delivery_quotes') {
      return item.delivery_quotes && item.delivery_quotes.length ? (
        <span
          className="furnishr-table__cell-quotes"
          aria-hidden
          onClick={() => handleOpenModal(item.delivery_quotes, 'quotes')}
        >
          View quotes
        </span>
      ) : 'No Quotes Yet';
    } if (col === 'shoppe_orders') {
      return item.shoppe_orders && item.shoppe_orders.length ? (
        <span
          className="furnishr-table__cell-orders"
          aria-hidden
          onClick={() => handleOpenModal(
            item.shoppe_orders,
            'orders'
          )}
        >
          View orders
        </span>
      ) : 'No Orders Yet';
    }

    if (col === 'company_name') {
      return (
        <Link className="furnishr-table__cell-company-name" to={`/delivery-agents/${item.id}`}>
          {item[col]}
        </Link>
      );
    }

    if (col === 'admin_contact_name' || col === 'contact_name') {
      return (item?.admin_contact_email || item?.email) ? (
        <a
          className="furnishr-table__cell-admin_contact_name"
          href={`mailto:${item?.admin_contact_email || item?.email}`}
        >{item[col]}
        </a>
      ) : (
        item[col]
      );
    }

    if (col === 'status') {
      const renderStatus = {
        inactive: 'Inactive',
        preferred_partner: 'Preferred Partner',
        tier_2_partner: 'Tier 2 Partner',
      };

      return renderStatus?.[item[col]];
    }

    if (titles()[col] === 'Order ID') {
      return (
        <a href={`${DOMAIN_FE}/shoppe/orders/${item[col]}?auth_token=${token?.replace('Bearer ', '')}`}>
          {item[col]}
        </a>
      );
    }

    if (col === 'view_more') {
      return (
        <div
          className="furnishr-table__cell-view-more"
          onClick={() => pushTo(`/suppliers/${item.id}`)}
          aria-hidden
        >
          View More
        </div>
      );
    }

    return (
      <div className={`furnishr-table__cell-${col}`}>
        {item[col] || '-'}
      </div>
    );
  };

  const renderClassNames = (col) => {
    const hasFields = col === 'colors' || col === 'materials' || col === 'stock_availability';
    return ClassNames({
      'd-none': hasFields,
      'd-xl-table-cell': hasFields,
      'dataStatus-cell': type === 'dataStatus',
      'furnishr-table__cell': true,
      'furnishr-table__cell_thumbnail': col === 'thumbnail_image',
    });
  };

  const selectAll = () => {
    setIsSelectedAll(!isSelectedAll);

    if (!isSelectedAll) {
      items.map((item) => handleSelect(item.id, !isSelectedAll));
    } else {
      handleSelectItems([]);
    }
  };

  const pages = Math.ceil(totalEntries / perPage);

  if (!tableLoaded) {
    return <Spinner />;
  }
  if (noResults) {
    return <NotFound />;
  }

  return (
    <section className="table-responsive">
      <table className="table">
        <thead>
          <tr className="furnishr-table__header">
            {renderColumns.map((col, i) => renderOneColumnHeader(col, i))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id} className="furnishr-table__row">
              {renderColumns.map((col) => (
                <td key={col} className={renderClassNames(col)}>
                  {renderOneCell(col, item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {(type === 'dataStatus'
        || type === 'deliveryAgentSummary'
        || type === 'suppliersSummary')
        && (
          <PaginationView activePage={currentPage} pages={pages} onPageChange={(page) => paginationChange(page)} />
        )}
    </section>
  );
};

Table.propTypes = {
  currentPage: PropTypes.number,
  handleOpenModal: PropTypes.func,
  handleSelectItems: PropTypes.func,
  items: PropTypes.array,
  noResults: PropTypes.bool,
  onEditItem: PropTypes.func,
  onSortChange: PropTypes.func,
  paginationChange: PropTypes.func,
  perPage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  renderColumns: PropTypes.array.isRequired,
  selected: PropTypes.array,
  tableLoaded: PropTypes.bool,
  token: PropTypes.string,
  totalEntries: PropTypes.number,
  type: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(null, {
    push,
  })
);

export default enhance(Table);
