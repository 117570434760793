import React, { createRef } from 'react';
import PropTypes from 'prop-types';

import ZoomImageStyle from './style';

const ZoomImage = ({
  srcThumb,
  alt,
  srcPreview,
  screenHeight,
}) => {
  const imagePreviewWrapperRef = createRef();
  const imagePreviewRef = createRef();

  const imagePreviewMouseEnterHandler = () => {
    const previewHeight = imagePreviewRef.current.offsetHeight;
    const previewWrapperHeight = imagePreviewWrapperRef.current.offsetHeight + 12;
    const bottom = imagePreviewWrapperRef.current.getBoundingClientRect().top + previewHeight;

    if (bottom >= screenHeight) {
      imagePreviewRef.current.style.top = `${-(previewHeight - previewWrapperHeight)}px`;
    } else {
      imagePreviewRef.current.style.top = '-0.75rem';
    }
  };

  return (
    <ZoomImageStyle>
      <div
        ref={imagePreviewWrapperRef}
        onMouseEnter={imagePreviewMouseEnterHandler}
      >
        <img className="zoom-image__image" src={srcThumb} alt={alt} />
        <div
          className="zoom-image__preview"
          ref={imagePreviewRef}
        >
          <img src={srcPreview} alt={alt} />
        </div>
      </div>
    </ZoomImageStyle>
  );
};

ZoomImage.propTypes = {
  // from Table Component
  alt: PropTypes.string.isRequired,
  screenHeight: PropTypes.number.isRequired,
  srcPreview: PropTypes.string.isRequired,
  srcThumb: PropTypes.string.isRequired,
};

export default ZoomImage;
