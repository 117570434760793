import { put, call, all, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { appName, POSTFIX } from "../constants"
import Http from "../api/design-templates-colors"
import { replace } from "react-router-redux"

/**
 * Constants
 **/
export const moduleName = "availableColors"
const prefix = `@@${appName}/${moduleName}`

const GET_COLORS_REQUEST = `${prefix}/GET_COLORS_REQUEST`
const GET_COLOR_BY_ID_REQUEST = `${prefix}/GET_COLOR_BY_ID_REQUEST`
const CREATE_COLOR_REQUEST = `${prefix}/CREATE_COLOR_REQUEST`
const UPDATE_COLOR_REQUEST = `${prefix}/UPDATE_COLOR_REQUEST`
const DELETE_COLOR_REQUEST = `${prefix}/DELETE_COLOR_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  colors: [],
  color: {},
  isLoading: false,
  error: null
}

export default function(state = initialState, { type, payload, error }) {
  switch (type) {

    case GET_COLORS_REQUEST + POSTFIX.START:
    case GET_COLOR_BY_ID_REQUEST + POSTFIX.START:
    case CREATE_COLOR_REQUEST + POSTFIX.START:
    case UPDATE_COLOR_REQUEST + POSTFIX.START:
    case DELETE_COLOR_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_COLORS_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_COLOR_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        colors: payload.colors
      }

    case GET_COLOR_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        color: payload.color
      }

    case GET_COLORS_REQUEST + POSTFIX.FAILURE:
    case GET_COLOR_BY_ID_REQUEST + POSTFIX.FAILURE:
    case CREATE_COLOR_REQUEST + POSTFIX.FAILURE:
    case UPDATE_COLOR_REQUEST + POSTFIX.FAILURE:
    case DELETE_COLOR_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableColorsReducer
 **/
export const getAvailableColors = function(state) {
  return state.availableColorsReducer.colors
}

export const getAvailableColorById = function(state) {
  return state.availableColorsReducer.color
}

export const getIsLoading = function(state) {
  return state.availableColorsReducer.isLoading
}

/**
 * Action Creators
 **/
export function getAvailableColorsRequest() {
  return { type: GET_COLORS_REQUEST }
}

export function getAvailableColorByIdRequest(id) {
  return {
    type: GET_COLOR_BY_ID_REQUEST,
    payload: { id }
  }
}

export function updateAvailableColorRequest(id, data) {
  return {
    type: UPDATE_COLOR_REQUEST,
    payload: { id, data }
  }
}

export function createAvailableColorRequest(data) {
  return {
    type: CREATE_COLOR_REQUEST,
    payload: { data }
  }
}

export function deleteAvailableColorsRequest(id) {
  return {
    type: DELETE_COLOR_REQUEST,
    payload: { id }
  }
}

/**
 * Sagas
 **/
function* getAvailableColorsSaga() {
  yield put({ type: GET_COLORS_REQUEST + POSTFIX.START })
  try {
    const colors = yield call(() => Http.getColors())
    yield put({
      type: GET_COLORS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { colors }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_COLORS_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* getAvailableColorByIdSaga({ payload: { id } }) {
  yield put({ type: GET_COLOR_BY_ID_REQUEST + POSTFIX.START })
  try {
    const color = yield call(() => Http.getColorById(id))
    yield put({
      type: GET_COLOR_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { color }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_COLOR_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* createAvailableColorSaga({ payload: { data } }) {
  yield put({ type: CREATE_COLOR_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.addColor(data))
    yield call(() => toastr.success("Successfully created"))
    yield put(replace("/design-templates/detail/colors"))
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: CREATE_COLOR_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* updateAvailableColorSaga({ payload: { id, data } }) {
  yield put({ type: UPDATE_COLOR_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.updateColor(id, data))
    yield call(() => toastr.success("Successfully updated"))
    yield put(replace("/design-templates/detail/colors"))
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: UPDATE_COLOR_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* deleteAvailableColorSaga({ payload: { id } }) {
  yield put({ type: DELETE_COLOR_REQUEST + POSTFIX.START })
  try {
    const colors = yield call(() => Http.deleteColor(id))
    yield call(() => toastr.success("Successfully deleted"))
    yield put({
      type: DELETE_COLOR_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { colors }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: DELETE_COLOR_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_COLORS_REQUEST, getAvailableColorsSaga),
    takeLatest(CREATE_COLOR_REQUEST, createAvailableColorSaga),
    takeLatest(GET_COLOR_BY_ID_REQUEST, getAvailableColorByIdSaga),
    takeLatest(UPDATE_COLOR_REQUEST, updateAvailableColorSaga),
    takeLatest(DELETE_COLOR_REQUEST, deleteAvailableColorSaga)
  ])
}
