import React from 'react';
import PropTypes from 'prop-types';

// Misc

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { formName } from '../../../../constants/forms';
import { getAvailableColorById } from '../../../../ducks/availableColors';

// Import Components
import DetailEditFormStyle from './style';
import { ReduxFormFileUploader, ReduxFormInput } from '../../../Shared/reduxForm';
import { Button } from '../../../Shared';

DetailEditForm.propTypes = {
  // from parent component
  onSubmit: PropTypes.func,
  disabledSubmit: PropTypes.bool,
};

function DetailEditForm({
  handleSubmit, onSubmit, pristine, submitting, invalid, initialValues,
}) {
  return (
    <DetailEditFormStyle>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="available-colors__form"
      >
        <div className="available-colors__column preview">
          <ReduxFormFileUploader
            fileUrl={initialValues.file}
            name="file"
            formName={formName.availableColorsEditForm}
            width="100px"
            height="100px"
          />
        </div>
        <div className="available-colors__column fields">
          <ReduxFormInput
            name="name"
            label="Color name"
          />
        </div>
        <div className="available-colors__button">
          <Button
            size="large"
            type="submit"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </DetailEditFormStyle>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  initialValues: {
    name: getAvailableColorById(state).label,
    file: getAvailableColorById(state).image_url && getAvailableColorById(state).image_url.url,
  },
});

const enhancer = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName.availableColorsEditForm,
  })
);

export default enhancer(DetailEditForm);
