import React, { Component } from "react"
import PropTypes from "prop-types"
import Multiselect from "react-select"

class MultiSelectTags extends Component {

  static propTypes = {
    tags: PropTypes.array,
    selected_tags: PropTypes.array,
    onTagsChange: PropTypes.func,
    getSelectedTagsList: PropTypes.func,
    variant_id: PropTypes.number
  }

  state = {
    tags: [],
    selected_tags: []
  }

  componentDidMount() {
  }

  onTagsChange = (tag) => {
    this.props.onTagsChange(tag, this.props.variant_id)
  }

  onSubmit = (event) => {
    return false;
  }

  render() {
    const { tags, selected_tags, getSelectedTagsList } = this.props
    return (
      <Multiselect
        isMulti={true}
        className={"select2"}
        value={getSelectedTagsList(tags, selected_tags)}
        options={tags}
        onChange={this.onTagsChange}
      />
    )
  }
}

export default MultiSelectTags
