import styled from "styled-components"

export default styled.div`
    overflow-y: auto;
    max-height: 500px;
    
    .modal-categories__title {
      margin-bottom: 15px;
      margin-top: 20px;
      font-weight: 400;
      font-size: 30px;
      text-align: center;
      text-transform: uppercase;
    }
    
    .modal-categories__button {
      text-align: center;
    }
`
