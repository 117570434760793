import React from "react"
import PropTypes from "prop-types"

Textarea.propTypes = {
  // from ReduxFormInput Component
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}

Textarea.defaultProps = {
  type: "text"
}

function Textarea({ input }) {
  return <textarea
    {...input}
    id={`${input.name}-id`}
    className="input__field"
  />
}

export default Textarea
