import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchFilterRequest } from '../../../ducks/filters';

// components
import { DKCheckbox } from '../index';

class Th extends Component {
  static propTypes = {
    // from TableHead components
    text: PropTypes.string.isRequired,
    filter: PropTypes.bool.isRequired,
    onCheckboxSelectedAll: PropTypes.func,
    selectedAllValue: PropTypes.bool,
    sort: PropTypes.string,
  };

  static defaultProps = {
    text: '',
    filter: false,
  };

  render() {
    const {
      sort,
      text,
      filter,
      onCheckboxSelectedAll,
      selectedAllValue,
      searchFilterRequest,
      page,
      entriesPerPage,
      inputSearch,
    } = this.props;

    const onSearch = (mark) => {
      searchFilterRequest({
        page,
        per_page: entriesPerPage,
        q: inputSearch,
        sort: `${mark}${sort}`,
      });
    };

    switch (text) {
      case 'checkbox': {
        return (
          <th className="table__cell">
            <div className="table__cell-inner">
              <DKCheckbox name="selectedAll" value={selectedAllValue} onChange={onCheckboxSelectedAll} />
            </div>
          </th>
        );
      }
      default: {
        if (filter) {
          return (
            <th className="table__cell table-filter">
              <div className="table__cell-inner">
                <span className="up" onClick={() => onSearch('')} />
                <span className="down" onClick={() => onSearch('-')} />
                {text}
              </div>
            </th>
          );
        }
        return (
          <th className="table__cell">
            <div className="table__cell-inner">{text}</div>
          </th>
        );
      }
    }
  }
}

const mapStateToProps = (state) => ({
  page: state.paginationReducer.value,
  entriesPerPage: state.selectorReducer.value,
  inputSearch: state.searchInputReducer.value,
});

export default connect(
  mapStateToProps,
  { searchFilterRequest }
)(Th);
