import axios from 'axios';

const http = axios.create({
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    withCredentials: true,
  },
});

const token = JSON.parse(window.localStorage.getItem('token'));

http.interceptors.request.use((req) => {
  if (token) {
    req.headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  return req;
});

if (token) {
  axios.defaults.headers.common.Authorization = token;
} else {
  delete axios.defaults.headers.common.Authorization;
}

export default http;
