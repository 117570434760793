import React from "react"
import { withRouter } from "react-router-dom"

// Import Components
import TemplateHeaderStyle from "./style"
import CreateInfoForm from "./Components/TemplateCreateInfoForm"
import EditInfoForm from "./Components/TemplateEditInfoForm"

function TemplateHeader({ match: { params: { id } } }) {
  const isEditMode = id !== "new"

  return (
    <TemplateHeaderStyle>
      {isEditMode ? <EditInfoForm /> : <CreateInfoForm />}
    </TemplateHeaderStyle>
  )
}

export default withRouter(TemplateHeader)
