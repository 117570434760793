import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// Actions/Selectors
import { push } from 'react-router-redux';
import {
  getAllAvailableScrapersAction,
  getAvailableScraperAction,
  getAllAvailableScrapersSelector,
  getAllAvailableScraperProductsAction,
  getIsLoadingSelector,
} from '../../../ducks/availableScrapers';

// Import Components
import { ETLLayout } from '../index';
import { ScraperRuleList } from '../index';
import { Button, Preloader } from '../../Shared';

Scrapers.propTypes = {
  push: PropTypes.func,
  allScrapers: PropTypes.array,
  isLoading: PropTypes.bool,
  getAllAvailableScrapersAction: PropTypes.func,
  getAvailableScraperAction: PropTypes.func,
  getAllAvailableScrapersSelector: PropTypes.func,
  getAllAvailableScraperProductsAction: PropTypes.func,
};

function Scrapers({
  push,
  goBack,
  getAllAvailableScrapersAction,
  getAllAvailableScraperProductsAction,
  isLoading,
  allScrapers,
}) {
  useEffect(() => {
    getAllAvailableScrapersAction();
  }, []);

  const buttonGoBack_clickHandler = function () {
    goBack();
  };

  const getItem = function () {

  };

  const onRunScraper = () => {
    const scraper_id = null;
    getAllAvailableScraperProductsAction(scraper_id);
  };

  return (
    <Preloader isLoading={isLoading}>
      <ETLLayout
        isLoading={isLoading}
        pageTitle="scrapers"
        buttonTitle="Go back"
        getItem={getItem}
        button_clickHandler={buttonGoBack_clickHandler}
      >
        <ScraperRuleList
          type="scrapers"
          items={allScrapers}
        />
        <Button
          type="button"
          size="large"
          color="warning"
          width={150}
          onClick={onRunScraper}
        >
          Run Scrapers
        </Button>
      </ETLLayout>
    </Preloader>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  allScrapers: getAllAvailableScrapersSelector(state),
  isLoading: getIsLoadingSelector(state),
});

const mapDispatchToProps = {
  push,
  goBack,
  getAvailableScraperAction,
  getAllAvailableScrapersAction,
  getAllAvailableScraperProductsAction,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Scrapers);
