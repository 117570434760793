import React from "react"
import PropTypes from "prop-types"

// Import Components
import PurgeFormStyle from "./style"
import { Button } from "../../Shared"
import ItemsList from "./ItemsList"

PurgeForm.propTypes = {
  // from Colors component
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  findButtonText: PropTypes.string.isRequired,
  onButtonFindClick: PropTypes.func.isRequired,
  onButtonPurgeClick: PropTypes.func.isRequired
}

PurgeForm.defaultProps = {
  items: [],
  selectedItems: [],
  onChange: () => {
  }
}

function PurgeForm({ items, selectedItems, onChange, findButtonText, onButtonFindClick, onButtonPurgeClick }) {
  const buttonPurgeDisabled = !!!selectedItems.length

  return (
    <PurgeFormStyle>
      <h2 className="purge__item-title">Purge:</h2>
      <div className="purge__box">
        <div className="purge__item">
          <Button
            onClick={onButtonFindClick}
            size="large"
            width={200}
            uppercase
          >
            {findButtonText}
          </Button>
        </div>
        <div className="purge__item">
          <ItemsList
            items={items}
            selectedItems={selectedItems}
            onChange={onChange}
          />
        </div>
        <div className="purge__item">
          <Button
            onClick={onButtonPurgeClick}
            size="large"
            width={150}
            uppercase
            disabled={buttonPurgeDisabled}
          >
            Purge
          </Button>
        </div>
      </div>
    </PurgeFormStyle>
  )
}

export default PurgeForm
