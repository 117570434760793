import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// ImportComponents
import { Button } from '../../Shared';

RuleListItem.propTypes = {
  // from RuleListItem
  type: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  info: PropTypes.array,
  buttonDelete_clickHandler: PropTypes.func,
  push: PropTypes.func,
};

function RuleListItem({
  type, title, img, id, push, collection,
}) {
  const buttonUpdate_clickHandler = function () {
    window.localStorage.setItem('url-name', JSON.stringify(type));
    push(`/etl/inventory-files/${id}/files`);
  };
  return (
    <>
      <a
        onClick={buttonUpdate_clickHandler}
        className="design-rule__title"
      >
        {
          img && (
            <div className="design-rule__preview-box">
              <img src={img} alt={title} className="design-rule__preview" />
            </div>
          )
        }
        <span>{title}</span>
      </a>
      <div className="design-rule__item-info">
        {collection[0]
        && (
          <div key={collection[0].id} className="design-rule__info">
            <span className="design-rule__info-name">Update Method:</span>
            <span className="design-rule__info-value"> {collection[0].inventory_update_method}</span>
          </div>
        )}
        {collection[0]
        && (
          <div key={collection[0].id} className="design-rule__info">
            <span className="design-rule__info-name">Filename:</span>
            <span className="design-rule__info-value"> {collection[0].name}</span>
          </div>
        )}
        {collection[0]
        && (
          <div key={collection[0].id} className="design-rule__info">
            <span className="design-rule__info-name">Last Update Date:</span>
            <span className="design-rule__info-value"> {collection[0].inventory_last_update_date}</span>
          </div>
        )}
        {collection[0]
        && (
          <div key={collection[0].id} className="design-rule__info">
            <span className="design-rule__info-name">Status:</span>
            <span className="design-rule__info-value"> {collection[0].inventory_status}</span>
          </div>
        )}
      </div>

      <div className="design-rule__item-buttons-box">
        <Button
          size="small"
          color="primary"
          width={85}
          onClick={buttonUpdate_clickHandler}
        >
          Show
        </Button>
      </div>
    </>
  );
}

// Enhancer
const mapStateToProps = null;
const mapDispatchToProps = { push };

export default connect(mapStateToProps, mapDispatchToProps)(RuleListItem);
