import { appName } from "../constants"
import { all, put, takeLatest } from "redux-saga/effects"

/**
 * Constants
 **/
export const moduleName = "searchInput"
const prefix = `${appName}/${moduleName}`

const SET_INPUT_VALUE_CALL = `${prefix}/SET_INPUT_VALUE_CALL`
const SET_INPUT_VALUE = `${prefix}/SET_INPUT_VALUE`

const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE"

/**
 * Reducer
 **/
const initialState = {
  searchInputAttributeTagging: "",
  searchInputCategoryRules: "",
  searchInputMergeColors: "",
  searchInputMergeMaterials: "",
  searchRooms: ""
}

export default function reducer(state = initialState, { type, value, fieldName }) {
  switch (type) {
    case SET_INPUT_VALUE:
      return {
        ...state,
        [fieldName]: value
      }

    case ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        ...initialState
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/

/**
 * Action Creators
 **/
export function setInputValue(value, fieldName) {
  return {
    type: SET_INPUT_VALUE_CALL,
    payload: { value, fieldName }
  }
}

/**
 * Sagas
 **/
function* setInputValueSaga({ payload: { value, fieldName } }) {
  yield put({
    type: SET_INPUT_VALUE,
    value,
    fieldName
  })
}

export function* saga() {
  yield all([takeLatest(SET_INPUT_VALUE_CALL, setInputValueSaga)])
}
