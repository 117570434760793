import React from "react"
import PropTypes from "prop-types"

const Input = (props) => {
  const onInputChange = (event) => {
    props.onChange(props.id, event)
  }

  return (
    <input
      className="categories-form__table-input"
      onChange={onInputChange}
      value={props.value}
      name={props.name}
      type="number"
      min={0}
    />
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  id: PropTypes.number
}

export default Input
