import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { uid } from 'uid';
import _set from 'lodash/fp/set';
import { getAddRuleRequest } from '../../../ducks/rule';

// components
import StyledComponents from './style';
import { Button } from '../../Shared';
import DynamicFormFields from './DynamicFormFields';
import MultiSelectCategories from './MultiSelectCategories';

class ModalAddRule extends Component {
  static propTypes = {
    // from parent component
    closeModal: PropTypes.func,
    categories: PropTypes.array,
    onRulesChange: PropTypes.func,
    onDeleteRule: PropTypes.func,
    onAddNewRule: PropTypes.func,
    rules: PropTypes.array,
    tableSelected: PropTypes.array,
    selectedCategories: PropTypes.array,
    getDataTableRequest: PropTypes.func,
  };

  state = {
    rules: [
      {
        id: 'first',
        fields: [
          {
            id: '1', name: 'rule-1', value: '', label: 'name:',
          },
          {
            id: '2', name: 'rule-2', value: '', label: 'value:',
          },
        ],
      },
    ],
    selected_categories: this.props.selectedCategories || [],
  };

  // simple attribute change
  onRulesChange = ({ target: { value } }, ruleId, fieldId) => {
    const { rules } = this.state;

    const newRules = rules.map((rule) => {
      if (rule.id === ruleId) {
        return _set(`fields[${fieldId}].value`, value, rule);
      }
      return rule;
    });

    this.setState({
      rules: newRules,
    });
  };

  onAddNewRule = (event) => {
    event && event.preventDefault && event.preventDefault();
    const { rules } = this.state;
    const id = uid('10');
    const newRules = rules.concat([
      {
        id,
        fields: [
          {
            id: `${id}1`, name: `rule-${id}1`, value: '', label: 'name:',
          },
          {
            id: `${id}2`, name: `rule-${id}2`, value: '', label: 'value:',
          },
        ],
      },
    ]);

    this.setState({
      rules: newRules,
    });
  };

  onDeleteRule = (event, ruleId) => {
    event && event.preventDefault && event.preventDefault();
    const { rules } = this.state;

    const newRules = rules.filter((item) => item.id !== ruleId);
    this.setState({
      rules: newRules,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { rules, selected_categories } = this.state;
    const {
      closeModal, getAddRuleRequest, getDataTableRequest, perPage, numberPage,
    } = this.props;

    const rule = rules.map((rule) => {
      const key = rule.fields[0].value.trim();
      const value = rule.fields[1].value.trim();

      return {
        key,
        value,
      };
    });

    const data = {
      rules: rule,
      selected_categories,
    };

    getAddRuleRequest(data);
    closeModal();
    getDataTableRequest(numberPage, perPage, null);
  };

  getSelectedCategoriesList = (itemsList, itemsIds = []) => {
    const categories_ids = itemsIds.map((category) => category.value);
    if (itemsList !== undefined) {
      return itemsList.filter((item) => categories_ids.some((e) => item.value === e));
    }
  };

  handleCategoryChange = (category) => {
    this.setState({
      selected_categories: category,
    });
  };

  buttonSaveDisabled = () => {
    const { rules } = this.state;

    let disabled = true;

    rules.forEach((rule) => {
      const key = rule.fields[0].value.trim();
      const value = rule.fields[1].value.trim();
      disabled = !!(!key.length || !value.length);
    });

    return disabled;
  };

  render() {
    const { closeModal, categories } = this.props;
    const { rules, selected_categories } = this.state;

    return (
      <StyledComponents>
        <div className="add-attributes__header">

        </div>

        <div className="add-attributes__header">
          <p className="add-attributes__title">Select Category:</p>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <MultiSelectCategories
              categories={categories}
              selected_categories={selected_categories}
              onCategoryChange={this.handleCategoryChange.bind(this)}
              getSelectedCategoriesList={this.getSelectedCategoriesList.bind(this)}
            />
            <div className="invalid-feedback">Please choose a category.</div>
          </div>
        </div>

        <div className="add-attributes__header">

        </div>

        <div className="add-attributes__header">
          <p className="add-attributes__title">New Rule:</p>
          <button className="add-attributes__add-button button" onClick={this.onAddNewRule}>
            +
          </button>
        </div>
        <form noValidate className="add-attributes__form" onSubmit={this.onSubmit}>
          <DynamicFormFields
            rules={rules}
            onRulesChange={this.onRulesChange}
            onDeleteRule={this.onDeleteRule}
          />
          <div className="add-attributes__footer">
            <Button type="button" width={120} onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" width={120} disabled={this.buttonSaveDisabled()}>
              Save
            </Button>
          </div>
        </form>
      </StyledComponents>
    );
  }
}

const mapStateToProps = (state) => ({
  tableSelected: state.tableReducer.attributeTagging,
});

export default connect(
  mapStateToProps,
  { getAddRuleRequest }
)(ModalAddRule);
