import styled from "styled-components"

export default styled.div`
  .design-styles__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    min-width: 1000px;
    max-width: 1200px;
    padding: 30px;
    margin-left:auto;
    margin-right:auto;
  }
  
  .design-styles__form-column {
  flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-left: 30px;
  }
  
  .design-styles__form-column .redux-form__input {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .design-styles__form-column .input__label {
    width: 35%;
  }
  
  .design-styles__form-column .input__field {
    width: 65%;
  }
  
  .design-styles__buttons {
    width: 100%;
    padding-top: 30px;
    text-align: center;
  }
  
  .design-styles__select-box {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .design-styles__select-label {
    width: 35%;
    margin-bottom: 0;
    padding-right: 20px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .design-styles__select {
    width: 65%;
  }
`
