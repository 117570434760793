import React from 'react';
import PropTypes from 'prop-types';

// Misc

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getAvailableDesignStyleOptions } from '../../../../ducks/availableDesignStyles';
import { formName } from '../../../../constants/forms';

// Import Components
import DetailCreateFormStyle from './style';
import { ReduxFormFileUploader, ReduxFormInput, ReduxFormSelect } from '../../../Shared/reduxForm';
import { Button } from '../../../Shared';

DetailCreateForm.propTypes = {
  // from DetailFloorPlans Component
  onSubmit: PropTypes.func.isRequired,
  // from reduxForm
  handleSubmit: PropTypes.func,
  // from connect/availableDesignStylesReducer
  stylesOptions: PropTypes.object,
};

function DetailCreateForm({
  handleSubmit, onSubmit, pristine, submitting, invalid, stylesOptions,
}) {
  return (
    <DetailCreateFormStyle>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="design-styles__form"
      >
        <ReduxFormFileUploader
          name="image_url"
          formName={formName.availableDesignStylesCreateForm}
          width="400px"
          height="400px"
        />
        <div className="design-styles__form-column">
          <ReduxFormInput
            name="name"
            label="Name"
          />
          <div className="design-styles__select-box">
            <p className="design-styles__select-label">Design Rules</p>
            <div className="design-styles__select">
              <ReduxFormSelect
                name="design_style_rules_attributes"
                isMulti
                options={stylesOptions.design_rules}
              />
            </div>
          </div>
          <div className="design-styles__select-box">
            <p className="design-styles__select-label">Colors</p>
            <div className="design-styles__select">
              <ReduxFormSelect
                name="design_style_colors_attributes"
                isMulti
                options={stylesOptions.colors}
              />
            </div>
          </div>
          <div className="design-styles__select-box">
            <p className="design-styles__select-label">Textures</p>
            <div className="design-styles__select">
              <ReduxFormSelect
                name="design_style_textures_attributes"
                isMulti
                options={stylesOptions.textures}
              />
            </div>
          </div>

        </div>
        <div className="design-styles__buttons">
          <Button
            type="submit"
            size="large"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </DetailCreateFormStyle>
  );
}

// Enhance
const mapStateToProps = (state) => ({
  stylesOptions: getAvailableDesignStyleOptions(state),
});

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName.availableDesignStylesCreateForm,
  })
);

export default enhance(DetailCreateForm);
