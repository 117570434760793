import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static addFloorPlan(floorPlanData) {
    return http({
      method: "POST",
      url: `${API}design_templates/floorplans`,
      data: floorPlanData
    })
      .then((res) => res.data)
  }

  static getFloorPlans({ page, perPage }) {
    return http({
      method: "GET",
      url: `${API}design_templates/floorplans?page=${page}&per_page=${perPage}`
    })
      .then((res) => res.data)
  }

  static getDesignRooms() {
    return http({
      method: "GET",
      url: `${API}design_templates/floorplans/design_rooms_list`
    })
      .then((res) => res.data)
  }

  static updateFloorPlan(id, floorPlanData) {
    return http({
      method: "PUT",
      url: `${API}design_templates/floorplans/${id}`,
      data: floorPlanData
    })
      .then((res) => res.data)
  }

  static getFloorPlanById(id) {
    return http({
      method: "GET",
      url: `${API}design_templates/floorplans/${id}/edit`
    })
      .then((res) => res.data)
  }

  static checkDesignRoomFloorPlanById(id, design_rooms) {
    return http({
      method: "POST",
      url: `${API}design_templates/floorplans/${id}/check_using_design_rooms`,
      data: {design_rooms: design_rooms}
    })
      .then((res) => res.data)
  }

  static deleteFloorPlan(id) {
    return http({
      method: "DELETE",
      url: `${API}design_templates/floorplans/${id}`
    })
      .then((res) => res.data)
  }
}
