import styled from "styled-components"

export default styled.div`
  display: flex;
  width: 100%;
  padding: 50px;
  
  .template-package-form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .template-package-form__item {
    width: 100%;
  
    &.part {
      width: 70%;
    }
    
    &.flags {
      width: 30%;
      
      .template-package-form__control {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        
        .template-package-form-switch {
          margin-right: 15px;
        }
        
        .template-package-form__button-wrapper {
          display: flex;
          justify-content: center;
          width: 100%;
          padding-top: 42px;
          padding-left: 50px;
          padding-right: 50px;
        }
      }
    }
    
    &.buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      
      button {
        margin-right: 50px;
      }
    }
  }
  
  .template-package-form__control {
    display: flex;
    align-items: center;  
    margin-bottom: 50px;
    
    > .redux-form__input {
      width: 100%;
      margin-right: 50px;
      
      .input__field {
        width: 100%;
      }
    }
  }
  
  .template-package-form__control-title {
    min-width: 200px;
    margin-bottom: 0;
    padding-right: 20px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .product-list__item-wrapper {
    width: 200px;
    margin-right: 50px;
    margin-bottom: 50px;
    list-style-type: none;
    background-color:#f7f7f7;
    border: 1px solid #ccc;
  }
  
  .product-list__image-wrapper {
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 200px;
  }
  
  .product-list__image {
    display: block;
    width: 100%;
    height:100%;
    max-width: 175px;
    max-height: 175px;
  }
  
  .product-list__caption {
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  
  .product-list__add-button {
    width: 200px;
    height: 200px;
    margin-top: 35px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    font-weight: 400;
    color: #4c4c4c;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .product-list__add-button:hover,
  .product-list__add-button:focus {
    color: #333;
    border-color: #8e8e8e;
  }
  
  .product-list__add-button:active {
    opacity: 0.7;
  }
  
  .product-list__add-button-icon {
    display: block; 
    font-size: 40px;
  }
  
   .product-list__add-button-text {
    display: block;
    font-size: 20px;
    text-transform: uppercase;
  }
`
