import React from "react"

import { BreadcrumbArrow, CurrentChild } from "./BreadcrumbsTools"

export const Breadcrumbs = ({ children }) => {
  const numberOfChildren = React.Children.count(children)
  const childrenWithArrows = React.Children.map(children, (child, index) => {
    return index === numberOfChildren - 1 ? [<CurrentChild name={child.props.name} />] : [child, <BreadcrumbArrow />]
  }).reduce((resultingChildren, child) => resultingChildren.concat(child), [])

  return <div className="breadcrumbs">{childrenWithArrows}</div>
}

export default Breadcrumbs
