import React, { Component } from 'react';
import PerPage from '../Shared/PerPage';

class FilteringSection extends Component {
  changeSearch = (event) => {
    this.props.onSearchChange(event.target.value);
  };

  render() {
    const {
      page: { page },
      pagesAmount,
      productsLog,
      tags,
      q,
    } = this.props;
    const {
      onModalOpen, onPageAmountChange, totalEntriesAmount, activePage, perPage, disableFilter,
    } = this.props;

    return (
      <div className="filter row pl-3 pr-3">
        <div className="filter-left-side col-12 col-lg-6 order-2 order-lg-1">
          {!productsLog && (
            <PerPage
              page={page}
              perPage={perPage}
              activePage={activePage}
              pagesAmount={pagesAmount}
              totalEntriesAmount={totalEntriesAmount}
              onPageAmountChange={onPageAmountChange}
            />
          )}
        </div>
        <div className="filter-right-side col-12 col-lg-6 pr-0 w-100 order-1 order-lg-2">
          <div className="row filter-left-side-search mr-0 w-100 w-100 justify-content-between">
            <input
              type="text"
              className={`filter-left-side-search-field w-100 ${!disableFilter ? 'col-9' : ''}`}
              placeholder={(productsLog || tags) ? 'Search' : 'Search in Furniture'}
              value={q}
              onChange={this.changeSearch}
            />
            {!disableFilter && (
              <button className="filter-right-side-button col-3 " onClick={onModalOpen}>
                FILTER
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FilteringSection;
