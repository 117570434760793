import { call, put, takeLatest, all } from "redux-saga/effects"
import { change } from "redux-form"
import { appName } from "../constants"
import { toastr } from "react-redux-toastr"

/**
 * Constants
 **/
export const moduleName = "filterUploader"
const prefix = `${appName}/${moduleName}`

export const FILE_UPLOADER_CLEAR_REQUEST = `${prefix}/FILE_UPLOADER_CLEAR_REQUEST`

/**
 * Action Creators
 **/
export const filterUploaderClearRequest = (formName, fieldName, value) => {
  return {
    type: FILE_UPLOADER_CLEAR_REQUEST,
    payload: { formName, fieldName, value }
  }
}

/**
 * Sagas
 **/
function* filterUploaderClearSaga({ payload: { formName, fieldName, value = "" } }) {
  try {
    yield put(change(formName, fieldName, value))
  } catch ({ message }) {
    const toastrError = toastr.error
    yield call(toastrError, message)
  }
}

export function* saga() {
  yield all([takeLatest(FILE_UPLOADER_CLEAR_REQUEST, filterUploaderClearSaga)])
}
