import styled from "styled-components"

export default styled.a`
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    text-decoration: none;
  }
  
  &:active {
    opacity: 0.7;
  }
 
  &.link {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 400;
    color: #494949;
  
    &:hover {
      color: #000;
    }
    
    &:hover::before {
      background-color:#000;
    }
    
    &:active::before {
      opacity: 0.7;
    }
    
    &::before {
      content: "";
      position: absolute;
      bottom: 3px;
      left: 5px;
      right: 5px;
      height: 1px;
      background-color:#494949;
      transition: all 0.3s;
    }
  }
  
  &.button {
    padding: 10px 15px;
    line-height: 1;
    color: #fff;
    border-radius: 10px;
    background-color:#494949;
    
    &:hover {
     background-color:  #000;
    }
  }
`
