import React, { PureComponent } from "react"
import PropTypes from "prop-types"

// components
import StyledComponent from "./style"
import { Breadcrumbs, BreadcrumbsItem } from "../index"

class PageHeader extends PureComponent {
  static propTypes = {
    // from parent component
    title: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    buttonsBlock: PropTypes.object
  }

  static defaultProps = {
    title: "",
    breadcrumbs: []
  }

  renderBreadcrumbsItems = this.props.breadcrumbs.map(({ name, to }) => (
    <BreadcrumbsItem key={name} to={to} name={name} />
  ))

  render() {
    const { title, buttonsBlock } = this.props

    return (
      <StyledComponent>
        <div className="page-header__item">
          <h1 className="page-header__title">{title}</h1>
          <Breadcrumbs>{this.renderBreadcrumbsItems}</Breadcrumbs>
        </div>
        <div className="page-header__item buttons">{buttonsBlock}</div>
      </StyledComponent>
    )
  }
}

export default PageHeader
