import { put, call, all, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { appName, POSTFIX } from "../constants"
import Http from "../api/design-templates-rules"
import { replace } from "react-router-redux"

/**
 * Constants
 **/
export const moduleName = "availableRules"
const prefix = `@@${appName}/${moduleName}`

const GET_RULES_REQUEST = `${prefix}/GET_RULES_REQUEST`
const GET_RULE_BY_ID_REQUEST = `${prefix}/GET_RULE_BY_ID_REQUEST`
const CREATE_RULE_REQUEST = `${prefix}/CREATE_RULE_REQUEST`
const UPDATE_RULE_REQUEST = `${prefix}/UPDATE_RULE_REQUEST`
const DELETE_RULE_REQUEST = `${prefix}/DELETE_RULE_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  rules: [],
  rule: {},
  isLoading: false,
  error: null
}

export default function(state = initialState, { type, payload, error }) {
  switch (type) {

    case GET_RULES_REQUEST + POSTFIX.START:
    case GET_RULE_BY_ID_REQUEST + POSTFIX.START:
    case CREATE_RULE_REQUEST + POSTFIX.START:
    case UPDATE_RULE_REQUEST + POSTFIX.START:
    case DELETE_RULE_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_RULES_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_RULE_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        rules: payload.rules
      }

    case GET_RULE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        rule: payload.rule
      }

    case GET_RULES_REQUEST + POSTFIX.FAILURE:
    case GET_RULE_BY_ID_REQUEST + POSTFIX.FAILURE:
    case CREATE_RULE_REQUEST + POSTFIX.FAILURE:
    case UPDATE_RULE_REQUEST + POSTFIX.FAILURE:
    case DELETE_RULE_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableRulesReducer
 **/
export function getAvailableRules(state) {
  return state.availableRulesReducer.rules
}

export function getAvailableRuleById (state) {
  return state.availableRulesReducer.rule
}

export function getIsLoading (state) {
  return state.availableRulesReducer.isLoading
}

/**
 * Action Creators
 **/
export function getAvailableRulesRequest() {
  return { type: GET_RULES_REQUEST }
}

export function getAvailableRuleByIdRequest(ruleId) {
  return {
    type: GET_RULE_BY_ID_REQUEST,
    payload: { ruleId }
  }
}

export function updateAvailableRuleRequest(ruleId, ruleData) {
  return {
    type: UPDATE_RULE_REQUEST,
    payload: { ruleId, ruleData }
  }
}

export function createAvailableRuleRequest(ruleData) {
  return {
    type: CREATE_RULE_REQUEST,
    payload: { ruleData }
  }
}

export function deleteAvailableRuleRequest(ruleId) {
  return {
    type: DELETE_RULE_REQUEST,
    payload: { ruleId }
  }
}

/**
 * Sagas
 **/
function* getAvailableRulesSaga() {
  yield put({ type: GET_RULES_REQUEST + POSTFIX.START })
  try {
    const rules = yield call(() => Http.getRules())
    yield put({
      type: GET_RULES_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { rules }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_RULES_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* getAvailableRuleByIdSaga({ payload: { ruleId } }) {
  yield put({ type: GET_RULE_BY_ID_REQUEST + POSTFIX.START })
  try {
    const rule = yield call(() => Http.getRuleById(ruleId))
    yield put({
      type: GET_RULE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { rule }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_RULE_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* createAvailableRuleSaga({ payload: { ruleData } }) {
  yield put({ type: CREATE_RULE_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.addRule(ruleData))
    yield call(() => toastr.success("Successfully created"))
    yield put(replace("/design-templates/detail/design-templates"))
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: CREATE_RULE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* updateAvailableRuleSaga({ payload: { ruleId, ruleData } }) {
  yield put({ type: UPDATE_RULE_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.updateRule(ruleId, ruleData))
    yield call(() => toastr.success("Successfully updated"))
    yield put(replace("/design-templates/detail/design-templates"))
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: UPDATE_RULE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* deleteAvailableRuleSaga({ payload: { ruleId } }) {
  yield put({ type: DELETE_RULE_REQUEST + POSTFIX.START })
  try {
    const rules = yield call(() => Http.deleteRule(ruleId))
    yield call(() => toastr.success("Successfully deleted"))
    yield put({
      type: DELETE_RULE_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { rules }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: DELETE_RULE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_RULES_REQUEST, getAvailableRulesSaga),
    takeLatest(CREATE_RULE_REQUEST, createAvailableRuleSaga),
    takeLatest(GET_RULE_BY_ID_REQUEST, getAvailableRuleByIdSaga),
    takeLatest(UPDATE_RULE_REQUEST, updateAvailableRuleSaga),
    takeLatest(DELETE_RULE_REQUEST, deleteAvailableRuleSaga)
  ])
}
