import styled from "styled-components"

export default styled.div`
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);

  .modal-window__window {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 750px;
    min-height: 150px;
    padding: 10px 45px 35px 45px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }

  .modal-window__button-close {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 45px;
    height: 45px;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 35px;
      height: 2px;
      background-color: #6b6b6b;
      transition: all 0.3s;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:hover::before,
    &:focus::before,
    &:hover::after,
    &:focus::after {
      background-color: #000;
    }

    &:active::before,
    &:active::after {
      opacity: 0.7;
    }
  }
`
