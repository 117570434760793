import React from "react"

// Misc
import { formName } from "../../../../../constants/forms"

// HOCs
import { reduxForm } from "redux-form"

// Import Component
import { ReduxFormInput, ReduxFormSwitch } from "../../../../Shared/reduxForm"

function TemplateInfoForm() {
  return (
    <form noValidate className="info-form">
      <div className="info-form__control full-size">
        <ReduxFormInput name="name" />
      </div>
      <div className="info-form__control switch">
        <div className="info-form__switch-title">Active</div>
        <ReduxFormSwitch name="active" />
      </div>
      <div className="info-form__control switch">
        <div className="info-form__switch-title">Default</div>
        <ReduxFormSwitch name="is_default" />
      </div>
    </form>
  )
}

export default reduxForm({
  form: formName.templateCreateInfoForm
})(TemplateInfoForm)
