import { put, takeLatest, all } from "redux-saga/effects"
import { appName } from "../constants"

/**
 * Constants
 **/
export const moduleName = "table"
const prefix = `${appName}/${moduleName}`

const SET_SELECTED_CALL = `${prefix}/SET_SELECTED_CALL`
const SET_SELECTED = `${prefix}/SET_SELECTED`
const CLEAR_SELECTED_CALL = `${prefix}/CLEAR_SELECTED_CALL`
const CLEAR_SELECTED = `${prefix}/CLEAR_SELECTED`

/**
 * Reducer
 **/
const initialState = {
  attributeTagging: [],
  rules: [],
  rooms: []
}

export default function reducer(state = initialState, { type, selected, name }) {
  switch (type) {
    case SET_SELECTED:
      return {
        ...state,
        [name]: [...selected]
      }

    case CLEAR_SELECTED:
      return {
        ...state,
        [name]: []
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/
export function getTableSelected(state) {
  return state.tableReducer
}

/**
 * Action Creators
 **/
export function setTableSelected(selected, name) {
  return {
    type: SET_SELECTED_CALL,
    payload: { selected, name }
  }
}

export function clearTableSelected() {
  return { type: CLEAR_SELECTED_CALL }
}

/**
 * Sagas
 **/
function* setTableSelectedSaga({ payload: { selected, name } }) {
  yield put({
    type: SET_SELECTED,
    selected,
    name
  })
}

function* clearTableSelectedSaga() {
  yield put({ type: CLEAR_SELECTED })
}

export function* saga() {
  yield all([takeLatest(SET_SELECTED_CALL, setTableSelectedSaga)])
  yield all([takeLatest(CLEAR_SELECTED_CALL, clearTableSelectedSaga)])
}
