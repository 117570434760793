import styled from "styled-components"

export default styled.section`
  width: 100%;
  min-width: 1030px;
  font-family: "Roboto", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 100;
  font-size: 17px;
  letter-spacing: 1px;
  color: #595959;
 
  
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 30px 30px;
    background-color: #ccc;
  }
  
  .page-title {
    text-transform: uppercase;
  }
  
  .page-body {
    max-width: 100%;
    padding-bottom: 20px;
    overflow-y: auto;
  }
`
