import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Router
import { push } from 'react-router-redux';
import { withRouter } from 'react-router-dom';
import { savePreviousUrlAction } from '../../../../../../ducks/router';

// Import Components
import ListItem from './ProductListItem';
import { saveRoomTypeId } from '../../../../../../ducks/room';

ProductList.propTypes = {
  // from TemplatePackageForm Component
  furniture: PropTypes.array.isRequired,
};

function ProductList({
  savePreviousUrlAction,
  saveRoomTypeId,
  furniture = [],
  push,
  variantId,
  match: {
    url,
    params: { id },
  },
}) {
  const designRoomid = furniture[0].design_room_id ? furniture[0].design_room_id : null;

  const buttonAddProductClick = () => {
    const variantIndex = window.localStorage.getItem('variantIndex') || 0;
    savePreviousUrlAction(url, variantIndex);
    saveRoomTypeId(designRoomid);
    push(`/rooms/${id}/variant/${variantId}/add-product`);
  };

  const listElements = furniture
    && furniture.map((el, index) => (
      <Fragment key={index}>
        {el.values
            && el.values.map((item) => (
              <li key={item.id} className="product-list__item-wrapper">
                <ListItem item={item} />
              </li>
            ))}
      </Fragment>
    ));

  return (
    <ul className="product-list">
      {listElements}
      <button className="product-list__add-button" type="button" onClick={buttonAddProductClick}>
        <span className="product-list__add-button-icon">+</span>
        <span className="product-list__add-button-text">Add product</span>
      </button>
    </ul>
  );
}

// Enhance
const mapStateToProps = null;
const mapDispatchToProps = { push, savePreviousUrlAction, saveRoomTypeId };

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(ProductList);
