import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// Actions
import { withRouter } from 'react-router-dom';
import { push } from 'react-router-redux';
import { reduxForm } from 'redux-form';
import _get from 'lodash/fp/get';
import {
  deleteAvailableTemplateAction,
  getAvailableTemplateSelector,
  cloneTemplateAction,
} from '../../../../../ducks/availableTemplates';
// Router

// Misc
import { formName } from '../../../../../constants/forms';

// HOCs

// Import Component
import { ReduxFormInput, ReduxFormSwitch } from '../../../../Shared/reduxForm';
import { Button } from '../../../../Shared';

function TemplateEditInfoForm({ cloneTemplateAction, deleteAvailableTemplateAction, match: { params: { id } } }) {
  const buttonDeleteClick = () => {
    deleteAvailableTemplateAction(id);
  };

  const buttonDuplicateClick = () => {
    cloneTemplateAction(id);
  };

  return (
    <form noValidate className="info-form">
      <div className="info-form__control full-size">
        <ReduxFormInput name="name" />
      </div>
      <div className="info-form__control switch">
        <div className="info-form__switch-title">Active</div>
        <ReduxFormSwitch name="active" />
      </div>
      <div className="info-form__control switch">
        <div className="info-form__switch-title">Default</div>
        <ReduxFormSwitch name="is_default" />
      </div>
      <div className="info-form__buttons">
        <Button size="large" color="danger" onClick={buttonDeleteClick}>Delete template</Button>
        <Button size="large" onClick={buttonDuplicateClick}>Duplicate</Button>
      </div>
    </form>
  );
}

// Enhance
const mapStateToProps = (state) => {
  const template = getAvailableTemplateSelector(state);
  const initialValues = {
    name: _get('name', template),
    active: _get('active', template),
    is_default: _get('room.default?', template),
  };

  return {
    initialValues,
  };
};
const mapDispatchToProps = { deleteAvailableTemplateAction, push, cloneTemplateAction };

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: formName.templateEditPackageFormHeader,
    enableReinitialize: true,
  })
);

export default enhance(TemplateEditInfoForm);
