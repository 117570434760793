import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  getRoomVariant,
  currentRoomVariantSelector, loadingForVariantSelector,
} from '../../../../ducks/moveDesignRoom';

class MoveCheckbox extends Component {
  state = {
    product: this.props.product,
    roomVariant: null,
    room: this.props.room,
    room_id: this.props.room_id,
    variant_id: this.props.variant_id,
    design_room: null,
    design_room_id: this.props.design_room_id,
    is_checked: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      push('/login');
    }

    this.setState({
      isMounted: true,
    });
  }

  handleChangeMovedProduct = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    this.props.onMoveChecked(
      this.props.variant_id,
      'move',
      this.props.product.id,
      this.props.room.design_room_id,
      this.props.product.is_checked_for_move = value
    );
  };

  render() {
    return (
      <label className="checkbox">
        <input
          type="checkbox"
          value={this.state.is_checked}
          className="checkbox__input"
          onChange={this.handleChangeMovedProduct}
        />
        <span className="checkbox__imitation" />
      </label>
    );
  }
}

export default connect(
  (state) => ({
    roomVariant: currentRoomVariantSelector(state),
    loadingVariant: loadingForVariantSelector(state),
  }),
  { getRoomVariant, push }
)(MoveCheckbox);

MoveCheckbox.propTypes = {
  product: PropTypes.object.isRequired,
  onMoveChecked: PropTypes.func.isRequired,
};
