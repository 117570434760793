import React, { Fragment } from "react"
import PropTypes from "prop-types"
import cn from "classnames"

Input.propTypes = {
  // from ReduxFormInput Component
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
}

Input.defaultProps = {
  type: "text"
}

function Input({ input: { value, name, onChange, ...restInput }, type, meta, defaultValue = "", placeholder }) {
  let initValue = value ? value : defaultValue

  if (meta.active) {
    initValue = value
  }

  const classnames = cn("input__field", {
    error: meta.error && meta.touched
  })

  return (
    <Fragment>
      <input
        {...restInput}
        value={initValue}
        onChange={onChange}
        type={type}
        id={`${name}-id`}
        className={classnames}
        placeholder={placeholder}
      />
      {(meta.error && meta.touched) && <span className="input__error">{meta.error}</span>}
    </Fragment>
  )
}

export default Input
