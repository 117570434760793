import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// ImportComponents
import { toastr } from 'react-redux-toastr';
import { Button } from '../../Shared';

RuleListItem.propTypes = {
  // from RuleListItem
  type: PropTypes.string,
  clone: PropTypes.bool,
  title: PropTypes.string,
  img: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  info: PropTypes.array,
  buttonDelete_clickHandler: PropTypes.func,
  // from connect/react-router-redux
  push: PropTypes.func,
};

const getFloorPlanName = function (imgUrl) {
  if (!imgUrl) return <span>&mdash;</span>;

  const array = imgUrl.split('/');
  const imgName = array[array.length - 1]
    .split('.')
    .slice(0, -1);

  return imgName;
};

function RuleListItem({
  type, clone, title, img, id, buttonDelete_clickHandler, push, info, designStylesInfo,
}) {
  const buttonDel_clickHandler = function () {
    const toastrConfirmOptions = {
      onOk: () => buttonDelete_clickHandler(id),
      onCancel: () => {},
    };
    toastr.confirm('Are you sure?', toastrConfirmOptions);
  };

  const buttonUpdate_clickHandler = function () {
    window.localStorage.setItem('url-name', JSON.stringify(type));
    push(`/design-templates/detail/${type}/${id}`);
  };

  const renderInfo = info && info.map(({ key, count, design_room_id }) => (
    <div key={design_room_id} className="design-rule__info">
      <span className="design-rule__info-name">{key}:</span>
      <span className="design-rule__info-value"> {count}</span>
    </div>
  ));

  const renderColorDesignStylesInfo = designStylesInfo.design_style_colors && designStylesInfo.design_style_colors.map(({ color_id, label }) => (
    <div key={color_id} className="design-rule__info">
      <span className="design-rule__info-name">Color:</span>
      <span className="design-rule__info-value"> {label}</span>
    </div>
  ));

  const renderTexturesDesignStylesInfo = designStylesInfo.design_style_textures && designStylesInfo.design_style_textures.map(({ texture_id, label }) => (
    <div key={texture_id} className="design-rule__info">
      <span className="design-rule__info-name">Texture:</span>
      <span className="design-rule__info-value"> {label}</span>
    </div>
  ));

  const renderRulesDesignStylesInfo = designStylesInfo.design_style_rules && designStylesInfo.design_style_rules.map(({ value, label }) => (
    <div key={value} className="design-rule__info">
      <span className="design-rule__info-name">Design rule:</span>
      <span className="design-rule__info-value"> {label}</span>
    </div>
  ));

  return (
    <>
      <a
        onClick={buttonUpdate_clickHandler}
        className="design-rule__title"
      >
        {
          img && (
            <div className="design-rule__preview-box">
              <img src={img} alt={title} className="design-rule__preview" />
            </div>
          )
        }
        <span>{title}</span>
      </a>
      <div className="design-rule__item-info">
        {info && (
          <div className="design-rule__info">
            <span className="design-rule__info-name">Name:</span>
            <span className="design-rule__info-value"> {getFloorPlanName(img)}</span>
          </div>
        )}
        {info && renderInfo}
        {designStylesInfo.design_style_colors && renderColorDesignStylesInfo}
        {designStylesInfo.design_style_textures && renderTexturesDesignStylesInfo}
        {designStylesInfo.design_style_rules && renderRulesDesignStylesInfo}
      </div>
      <div className="design-rule__item-buttons-box">
        <Button
          size="small"
          color="primary"
          width={85}
          onClick={buttonUpdate_clickHandler}
        >
          Edit
        </Button>
        <Button
          size="small"
          color="danger"
          width={85}
          onClick={buttonDel_clickHandler}
        >
          Delete
        </Button>
        {clone && (
          <Button
            size="small"
            color="secondary"
            width={85}
          >
            Clone
          </Button>
        )}
      </div>
    </>
  );
}

// Enhancer
const mapStateToProps = null;
const mapDispatchToProps = { push };

export default connect(mapStateToProps, mapDispatchToProps)(RuleListItem);
