import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import _get from 'lodash/fp/get';
import {
  fetchFurnitureCategoriesAction,
  getLoadingSelector,
  getRoomTypeAction,
} from '../../../ducks/roomType';

import RoomTypeLayout from '../Layout';
import CreateForm from '../CreateForm';
import EditForm from '../EditForm';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: props.match.params.id !== '0',
    };
  }

  componentDidMount() {
    this.getItem();
  }

  getItem = () => {
    const { isEditMode } = this.state;
    const { getRoomType, fetchFurnitureCategories } = this.props;

    if (isEditMode) {
      getRoomType();
    } else {
      fetchFurnitureCategories();
    }
  };

  render() {
    const { isLoading, buttonGoBackOnClick } = this.props;
    const { isEditMode } = this.state;

    return (
      <RoomTypeLayout
        isLoading={isLoading}
        pageTitle="Room Types"
        buttonTitle="Go back"
        getItem={this.getItem}
        button_clickHandler={buttonGoBackOnClick}
      >
        {isEditMode ? <EditForm /> : <CreateForm />}
      </RoomTypeLayout>
    );
  }
}

Detail.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  buttonGoBackOnClick: PropTypes.func.isRequired,
  getRoomType: PropTypes.func.isRequired,
  fetchFurnitureCategories: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: getLoadingSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  buttonGoBackOnClick: () => dispatch(goBack()),
  getRoomType: () => {
    const roomTypeId = _get('match.params.id', ownProps);
    dispatch(getRoomTypeAction(roomTypeId));
  },
  fetchFurnitureCategories: () => dispatch(fetchFurnitureCategoriesAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
