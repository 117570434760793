import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import equal from 'deep-equal';

import qs from '../../modules/normalizingQs';
import { getTags, tagsSelector, deleteItems } from '../../ducks/tags';
import { closeModal, showModal } from '../../ducks/modal';

import FilteringSection from '../../Components/Filtering';
import TopTagSection from '../../Components/Shared/TopTagSection';
import Pagination from '../../Components/Shared/Pagination';
import Spinner from '../../Components/Shared/Spinner';
import TagsTable from '../../Components/Shared/TagsTable';

import { WAIT_INTERVAL } from '../../constants';

const renderColumns = [
  'checkbox',
  'id',
  'name',
  'description',
  'active',
  'created_at',
  'updated_at',
];

const breadcrumbsItems = [
  {
    to: '/',
    name: 'rooms',
  },
  {
    to: '/tags',
    name: 'tags',
  },
];

const basicStateValues = {
  selected: [],
  q: undefined,
  sort: undefined,
};

export class Tags extends Component {
  state = {
    ...basicStateValues,
    params: {
      page: this.props.history.location.search,
    },
    modalIsOpen: false,
    q: undefined,
    sort: undefined,
    isDisabled: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }
    const { getTags } = this.props;

    const {
      history: {
        location: { search },
      },
    } = this.props;
    const { q } = this.getQueryParamsFromUrl();
    this.setState({ q });
    const requestParams = `/tags${search || `?${qs.stringify({ page: 1 })}`}`;
    this.changeSelected([]);
    getTags(requestParams);
  }

  getQueryParamsFromUrl = () => {
    const urlParse = qs.parse(this.props.history.location.search);

    return {
      page: urlParse.page || 1,
      per_page: urlParse.perPage || this.getPerPage(),
      q: urlParse.q,
      sort: urlParse.sort,
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilter(nextProps);
    this.setCurrentPage(nextProps);
  }

  getPerPage = () => {
    const { tags } = this.props;

    return tags.loaded && tags.tagsData.meta.perPage;
  };

  onViewAll = () => {
    const { getTags, push, location } = this.props;
    const perPage = this.getPerPage();

    this.setState({
      ...this.state,
      q: undefined,
      sort: undefined,
    });

    push(`${location.pathname}?page=1&per_page=${perPage}${location.hash}`);
    getTags(`/tags?page=1&per_page=${perPage}`);
  };

  onParamsChange = (params) => {
    const {
      push,
      location: { pathname, hash },
    } = this.props;
    const queryParams = qs.stringify({ ...this.getQueryParamsFromUrl(), ...params });
    push(`${pathname}?${queryParams}${hash}`);
  };

  handleSearchChange = (q) => {
    clearTimeout(this.timer);

    this.setState({ q: q.length ? q : undefined });

    this.timer = setTimeout(this.onSearchChange, WAIT_INTERVAL);
  };

  handleTagEdit = (tagId) => {
    const { push } = this.props;
    push(`/tags/${tagId}`);
  };

  onSearchChange = () => {
    const { q } = this.state;
    this.onParamsChange({ q });
  };

  onSortChange = (sort) => {
    this.setState(() => ({ sort }));
    this.onParamsChange({ sort });
  };

  onPageChange = (page) => {
    this.setState(() => ({ page }));
    this.onParamsChange({ page });
  };

  onPageAmountChange = (perPage) => {
    this.onParamsChange({ per_page: perPage, page: 1 });
  };

  changeSelected = (items) => {
    this.setState(() => ({ selected: items }));
  };

  onRemoveItem = () => {
    const { selected } = this.state;
    const { deleteItems, history } = this.props;

    const updatedSelects = selected.map((item) => `tags_ids[]=${item}`).join('&');

    if (this.withConfirmation()) {
      deleteItems(updatedSelects, history.location.search);

      this.setState(() => ({ selected: [] }));
    }
  };

  withConfirmation = () => window.confirm('Remove element(s) ?');

  setFilter(nextProps) {
    const { search } = nextProps.history.location;
    this.setState(() => ({
      params: {
        page: search,
      },
      q: qs.parse(search).q || undefined,
    }));
  }

  setCurrentPage(nextProps) {
    const { page } = this.state.params;
    const { search } = nextProps.history.location;

    if (!equal(page, search)) {
      const requestParams = `/tags${search}`;
      this.props.getTags(requestParams);
    }
  }

  render() {
    const { params, selected, q } = this.state;
    const {
      history: {
        location: { search },
      },
      tags,
    } = this.props;

    const pageLoaded = tags.loaded;
    const tableLoaded = tags.tableLoaded === true;
    const pages = pageLoaded && Math.ceil(tags.tagsData.meta.totalEntries / tags.tagsData.meta.perPage);
    const noResults = pageLoaded && tags.tagsData.data.length === 0;

    if (!pageLoaded) {
      return (
        <div className="tags-page">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="tags-page">
        <div className="tags-page-content">
          <TopTagSection
            pageTitle="tags"
            breadcrumbs={breadcrumbsItems}
            onRemoveItem={this.onRemoveItem}
            isDisabled={selected.length === 0}
            shouldCreate
          />
          <FilteringSection
            page={params}
            q={q || ''}
            productsLog={false}
            tags
            onModalOpen={this.openModal}
            onSearchChange={this.handleSearchChange}
            perPage={tags.tagsData.meta.perPage}
            activePage={+qs.parse(search).page || 1}
            totalEntriesAmount={tags.tagsData.meta.totalEntries}
            pagesAmount={tags.tagsData.meta.perPage}
            onPageAmountChange={this.onPageAmountChange}
          />
          <TagsTable
            type="tags"
            noResults={noResults}
            tableLoaded={tableLoaded}
            renderColumns={renderColumns}
            items={tags.tagsData.data}
            selected={selected}
            handleSelectItems={this.changeSelected}
            onEditItem={this.handleTagEdit}
            onSortChange={this.onSortChange}
          />
          <Pagination
            pages={pages}
            activePage={+qs.parse(search).page}
            onPageChange={(page) => this.onPageChange(page)}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ tags, authReducer, routeReducer }) => ({
    tags: {
      tagsData: tagsSelector(tags.tags),
      loading: tags.loading,
      loaded: tags.loaded,
      tableLoaded: tags.tableLoaded,
    },
    isAuthenticated: authReducer.isAuthenticated,
    prevUrl: routeReducer.url,
  }),
  {
    getTags,
    showModal,
    closeModal,
    deleteItems,
    push,
  }
)(Tags);
