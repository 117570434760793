import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable/dist/immutable';
import CheckboxTree from 'react-checkbox-tree';

class FilterModal extends Component {
  state = {
    categories: this.props.categories,
    colors: this.props.colors,
    countries: this.props.countries,
    currency: this.props.currency || 'USD',
    expandedCategories: [],
    price_ranges: this.props.price_ranges,
    suppliers: this.props.suppliers,
  };

  componentDidMount() {
    this.setState({
      price_ranges: this.convertPricesFromBE(this.props.price_ranges),
    });
  }

  convertPricesFromBE = (price_ranges) => {
    const results = [];
    let i = 0;
    if (price_ranges[i] === '900') {
      results.push(price_ranges[0]);
      i++;
    }
    for (i; i < price_ranges.length; i += 2) {
      price_ranges[i] && results.push(`${price_ranges[i]}-${price_ranges[i + 1]}`);
    }
    return results;
  };

  convertPricesForBE = (price_ranges) => {
    const newPrices = [];
    price_ranges.map((e) => e.split('-')).forEach((e) => (e[1] ? newPrices.push(e[0], e[1]) : newPrices.push(e[0])));
    return newPrices;
  };

  getCurrenciesList = (currencies) => currencies.map((item, index) => ({
    label: item.value,
    value: index,
  }));

  getPricesList = (price_ranges) => price_ranges.map((item) => ({
    label: item.value[1] ? `${item.value[0]}-${item.value[1]}` : item.value[0],
    value: item.value[1] ? `${item.value[0]}-${item.value[1]}` : item.value[0],
  }));

  changeValues = (key, val) => {
    this.setState({ [key]: val });
  };

  saveChanges = () => {
    const newState = fromJS(this.state).toJS();

    newState.price_ranges = this.convertPricesForBE(newState.price_ranges);

    this.props.controlFunc(newState);
  };

  render() {
    const {
      categories, colors, suppliers, countries, price_ranges,
    } = this.props.filters;
    const pricesList = this.getPricesList(price_ranges);

    return (
      <>
        <section className="filters">
          {categories && (
            <div className="filters__one-column">
              <h2 className="filters__title">Category</h2>
              <CheckboxTree
                nodes={categories}
                checked={this.state.categories}
                expanded={this.state.expandedCategories}
                expandOnClick
                onlyLeafCheckboxes={false}
                // it's required for expandOnClick
                onClick={() => {}}
                onCheck={(value) => this.changeValues('categories', value)}
                onExpand={(value) => this.changeValues('expandedCategories', value)}
              />
            </div>
          )}
          {suppliers && (
            <div className="filters__one-column">
              <h2 className="filters__title">Suppliers</h2>
              <CheckboxTree
                nodes={suppliers}
                checked={this.state.suppliers}
                onCheck={(value) => this.changeValues('suppliers', value)}
              />
            </div>
          )}
          {colors && (
            <div className="filters__one-column">
              <h2 className="filters__titl e">Colors</h2>
              <CheckboxTree
                nodes={colors}
                checked={this.state.colors}
                onCheck={(value) => this.changeValues('colors', value)}
              />
            </div>
          )}
          {countries && (
            <div className="filters__one-column">
              <h2 className="filters__title">Country</h2>
              <CheckboxTree
                nodes={countries}
                checked={this.state.countries}
                onCheck={(value) => this.changeValues('countries', value)}
              />
            </div>
          )}
          {pricesList && (
            <div className="filters__one-column filters__one-column--no-wrap">
              <h2 className="filters__title">Price Range</h2>
              <CheckboxTree
                nodes={pricesList}
                checked={this.state.price_ranges}
                onCheck={(value) => this.changeValues('price_ranges', value)}
              />
            </div>
          )}
        </section>
        <button className="filters__apply-btn" type="button" onClick={this.saveChanges}>
          Apply
        </button>
      </>
    );
  }
}

FilterModal.propTypes = {
  categories: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  controlFunc: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  price_ranges: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
};

FilterModal.defaultProps = {
  currencies: [],
  price_ranges: [],
};

export default FilterModal;
