import React from 'react';
import PropTypes from 'prop-types';
// Utils
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import { syncValidate } from '../../Shared/reduxForm/sync-validate';
// Redux
import {
  cloneDesignRulesAction,
  createDesignRulesAction,
  deleteDesignRulesAction,
  getRuleErrors,
  getRuleOptions,
} from '../../../ducks/designRuleMatching';
// Components
import { ReduxFormSelect } from '../../Shared/reduxForm';
import { Button, Link } from '../../Shared';
import UpdateErrorIcon from './UpdateErrorIcon';
import UpdateSuccessIcon from './UpdateSuccessIcon';

function FieldsArray({
  fields, ruleOptions, createDesignRulesAction, deleteDesignRulesAction, cloneDesignRulesAction, rules, invalid, updateErrors,
}) {
  const buttonCreateNewRuleClick = () => createDesignRulesAction();

  const rulesBody = fields.map((rule, index) => {
    const ruleId = `${rule}.${index}`;
    const { templates = [], floorplans = [], design_styles = [] } = ruleOptions;

    const linkDeleteClick = () => {
      const toastrConfirmOptions = {
        onOk: () => deleteDesignRulesAction(rules[index].value),
        onCancel: () => {},
      };
      toastr.confirm('Are you sure?', toastrConfirmOptions);
    };

    const linkDuplicateRuleClick = () => {
      const toastrConfirmOptions = {
        onOk: () => cloneDesignRulesAction(rules[index].value),
        onCancel: () => {},
      };
      toastr.confirm('Are you sure?', toastrConfirmOptions);
    };

    return (
      <li key={index} data-id={ruleId} className="form-list-item">
        {updateErrors ? (
          <div className="form-col-icon">
            {updateErrors[index].length ? <UpdateErrorIcon /> : <UpdateSuccessIcon />}
          </div>
        ) : null}
        <div className="form-col">
          <p className="form-col-title">Select a template*</p>
          <div className="form-col-control">
            <ReduxFormSelect name={`${rule}.template`} options={templates} validate={[syncValidate.required]} />
          </div>
          <div className="form-col-links">
            <Link type="button" onClick={linkDuplicateRuleClick}>Duplicate Rule</Link>
            <Link onClick={linkDeleteClick}>Delete</Link>
          </div>
        </div>
        <span className="form-list-item-divider">=</span>
        <div className="form-col">
          <div className="form-col-title">
            <p className="form-col-title">Floor plan*</p>
            <div className="form-col-control">
              <ReduxFormSelect name={`${rule}.floorplan`} options={floorplans} validate={[syncValidate.required]} />
            </div>
          </div>
        </div>
        <span className="form-list-item-divider">+</span>
        <div className="form-col">
          <div className="form-col-title">
            <p className="form-col-title">Design Style*</p>
            <div className="form-col-control">
              <ReduxFormSelect
                isMulti
                name={`${rule}.design_styles`}
                options={design_styles}
                validate={[
                  syncValidate.required,
                  syncValidate.onlyThreeRules,
                ]}
              />
            </div>
          </div>
        </div>
      </li>
    );
  });

  return (
    <ul className="form-list">
      {rulesBody}
      <li className="form-list-item">
        <div className="form-list-buttons-wrapper">
          <Button size="large" color="warning" width={200} onClick={buttonCreateNewRuleClick}>
            Create New Rule
          </Button>
          {!!fields.length && (
            <Button size="large" width={200} type="submit" disabled={invalid}>
              Save changes
            </Button>
          )}
        </div>
      </li>
    </ul>
  );
}

FieldsArray.propTypes = {
  fields: PropTypes.object,
  ruleOptions: PropTypes.object,
  createDesignRulesAction: PropTypes.func,
  deleteDesignRulesAction: PropTypes.func,
  invalid: PropTypes.bool,
  updateErrors: PropTypes.array,
};

// Enhancer
const mapStateToProps = (state) => ({
  ruleOptions: getRuleOptions(state),
  updateErrors: getRuleErrors(state),
});

const mapDispatchToProps = {
  push, createDesignRulesAction, deleteDesignRulesAction, cloneDesignRulesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldsArray);
