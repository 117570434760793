import React, { Component } from "react"
import PropTypes from "prop-types"
import { push } from "react-router-redux"

class StockCheckbox extends Component {
  state = {
    is_checked: false
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      push("/login")
    }

    this.setState({
      isMounted: true,
      is_checked: this.props.value
    })
  }

  handleCheckboxChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.props.onStockAvailability(value)
    this.setState({
      is_checked: value
    })
  }

  render() {
    return (
      <label className={"checkbox_in_stock"}>
        <input
          type={"checkbox"}
          checked={this.state.is_checked || this.props.value}
          className={"checkbox__input_in_stock"}
          onChange={this.handleCheckboxChange}
        />
        <span className={"checkbox__imitation_in_stock"} />
        {this.props.title && <span className={"checkbox__label_in_stock"}>{this.props.title}</span>}
      </label>
    )
  }
}

StockCheckbox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onStockAvailability: PropTypes.func.isRequired
}

export default StockCheckbox
