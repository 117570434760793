import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { searchFilterRequest } from '../../../ducks/filters';

// components
import StyledComponent from './style';
import FilterItem from './FilterItem';
import { ModalWindow, Button } from '../../Shared';

class ModalFilterRule extends Component {
  static propTypes = {
    // from TableFilters component
    closeModal: PropTypes.func,
    // from connect
    filtersData: PropTypes.object,
  };

  state = {
    categoriesChecked: [],
    categoriesExpanded: [],
    colorsChecked: [],
    colorsExpanded: [],
    suppliersChecked: [],
    suppliersExpanded: [],
    materialsChecked: [],
    materialsExpanded: [],
  };

  onButtonApplyClick = (event) => {
    event && event.preventDefault && event.preventDefault();
    const {
      categoriesChecked, colorsChecked, suppliersChecked, materialsChecked,
    } = this.state;
    const {
      searchFilterRequest, page, entriesPerPage, inputSearch, closeModal,
    } = this.props;

    const data = {
      page,
      per_page: entriesPerPage,
      q: inputSearch,
      categories: categoriesChecked,
      colors: colorsChecked,
      suppliers: suppliersChecked,
      materials: materialsChecked,
    };

    searchFilterRequest(data);
    closeModal();
  };

  onChangeFilter = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const { closeModal, filtersData } = this.props;
    const {
      categoriesChecked,
      categoriesExpanded,
      colorsChecked,
      colorsExpanded,
      materialsChecked,
      materialsExpanded,
      suppliersChecked,
      suppliersExpanded,
    } = this.state;

    return (
      <ModalWindow closeModal={closeModal}>
        <StyledComponent>
          <h1 className="modal-filter__title">Product filter</h1>
          <div className="modal-filter__filters">
            <FilterItem
              title="Categories"
              data={filtersData.categories}
              dataChecked={categoriesChecked}
              dataExpanded={categoriesExpanded}
              onChangeFilter={this.onChangeFilter}
            />
            <FilterItem
              title="Colors"
              data={filtersData.colors}
              dataChecked={colorsChecked}
              dataExpanded={colorsExpanded}
              onChangeFilter={this.onChangeFilter}
            />
            <FilterItem
              title="Suppliers"
              data={filtersData.suppliers}
              dataChecked={suppliersChecked}
              dataExpanded={suppliersExpanded}
              onChangeFilter={this.onChangeFilter}
            />
            <FilterItem
              title="Materials"
              data={filtersData.materials}
              dataChecked={materialsChecked}
              dataExpanded={materialsExpanded}
              onChangeFilter={this.onChangeFilter}
            />
          </div>
          <div className="modal-filter__footer">
            <Button width={150} onClick={this.onButtonApplyClick}>
              Apply
            </Button>
          </div>
        </StyledComponent>
      </ModalWindow>
    );
  }
}

const mapStateToProps = (state) => ({
  filtersData: state.filtersReducer.filtersData,
  page: state.paginationReducer.value,
  entriesPerPage: state.selectorReducer.value,
  inputSearch: state.searchInputReducer.value,
});

export default connect(
  mapStateToProps,
  { searchFilterRequest }
)(ModalFilterRule);
