import React, {
  useCallback, useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { compose } from 'redux';
import Select from 'react-select';

import { goBack } from 'react-router-redux';
import {
  updateSupplier,
  numberAtributesOnCreateDeliveryAgent,
  supplierObject,
} from '../../constants/createHelper';
import { suppliersReducerShape } from '../../constants/shapes';

import {
  getSupplierByIdRequest,
  getSuppliersSelector,
  getZonesRequest,
  updateSupplierRequest,
} from '../../ducks/suppliers';

import withModal from '../../Components/HOCs/modal';
import {
  Button,
  Input,
  Select as SelectComponent,
  Spinner,
} from '../../Components/Shared';

import './index.scss';

const Supplier = ({
  suppliersReducer: {
    isLoading,
    supplier,
    zones,
  },
  updateSupplierRequest: updateDeliveryAgent,
  getSupplierByIdRequest: getSupplierById,
  getZonesRequest: getZones,
  goBack: goPreviousPage,
  match: { params: { id } },
}) => {
  const [updateSupplierData, setUpdateSupplierData] = useState(supplierObject);

  useEffect(() => {
    getZones();
    getSupplierById(id);
  }, []);

  useEffect(() => {
    if (supplier) {
      setUpdateSupplierData({
        ...supplier,
      });
    }
  }, [supplier]);

  const handleSubmit = useCallback(() => {
    const { zones: zonesUpdated, suppliers_zones, ...rest } = updateSupplierData;
    const newData = {
      ...rest,
      suppliers_zones_attributes: [
        ...supplier.suppliers_zones.map(({ id: supplierZoneId, zone_id }) => ({
          _destroy: !zonesUpdated.find(({ value }) => value === zone_id),
          id: supplierZoneId,
        })).filter(({ _destroy }) => _destroy),
        ...zonesUpdated.filter(
          ({ value }) => !supplier.suppliers_zones.find(
            ({ zone_id }) => zone_id === value
          )
        ).map(({ value }) => ({
          supplier_id: id,
          zone_id: value,
        })),
      ],
    };

    updateDeliveryAgent(newData);
  }, [updateSupplierData]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="supplier-view">
      <div className="supplier-view__back-button">
        <Button
          color="blue"
          type="button"
          size="large"
          onClick={() => goPreviousPage()}
        >
          Back
        </Button>
      </div>
      <form className="supplier-view__form">
        {
          updateSupplier.map(({
            component,
            name,
            label,
            required,
            disabled,
            placeholder,
            type,
            options,
          }) => {
            const InputComponent = {
              input: Input,
              multiselect: Select,
              select: SelectComponent,
            }[component];

            return (
              <div
                className="supplier-view__form-element"
                key={name}
              >
                {
                  component !== 'input' && (
                    <div className="supplier-view__form-select">
                      <label className="supplier-view__form-label" htmlFor={name}>
                        {label}
                        {required && <span className="supplier-view__form-required">*</span>}
                      </label>
                    </div>
                  )
                }
                <InputComponent
                  disabled={disabled}
                  name={name}
                  label={label}
                  className="select"
                  classNamePrefix="select"
                  value={updateSupplierData[name]}
                  onChange={(e) => setUpdateSupplierData({
                    ...updateSupplierData,
                    [name]: (
                      numberAtributesOnCreateDeliveryAgent.includes(name)
                      && (e.target.value < 0 || e.target.value)
                        ? Math.abs(e.target.value, 10)
                        : e?.target?.value || e),
                  })}
                  {...type && { type }}
                  {...(type === 'number') && { min: 0 }}
                  {...placeholder && { placeholder }}
                  {...(options && {
                    options,
                    placeholder: 'Select an option',
                  })}
                  {...component === 'multiselect' && {
                    closeMenuOnSelect: false,
                    isMulti: true,
                  }}
                  {...component === 'multiselect' && name === 'zones' && {
                    options: zones.map(({ name: nameZone, id: idZone }) => ({
                      label: nameZone,
                      value: idZone,
                    })),
                  }}
                  {...(required && { required })}
                />
              </div>
            );
          })
        }
        <div className="supplier-view__form-buttons">
          <Button
            color="blue"
            type="button"
            size="large"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

Supplier.propTypes = {
  getSupplierByIdRequest: propTypes.func.isRequired,
  getZonesRequest: propTypes.func.isRequired,
  goBack: propTypes.func.isRequired,
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.string,
    }),
  }).isRequired,
  suppliersReducer: suppliersReducerShape.isRequired,
  updateSupplierRequest: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  suppliersReducer: getSuppliersSelector(state),
});

const mapDispatchToProps = {
  getSupplierByIdRequest,
  getZonesRequest,
  goBack,
  updateSupplierRequest,
};

const enhance = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Supplier);
