import { all, call, put, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { appName, POSTFIX } from "../constants"
import Http from "../api/etl-inventories"
import { errorFormatter } from "../utils/errorFormatter"


/**
 * Constants
 **/
export const moduleName = "availableInventorySuppliers"
const prefix = `@@${appName}/${moduleName}`

const GET_INVENTORY_SUPPLIERS_REQUEST = `${prefix}/GET_INVENTORY_SUPPLIERS_REQUEST`
const GET_INVENTORY_SUPPLIER_BY_ID_REQUEST = `${prefix}/GET_INVENTORY_SUPPLIER_BY_ID_REQUEST`
const GET_INVENTORY_SUPPLIERS_FILES_REQUEST = `${prefix}/GET_INVENTORY_SUPPLIERS_FILES_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  allInventorySuppliers: [],
  supplier: {},
  isLoading: false,
  error: null
}

export default function(state = initialState, { type, payload, error }) {
  switch (type) {
    case GET_INVENTORY_SUPPLIERS_REQUEST + POSTFIX.START:
    case GET_INVENTORY_SUPPLIER_BY_ID_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_INVENTORY_SUPPLIERS_REQUEST + POSTFIX.SUCCESSFUL:
    case GET_INVENTORY_SUPPLIERS_FILES_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        allInventorySuppliers: payload.allInventorySuppliers
      }

    case GET_INVENTORY_SUPPLIER_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        supplier: payload.supplier
      }

    case GET_INVENTORY_SUPPLIERS_REQUEST + POSTFIX.FAILURE:
    case GET_INVENTORY_SUPPLIER_BY_ID_REQUEST + POSTFIX.FAILURE:
    case GET_INVENTORY_SUPPLIERS_FILES_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableInventorySuppliersReducer
 **/
export const getAllAvailableInventorySuppliersSelector = function(state) {
  const allInventorySuppliers = state.availableInventorySuppliersReducer.allInventorySuppliers

  return allInventorySuppliers && allInventorySuppliers.data ? allInventorySuppliers.data : []
}

export const getAvailableInventorySupplierSelector = function(state) {
  return state.availableInventorySuppliersReducer.supplier
}

export const getIsLoadingSelector = function(state) {
  return state.availableInventorySuppliersReducer.isLoading
}

/**
 * Action Creators
 **/
export function getAllAvailableInventorySuppliersAction() {
  return { type: GET_INVENTORY_SUPPLIERS_REQUEST }
}

export function getAllAvailableInventorySuppliersFilesAction(supplierId) {
  return { type: GET_INVENTORY_SUPPLIERS_FILES_REQUEST,
    payload: { supplierId }
  }
}

export function getAvailableInventorySupplierAction(supplierId) {
  return {
    type: GET_INVENTORY_SUPPLIER_BY_ID_REQUEST,
    payload: { supplierId }
  }
}

/**
 * Sagas
 **/
function* getAvailableInventorySuppliersSaga() {
  yield put({ type: GET_INVENTORY_SUPPLIERS_REQUEST + POSTFIX.START })
  try {
    const allInventorySuppliers = yield call(() => Http.getInventorySuppliers())
    yield put({
      type: GET_INVENTORY_SUPPLIERS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { allInventorySuppliers }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_INVENTORY_SUPPLIERS_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* getAllAvailableInventorySuppliersFilesSaga({ payload: { supplierId } }) {
  yield put({ type: GET_INVENTORY_SUPPLIERS_FILES_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getInventoryById(supplierId))
    yield put({
      type: GET_INVENTORY_SUPPLIERS_FILES_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { scraper: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_INVENTORY_SUPPLIERS_FILES_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* getAvailableInventorySupplierByIdSaga({ payload: { supplierId } }) {
  yield put({ type: GET_INVENTORY_SUPPLIER_BY_ID_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getInventoryById(supplierId))
    yield put({
      type: GET_INVENTORY_SUPPLIER_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { scraper: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_INVENTORY_SUPPLIER_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_INVENTORY_SUPPLIERS_REQUEST, getAvailableInventorySuppliersSaga),
    takeLatest(GET_INVENTORY_SUPPLIER_BY_ID_REQUEST, getAvailableInventorySupplierByIdSaga),
    takeLatest(GET_INVENTORY_SUPPLIERS_FILES_REQUEST, getAllAvailableInventorySuppliersFilesSaga)
  ])
}
