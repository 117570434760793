import styled from "styled-components"

export default styled.div`
  width: 100%;

  .buttons__wrapper > * {
    margin-right: 15px;
  }
  
  .buttons__wrapper > *:last-child {
    margin-right: 0;
  }
`
