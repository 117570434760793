import React, { Component } from 'react';
import { connect } from 'react-redux';
import { modalSelector, closeModal } from '../../../ducks/modal';

import FiltersModal from '../../Products/FilterModal';
import ModalAddAttributes from '../../AttributeTagging/ModalAddAttribute';

const ModalComponents = {
  attributeTagging: ModalAddAttributes,
  productFilters: FiltersModal,
};

export class Modal extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.closeModal();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escHandler, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escHandler, false);
  }

  escHandler = (e) => {
    if (e.keyCode === 27) {
      this.props.closeModal();
    }
  };

  handleOutSideClick = (e) => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  render() {
    const {
      closeModal, title, component, info,
    } = this.props;
    if (!component) {
      return null;
    }

    const Component = ModalComponents[component];
    return (
      <article className="modal" onClick={this.handleOutSideClick}>
        <section className="modal-wrap">
          <div className="modal__header">
            <h1 className="modal__title">{title}</h1>
            <button onClick={closeModal} className="modal__close-btn" />
          </div>
          <section className="modal__cont">
            <Component {...info} close={closeModal} />
          </section>
        </section>
      </article>
    );
  }
}

export default connect(
  (state) => modalSelector(state),
  {
    closeModal,
  }
)(Modal);
