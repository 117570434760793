import { appName } from '../constants';

/**
 * Constants
 * */
export const moduleName = 'modal';
const prefix = `${appName}/${moduleName}`;

export const MODAL_INFO = `${prefix}/MODAL_INFO`;

/**
 * Reducer
 * */

const defaultState = {
  component: '',
  info: {},
  title: '',
};

export default function reducer(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case MODAL_INFO:
      return payload;

    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const modalSelector = (state) => state.modal;

/**
 * Action Creators
 * */

export const showModal = (component, title, info) => ({
  payload: {
    component,
    info,
    title,
  },
  type: MODAL_INFO,
});

export const closeModal = () => ({
  payload: {},
  type: MODAL_INFO,
});
