import styled from 'styled-components';

export default styled.section`
  color: #595959;
  font-weight: 100;

  .modal-window__window{
    height: 750px;

    .filters__one-column {
      display: flex;
      flex-direction: column;
      height: 640px;
      width: 700px;
      margin: 0 0.7rem 0 0;
    }
  }

  .modal-filter__title {
    margin-bottom: 15px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  .modal-filter__filters {
    display: flex;
  }

  .modal-filter__footer {
    text-align: center;
  }


`;
