import styled from "styled-components"

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  
  .input__field {
    display: inline-block;
    padding: 11px;
    font-family: inherit;
    font-weight: 100;
    font-size: 17px;
    line-height: 1;
    text-align: left;
    vertical-align: middle;
    color: #595959;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    outline: none;
    cursor: text;
    transition: all 0.3s;
  }
  
  .input__field:hover,
  .input__field:focus {
    border-color: #8e8e8e;
  }
  
  .input__field.error {
    border-color: #ff3232;
  }
  
  .input__label {
    margin-bottom: 0;
    padding-right: 20px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .input__error {
    color:#ff3232;
  }
`
