import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { fromJS } from 'immutable';
import diff from 'immutablediff';

import {
  getProduct, updateProduct, getProductOptions, getFurniture,
} from '../../../ducks/etl_product';
import {
  loadingSelector, productSelector, productOptionsSelector, furnitureSelector,
} from '../../../ducks/etl_product';

import Spinner from '../../Shared/Spinner';
import {
  Header, AttributesForm, ImagesContainer, GlobalButtons,
} from '../../Product';

const defaultState = {
  furniture: {
    arm_height: '',
    color_ids: [],
    cost_cad: '',
    cost_usd: '',
    material_ids: [],
    seat_depth: '',
    seat_height: '',
    seat_width: '',
    weight: '',
  },
  id: 0,
  images: {},
  product: {
    active: true,
    cad_price: '',
    default_image: { medium: { url: '' } },
    depth: '',
    description: '',
    height: '',
    image2: { medium: { url: '' } },
    image3: { medium: { url: '' } },
    image4: { medium: { url: '' } },
    image5: { medium: { url: '' } },
    image6: { medium: { url: '' } },
    name: '',
    product_attributes_array: [],
    sku: '',
    stock_control: '',
    supplier_id: '',
    tax_rate_id: '',
    url_default_image: '',
    url_image2: '',
    url_image3: '',
    url_image4: '',
    url_image5: '',
    url_image6: '',
    width: '',
    usd_price: '',
  },
  product_category_ids: [],
};

export class Product extends Component {
  state = {
    categories: [],
    colors: [],
    delete_attribute_ids: [],
    hasProduct: false,
    materials: [],
    missingRequiredFields: false,
    productData: defaultState,
    suppliers: [],
  };

  componentDidMount() {
    const { getProduct, getProductOptions, match } = this.props;
    getProductOptions();
    getProduct(match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.product) {
      this.updateProductData(nextProps);
    }

    if (nextProps.productOptions) {
      this.setState({
        categories: nextProps.productOptions.categories || [],
        colors: nextProps.productOptions.colors || [],
        materials: nextProps.productOptions.materials || [],
        suppliers: nextProps.productOptions.suppliers || [],
      });
    }
  }

  updateProductData = (nextProps) => {
    this.setState({
      productData: this.getProductData(nextProps),
    });
  };

  getProductData = (data) => {
    let results = {
      ...defaultState,
    };
    if (data.product) {
      results = {
        ...defaultState,
        furniture: {
          ...defaultState.product.furniture,
          ...data.product.furniture,
          color_ids: data.product.furniture.colors,
          material_ids: data.product.furniture.materials,
        },
        id: data.product.id,
        images: {},
        product: {
          ...defaultState.product,
          active: data.product.active,
          cad_price: data.product.msrp_cad,
          default_image: data.product.default_image,
          depth: data.product.depth,
          description: data.product.description,
          height: data.product.height,
          image2: data.product.image2,
          image3: data.product.image3,
          image4: data.product.image4,
          image5: data.product.image5,
          image6: data.product.image6,
          name: data.product.name,
          product_attributes_array: data.product.product_attributes,
          sku: data.product.sku,
          stock_control: data.product.stock_control,
          supplier_id: data.product.supplier_id,
          url_default_image: data.product.url_default_image,
          url_image2: data.product.url_image2,
          url_image3: data.product.url_image3,
          url_image4: data.product.url_image4,
          url_image5: data.product.url_image5,
          url_image6: data.product.url_image6,
          usd_price: data.product.msrp_usd,
          width: data.product.width,
        },
        product_category_ids: data.product.categories,
      };
    }
    return results;
  };

  getUpdateData = (productData) => {
    const result = {
      furniture: {},
      images: {},
      product: {
        name: productData.product.name,
        sku: productData.product.sku,
        supplier_id: productData.product.supplier_id,
      },
      product_category_ids: productData.product_category_ids,
    };
    const oldData = this.getProductData(this.props);
    const updateFields = diff(fromJS(oldData), fromJS(productData)).toJS();
    for (const item of updateFields) {
      const path = item.path.split('/');
      const group = path[1];
      const key = path[2];
      if (['color_ids', 'material_ids'].indexOf(key) !== -1) {
        result[group][key] = productData[group][key];
      } else if (['product_attributes_array'].indexOf(key) !== -1) {
        result[group][key] = productData[group][key];
      } else if (['product_category_ids'].indexOf(group) !== -1) {
        result[group] = productData[group];
      } else if (['delete_attribute_ids'].indexOf(group) !== -1) {
        result.product[group] = productData[group];
      } else {
        result[group][key] = item.value;
      }
    }
    if (result.images.default_image) {
      result.product.default_image = result.images.default_image;
    }
    delete result.images;
    if (!Object.keys(result.furniture).length) {
      delete result.furniture;
    }
    return result;
  };

  getUrlKey = (key) => (key === 1 ? 'url_default_image' : `url_image${key}`);

  getFileKey = (key) => (key === 1 ? 'default_image' : `image${key}`);

  getImagesList = (productData) => {
    const indexList = [1, 2, 3, 4, 5];
    return indexList.map((key) => {
      const url = productData.product[`${this.getUrlKey(key)}`];
      const file = productData.product[`${this.getFileKey(key)}`];
      const isFile = (file && file.type) || (file && file.medium.url);
      return isFile ? this.getImageData(file) : url;
    });
  };

  getImageData = (file) => (file.type ? URL.createObjectURL(file) : file.medium.url);

  addCategory = (data, category) => [...data, category];

  removeCategory = (data, category) => data.product_category_ids.filter((id) => id !== category);

  handleImagesChange = (file, url, index) => {
    const newProductData = fromJS(this.state.productData).toJS();
    newProductData.product[this.getFileKey(index + 1)] = file || '';
    if (!file) {
      newProductData.product[this.getUrlKey(index + 1)] = url || '';
    }
    if (!file && !url) {
      newProductData.product[`remove_${this.getFileKey(index + 1)}`] = true;
    }
    this.setState({
      productData: newProductData,
    });
  };

  handleChangeRoomPlannerImage = (file, url) => {
    const newProduct = fromJS(this.state.productData).toJS();
    newProduct.product.image6 = file || '';
    if (!file) {
      newProduct.product.url_image6 = url || '';
    }
    if (!file && !url) {
      newProduct.product.remove_image6 = true;
    }
    this.setState({
      productData: newProduct,
    });
  };

  handleCategoryChange = (elements) => {
    const newProductData = this.state.productData;

    newProductData.product_category_ids = elements;
    this.setState({
      productData: newProductData,
    });
  };

  handleSupplierChange = (elements) => {
    const newProductData = this.state.productData;
    newProductData.product.supplier_id = elements.value;
    this.setState({
      productData: newProductData,
    });
  };

  handleColorsChange = (elements) => {
    const newProductData = this.state.productData;
    newProductData.furniture.color_ids = elements.map((el) => el.value);
    this.setState({
      productData: newProductData,
    });
  };

  handleMaterialsChange = (elements) => {
    const newProductData = this.state.productData;
    newProductData.furniture.material_ids = elements.map((el) => el.value);
    this.setState({
      productData: newProductData,
    });
  };

  handleChangeAttributes = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    const { files } = e.target;

    if (!name) {
      return;
    }

    if (files && files[0]) {
      this.setNewImage('image6', files[0]);
      return;
    }

    if (name === 'product.stock_control') {
      this.setNewProduct(name, value === 'true');
      return;
    }

    const prices = ['product.cad_price', 'product.usd_price', 'furniture.cost_cad', 'furniture.cost_usd'];
    if (prices.indexOf(name) !== -1 && value.indexOf('$') !== -1) {
      value = parseFloat(value.slice(2));
    }

    if (name.substring(0, 7) === 'product') {
      this.setNewProduct(name, value);
    } else {
      this.setNewFurniture(name, value);
    }
  };

  handleChangeOtherAttributes = (e) => {
    const { name } = e;
    const { value } = e;
    let newProduct = fromJS(this.state.productData);
    const itemNumber = name.split(':')[1];
    const itemKey = name.split(':')[0] === 'name ' ? 'key' : 'value';
    const newAttr = newProduct.toJS().product.product_attributes_array;
    newAttr[itemNumber][itemKey] = value;
    newProduct = newProduct.setIn(['product', 'product_attributes_array'], newAttr);
    this.setState({
      productData: newProduct.toJS(),
    });
  };

  handleReturn = () => {
    this.props.goBack();
  };

  handleSave = () => {
    if (!this.hasRequiredFields()) {
      this.setState({
        missingRequiredFields: true,
      });
      return;
    }
    const { productData, delete_attribute_ids } = this.state;
    const newProduct = { ...productData, delete_attribute_ids };

    const { match } = this.props;
    const formData = this.getUpdateData(newProduct);

    if (parseInt(match.params.product_id, 10)) {
      this.props.updateProduct(productData.id, formData);
    } else {
      this.props.createProduct(formData);
    }
  };

  hasRequiredFields = () => {
    const { product_category_ids } = this.state.productData;
    const { name, sku, supplier_id } = this.state.productData.product;
    const requiredFields = product_category_ids[0] && name && sku && supplier_id;
    return !!requiredFields;
  };

  handleAddAttribute = () => {
    let newProduct = fromJS(this.state.productData);
    const OtherAttr = this.state.productData.product.product_attributes_array.slice();
    const newItem = { key: '', value: '' };
    OtherAttr.push(newItem);
    newProduct = newProduct.setIn(['product', 'product_attributes_array'], OtherAttr);
    this.setState({
      productData: newProduct.toJS(),
    });
  };

  handleDeleteAttribute = (id, attributeId) => {
    this.setState({
      delete_attribute_ids: [...this.state.delete_attribute_ids, attributeId],
      product_attributes_array: this.state.productData.product.product_attributes_array.splice(id, 1),
    });
  };

  handleChangeSwitch = (e) => {
    const newProduct = fromJS(this.state.productData).toJS();
    newProduct.product.active = e;
    this.setState({
      productData: newProduct,
    });
  };

  handleDiscontinuedChange = (element) => {
    const newProductData = this.state.productData;
    newProductData.furniture.is_discounted = element;
    this.setState({
      productData: newProductData,
    });
  };

  setNewImage = (name, file) => {
    const newProduct = fromJS(this.state.productData).toJS();
    newProduct.images[name] = file;
    this.setState({
      productData: newProduct,
    });
  };

  setNewProduct = (name, value) => {
    const newProduct = fromJS(this.state.productData).toJS();
    const category = name.substring(8);
    newProduct.product[category] = value;
    this.setState({
      productData: newProduct,
    });
  };

  setNewFurniture = (name, value) => {
    const newFurniture = this.state.productData.furniture;
    const category = name.substring(10);
    newFurniture[category] = value;
    this.setState({
      furniture: newFurniture,
    });
  };

  buttonClone_clickHandler = (event) => {
    const { match } = this.props;
    event && event.preventDefault && event.preventDefault();
    window.open(`/products/clone/${match.params.id}`);
  };

  render() {
    if (this.props.loading || !this.state.productData || !this.props.productOptions) {
      return <Spinner />;
    }
    const {
      productData, categories, colors, materials, suppliers, missingRequiredFields,
    } = this.state;
    return (
      <div className="product-edit-page">
        <div className="product-edit-page__wrapper">
          <div className="product-edit-page__header">
            <Header
              pushToClone={this.buttonClone_clickHandler}
              productName={productData.product.name}
              checked={productData.product.active}
              onReturn={this.handleReturn.bind(this)}
              onChangeSwitch={this.handleChangeSwitch.bind(this)}
            />
          </div>
          <div className="product-edit-page__images-container pl-0 pl-lg-5 ml-0 ml-lg-5">
            <ImagesContainer
              images={this.getImagesList(productData)}
              onImagesChange={this.handleImagesChange.bind(this)}
              imageSize="large"
              customStyles="product"
            />
          </div>
          <div className="product-edit-page__attributes-from">
            <AttributesForm
              missingRequiredFields={missingRequiredFields}
              productData={productData}
              categories={categories}
              colors={colors}
              materials={materials}
              suppliers={suppliers}
              onChangeAttribute={this.handleChangeAttributes.bind(this)}
              onCategoryChange={this.handleCategoryChange.bind(this)}
              onSupplierChange={this.handleSupplierChange.bind(this)}
              onColorsChange={this.handleColorsChange.bind(this)}
              onMaterialsChange={this.handleMaterialsChange.bind(this)}
              onChangeOtherAttribute={this.handleChangeOtherAttributes.bind(this)}
              onAddAttribute={this.handleAddAttribute.bind(this)}
              onDeleteAttribute={this.handleDeleteAttribute.bind(this)}
              onChangeRoomPlannerImage={this.handleChangeRoomPlannerImage.bind(this)}
              onDiscontinuedChange={this.handleDiscontinuedChange.bind(this)}
            />
          </div>
          <div className="mt-md-5 mb-md-5  mt-4 mb-2 container">
            <GlobalButtons onSave={this.handleSave.bind(this)} onCancel={this.handleReturn.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    furniture: furnitureSelector(state),
    loading: loadingSelector(state),
    product: productSelector(state),
    productOptions: productOptionsSelector(state),
  }),
  {
    getFurniture,
    getProduct,
    getProductOptions,
    goBack,
    push,
    updateProduct,
  }
)(Product);
