import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';
import {
  checkedFurnitureCategoriesAction,
  makeAddFurnitureCategoriesSelector,
} from '../../../../ducks/roomType';
// Components
import StyledTable from './style';
import StyledModal from './modalStyle';
import Th from './Th';
import FurnitureTd from './FurnitureTd';
import CategoryTd from './CategoryTd';
import { Button, ModalWindow } from '../../../Shared';
import withModal from '../../../HOCs/modal';

class Table extends PureComponent {
  static propTypes = {
    checkedFurnitureCategories: PropTypes.func,
    furnitureCategories: PropTypes.array,
    onChangeCategory: PropTypes.func,
    onChangeCategories: PropTypes.func,
    categories: PropTypes.object,
    onChangeSelectCategories: PropTypes.func,
  };

  state = {
    checked: [],
    expanded: [],
  };

  onCheck = (checked) => this.setState({ checked });

  onExpand = (expanded) => this.setState({ expanded });

  onButtonAddCategoryClick = () => {
    this.props.checkedFurnitureCategories(this.state.checked);
    this.props.closeModal();
    this.setState({ expanded: [] });
  };

  onButtonDeleteClick = (categoryId) => {
    const newChecked = this.state.checked.filter((id) => parseInt(id, 10) !== parseInt(categoryId, 10));
    this.setState({ checked: newChecked });
    this.props.checkedFurnitureCategories(newChecked);
  };

  render() {
    const {
      data, furnitureCategories, isModalOpen, onChangeCategory, categories, onChangeCategories,
    } = this.props;

    return (
      <>
        <StyledTable>
          <thead>
            <tr>
              <Th />
            </tr>
          </thead>
          <tbody>
            {furnitureCategories && furnitureCategories.map((item) => (
              <Fragment key={item.value}>
                <tr>
                  <FurnitureTd furnitureName={item.label} openModal={this.props.openModal} />
                </tr>
                {item.children && item.children.map((category) => (
                  <tr key={category.value}>
                    <CategoryTd
                      category={category}
                      onChangeCategory={onChangeCategory}
                      onChangeCategories={onChangeCategories}
                      onButtonDeleteClick={this.onButtonDeleteClick}
                      categories={categories}
                      onChangeSelectCategories={this.props.onChangeSelectCategories}
                    />
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </StyledTable>
        {isModalOpen && (
          <ModalWindow closeModal={this.props.closeModal}>
            <StyledModal>
              <h1 className="modal-categories__title">Categories</h1>
              <CheckboxTree
                nodes={data}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={this.onCheck}
                onExpand={this.onExpand}
              />
              <div className="modal-categories__button">
                <Button
                  width={200}
                  size="large"
                  onClick={this.onButtonAddCategoryClick}
                >
                  Add categories
                </Button>
              </div>
            </StyledModal>
          </ModalWindow>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const addFurnitureCategoriesSelector = makeAddFurnitureCategoriesSelector();

  return {
    furnitureCategories: addFurnitureCategoriesSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkedFurnitureCategories: (checkedFurnitureCategories) => dispatch(checkedFurnitureCategoriesAction(checkedFurnitureCategories)),
});

const enhancer = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhancer(Table);
