import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Utils
import _get from 'lodash/fp/get';
import { connect } from 'react-redux';
import objectToFormData from '../../../utils/objectToFormData';
// Redux
import {
  getDesignRuleOptionsAction,
  getDesignRules,
  getDesignRulesAction,
  getIsLoading,
  updateDesignRulesAction,
} from '../../../ducks/designRuleMatching';
// Components
import IntroScreenStyle from './style';
import TemplateForm from './TemplateForm';
import { Preloader } from '../../Shared';

// Helpers for submit
const getInitialRule = (initialValues) => {
  if (!initialValues) return null;

  return initialValues.rules && initialValues.rules.filter((rule) => rule.design_styles.length > 0);
};

const getFormValues = (currentRule, initialRule) => {
  if (!currentRule || !currentRule.design_styles) return [''];
  if (currentRule.design_styles.length === 0) return [''];

  return currentRule.design_styles.map(({ value }, index) => {
    if (initialRule) {
      return {
        design_style_id: value,
        id: initialRule.design_styles[index].relation_id,
      };
    }
    return { design_style_id: value };
  });
};

function IntroScreen({
  updateDesignRules, isLoading, getDesignRuleOptions, getDesignRules, initialTemplates,
}) {
  useEffect(() => {
    getDesignRuleOptions();
    getDesignRules();
  }, []);

  const templateForm_submitHandler = (values) => {
    // if (disabledSubmit) return

    const ruleIds = [];
    const data = values.rules.map((rule, index) => {
      const initialRules = getInitialRule(initialTemplates, rule);
      const designStyles = getFormValues(rule, initialRules[index]);
      const ruleId = _get('value', rule);

      ruleIds.push(ruleId);

      return {
        id: ruleId,
        template_id: _get('template.value', rule),
        floorplan_id: _get('floorplan.value', rule),
        rule_styles_attributes: [...designStyles],
      };
    });

    const dataToSend = {
      rule_ids: ruleIds,
      attributes: data,
    };

    updateDesignRules(objectToFormData(dataToSend));
  };

  return (
    <Preloader isLoading={isLoading}>
      <IntroScreenStyle>
        <TemplateForm onSubmit={templateForm_submitHandler} />
      </IntroScreenStyle>
    </Preloader>
  );
}

IntroScreen.propTypes = {
  // from DesignTemplates Component
  changeDisabledSubmit: PropTypes.func,
  // From connect/redux-form
  initialTemplates: PropTypes.object,
  // From connect/designTemplatesReducer
  isLoading: PropTypes.bool,
  getDesignRuleOptions: PropTypes.func,
  getDesignRules: PropTypes.func,
  updateDesignRules: PropTypes.func,
};

// Enhancer
const mapStateToProps = (state) => ({
  initialTemplates: getDesignRules(state),
  isLoading: getIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDesignRuleOptions: () => dispatch(getDesignRuleOptionsAction()),
  getDesignRules: () => dispatch(getDesignRulesAction()),
  updateDesignRules: (data) => dispatch(updateDesignRulesAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IntroScreen);
