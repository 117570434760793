import React, { Component } from 'react';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import styled from 'styled-components';
import {
  getAvailableFloorPlanRoomInner,
  getFloorPlanRoomInnerDesignRooms,
} from '../../../../ducks/availableFloorPlans';
// ImportComponents
import { ReduxFormSwitch } from '../../../Shared/reduxForm';

const SwitchStyleCreate = styled.div`
  .box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .title{
    margin-bottom: 0;
    padding-right: 20px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
  }
  .input{
    display: none;
  }
`;

class RoomInner extends Component {
  state = {
    arrValue: [],
  };

  componentDidMount() {
    const { fields, designRooms } = this.props;
    fields.push({});
    this.setState({
      arrValue: designRooms,
    });
  }

  render() {
    const { fields } = this.props;
    const { arrValue } = this.state;

    return (
      <SwitchStyleCreate>
        {
          fields.map((roomType) => arrValue.map(({
            value, key, count, design_room_id,
          }) => (
            <div className="box" key={value}>
              <p className="title">{key}</p>
              <ReduxFormSwitch
                id={key}
                name={`${roomType}.${design_room_id}`}
                width={60}
                height={27}
                handleDiameter={20}
                onColor="#1ab394"
              />

            </div>
          )))
        }
      </SwitchStyleCreate>
    );
  }
}

// Enhance
const mapStateToProps = (state) => ({
  floorPlanRoomInner: getAvailableFloorPlanRoomInner(state),
  designRooms: getFloorPlanRoomInnerDesignRooms(state),
});

const mapDispatchToProps = { initialize };

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(RoomInner);
