import React, { Component } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';

// ImportComponents
import Switch from 'react-switch';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getAvailableFloorPlanById } from '../../../../ducks/availableFloorPlans';
import { Button, ModalWindow } from '../../../Shared';
import StyledComponents from '../../../Room/Variant/MoveModal/style';

const SwitchStyle = styled.div`
  .box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .title{
    margin-bottom: 0;
    padding-right: 20px;
    font-weight: 400;
    text-transform: uppercase;
    cursor: pointer;
  }
  .modal__title_floorplan_edit {
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 3px;
    text-align: center;
    text-transform: uppercase;
    margin: 15px 0;
  }
`;

class RoomInner extends Component {
  static propTypes = {
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
  };

  state = {
    isModalOpen: false,
    arrValue: [],
  };

  componentDidMount() {
    const { floorPlanRoomInner, fields } = this.props;
    fields.push({});
    this.setState({
      arrValue: floorPlanRoomInner,
    });
  }

  handleChange = (id) => {
    const newArr = this.state.arrValue.map((item) => {
      if (item.design_room_id === id) {
        if (item.can_change) {
          if (item.count === 1) {
            item.count = 0;
          } else {
            item.count = 1;
          }
          item.is_changed = true;
        } else {
          this.setState({
            design_room_name: item.key.toString(),
          });
          this.openModal();
        }
      }
      return item;
    });
    this.setState({
      arrValue: newArr,
    });
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    const { fields } = this.props;
    const { arrValue, isModalOpen, design_room_name } = this.state;

    return (
      <SwitchStyle>
        {
          fields.map((roomType) => arrValue.map(({
            value, key, count, design_room_id,
          }) => (
            <div className="box" key={key}>
              <p className="title">{key}</p>
              <Switch
                id={key}
                disabled={key === 'Entire Unit'}
                checked={count === 1}
                width={60}
                height={27}
                handleDiameter={20}
                onColor="#1ab394"
                onChange={() => this.handleChange(design_room_id)}
              />
            </div>
          )))
        }
        {isModalOpen && (
          <ModalWindow closeModal={this.closeModal}>
            <StyledComponents>
              <div className="modal__header">
                <h1 className="modal__title">Disable (<strong>{design_room_name}</strong>) Design Room </h1>
              </div>
              <div className="row move-modal-content">
                <span className="modal__content">
                  <p>Other proposals are using this floor plan so you cannot disable this room</p>
                </span>
              </div>
              <div className="add-attributes__footer">
                <Button type="button" width={120} onClick={this.closeModal}>
                  Close
                </Button>
              </div>
            </StyledComponents>
          </ModalWindow>
        )}
      </SwitchStyle>

    );
  }
}

// Enhance
const mapStateToProps = (state) => ({
  floorPlanRoomInner: getAvailableFloorPlanById(state).room_inner,
});

const mapDispatchToProps = { initialize };

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(RoomInner);
