import { put, call, all, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { appName, POSTFIX } from "../constants"
import Http from "../api/design-templates-design-styles"
import { replace } from "react-router-redux"
import { createSelector } from "reselect"
import _differenceBy from "lodash/fp/differenceBy"

/**
 * Constants
 **/
export const moduleName = "availableDesignStyles"
const prefix = `@@${appName}/${moduleName}`

const GET_DESIGN_STYLES_REQUEST = `${prefix}/GET_DESIGN_STYLES_REQUEST`
const GET_DESIGN_STYLE_BY_ID_REQUEST = `${prefix}/GET_DESIGN_STYLE_BY_ID_REQUEST`
const GET_DESIGN_STYLE_OPTIONS_REQUEST = `${prefix}/GET_DESIGN_STYLE_OPTIONS_REQUEST`
const CREATE_DESIGN_STYLE_REQUEST = `${prefix}/CREATE_DESIGN_STYLE_REQUEST`
const UPDATE_DESIGN_STYLE_REQUEST = `${prefix}/UPDATE_DESIGN_STYLE_REQUEST`
const DELETE_DESIGN_STYLE_REQUEST = `${prefix}/DELETE_DESIGN_STYLE_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  designStyles: [],
  designStyle: {},
  designStyleOptions: {},
  isLoading: false,
  error: null
}

export default function(state = initialState, { type, payload, error }) {
  switch (type) {

    case GET_DESIGN_STYLES_REQUEST + POSTFIX.START:
    case GET_DESIGN_STYLE_BY_ID_REQUEST + POSTFIX.START:
    case CREATE_DESIGN_STYLE_REQUEST + POSTFIX.START:
    case UPDATE_DESIGN_STYLE_REQUEST + POSTFIX.START:
    case DELETE_DESIGN_STYLE_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_DESIGN_STYLES_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_DESIGN_STYLE_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        designStyles: payload.designStyles
      }

    case GET_DESIGN_STYLE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        designStyle: payload.designStyle
      }

    case GET_DESIGN_STYLE_OPTIONS_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        designStyleOptions: payload.designStyleOptions
      }

    case GET_DESIGN_STYLES_REQUEST + POSTFIX.FAILURE:
    case GET_DESIGN_STYLE_BY_ID_REQUEST + POSTFIX.FAILURE:
    case GET_DESIGN_STYLE_OPTIONS_REQUEST + POSTFIX.FAILURE:
    case CREATE_DESIGN_STYLE_REQUEST + POSTFIX.FAILURE:
    case UPDATE_DESIGN_STYLE_REQUEST + POSTFIX.FAILURE:
    case DELETE_DESIGN_STYLE_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableDesignStylesReducer
 **/
const getColorOptions = function(state) {
  return state.availableDesignStylesReducer.designStyleOptions.colors
}

const getColorSelectorOptions = function(state) {
  return state.availableDesignStylesReducer.designStyle.design_style_colors
}

export const colorSelectorOptions = () => createSelector(
  [getColorOptions, getColorSelectorOptions],
  (colors, selectorColors) => _differenceBy("label", colors, selectorColors)
)

const getTextureOptions = function(state) {
  return state.availableDesignStylesReducer.designStyleOptions.textures
}

const getTextureSelectorOptions = function(state) {
  return state.availableDesignStylesReducer.designStyle.design_style_textures
}

export const textureSelectorOptions = () => createSelector(
  [getTextureOptions, getTextureSelectorOptions],
  (textures, selectorTextures) => _differenceBy("label", textures, selectorTextures)
)

const getDesignRulesOptions = function(state) {
  return state.availableDesignStylesReducer.designStyleOptions.design_rules
}

const getRuleSelectorOptions = function(state) {
  return state.availableDesignStylesReducer.designStyle.design_style_rules
}

export const designRulesSelectorOptions = () => createSelector(
  [getDesignRulesOptions, getRuleSelectorOptions],
  (designRules, selectorDesignRules) => _differenceBy("label", designRules, selectorDesignRules)
)


export const getAvailableDesignStyles = function(state) {
  return state.availableDesignStylesReducer.designStyles
}

export const getAvailableDesignStyleById = function(state) {
  return state.availableDesignStylesReducer.designStyle
}

export const getAvailableDesignStyleOptions = function(state) {
  return state.availableDesignStylesReducer.designStyleOptions
}

export const getIsLoading = function(state) {
  return state.availableDesignStylesReducer.isLoading
}

/**
 * Action Creators
 **/
export function getAvailableDesignStylesRequest() {
  return { type: GET_DESIGN_STYLES_REQUEST }
}

export function getAvailableDesignStyleByIdRequest(id) {
  return {
    type: GET_DESIGN_STYLE_BY_ID_REQUEST,
    payload: { id }
  }
}

export function getAvailableDesignStyleOptionsRequest() {
  return { type: GET_DESIGN_STYLE_OPTIONS_REQUEST }
}

export function updateAvailableDesignStyleRequest(id, data) {
  return {
    type: UPDATE_DESIGN_STYLE_REQUEST,
    payload: { id, data }
  }
}

export function createAvailableDesignStyleRequest(data) {
  return {
    type: CREATE_DESIGN_STYLE_REQUEST,
    payload: { data }
  }
}

export function deleteAvailableDesignStyleRequest(id) {
  return {
    type: DELETE_DESIGN_STYLE_REQUEST,
    payload: { id }
  }
}

/**
 * Sagas
 **/
function* getAvailableDesignStylesSaga() {
  yield put({ type: GET_DESIGN_STYLES_REQUEST + POSTFIX.START })
  try {
    const designStyles = yield call(() => Http.getDesignStyles())
    yield put({
      type: GET_DESIGN_STYLES_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { designStyles }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_DESIGN_STYLES_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* getAvailableDesignStyleByIdSaga({ payload: { id } }) {
  yield put({ type: GET_DESIGN_STYLE_BY_ID_REQUEST + POSTFIX.START })
  try {
    const designStyle = yield call(() => Http.getDesignStyleById(id))
    yield put({
      type: GET_DESIGN_STYLE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { designStyle }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_DESIGN_STYLE_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* getAvailableDesignStyleOptionsSaga() {
  try {
    const designStyleOptions = yield call(() => Http.getDesignStyleOptions())
    yield put({
      type: GET_DESIGN_STYLE_OPTIONS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { designStyleOptions }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_DESIGN_STYLE_OPTIONS_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* createAvailableDesignStyleSaga({ payload: { data } }) {
  yield put({ type: CREATE_DESIGN_STYLE_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.addDesignStyle(data))
    yield call(() => toastr.success("Successfully created"))
    yield put(replace("/design-templates/detail/design-styles"))
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: CREATE_DESIGN_STYLE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* updateAvailableDesignStyleSaga({ payload: { id, data } }) {
  yield put({ type: UPDATE_DESIGN_STYLE_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.updateDesignStyle(id, data))
    yield call(() => toastr.success("Successfully updated"))
    yield put(replace("/design-templates/detail/design-styles"))
    window.localStorage.removeItem("_destroy-design_style_rules_attributes")
    window.localStorage.removeItem("_destroy-design_style_colors_attributes")
    window.localStorage.removeItem("_destroy-design_style_textures_attributes")
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: UPDATE_DESIGN_STYLE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
    window.localStorage.removeItem("_destroy-design_style_rules_attributes")
    window.localStorage.removeItem("_destroy-design_style_colors_attributes")
    window.localStorage.removeItem("_destroy-design_style_textures_attributes")
  }
}

function* deleteAvailableDesignStyleSaga({ payload: { id } }) {
  yield put({ type: DELETE_DESIGN_STYLE_REQUEST + POSTFIX.START })
  try {
    const designStyles = yield call(() => Http.deleteDesignStyle(id))
    yield call(() => toastr.success("Successfully deleted"))
    yield put({
      type: DELETE_DESIGN_STYLE_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { designStyles }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: DELETE_DESIGN_STYLE_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_DESIGN_STYLES_REQUEST, getAvailableDesignStylesSaga),
    takeLatest(CREATE_DESIGN_STYLE_REQUEST, createAvailableDesignStyleSaga),
    takeLatest(GET_DESIGN_STYLE_BY_ID_REQUEST, getAvailableDesignStyleByIdSaga),
    takeLatest(GET_DESIGN_STYLE_OPTIONS_REQUEST, getAvailableDesignStyleOptionsSaga),
    takeLatest(UPDATE_DESIGN_STYLE_REQUEST, updateAvailableDesignStyleSaga),
    takeLatest(DELETE_DESIGN_STYLE_REQUEST, deleteAvailableDesignStyleSaga)
  ])
}
