import axios from "axios"
import objectToFormData from "../utils/objectToFormData"
import { API } from "../constants"
import { apiUrl } from "./lt-api"

axios.defaults.baseURL = API

const defaultHeaders = {
  method: "get",
  crossDomain: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    withCredentials: true
  }
}

export function getCategoriesMapping(id){
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/${id}/categories_mapping`
  })
}


export function updateCategoriesMappingDataRequest(id, payload) {
  const formData = objectToFormData(payload)

  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/${id}/update_categories_mapping`,
    method: "POST",
    data: formData
  })
}

export function deleteCategoriesMappingDataRequest(id, payload) {
  const formData = objectToFormData(payload)

  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/${id}/delete_categories_mapping`,
    method: "POST",
    data: formData
  })
}

