import React, { Fragment } from "react"
import PropTypes from "prop-types"

// Import Components
import { ReduxFormInputCounter } from "../../../../../Shared/reduxForm"

ProductListItem.propTypes = {
  // from ProductList Component
  item: PropTypes.object.isRequired
}

function ProductListItem({ item: { id, name, quantity, image, url_image } }) {
  return (
    <Fragment>
      <div className="product-list__image-wrapper">
        <img
          className="product-list__image"
          src={
           image.url === null ? url_image : image.url
          }
          alt={name}
        />
      </div>
      <ReduxFormInputCounter name={id.toString()} width={200} defaultValue={quantity} />
      <p className="product-list__caption">{name}</p>
    </Fragment>
  )
}

export default ProductListItem
