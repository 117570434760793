import styled from "styled-components"

export default styled.section`
  color: #595959;
  font-weight: 100;

  .modal-filter__title {
    margin-bottom: 15px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  .modal-filter__filters {
    display: flex;
  }

  .modal-filter__footer {
    text-align: center;
  }
`
