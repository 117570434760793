import styled from "styled-components"

export default styled.div`
  max-width: 100%;
  overflow-y: auto;

  .table {
    border-collapse: collapse;
  }

  .table__title {
    caption-side: top;
  }

  .table__cell {
    padding: 0;
    font-size: 14px;
    border: 1px solid #b8b8b8;
  }

  .table__cell .item-null {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    text-align: center;
  }

  .table__cell-inner {
    padding: 5px;
  }

  .table__header .table__cell {
    padding: 10px;
    color: #434343;
    text-align: center;
    vertical-align: middle;
    background-color: #d9d9d9;
  }

  .table__body .table__row {
    position: relative;
    
    &:nth-child(2n) {
      background-color: #f9f9f9;
    }
  }

  .table__body .table__row.checked {
    background-color: #e5e5e5;
  }

  .table__body .table__cell.center {
    text-align: center;
  }

  .table__cell.table-filter {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background-color: #c3c3c3;
    }

    &:active {
      opacity: 0.7;
    }
  }

  .table__cell.table-filter .table__cell-inner {
    position: relative;
    display: inline-block;
    padding-right: 25px;

    .up {
      position: absolute;
      top: 3px;
      right: 5px;
      border-bottom: 8px solid #434343;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      transition: all 0.3s;

      &:hover {
        border-bottom-color: #000;
      }
    }

    .down {
      position: absolute;
      bottom: 3px;
      right: 5px;
      border-top: 8px solid #434343;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      transition: all 0.3s;

      &:hover {
        border-top-color: #000;
      }
    }
  }
`
