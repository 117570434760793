import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static addDesignStyle(designStyle) {
    return http({
      method: "POST",
      url: `${API}design_templates/design_styles`,
      data: designStyle
    })
      .then((res) => res.data)
  }

  static getDesignStyles() {
    return http({
      method: "GET",
      url: `${API}design_templates/design_styles`
    })
      .then((res) => res.data)
  }

  static updateDesignStyle(id, designStyleData) {
    return http({
      method: "PUT",
      url: `${API}design_templates/design_styles/${id}`,
      data: designStyleData
    })
      .then((res) => res.data)
  }

  static getDesignStyleById(id) {
    return http({
      method: "GET",
      url: `${API}design_templates/design_styles/${id}/edit`
    })
      .then((res) => res.data)
  }

  static deleteDesignStyle(id) {
    return http({
      method: "DELETE",
      url: `${API}design_templates/design_styles/${id}`
    })
      .then((res) => res.data)
  }

  static getDesignStyleOptions() {
    return http({
      method: "GET",
      url: `${API}design_templates/design_styles/design_style_options`
    })
      .then((res) => res.data)
  }
}
