import React from "react"
import PropTypes from "prop-types"

export const GlobalButtons = ({ onSave, onCancel }) => (
  <div className="row global-buttons justify-content-md-center">
    <div className="col-12 col-md-4 order-2 order-md-1 d-flex justify-content-center justify-content-md-start">
      <button className="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
    <div className="col-12 col-md-4 order-1 order-md-2 d-flex justify-content-center justify-content-md-start">
      <button className="button mb-md-0 mb-4" onClick={onSave}>
        Save
      </button>
    </div>
  </div>
)

export default GlobalButtons

GlobalButtons.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
