import React from 'react';

import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import MultiSelectShoppeCategories from './MultiSelectShoppeCategories';

function MapCategory({
  category, shoppe_categories, selected_shoppe_categories, onShoppeCategoriesChange,
  getSelectedShoppeCategoriesList,
}) {
  return (
    <div>
      <div className="row categories-mapping">
        <div className="col-md-4 scraper-category-name">
          <span>{category.label}</span>
        </div>
        <div className="col-md-2">
          <FontAwesomeIcon className="fa-2x" icon={faLongArrowAltRight} />
        </div>

        <div className="col-md-6">
          <MultiSelectShoppeCategories
            remote_category_id={category.value}
            shoppe_categories={shoppe_categories}
            selected_shoppe_categories={selected_shoppe_categories}
            onShoppeCategoriesChange={onShoppeCategoriesChange}
            getSelectedShoppeCategoriesList={getSelectedShoppeCategoriesList}
          />
          <div className="invalid-feedback">Please choose a shoppe category.</div>
        </div>
      </div>
    </div>

  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(null, mapDispatchToProps)(MapCategory);
