import React, { lazy } from "react"

// Import Components
const FloorPlans = lazy(() => import("../FloorPlans"))
const Categories = lazy(() => import("../Categories"))
const Textures = lazy(() => import("../Textures"))
const Colors = lazy(() => import("../Colors"))
const DesignStyles = lazy(() => import("../DesignStyles"))
const DesignRules = lazy(() => import("../DesignRules"))
const Templates = lazy(() => import("../Templates"))

export default [
  {
    path: "/design-templates/detail/templates",
    component: () => <Templates />,
    data: {
      title: "Templates"
    }
  },
  {
    path: "/design-templates/detail/floor-plans",
    component: () => <FloorPlans />,
    data: {
      title: "Floor plans"
    }
  },
  {
    path: "/design-templates/detail/categories",
    component: () => <Categories />,
    data: {
      title: "Categories"
    }
  },
  {
    path: "/design-templates/detail/colors",
    component: () => <Colors />,
    data: {
      title: "Colors"
    }
  },
  {
    path: "/design-templates/detail/textures",
    component: () => <Textures />,
    data: {
      title: "Textures"
    }
  },
  {
    path: "/design-templates/detail/design-styles",
    component: () => <DesignStyles />,
    data: {
      title: "Design styles"
    }
  },
  {
    path: "/design-templates/detail/design-templates",
    component: () => <DesignRules />,
    data: {
      title: "Design rules"
    }
  }
]
