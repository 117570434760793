import React from "react"

// Import Components
import { MergePurgeContainer } from "../../../Components/MergePurge"

function Colors() {
  return <MergePurgeContainer type="colors" />
}

export default Colors
