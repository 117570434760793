export const syncValidate = {
  images(value, allValue, props) {
    if (!value) return
    const images = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"]
    const valueType = value && value.type
    const isError = (typeof valueType !== "undefined") && (!images.includes(valueType))
    if (isError) return "Only JPEG, PNG, SVG, GIF files"
  },

  maxLength5(value, allValue, props) {
    return maxLength(5, value, props)
  },

  onlyNumbers(value, allValue, props) {
    if (!value) return
    if ((+value !== 0) && !(+value)) return "Only numbers"
  },

  email(value, allValue, props) {
    const regexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regexp.test(value)) return "Incorrect email";
  },

  greaterThanZero(value, allValue, props) {
    if (value < 0) return "Must be greater than zero or equal zero"
  },

  required(value) {
    if (!value) return "Field is required"
  },

  onlyThreeRules(value) {
    if (value.length !== 3) return "Need to choose three rules"
  }
}

function maxLength(max, value) {
  if (!value) return

  const array = formatNumber(value).split(".")
  if ((array[0].length > max) || (array[1].length > 2)) {
    return `Must be ${max} characters or less before the dot, and max 2 characters after`
  }
}

const formatNumber = (number) => {
  if (Number.isInteger(number)) {
    return +number
  }

  return (+number).toFixed(2)
}
