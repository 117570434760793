import React from "react"
import PropTypes from "prop-types"

// Import Components
import { Field } from "redux-form"
import InputField from "./input"
import InputStyle from "./style"

Input.propTypes = {
  // from parent Components
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string
}

function Input({ name, type, label, defaultValue, validate, placeholder }) {
  return (
    <InputStyle className="redux-form__input">
      {label && <label htmlFor={`${name}-id`} className="input__label">{label}</label>}
      <Field
        name={name}
        component={InputField}
        validate={validate}
        defaultValue={defaultValue}
        placeholder={placeholder}
        type={type}
      />
    </InputStyle>
  )
}

export default Input
