import styled from "styled-components"

export default styled.div`
  position: relative;
  cursor: pointer;
  
  .zoom-image__image {
    width: 50px;
  }
  
  &:hover,
  &:focus {
    .zoom-image__preview {
      display: block;
      border: 3px double #7f7f7f;
    }
  }
  
  .zoom-image__preview {
    display: none;
    position: absolute;
    left: 100%;
    width: 300px;
    min-height: 50px;
    background-color:#fff;
  }
  
  .zoom-image__preview img {
    width: 100%;
    height:auto;
    max-height: 450px;
  }
`
