import React, { Component } from "react"

class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <p className="not-found-wrapper-title">Results not found!</p>
      </div>
    )
  }
}

export default NotFound
