export const newVariantMockData = {
  active: false,
  image2: {
    url: null,
    thumb: { url: null },
    medium: { url: null },
    catalog: { url: null },
    large: { url: null }
  },
  image3: {
    url: null,
    thumb: { url: null },
    medium: { url: null },
    catalog: { url: null },
    large: { url: null }
  },
  default: false,
  default_image: {
    url: null,
    thumb: { url: null },
    medium: { url: null },
    catalog: { url: null },
    large: { url: null }
  },
  id: 0,
  cad_price: "0",
  usd_price: "0",
  image4: {
    url: null,
    thumb: { url: null },
    medium: { url: null },
    catalog: { url: null },
    large: { url: null }
  },
  image5: {
    url: null,
    thumb: { url: null },
    medium: { url: null },
    catalog: { url: null },
    large: { url: null }
  },
  image6: {
    url: null,
    thumb: { url: null },
    medium: { url: null },
    catalog: { url: null },
    large: { url: null }
  },
  msrp_cad: 0,
  msrp_usd: 0,
  name: "",
  sku: "",
  permalink: "",
  url_default_image: null,
  url_image2: null,
  url_image3: null,
  url_image4: null,
  url_image5: null,
  url_image6: null,
  products: []
}
