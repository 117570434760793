import styled from "styled-components"

export default styled.div`
  padding: 30px;
  
  .available-rules__form {
    min-width: 810px;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right:auto;
  }
  
  .available-rules__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 0;
  }
  
  .available-rules__column.fields {
    flex-basis: 100%;
  }
  
  .available-rules__column .redux-form__input {
    margin-bottom: 15px;
  }
  
   .available-rules__column .input__field {
    min-width: 600px;
  }
  
  .available-rules__column .input__label {
    min-width: 200px;
  }
  
  .available-rules__button {
    flex-basis: 100%;
    padding: 10px;
    text-align: center;
  }
`
