import {
  call, put, takeEvery, all,
} from 'redux-saga/effects';
import { Record } from 'immutable';
import { toastr } from 'react-redux-toastr';
import { getTableData, deleteItemsRequest, assignCategoryToItemsRequest } from '../api';
import { appName } from '../constants';

/**
 * Constants
 * */
export const moduleName = 'products';
const prefix = `${appName}/${moduleName}`;

export const TABLE_DATA_REQUEST = `${prefix}/TABLE_DATA_REQUEST`;
export const TABLE_DATA_SUCCESS = 'TABLE_DATA_SUCCESS';
export const TABLE_DATA_ERROR = 'TABLE_DATA_ERROR';

export const REMOVE_ITEM_REQUEST = `${prefix}/REMOVE_ITEM_REQUEST`;
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';
export const REMOVE_ITEM_ERROR = 'REMOVE_ITEM_ERROR';

export const ASSIGN_CATEGORY_ITEM_REQUEST = `${prefix}/ASSIGN_CATEGORY_ITEM_REQUEST`;
export const ASSIGN_CATEGORY_ITEM_SUCCESS = 'ASSIGN_CATEGORY_ITEM_SUCCESS';
export const ASSIGN_CATEGORY_ITEM_ERROR = 'ASSIGN_CATEGORY_ITEM_ERROR';

/**
 * Reducer
 * */

export const ReducerRecord = Record({
  loaded: false,
  loading: false,
  products: null,
  tableLoaded: true,
});

export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload } = action;

  switch (type) {
    case TABLE_DATA_REQUEST:
      return state.set('loading', true).set('loaded', false);
    case TABLE_DATA_SUCCESS:
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('products', payload);

    default:
      return state;
  }
}

/**
 * Selectors
 * */

export const productsSelector = (state) => state;

/**
 * Action Creators
 * */

// Getting products list
export const getProducts = (url) => ({
  payload: url,
  type: TABLE_DATA_REQUEST,
});

const productsToSuccess = (data) => ({ payload: data, type: TABLE_DATA_SUCCESS });

const productsWithError = (error) => ({ type: TABLE_DATA_ERROR, ...error });

// Removing items
export const deleteItems = (tableItems, currentPageLocation) => ({
  payload: {
    currentPageLocation,
    tableItems,
  },
  type: REMOVE_ITEM_REQUEST,
});

const deleteItemsToSuccess = (data) => ({ payload: data, type: ASSIGN_CATEGORY_ITEM_SUCCESS });

const deleteItemsWithError = (error) => ({ type: ASSIGN_CATEGORY_ITEM_ERROR, ...error });

// Assign Category To items
export const assignCategoryToItems = (tableItems, currentPageLocation) => ({
  payload: {
    currentPageLocation,
    tableItems,
  },
  type: ASSIGN_CATEGORY_ITEM_REQUEST,
});

const assignCategoryToItemsToSuccess = (data) => ({ payload: data, type: REMOVE_ITEM_SUCCESS });

const assignCategoryToItemsWithError = (error) => ({ type: REMOVE_ITEM_ERROR, ...error });

/**
 * Sagas
 * */

function* productsData(action) {
  try {
    const result = yield call(() => getTableData(action.payload));

    yield put(productsToSuccess(result.data));
  } catch (error) {
    yield put(productsWithError(error));
  }
}

function* productsRemoveItem(action) {
  try {
    const result = yield call(() => deleteItemsRequest(`/products?${action.payload.tableItems}`));

    yield put(deleteItemsToSuccess(result.data));

    yield put(getProducts(`/products${action.payload.currentPageLocation}`));
  } catch (error) {
    yield put(deleteItemsWithError(error));
  }
}

function* productsAssignCategoryItem(action) {
  try {
    const result = yield call(() => assignCategoryToItemsRequest(`/products/assign_category?${action.payload.tableItems}`));

    toastr.success('Success', 'Categories assigned successfully!');

    yield put(assignCategoryToItemsToSuccess(result.data));

    yield put(getProducts(`/products${action.payload.currentPageLocation}`));
  } catch (error) {
    const toastrError = toastr.error;
    yield call(toastrError, error.message);
    yield put(assignCategoryToItemsWithError(error));
  }
}

export function* saga() {
  yield all([takeEvery(TABLE_DATA_REQUEST, productsData),
    takeEvery(REMOVE_ITEM_REQUEST, productsRemoveItem),
    takeEvery(ASSIGN_CATEGORY_ITEM_REQUEST, productsAssignCategoryItem),
  ]);
}
