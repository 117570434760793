import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static getInventorySuppliers(){
    return http({
      method: "GET",
      url: `${API}etl/inventories/suppliers`
    })
      .then((res) => res.data)
  }

  static addInventory() {
    return http({
      method: "POST",
      url: `${API}etl/inventories`,
    })
      .then((res) => res.data)
  }

  static getInventories() {
    return http({
      method: "GET",
      url: `${API}etl/inventories`
    })
      .then((res) => res.data)
  }

  static updateInventory(id, inventoryData) {
    return http({
      method: "PUT",
      url: `${API}etl/inventories/${id}`,
      data: inventoryData
    })
      .then((res) => res.data)
  }

  static getInventoryById(id) {
    return http({
      method: "GET",
      url: `${API}etl/inventories/${id}/edit`
    })
      .then((res) => res.data)
  }

  static deleteInventory(id) {
    return http({
      method: "DELETE",
      url: `${API}etl/inventories/${id}`
    })
      .then((res) => res.data)
  }
}
