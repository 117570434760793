import React from "react"
import PropTypes from "prop-types"
import StyledComponents from "./style"

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
}

function Layout({ children }) {
  return <StyledComponents>{children}</StyledComponents>
}

export default Layout
