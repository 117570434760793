import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Misc
import { push } from 'react-router-redux';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { rooms } from '../../constants/tableHeaders';
import { breadcrumbs } from '../../constants/breadcrumbs';
import { clearTableSelected } from '../../ducks/table';
import {
  getDataTableRequest,
  getTableData,
  getIsLoading,
  getTotalEntries,
  getCurrentPage,
  getPerPage,
  changeCurrentPage,
  deleteRequest,
  getSelectedCategories,
} from '../../ducks/rooms';

// Redux

// Import Components
import withModal from '../../Components/HOCs/modal';
import RoomsStyle from './style';
import {
  Layout, PageHeader, TableConstructor, Preloader, ModalWindow, Button,
} from '../../Components/Shared';
import TableFilters from '../../Components/Rooms/TableFilters';
import FilterModal from '../../Components/Rooms/FilterModal';
import { getIsAuthenticated } from '../../ducks/auth';

class Rooms extends Component {
  static propTypes = {
    // from HOC modal
    isModalOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    openModal: PropTypes.func,
    // from connect
    tableData: PropTypes.array,
    isLoading: PropTypes.bool,
    totalEntries: PropTypes.number,
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    selectedTable: PropTypes.array,
    push: PropTypes.func,
    q: PropTypes.string,
    categoryIds: PropTypes.array,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }

    const {
      getDataTableRequest, currentPage: page, perPage, q, categoryIds,
    } = this.props;
    getDataTableRequest({
      page, perPage, q, categoryIds,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      getDataTableRequest, currentPage: page, perPage, totalEntries, q, categoryIds,
    } = this.props;
    if ((prevProps.currentPage !== page) || (prevProps.perPage !== perPage)) {
      this.updatePaginationValue(totalEntries, perPage, page);
      getDataTableRequest({
        page, perPage, q, categoryIds,
      });
    }
  }

  updatePaginationValue = (totalEntries, perPage, numberPage) => {
    const pages = Math.ceil(totalEntries / perPage);
    const pageNumber = numberPage > pages ? pages : numberPage;
    this.props.changeCurrentPage(pageNumber);
  };

  onButtonDelete_click = () => {
    const { deleteRequest, selectedTable } = this.props;

    const roomIds = selectedTable.reduce((acc, { name, value }) => {
      if (value === true) {
        acc.push(name);
      }

      return acc;
    }, []);

    deleteRequest(roomIds);
  };

  onButtonCreate_click = () => {
    this.props.push('/rooms/0');
  };

  buttons = (
    <div className="buttons__wrapper">
      <Button
        color="primary"
        size="large"
        type="button"
        uppercase
        width={150}
        onClick={this.onButtonCreate_click}
      >
        Create new room
      </Button>
      <Button
        color="danger"
        size="large"
        type="button"
        uppercase
        width={150}
        onClick={this.onButtonDelete_click}
      >
        Delete
      </Button>
    </div>
  );

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  openModal = () => {
    const { openModal } = this.props;
    openModal();
  };

  render() {
    const {
      isModalOpen, tableData, isLoading, totalEntries, currentPage, perPage, changeCurrentPage,
    } = this.props;
    const noDataStyle = { textAlign: 'center', fontWeight: 700 };

    return (
      <RoomsStyle>
        {isModalOpen && (
          <ModalWindow closeModal={this.closeModal}>
            <FilterModal closeModal={this.closeModal} />
          </ModalWindow>
        )}
        <Layout>
          <Preloader isLoading={isLoading}>
            <PageHeader title="rooms" breadcrumbs={breadcrumbs.rooms} buttonsBlock={this.buttons} />
            <TableFilters openModal={this.openModal} />
            {tableData.length ? (
              <TableConstructor
                name="rooms"
                tableHead={rooms}
                tableData={tableData}
                rowKey="rooms"
                currentPage={currentPage}
                perPage={perPage}
                totalEntries={totalEntries}
                paginationChange={changeCurrentPage}
              />
            ) : (
              <p style={noDataStyle}>No data to display</p>
            )}
          </Preloader>
        </Layout>
      </RoomsStyle>
    );
  }
}

// Enhance
const mapStateToProps = (state) => ({
  tableData: getTableData(state),
  isLoading: getIsLoading(state),
  totalEntries: getTotalEntries(state),
  currentPage: getCurrentPage(state),
  perPage: getPerPage(state),
  selectedTable: state.tableReducer.rooms,
  q: state.searchInputReducer.searchRooms,
  categoryIds: getSelectedCategories(state),
  isAuthenticated: getIsAuthenticated(state),
});
const mapDispatchToProps = {
  clearTableSelected, getDataTableRequest, changeCurrentPage, deleteRequest, push,
};

const enhance = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Rooms);
