import { call, put, takeLatest, all } from "redux-saga/effects"
import { appName } from "../constants"
import { getProductsRequest } from "../api/lt-api"
import { toastr } from "react-redux-toastr"

/**
 * Constants
 **/
export const moduleName = "productsLT"
const prefix = `${appName}/${moduleName}`

const TABLE_DATA_REQUEST = `${prefix}/TABLE_DATA_REQUEST`
const TABLE_DATA_REQUEST_SUCCESSFUL = `${prefix}/TABLE_DATA_REQUEST_SUCCESSFUL`
const TABLE_DATA_REQUEST_FAILURE = `${prefix}/TABLE_DATA_REQUEST_FAILURE`

/**
 * Reducer
 **/
const initialState = {
  tableData: [],
  totalEntries: 0,
  error: null,
  isLoading: false
}

export default function reducer(state = initialState, { type, payload, error, tableData, totalEntries }) {
  switch (type) {
    case TABLE_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case TABLE_DATA_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        tableData: tableData,
        totalEntries: totalEntries
      }

    case TABLE_DATA_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        tableData: [],
        totalEntries: 0,
        error: error
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/

/**
 * Action Creators
 **/
export function getDataTableRequest(pageNumber, perPage, search) {
  return {
    type: TABLE_DATA_REQUEST,
    payload: { pageNumber, perPage, search }
  }
}

export function setTableDataAction(tableData, totalEntries) {
  return {
    type: TABLE_DATA_REQUEST_SUCCESSFUL,
    tableData: tableData,
    totalEntries: totalEntries
  }
}

export function setTableErrorAction(error) {
  return {
    type: TABLE_DATA_REQUEST_FAILURE,
    error: error.message
  }
}

/**
 * Sagas
 **/
function* getDataTableSaga({ payload: { pageNumber, perPage, search } }) {
  try {
    const response = yield call(getProductsRequest, pageNumber, perPage, search)
    yield put(setTableDataAction(response.data, response.meta.totalEntries))
  } catch (error) {
    const toastrError = toastr.error
    yield call(toastrError, error.message)
    yield put(setTableErrorAction(error))
  }
}

export function* saga() {
  yield all([takeLatest(TABLE_DATA_REQUEST, getDataTableSaga)])
}
