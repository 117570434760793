export const filtersDeliveryAgents = {
  country: [
    {
      label: 'United States',
      value: 'us',
    },
    {
      label: 'Canada',
      value: 'ca',
    },
  ],
  quality_rating: [],
  status: [
    {
      label: 'Preferred partner',
      value: '0',
    },
    {
      label: 'Tier 2 partner',
      value: '1',
    },
    {
      label: 'Inactive',
      value: '2',
    },
  ],
};

export const filtersSuppliers = {
  country: [
    {
      label: 'United States',
      value: '1',
    },
    {
      label: 'Canada',
      value: '2',
    },
  ],
};
