import React from "react"
import PropTypes from "prop-types"

// components
import { TableTr } from "../../AttributeTagging"
import { TableTrRule } from "../../CategoryRules"
import TableTrRooms from "../../Rooms/TableRow"

TableBody.propTypes = {
  // from TableConstructor
  tableData: PropTypes.array.isRequired,
  onCheckboxSelected: PropTypes.func,
  selected: PropTypes.array,
  screenHeight: PropTypes.number,
  rowKey: PropTypes.string
}

TableBody.defaultProps = {
  tableData: []
}

function TableBody({ tableData, onCheckboxSelected, selected, screenHeight, rowKey }) {
  const renderTr = tableData.map((item) => {
    const checkbox = selected.find((checkbox) => checkbox.name.toString() === item.id.toString()) || {}
    if (rowKey === "rooms") {
      return (
        <TableTrRooms
          key={item.id}
          item={item}
          selected={selected}
          checkbox={checkbox}
          onCheckboxSelected={onCheckboxSelected}
        />
      )
    }

    if (rowKey === "categoryRules") {
      return (
        <TableTrRule
          key={item.id}
          item={item}
          selected={selected}
          checkbox={checkbox}
          onCheckboxSelected={onCheckboxSelected}
        />
      )
    }

    return (
      <TableTr
        key={item.id}
        item={item}
        selected={selected}
        checkbox={checkbox}
        onCheckboxSelected={onCheckboxSelected}
        screenHeight={screenHeight}
      />
    )
  })

  return <tbody className="table__body">{renderTr}</tbody>
}

export default TableBody
