import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// components
import StyledComponents from './style';

class Input extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    inputClassName: PropTypes.string,
    label: PropTypes.string,
    // from parent component
    name: PropTypes.string,
    onChange: PropTypes.func,
    // "primary"
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    styleType: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    inputClassName: '',
    label: '',
    name: '',
    onChange: () => {},
    onInput: () => {},
    placeholder: '',
    type: 'text',
    value: '',
  };

  labelText = this.props.label.trim();

  inputClassName = classNames({
    input: true,
    'input-primary': this.props.styleType === 'primary',
  });

  labelClassName = classNames({
    'label-primary': this.props.styleType === 'primary',
  });

  render() {
    const {
      name,
      type,
      placeholder,
      value,
      onChange,
      inputClassName,
      onInput,
      dataName,
      className,
      disabled,
      onKeyUp,
    } = this.props;

    return (
      <StyledComponents className={`form-control-wrapper ${inputClassName}`}>
        {!!this.labelText && (
          <label htmlFor={`${name}-id`} className={this.labelClassName}>
            {this.labelText}
          </label>
        )}
        <div className="input__container">
          <input
            data-name={dataName}
            disabled={disabled}
            className={classNames(this.inputClassName, className)}
            id={`${name}-id`}
            onKeyUp={onKeyUp}
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onInput={onInput}
          />
        </div>
      </StyledComponents>
    );
  }
}

export default Input;
