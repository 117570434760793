import React, { PureComponent } from "react"
import PropTypes from "prop-types"

// components
import StyledComponents from "./style"

class Checkbox extends PureComponent {
  static propTypes = {
    // from parent component
    onChange: PropTypes.func,
    value: PropTypes.bool,
    name: PropTypes.any.isRequired
  }

  static defaultProps = {}

  render() {
    const { onChange, value, name, small } = this.props
    return (
      <StyledComponents small={small}>
        <input id={`${name}-id`} name={name} type="checkbox" checked={value} onChange={onChange} />
        <label htmlFor={`${name}-id`} />
      </StyledComponents>
    )
  }
}

export default Checkbox
