import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Import Components
import { Switch, NavLink } from 'react-router-dom';
import MergePurgeStyle from './style';
import { ProtectedRoute } from '../../Components/Shared';
import Colors from './Colors';
import Materials from './Materials';
import { getIsAuthenticated } from '../../ducks/auth';

class MergePurge extends Component {
  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }

    this.props.push('/merge-purge/colors');
  }

  render() {
    return (
      <MergePurgeStyle>
        <h1 className="merge-purge__title">Merge/Purge</h1>
        <nav className="merge-purge-navigate">
          <ul className="merge-purge-navigate__list">
            <li className="merge-purge-navigate__item">
              <NavLink
                to="/merge-purge/colors"
                className="merge-purge-navigate__link"
                activeClassName="active"
              >
                Colors
              </NavLink>
            </li>
            <li className="merge-purge-navigate__item">
              <NavLink
                to="/merge-purge/materials"
                className="merge-purge-navigate__link"
                activeClassName="active"
              >
                Materials
              </NavLink>
            </li>
          </ul>
        </nav>
        <Switch>
          <ProtectedRoute exact path="/merge-purge/colors" component={Colors} />
          <ProtectedRoute exact path="/merge-purge/materials" component={Materials} />
        </Switch>
      </MergePurgeStyle>
    );
  }
}

// Enhance
const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
});
const mapDispatchToProps = { push };

export default connect(mapStateToProps, mapDispatchToProps)(MergePurge);
