import React, { Component } from "react"
import PropTypes from "prop-types"

class AttributesForm extends Component {

  validate = (e) => {
    const value = Array.isArray(e) ? e[0] : e
    const isNotValid = this.props.missingRequiredFields && !value
    return isNotValid ? "is-invalid" : ""
  }

  changeAttributes = (e) => {
    if (this.props.onChangeAttribute) {
      this.props.onChangeAttribute(e)
    }
  }
  render() {
    const { tagData } = this.props

    return (
      <form className="container-fluid attributes-form pl-3 pl-lg-5">
        <div className="row pl-3 pl-lg-5">
          <div className="col-xl-8 order-xl-1 order-2 pl-0 pl-lg-5">
            <div className="row ">
              <div className="col-12 col-md-3">
                <p>NAME: *</p>
              </div>
              <div className="col-12 col-md-9">
                <input
                  className={`form-control ${this.validate(tagData.tag.name)}`}
                  name="tag.name"
                  value={tagData.tag.name || ""}
                  onChange={(e) => this.changeAttributes(e)}
                />
                <div className="invalid-feedback">Please choose a name.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <p>DESCRIPTION: </p>
              </div>
              <div className="col-12 col-md-9">
                <textarea
                  name="tag.description"
                  value={tagData.tag.description || ""}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default AttributesForm

AttributesForm.propTypes = {
  tagData: PropTypes.object.isRequired,
  onChangeAttribute: PropTypes.func.isRequired,
  missingRequiredFields: PropTypes.bool.isRequired
}
