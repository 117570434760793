import React, { Component } from 'react';
import Select from 'react-select';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CopyItemsFormStyle from './style';
import { Button, ModalWindow } from '../../../Shared';
import { getCopyFurnitureItemsRequest } from '../../../../ducks/copyFurnitureItems';
import StyledComponents from '../MoveModal/style';

class CopyItemsForm extends Component {
  static propTypes = {
    products: PropTypes.array,
    room_variants: PropTypes.array,
    roomVariant: PropTypes.object,
    room: PropTypes.object,
    room_id: PropTypes.string,
    current_variant_id: PropTypes.number,
    design_room: PropTypes.object,
    design_room_id: PropTypes.number,
    destination_room_variant_id: PropTypes.number,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
  };

  state = {
    products: this.props.products,
    room_variants: this.props.room_variants,
    roomVariant: null,
    room: this.props.room,
    room_id: this.props.room_id,
    current_variant_id: this.props.current_variant_id,
    design_room: null,
    design_room_id: this.props.design_room_id,
    destination_room_variant_id: null,
    isModalOpen: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      push('/login');
    }

    this.setState({
      isMounted: true,
    });
  }

  validate = (e) => {
    const value = Array.isArray(e) ? e[0] : e;
    const isNotValid = this.props.missingRequiredFields && !value;
    return isNotValid ? 'is-invalid' : '';
  };

  getListRoomVariants = (room_variants) => {
    const variantId = this.state.current_variant_id;
    return room_variants.filter((room_variant) => (
      (room_variant.id !== variantId)
    )).map((room_variant) => (
      { label: room_variant.name, value: room_variant.id }
    ));
  };

  getSelectedListRoomVariant = (itemsList, itemsIds = []) => itemsList.filter((item) => item.value === itemsIds);

  handleChangeRoomVariant = (target) => {
    this.setState({ value: target.value });
    this.setState({ design_room_id: target.value });
    this.setState({ destination_room_variant_id: target.value });
  };

  getCheckedFurniture = (design_rooms, design_room_id) => {
    const design_room_products = design_rooms.filter((design_room) => design_room.design_room_id === design_room_id);

    const products = design_room_products.map((design_room) => design_room.values);

    return products[0].filter((product) => product !== null && product.is_checked_for_move === true).map((product) => product.furniture_id);
  };

  openModal = () => {
    this.setState({
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  handleSubmitRoomVariant = (event) => {
    const { current_variant_id } = this.state;
    const design_room_id = this.state.value;
    const { room_id } = this.state;
    const from_design_room_id = this.props.room.design_room_id;
    const { destination_room_variant_id } = this.state;
    const furniture_ids = this.getCheckedFurniture(this.props.products, from_design_room_id);

    if (destination_room_variant_id === null) {
      this.setState({
        message_title: 'You have not selected destination room variant!',
        message_content: 'Please select the destination room variant and try again.',
      });
      this.openModal();
    }
    if (furniture_ids.length === 0) {
      this.setState({
        message_title: 'You have not selected any products!',
        message_content: 'Please select at least one product and try again.',
      });
      this.openModal();
    }

    if (furniture_ids.length !== 0 && destination_room_variant_id !== null) {
      const { getCopyFurnitureItemsRequest } = this.props;
      const payload = {
        current_variant_id,
        room_id,
        design_room_id,
        from_design_room_id,
        destination_room_variant_id: this.state.destination_room_variant_id,
        furniture_ids,
      };
      getCopyFurnitureItemsRequest(payload);
    }
    event.preventDefault();
  };

  render() {
    const { room_variants } = this.props;
    const listRoomVariants = this.getListRoomVariants(room_variants);
    const { isModalOpen, message_title, message_content } = this.state;

    return (
      <CopyItemsFormStyle>
        <form className="container-fluid" onSubmit={this.handleSubmitRoomVariant}>
          <div className="row mb-5pl-0 copy-item__box-select">
            <div className="col-lg-9 pl-2 flex copy-item__box-label">
              Copy to Variant:
            </div>
            <div className="col-lg-9 pl-2 flex">
              <Select
                className="select2-single"
                options={listRoomVariants}
                onChange={this.handleChangeRoomVariant}
                value={this.getSelectedListRoomVariant(listRoomVariants, this.state.destination_room_variant_id)}
              />
            </div>
          </div>
          <div className="copy-item__box-button">
            <Button type="submit" size="small" width={100}>
              Copy Items
            </Button>
          </div>
        </form>
        {isModalOpen && (
          <ModalWindow closeModal={this.closeModal}>
            <StyledComponents>
              <div className="modal__header">
                <h1 className="modal__title">Copy Products</h1>
              </div>
              <div className="row move-modal-content">
                <span className="modal__content">
                  {message_title}
                </span>
              </div>
              <div className="row move-modal-content">
                <span>
                  {message_content}
                </span>
              </div>
              <div className="add-attributes__footer">
                <Button type="button" width={120} onClick={this.closeModal}>
                  Cancel
                </Button>
              </div>
            </StyledComponents>
          </ModalWindow>
        )}
      </CopyItemsFormStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roomsReducer.isLoading,
});

export default connect(
  mapStateToProps,
  { getCopyFurnitureItemsRequest }
)(CopyItemsForm);
