import { call, put, takeLatest, all, throttle } from "redux-saga/effects"
import { appName } from "../constants"
import { getFiltersRequest, postInputFilter } from "../api/lt-api"
import { toastr } from "react-redux-toastr"
import { setTableDataAction, setTableErrorAction } from "./productsLT"

/**
 * Constants
 **/
export const moduleName = "productsLT"
const prefix = `${appName}/${moduleName}`

const GET_FILTERS_REQUEST = `${prefix}/GET_FILTERS_REQUEST`
const GET_FILTERS_REQUEST_SUCCESSFUL = `${prefix}/GET_FILTERS_REQUEST_SUCCESSFUL`
const GET_FILTERS_REQUEST_FAILURE = `${prefix}/GET_FILTERS_REQUEST_FAILURE`

const SEARCH_FILTER_REQUEST = `${prefix}/SEARCH_FILTER_REQUEST`
const SEARCH_FILTER_REQUEST_SUCCESSFUL = `${prefix}/SEARCH_FILTER_REQUEST_SUCCESSFUL`
const SEARCH_FILTER_REQUEST_FAILURE = `${prefix}/SEARCH_FILTER_REQUEST_FAILURE`

/**
 * Reducer
 **/
const initialState = {
  filtersData: {},
  error: null,
  isLoading: false
}

export default function reducer(state = initialState, { type, error, filtersData }) {
  switch (type) {
    case GET_FILTERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_FILTERS_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        filtersData: filtersData
      }

    case GET_FILTERS_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        filtersData: {},
        error: error
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/

/**
 * Action Creators
 **/
export function getFiltersDataRequest() {
  return { type: GET_FILTERS_REQUEST }
}

export function searchFilterRequest(data) {
  return {
    type: SEARCH_FILTER_REQUEST,
    payload: { data }
  }
}

/**
 * Sagas
 **/
function* getFiltersDataSaga() {
  try {
    const data = yield call(getFiltersRequest)
    yield put({
      type: GET_FILTERS_REQUEST_SUCCESSFUL,
      filtersData: data
    })
  } catch (error) {
    const toastrError = toastr.error
    yield call(toastrError, error.message)
    yield put({
      type: GET_FILTERS_REQUEST_FAILURE,
      error: error.message
    })
  }
}

function* searchFilterRequestSaga(action) {
  try {
    const response = yield call(postInputFilter, action.payload.data)
    yield put({ type: SEARCH_FILTER_REQUEST_SUCCESSFUL })
    yield put(setTableDataAction(response.data, response.meta.totalEntries))
  } catch (error) {
    const toastrError = toastr.error
    yield call(toastrError, error.message)
    yield put({ type: SEARCH_FILTER_REQUEST_FAILURE })
    yield put(setTableErrorAction(error))
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_FILTERS_REQUEST, getFiltersDataSaga),
    throttle(500, SEARCH_FILTER_REQUEST, searchFilterRequestSaga)
  ])
}
