const createFloorPlan = function(designRoomId, roomCount) {
  return {
    id: Number(designRoomId),
    count_room: Number(roomCount)
  }
}

const getFloorPlanAttributesCount = function(roomInner, floorPlanAttributesArray) {
  return roomInner.reduce((acc, { value, count }) => {
    const isRoomAttributeExist = floorPlanAttributesArray.find(({ id }) => id === value)

    if (!isRoomAttributeExist) {
      acc.push(createFloorPlan(value, count))
    }

    return acc
  }, [])
}

const getFloorPlanAttributes = function(floorPlan, roomInner) {
  return floorPlan.floorplan_rooms_attributes.map((item) => {
    const floorPlanAttributesArray = []

    for (const [key, value] of Object.entries(item)) {
      floorPlanAttributesArray.push(createFloorPlan(key, value))
    }

    const floorPlanAttributesCount = getFloorPlanAttributesCount(roomInner, floorPlanAttributesArray)

    return floorPlanAttributesArray.concat(floorPlanAttributesCount)
  })
}

export default function(values, roomInner) {
  const data = { floorplan_rooms_attributes: [] }

  for (const [key, value] of Object.entries(values)) {

    if (key === "floorplan_rooms_attributes") {
      const attributes = getFloorPlanAttributes(values, roomInner)
      data[key] = attributes[0]
    }
    else {
      data[key] = value
    }
  }

  return data
}
