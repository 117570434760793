import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Pagination } from '@zendeskgarden/react-pagination';

const PaginationView = ({ activePage, onPageChange, pages }) => (
  <div className="pagination">
    <ThemeProvider>
      <Pagination aria-label="" totalPages={pages || 1} currentPage={+activePage || 1} pagePadding={1} onChange={onPageChange} />
    </ThemeProvider>
  </div>
);

PaginationView.propTypes = {
  activePage: PropTypes.number,
  onPageChange: PropTypes.func,
  pages: PropTypes.number,
};

export default PaginationView;
