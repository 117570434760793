import React, { useState } from "react"
import PropTypes from "prop-types"
// Misc
import _differenceBy from "lodash/fp/differenceBy"
// Import Components
import ReactSelect from "react-select"

Select.propTypes = {
  // from parent Component
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func
  }).isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool
}

Select.defaultProps = {
  isMulti: false
}

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary50: "#7a7a7a",
    primary25: "#ccc",
    primary: "#8a8a8a",
    neutral0: "#f7f7f7"
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 46
  }
})

const selectError = {
  marginBottom: 0,
  paddingTop: "5px",
  fontSize: "14px",
  color: "red"
}

function Select({
                  input: { name, value, onChange },
                  meta: { error },
                  options,
                  isMulti,
                  deleteFlag
                }) {
  const [touched, setTouched] = useState(false)

  const select_changeHandler = function(option) {
    if (deleteFlag) {
      const difference = _differenceBy("value", value, option)
        .reduce((acc, item) => {
          if (item._destroy === false) {
            acc.push({ ...item, _destroy: true })
          }
          else if (item._destroy) {
            acc.push(item)
          }
          return acc
        }, [])

      if (difference.length) {
        const array = window.localStorage.getItem(`_destroy-${name}`) ?
          JSON.parse(window.localStorage.getItem(`_destroy-${name}`)) : []

        window.localStorage.setItem(`_destroy-${name}`, JSON.stringify([...difference, ...array]))
      }
      onChange(option)
    }
    else {
      onChange(option)
    }
  }

  const select_blurHandler = () => setTouched(true)

  return (
    <div>
      <ReactSelect
        name={name}
        value={value}
        isMulti={isMulti}
        options={options}
        onChange={select_changeHandler}
        onBlur={select_blurHandler}
        theme={selectTheme}
      />
      {(touched && error) && <p style={selectError}>{error}</p>}
    </div>
  )
}

export default Select
