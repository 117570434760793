import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// components
import Td from '../../Shared/TableConstructor/Td';
import { DKCheckbox } from '../../Shared';

TableTrRule.propTypes = {
  onCheckboxSelected: PropTypes.func,
  selected: PropTypes.array,
  item: PropTypes.object,
  screenHeight: PropTypes.number,
};

function TableTrRule({
  item, checkbox, onCheckboxSelected, selected, screenHeight,
}) {
  const rowClassName = classNames({
    table__row: true,
    checked: checkbox.value,
  });

  const renderItem = (item) => {
    if (Array.isArray(item) && !!item.length) {
      return item.map((text) => <span key={text}>{text} </span>);
    }
    if (item === null || item.length === 0) {
      return <span className="item-null">&mdash;</span>;
    }

    return item;
  };

  return (
    <tr className={rowClassName}>
      <Td center>
        {!!selected.length && <DKCheckbox name={item.id} value={checkbox.value} onChange={onCheckboxSelected} />}
      </Td>
      <Td width={100} center>
        {renderItem(item.id)}
      </Td>
      <Td>{renderItem(item.category_name)}</Td>
      <Td>{renderItem(item.key)}</Td>
      <Td>{renderItem(item.value)}</Td>
    </tr>
  );
}

export default connect(
  null,
  { push }
)(TableTrRule);
