import { call, put, takeEvery, all } from "redux-saga/effects"
import { Record } from "immutable"
import { toastr } from "react-redux-toastr"
import { push } from "react-router-redux"

import {
  getTagData,
  updateTagData,
  createTagData,
} from "../api/tag"
import { appName } from "../constants"

/**
 * Constants
 * */
export const moduleName = "tags"
export const subModuleName = "edit"
const prefix = `${appName}/${moduleName}/${subModuleName}`

export const TAG_DATA_REQUEST = `${prefix}/TAG_DATA_REQUEST`
export const TAG_DATA_SUCCESS = "TAG_DATA_SUCCESS"
export const TAG_DATA_ERROR = "TAG_DATA_ERROR"

export const TAG_UPDATE_REQUEST = `${prefix}/TAG_UPDATE_REQUEST`
export const TAG_UPDATE_SUCCESS = "TAG_UPDATE_SUCCESS"
export const TAG_UPDATE_ERROR = "TAG_UPDATE_ERROR"

export const TAG_CREATE_REQUEST = `${prefix}/TAG_CREATE_REQUEST`
export const TAG_CREATE_SUCCESS = "TAG_CREATE_SUCCESS"
export const TAG_CREATE_ERROR = "TAG_CREATE_ERROR"

/**
 * Helpers
 */

const errorBuilder = (err, statusMessage, defaultMessage = "Tag was not") => {
  const resultTitle = "Failed"

  let errorData = err.response.data

  return {
    invoke: () => {
      if (errorData && errorData.error_msg) {
        return toastr.error(resultTitle, errorData.error_msg)
      }

      toastr.error(resultTitle, `${defaultMessage} ${statusMessage}!`)
    }
  }
}

/**
 * Reducer
 * */

export const ReducerRecord = Record({
  loading: true,
  tag: null,
})

export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload } = action

  switch (type) {
    case TAG_DATA_REQUEST:
      return state
        .set("loading", false)
        .set("tag", null)
    case TAG_DATA_SUCCESS:
      return state.set("loading", false).set("tag", payload)
    case TAG_DATA_ERROR:
      return state.set("loading", false)
    case TAG_UPDATE_REQUEST:
      return state.set("loading", true)
    case TAG_UPDATE_SUCCESS:
      return state.set("loading", false)
    case TAG_UPDATE_ERROR:
      return state.set("loading", false)
    case TAG_CREATE_REQUEST:
      return state.set("loading", true)
    case TAG_CREATE_SUCCESS:
      return state.set("loading", false)
    case TAG_CREATE_ERROR:
      return state.set("loading", false)
    default:
      return state
  }
}

/**
 * Selectors
 * */

export const tagSelector = (state) => state.tag.tag
export const loadingSelector = (state) => state.tag.loading

/**
 * Action Creators
 * */


export const getTag = (id) => ({ type: TAG_DATA_REQUEST, payload: id })
const getTagSuccess = (data) => ({ type: TAG_DATA_SUCCESS, payload: data })
const getTagError = (error) => ({ type: TAG_DATA_ERROR, ...error })

export const updateTag = (id, data) => ({ type: TAG_UPDATE_REQUEST, id: id, payload: data })
const updateTagSuccess = (data) => ({ type: TAG_UPDATE_SUCCESS, payload: data })
const updateTagError = (error) => {
  toastr.error(error.message)
  return { type: TAG_UPDATE_ERROR, ...error }
}

export const createTag = (data) => ({ type: TAG_CREATE_REQUEST, payload: data })
const createTagSuccess = (data) => ({ type: TAG_CREATE_SUCCESS, payload: data })
const createTagError = (error) => ({ type: TAG_CREATE_ERROR, ...error })


/**
 * Sagas
 **/

function* tagData(action) {
  try {
    let result = {}
    if (action.payload !== "0") {
      result = yield call(() => getTagData(action.payload))
    }
    yield put(getTagSuccess(result.data))
  } catch (error) {
    yield put(getTagError(error))
  }
}

function* tagUpdate(action) {
  const postfix = "updated"
  try {
    const result = yield call(() => updateTagData(action.id, action.payload))
    yield put(updateTagSuccess(result.data))

    toastr.success("Success", `Tag successfully ${postfix}!`)

    yield put(push("/tags"))
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(updateTagError(error))
  }
}

function* tagCreate(action) {
  const postfix = "created"

  try {
    const result = yield call(() => createTagData(action.payload))
    yield put(createTagSuccess(result.data))

    toastr.success("Success", `Tag successfully ${postfix}!`)

    yield put(push("/tags"))
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(createTagError(error))
  }
}

export function* saga() {
  yield all([
    takeEvery(TAG_DATA_REQUEST, tagData),
    takeEvery(TAG_UPDATE_REQUEST, tagUpdate),
    takeEvery(TAG_CREATE_REQUEST, tagCreate)
  ])
}
