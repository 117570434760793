import React, { Component } from "react"
import { Link } from "react-router-dom"

class ETLProductListOptions extends Component {
  render() {
    const {
      onRemoveItem,
      onMoveScraperSelectedProducts,
      isDisabled,
      shouldCreate,
      shouldDataStatusReport,
      shouldAdd,
      onAddProducts,
      onMoveScraper,
      onMapCategories,
      onRunScraper,
      isAddDisabled
    } = this.props
    return (
      <div className="top-section-links">
        <div className="top-section-links-first">
          <button
            className="top-section-links-first-move-common"
            onClick={onMapCategories}
            disabled={isAddDisabled}
          >
            MAP CATEGORIES
          </button>
          <button
            className="top-section-links-first-move-common"
            onClick={onMoveScraperSelectedProducts}
            disabled={isAddDisabled}
          >
            MOVE SELECTED PRODUCTS
          </button>
          {shouldCreate && (
            <Link className="top-section-links-first-create" to="/products/0">
              CREATE
            </Link>
          )}
          {shouldAdd && (
            <button
              className="top-section-links-first-add top-section-links-second-common"
              onClick={onAddProducts}
              disabled={isAddDisabled}
            >
              ADD
            </button>
          )}
        </div>
        <div className="top-section-links-second">
          {shouldDataStatusReport && (
            <Link className="top-section-links-second-import top-section-links-second-dataStatus" to="/products-logs">
              DATA STATUS REPORT
            </Link>
          )}

          <button
            className="top-section-links-first-add top-section-links-second-move-common"
            onClick={onMoveScraper}
            disabled={isAddDisabled}
          >
            MOVE ALL
          </button>
          <button
            className="top-section-links-first-add top-section-links-second-move-common"
            onClick={onRunScraper}
            disabled={isAddDisabled}
          >
            RUN
          </button>

          {onRemoveItem && (
            <button
              className="top-section-links-second-delete top-section-links-second-common"
              onClick={onRemoveItem}
              disabled={isDisabled}
            >
              DELETE
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default ETLProductListOptions
