import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// components
import Td from '../../Shared/TableConstructor/Td';
import { DKCheckbox, ZoomImage } from '../../Shared';

TableTr.propTypes = {
  // from TableConstructor
  onCheckboxSelected: PropTypes.func,
  selected: PropTypes.array,
  item: PropTypes.object,
  screenHeight: PropTypes.number,
};

function TableTr({
  item, checkbox, onCheckboxSelected, selected, screenHeight,
}) {
  const rowClassName = classNames({
    table__row: true,
    checked: checkbox.value,
  });

  const renderItem = (item) => {
    if (Array.isArray(item) && !!item.length) {
      return item.map((text) => <span key={text}>{text} </span>);
    }
    if (item === null || item.length === 0) {
      return <span className="item-null">&mdash;</span>;
    }

    return item;
  };

  return (
    <tr className={rowClassName}>
      <Td center>
        {!!selected.length && <DKCheckbox name={item.id} value={checkbox.value} onChange={onCheckboxSelected} />}
      </Td>
      <Td width={100} center>
        <ZoomImage
          alt={item.name}
          srcThumb={item.default_image.thumb.url || item.url_default_image}
          srcPreview={item.default_image.large.url || item.url_default_image}
          screenHeight={screenHeight}
        />
      </Td>
      <Td>{renderItem(item.categories)}</Td>
      <Td>{renderItem(item.name)}</Td>
      <Td>{renderItem(item.supplier)}</Td>
      <Td>{renderItem(item.sku)}</Td>
      <Td>{renderItem(item.colors)}</Td>
      <Td>{renderItem(item.materials)}</Td>
      <Td>{renderItem(item.style)}</Td>
      <Td>{renderItem(item.value)}</Td>
      <Td>{renderItem(item.comfort)}</Td>
    </tr>
  );
}

export default connect(
  null,
  { push }
)(TableTr);
