import {
  call, put, all, takeEvery,
} from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { getUsersApi } from '../api/users';

/**
 * Reducer
 * */
const initialState = {
  adminUsers: [],
  isLoading: false,
};

const GET_ADMIN_USERS_REQUEST = 'GET_ADMIN_USERS_REQUEST';
const GET_ADMIN_USERS_SUCCESSFUL = 'GET_ADMIN_USERS_SUCCESSFUL';
const GET_ADMIN_USERS_FAILURE = 'GET_ADMIN_USERS_FAILURE';

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case GET_ADMIN_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ADMIN_USERS_SUCCESSFUL:
      return {
        ...state,
        adminUsers: payload,
        isLoading: false,
      };
    case GET_ADMIN_USERS_FAILURE:
      return {
        ...state,
        error,
        isLoading: false,
      };
    default:
      return state;
  }
}

/**
 * Selectors
 * */
export const usersReducerSelector = (state) => state.usersReducer;

/**
 * Action Creators
 * */
export const getAdminUsersRequest = (payload) => ({
  payload,
  type: GET_ADMIN_USERS_REQUEST,
});

/**
 * Sagas
 * */

function* getAdminUsers({ payload }) {
  try {
    const response = yield call(() => getUsersApi(payload));
    const adminUsers = response.data.map(({ first_name, last_name, id }) => ({
      text: `${first_name} ${last_name}`,
      value: id,
    }));

    yield put({
      payload: adminUsers,
      type: GET_ADMIN_USERS_SUCCESSFUL,
    });
  } catch (error) {
    yield call(() => toastr.error('Somenthing went wrong getting admin users, please try again later'));
    yield put({
      error,
      type: GET_ADMIN_USERS_REQUEST,
    });
  }
}

export function* saga() {
  yield all([
    takeEvery(GET_ADMIN_USERS_REQUEST, getAdminUsers),
  ]);
}
