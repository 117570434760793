/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import { debounce } from 'lodash';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { connect } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';
import BeatLoader from 'react-spinners/BeatLoader';

import { modalSelector } from '../../ducks/modal';

import { Input, Select } from '../Shared';

import './index.scss';

const FilterSidebar = ({ state, saveChanges, filters }) => {
  const filterCategories = Object?.keys(filters)?.filter((key) => !key.includes('currencies'));
  const [actualFilter, setActualFilter] = useState({
    ...state,
  });
  const [filtersSearch, setFiltersSearch] = useState(filters);
  const [search, setSearch] = useState('');
  const [renderTreeCategories, setRenderTreeCategories] = useState(filterCategories.reduce((acc, key) => ({ ...acc, [key]: false }), {}));
  const [categoriesLoading, setCategoriesLoading] = useState(filterCategories.reduce((acc, key) => ({ ...acc, [key]: false }), {}));

  const saveActualFilter = (key, val) => {
    const newState = fromJS((key === 'price_ranges') ? ({
      ...actualFilter,
      filter_currency: val.filter_currency,
      [key]: val.value,
    }) : ({
      ...actualFilter,
      [key]: val,
    })).toJS();
    setActualFilter(newState);
  };

  const disabled = JSON.stringify(actualFilter) === JSON.stringify(state);

  const filterObjectChildrenNodes = (obj, searchValue, expandedCategories) => obj?.map((item) => {
    if (item) {
      if (searchValue.some((combination) => item?.label?.toLowerCase()?.includes(combination?.toLowerCase()))) {
        return item;
      }

      if (item?.children) {
        const children = filterObjectChildrenNodes(item?.children, searchValue, expandedCategories);
        const filteredChildrens = children.filter((child) => child !== undefined);

        if (filteredChildrens.length) {
          if (item?.children && searchValue) {
            expandedCategories.push(item?.value.toString());
          }

          return {
            ...item,
            children: filteredChildrens,
          };
        }
      }
    }

    return undefined;
  }).filter((item) => item !== undefined);

  const debounceSearch = debounce((searchValue) => {
    const seachValueCombinations = [searchValue, ...searchValue.split(' ')];

    const expandedCategories = [];

    const filteredSearch = Object.keys(filters).reduce((acc, key) => ({
      ...acc,
      [key]: filterObjectChildrenNodes(filters[key], seachValueCombinations, expandedCategories),
    }), {});

    setActualFilter({
      ...actualFilter,
      expandedCategories,
    });

    setFiltersSearch(
      searchValue ? filteredSearch : filters,
    );
  }, 300);

  useEffect(() => {
    debounceSearch(search);

    return () => debounceSearch.cancel();
  }, [search]);

  const renderCategories = useMemo(() => ({
    price_ranges: (
      <div className="filter-sidebar__ranges">
        <Select
          className="filter-sidebar__select"
          onChange={(event) => {
            setActualFilter({
              ...actualFilter,
              filter_currency: event.target.value,
            });
          }}
          value={actualFilter.filter_currency}
          name="filter_currency"
          options={[
            {
              text: 'USD',
              value: 'USD',
            },
            {
              text: 'CAD',
              value: 'CAD',
            },
          ]}
        />
        <div className="filter-sidebar__inputs">
          <Input
            name="minRange"
            type="text"
            placeholder="Min"
            value={actualFilter.price_ranges?.[0] || ''}
            onChange={(event) => setActualFilter({
              ...actualFilter,
              price_ranges: [event.target.value, actualFilter?.price_ranges?.[1]],
            })}
          />
          -
          <Input
            name="maxRange"
            type="text"
            placeholder="Max"
            value={actualFilter.price_ranges?.[1] || ''}
            onChange={(event) => setActualFilter({
              ...actualFilter,
              price_ranges: [actualFilter?.price_ranges?.[0], event.target.value],
            })}
          />
        </div>
      </div>
    ),
    quality_rating: (
      <div className="filter-sidebar__ranges">
        <div className="filter-sidebar__inputs">
          <Input
            name="minRating"
            type="text"
            placeholder="Min"
            value={actualFilter.quality_rating?.[0] || ''}
            onChange={(event) => setActualFilter({
              ...actualFilter,
              quality_rating: [event.target.value, actualFilter?.quality_rating?.[1]],
            })}
          />
          -
          <Input
            name="maxRating"
            type="text"
            placeholder="Max"
            value={actualFilter.quality_rating?.[1] || ''}
            onChange={(event) => setActualFilter({
              ...actualFilter,
              quality_rating: [actualFilter?.quality_rating?.[0], event.target.value],
            })}
          />
        </div>
      </div>
    ),
  }), [actualFilter]);

  const renderCheckCategories = useMemo(() => filterCategories.map((category) => (filtersSearch?.[category]?.length || category === 'quality_rating') ? (
    <AccordionItem
      key={category}
      onClick={() => {
        setCategoriesLoading({ ...categoriesLoading, [category]: true });
        setTimeout(() => {
          setRenderTreeCategories({ ...renderTreeCategories, [category]: !renderTreeCategories[category] });
          setCategoriesLoading({ ...categoriesLoading, [category]: false });
        }, 0);
      }}
    >
      <AccordionItemHeading>
        <AccordionItemButton>
          {(category.charAt(0).toUpperCase() + category.slice(1))?.replace('_', ' ')}
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <div className="filter-sidebar__content">
          {categoriesLoading[category] && (
            <div className="filter-sidebar__loader">
              <BeatLoader color="#000" />
            </div>
          )}
          <CheckboxTree
            nodes={filtersSearch[category]}
            checked={actualFilter[category]}
            expanded={actualFilter.expandedCategories}
            expandOnClick
            onlyLeafCheckboxes={false}
            onCheck={(value) => saveActualFilter(category, value)}
            onExpand={(value) => saveActualFilter('expandedCategories', value)}
          />
        </div>
      </AccordionItemPanel>
    </AccordionItem>
  ) : null), [actualFilter, filtersSearch, renderTreeCategories, categoriesLoading]);

  return (
    <div className="filter-sidebar">
      <button
        disabled={disabled}
        onClick={() => saveChanges(actualFilter)}
        className="filter-sidebar__button"
      >
        Apply filters
      </button>
      <Input
        name="filters"
        type="text"
        placeholder="Search filters"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
      >
        {renderCheckCategories}
      </Accordion>
    </div>
  );
};

FilterSidebar.propTypes = {
  filters: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
  }).isRequired,
  saveChanges: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

export default connect(
  (state) => modalSelector(state),
)(FilterSidebar);
