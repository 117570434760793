import { call, put, takeEvery, all } from "redux-saga/effects"
import { Record } from "immutable"
import { getTableData, deleteItemsRequest } from "../api"
import { appName } from "../constants"

/**
 * Constants
 * */
export const moduleName = "roomTypes"
const prefix = `${appName}/${moduleName}`

export const TABLE_DATA_REQUEST = `${prefix}/TABLE_DATA_REQUEST`
export const TABLE_DATA_SUCCESS = "TABLE_DATA_SUCCESS"
export const TABLE_DATA_ERROR = "TABLE_DATA_ERROR"

export const REMOVE_ITEM_REQUEST = `${prefix}/REMOVE_ITEM_REQUEST`
export const REMOVE_ITEM_SUCCESS = "REMOVE_ITEM_SUCCESS"
export const REMOVE_ITEM_ERROR = "REMOVE_ITEM_ERROR"

/**
 * Reducer
 * */

export const ReducerRecord = Record({
  roomTypes: null,
  loading: false,
  loaded: false,
  tableLoaded: true
})

export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload } = action

  switch (type) {
    case TABLE_DATA_REQUEST:
      return state.set("loading", true).set("loaded", false)
    case TABLE_DATA_SUCCESS:
      return state
        .set("loading", false)
        .set("loaded", true)
        .set("roomTypes", payload)

    default:
      return state
  }
}

/**
 * Selectors
 * */

export const roomTypesSelector = (state) => state

/**
 * Action Creators
 * */


export const getRoomTypes = (url) => {
  return {
    type: TABLE_DATA_REQUEST,
    payload: url
  }
}

const roomTypesToSuccess = (data) => ({ type: TABLE_DATA_SUCCESS, payload: data })

const roomTypesWithError = (error) => ({ type: TABLE_DATA_ERROR, ...error })

// Removing items
export const deleteItems = (tableItems, currentPageLocation) => {
  return {
    type: REMOVE_ITEM_REQUEST,
    payload: {
      tableItems,
      currentPageLocation
    }
  }
}

const deleteItemsToSuccess = (data) => ({ type: REMOVE_ITEM_SUCCESS, payload: data })

const deleteItemsWithError = (error) => ({ type: REMOVE_ITEM_ERROR, ...error })

/**
 * Sagas
 **/

function* roomTypesData(action) {
  try {
    const result = yield call(() => {
      return getTableData(action.payload)
    })

    yield put(roomTypesToSuccess(result.data))
  } catch (error) {
    yield put(roomTypesWithError(error))
  }
}

function* roomTypesRemoveItem(action) {
  try {
    const result = yield call(() => {
      return deleteItemsRequest(`/room_types?${action.payload.tableItems}`)
    })

    yield put(deleteItemsToSuccess(result.data))

    yield put(getRoomTypes(`/room_types${action.payload.currentPageLocation}`))
  } catch (error) {
    yield put(deleteItemsWithError(error))
  }
}

export function* saga() {
  yield all([takeEvery(TABLE_DATA_REQUEST, roomTypesData), takeEvery(REMOVE_ITEM_REQUEST, roomTypesRemoveItem)])
}
