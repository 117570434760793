import React, { Suspense } from "react"
// Routes
import { NavLink, Switch } from "react-router-dom"
// Import Components
import DesignTemplatesNavigationStyle from "./style"

import routes from "./routes"
import ProtectedRoute from "../../Shared/ProtectedRoute"

function DesignTemplatesNavigation() {

  const navigationElements = routes.map(({ data, path }) => (
    <li key={data.title} className="navigate-item">
      <NavLink to={path} className="navigate-link" activeClassName="current">
        {data.title}
      </NavLink>
    </li>
  ))

  const navigationRoutes = routes.map(({ data, path, component: Component }) => (
    <ProtectedRoute key={path} exac path={path} component={Component} />
  ))

  return (
    <DesignTemplatesNavigationStyle>
      <h2 className="visually-hidden">Design templates navigation</h2>
      <nav className="navigate">
        <ul className="navigate-list">{navigationElements}</ul>
      </nav>
      <div className="navigate-routes">
        <Suspense fallback={<div>Loading, please wait...</div>}>
          <Switch>{navigationRoutes}</Switch>
        </Suspense>
      </div>
    </DesignTemplatesNavigationStyle>
  )
}

export default DesignTemplatesNavigation
