import { call, put, takeLatest, all } from "redux-saga/effects"
import { appName, POSTFIX } from "../constants"
import Http from "../api/rooms"
import { toastr } from "react-redux-toastr"

/**
 * Constants
 **/
export const moduleName = "rooms"
const prefix = `${appName}/${moduleName}`

const TABLE_DATA_REQUEST = `${prefix}/TABLE_DATA_REQUEST`
const SET_PAGINATION_CALL = `${prefix}/SET_PAGINATION_CALL`
const SET_PER_PAGE_CALL = `${prefix}/SET_PER_PAGE_CALL`
const GET_CATEGORIES_REQUEST = `${prefix}/GET_CATEGORIES_REQUEST`
const SEARCH_REQUEST = `${prefix}/SEARCH_REQUEST`
const SET_SELECTED_CATEGORIES_CALL = `${prefix}/SET_SELECTED_CATEGORIES_CALL`
const SET_SELECTED_TAGS_CALL = `${prefix}/SET_SELECTED_TAGS_CALL`
const SET_SELECTED_BUDGET_FROM_CALL = `${prefix}/SET_SELECTED_BUDGET_FROM_CALL`
const SET_SELECTED_BUDGET_TO_CALL = `${prefix}/SET_SELECTED_BUDGET_TO_CALL`
const DELETE_REQUEST = `${prefix}/DELETE_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  tableData: [],
  tags: [],
  categories: [],
  selectedCategories: [],
  selectedTags: [],
  selectedBudgetFrom: null,
  selectedBudgetTo: null,
  totalEntries: 0,
  currentPage: 1,
  perPage: 50,
  error: null,
  isLoading: false
}

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case TABLE_DATA_REQUEST + POSTFIX.START:
    case DELETE_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case SEARCH_REQUEST + POSTFIX.SUCCESSFUL:
    case TABLE_DATA_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        tableData: payload.tableData,
        totalEntries: payload.totalEntries
      }

    case SEARCH_REQUEST + POSTFIX.FAILURE:
    case TABLE_DATA_REQUEST + POSTFIX.FAILURE:
    case DELETE_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        tableData: [],
        error
      }

    case  SET_PAGINATION_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        currentPage: payload.page
      }

    case SET_PER_PAGE_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        perPage: payload.perPage
      }

    case GET_CATEGORIES_REQUEST + POSTFIX.START:
      return {
        ...state,
        error: null
      }

    case GET_CATEGORIES_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        categories: payload.categories,
        tags: payload.tags
      }

    case GET_CATEGORIES_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        categories: [],
        error
      }

    case SET_SELECTED_CATEGORIES_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        selectedCategories: payload.selectedCategories
      }

    case SET_SELECTED_TAGS_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        selectedTags: payload.selectedTags
      }

    case SET_SELECTED_BUDGET_FROM_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        selectedBudgetFrom: payload.selectedBudgetFrom
      }

    case SET_SELECTED_BUDGET_TO_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        selectedBudgetTo: payload.selectedBudgetTo
      }
    default:
      return state
  }
}

/**
 * Selectors
 **/
export function getTableData(state) {
  return state.roomsReducer.tableData
}

export function getTotalEntries(state) {
  return state.roomsReducer.totalEntries
}

export function getCurrentPage(state) {
  return state.roomsReducer.currentPage
}

export function getPerPage(state) {
  return state.roomsReducer.perPage
}

export function getIsLoading(state) {
  return state.roomsReducer.isLoading
}

export function getCategories(state) {
  return state.roomsReducer.categories
}

export function getTags(state) {
  return state.roomsReducer.tags
}

export function getSelectedCategories(state) {
  return state.roomsReducer.selectedCategories
}

export function getSelectedTags(state) {
  return state.roomsReducer.selectedTags
}

export function getSelectedBudgetFrom(state) {
  return state.roomsReducer.selectedBudgetFrom
}

export function getSelectedBudgetTo(state) {
  return state.roomsReducer.selectedBudgetTo
}

/**
 * Action Creators
 **/
export function getDataTableRequest(data) {
  return {
    type: TABLE_DATA_REQUEST,
    payload: data
  }
}

export function getSearchRequest(data) {
  return {
    type: SEARCH_REQUEST,
    payload: data
  }
}

export function changeCurrentPage(page) {
  return {
    type: SET_PAGINATION_CALL,
    payload: { page }
  }
}

export function changePerPage(page) {
  return {
    type: SET_PER_PAGE_CALL,
    payload: { page }
  }
}

export function getCategoriesRequest() {
  return { type: GET_CATEGORIES_REQUEST }
}

export function setSelectedCategoriesCall(selectedCategories) {
  return {
    type: SET_SELECTED_CATEGORIES_CALL,
    payload: { selectedCategories }
  }
}

export function setSelectedTagsCall(selectedTags) {
  return {
    type: SET_SELECTED_TAGS_CALL,
    payload: { selectedTags }
  }
}

export function setSelectedBudgetFromCall(selectedBudgetFrom) {
  return {
    type: SET_SELECTED_BUDGET_FROM_CALL,
    payload: { selectedBudgetFrom }
  }
}

export function setSelectedBudgetToCall(selectedBudgetTo) {
  return {
    type: SET_SELECTED_BUDGET_TO_CALL,
    payload: { selectedBudgetTo }
  }
}

export function deleteRequest(roomIds) {
  return {
    type: DELETE_REQUEST,
    payload: { roomIds }
  }
}

/**
 * Sagas
 **/
function* getDataTableRequestSaga({ payload }) {
  yield put({ type: TABLE_DATA_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getRooms(payload))
    yield put({
      type: TABLE_DATA_REQUEST + POSTFIX.SUCCESSFUL,
      payload: {
        tableData: response.data,
        totalEntries: response.meta.totalEntries
      }
    })
  }
  catch ({ message }) {
    yield call(() => toastr.error(message))
    yield put({
      type: TABLE_DATA_REQUEST + POSTFIX.FAILURE,
      error: message
    })
  }
}

function* changePaginationSaga({ payload: { page } }) {
  yield put({
    type: SET_PAGINATION_CALL + POSTFIX.SUCCESSFUL,
    payload: { page: +page }
  })
}

function* changePerPageSaga({ payload: { page } }) {
  yield put({
    type: SET_PER_PAGE_CALL + POSTFIX.SUCCESSFUL,
    payload: { perPage: +page }
  })
}

function* getCategoriesRequestSaga() {
  yield put({ type: GET_CATEGORIES_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getCategories())
    yield put({
      type: GET_CATEGORIES_REQUEST + POSTFIX.SUCCESSFUL,
      payload: {
        categories: response.design_room_categories,
        tags: response.tags
      }
    })
  }
  catch ({ message }) {
    yield call(() => toastr.error(message))
    yield put({
      type: GET_CATEGORIES_REQUEST + POSTFIX.FAILURE,
      error: message
    })
  }
}

function* getSearchRequestSaga({ payload }) {
  try {
    const response = yield call(() => Http.searchInRooms(payload))
    yield put({
      type: SEARCH_REQUEST + POSTFIX.SUCCESSFUL,
      payload: {
        tableData: response.data,
        totalEntries: response.meta.totalEntries
      }
    })
  }
  catch ({ message }) {
    yield call(() => toastr.error(message))
    yield put({
      type: SEARCH_REQUEST + POSTFIX.FAILURE,
      error: message
    })
  }
}

function* setSelectedCategoriesSaga({ payload: { selectedCategories } }) {
  yield put({
    type: SET_SELECTED_CATEGORIES_CALL + POSTFIX.SUCCESSFUL,
    payload: { selectedCategories }
  })
}

function* setSelectedTagsSaga({ payload: { selectedTags } }) {
  yield put({
    type: SET_SELECTED_TAGS_CALL + POSTFIX.SUCCESSFUL,
    payload: { selectedTags }
  })
}

function* setSelectedBudgetFromSaga({ payload: { selectedBudgetFrom } }) {
  yield put({
    type: SET_SELECTED_BUDGET_FROM_CALL + POSTFIX.SUCCESSFUL,
    payload: { selectedBudgetFrom }
  })
}

function* setSelectedBudgetToSaga({ payload: { selectedBudgetTo } }) {
  yield put({
    type: SET_SELECTED_BUDGET_TO_CALL + POSTFIX.SUCCESSFUL,
    payload: { selectedBudgetTo }
  })
}

function* deleteRequestSaga({ payload: { roomIds } }) {
  yield put({ type: DELETE_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.deleteRooms(roomIds))
    yield put({
      type: DELETE_REQUEST + POSTFIX.SUCCESSFUL,
      payload: {
        tableData: response.data,
        totalEntries: response.meta.totalEntries
      }
    })
  }
  catch ({ message }) {
    yield call(() => toastr.error(message))
    yield put({
      type: DELETE_REQUEST + POSTFIX.FAILURE,
      error: message
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(TABLE_DATA_REQUEST, getDataTableRequestSaga),
    takeLatest(SET_PAGINATION_CALL, changePaginationSaga),
    takeLatest(SET_PER_PAGE_CALL, changePerPageSaga),
    takeLatest(GET_CATEGORIES_REQUEST, getCategoriesRequestSaga),
    takeLatest(SEARCH_REQUEST, getSearchRequestSaga),
    takeLatest(SET_SELECTED_CATEGORIES_CALL, setSelectedCategoriesSaga),
    takeLatest(SET_SELECTED_TAGS_CALL, setSelectedTagsSaga),
    takeLatest(SET_SELECTED_BUDGET_FROM_CALL, setSelectedBudgetFromSaga),
    takeLatest(SET_SELECTED_BUDGET_TO_CALL, setSelectedBudgetToSaga),
    takeLatest(DELETE_REQUEST, deleteRequestSaga)
  ])
}
