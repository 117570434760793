import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const pagesAmountOptions = [
  { label: 30, value: 30 }, // change to 25
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

const seed = 1;

const PerPage = ({
  activePage,
  onPageAmountChange,
  pagesAmount,
  perPage,
  totalEntriesAmount,
}) => {
  const changeItemsAmount = (item) => {
    onPageAmountChange(item.value);
  };

  return (
    <>
      <div className="per-page">
        <p className="per-page-left-text">Show</p>
        <Select
          placeholder={pagesAmount}
          options={pagesAmountOptions}
          styles={colourStyles}
          onChange={(value) => changeItemsAmount(value)}
        />
        <p className="per-page-right-text">entries</p>
      </div>
      <div className="per-page-info">
        <p className="per-page-info-text">
          Showing {(activePage - seed) * perPage + seed} to{' '}
          {activePage * perPage > totalEntriesAmount ? totalEntriesAmount : activePage * perPage} of{' '}
          {totalEntriesAmount} entries
        </p>
      </div>
    </>
  );
};

PerPage.propTypes = {
  activePage: PropTypes.number.isRequired,
  onPageAmountChange: PropTypes.func.isRequired,
  pagesAmount: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  totalEntriesAmount: PropTypes.number.isRequired,
};

const colourStyles = {
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,
    ':hover': {
      borderColor: '#1ab394',
    },
    borderColor: isSelected || isFocused ? '#1ab394' : '#ccc',
    boxShadow: isSelected || isFocused ? '#1ab394' : null,
    cursor: 'pointer',
    width: '85px',
  }),
  input: (styles) => ({ ...styles, width: '85px' }),
  menu: (styles) => ({ ...styles, width: '85px' }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    ':hover': {
      backgroundColor: '#1ab394',
      color: '#fff',
    },
    backgroundColor: isSelected || isFocused ? '#1ab394' : '#fff',
    color: isSelected || isFocused ? '#fff' : '#5d5d5d',
    cursor: 'pointer',
    width: '85px',
  }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles) => ({ ...styles }),
};

export default PerPage;
