import React from "react"
import PropTypes from "prop-types"

// Misc
import { syncValidate } from "../sync-validate"

// Import Components
import { Field } from "redux-form"
import InputCounterStyle from "./style"
import Counter from "./Counter"

InputCounter.propTypes = {
  // from parent component
  name: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  defaultValue: PropTypes.any
}

function InputCounter({ name, width, defaultValue }) {
  return (
    <InputCounterStyle width={width}>
      <Field
        name={name}
        component={Counter}
        defaultValue={defaultValue}
        validate={[
          syncValidate.onlyNumbers,
          syncValidate.greaterThanZero
        ]}
      />
    </InputCounterStyle>
  )
}

export default InputCounter
