import React from "react"
import PropTypes from "prop-types"

// Import Components
import MergeFormStyle from "./style"
import ReactSelect from "react-select"
import { Button, Input } from "../../Shared"

MergeForm.propTypes = {
  // from MergePurgePage
  onSubmit: PropTypes.func.isRequired,
  itemsFirst: PropTypes.array.isRequired,
  itemsSecond: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  searchInputFirst: PropTypes.string.isRequired,
  searchInputSecond: PropTypes.string.isRequired,
  selectedItemFirst: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ]),
  selectedItemSecond: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ]),
  selectedItemMerge: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.object.isRequired
  ]),
  onChange: PropTypes.func.isRequired,
  onSearchInputChange: PropTypes.func.isRequired,
  disableSubmit: PropTypes.bool
}

MergeForm.defaultProps = {
  onSubmit: () => {},
  itemsFirst: [],
  itemsSecond: [],
  searchInputFirst: "",
  searchInputSecond: "",
  selectedItems: [],
  selectedItemFirst: [],
  selectedItemSecond: [],
  selectedItemMerge: [],
  onChange: () => {},
  onSearchInputChange: () => {}
}

const selectTheme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary50: "#7a7a7a",
    primary25: "#ccc",
    primary: "#8a8a8a"
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 46
  }
})

const setSelectValue = (items, selectedValue) => {
  return items.filter(({ value }) => value !== selectedValue)
}

function MergeForm({
                     onSubmit, itemsFirst, itemsSecond, selectedItems, selectedItemFirst,
                     selectedItemSecond, selectedItemMerge, onChange, disableSubmit,
                     searchInputFirst, searchInputSecond, onSearchInputChange
                   }) {
  const isDisabled = selectedItems
    .map((item) => !!item.value)
    .includes(false)

  const firstSelect = setSelectValue(itemsFirst, selectedItemSecond.value)
  const secondSelect = setSelectValue(itemsSecond, selectedItemFirst.value)

  return (
    <MergeFormStyle>
      <form
        className="merge-form"
        noValidate
        onSubmit={onSubmit}
      >
        <div className="merge-form__item">
          <p className="merge-form__item-title">Merge:</p>
          <div className="merge-form__control">
            <Input
              dataName="itemsFirst"
              name="searchInputFirst"
              type="search"
              placeholder="Search..."
              value={searchInputFirst}
              onChange={onSearchInputChange}
              disabled={true}
            />
            <ReactSelect
              placeholder="Type or Select..."
              options={firstSelect}
              value={selectedItemFirst}
              onChange={(selectedOption) => onChange("selectedToMergeFirst", selectedOption)}
              theme={selectTheme}
              isDisabled={!!!firstSelect.length}
            />
          </div>
          <div className="merge-form__control">
            <Input
              dataName="itemsSecond"
              name="searchInputSecond"
              type="search"
              placeholder="Search..."
              value={searchInputSecond}
              onChange={onSearchInputChange}
            />
            <ReactSelect
              placeholder="Type or Select..."
              options={secondSelect}
              value={selectedItemSecond}
              onChange={(selectedOption) => onChange("selectedToMergeSecond", selectedOption)}
              theme={selectTheme}
              isDisabled={!!!secondSelect.length}
            />
          </div>
        </div>
        <div className="merge-form__item">
          <p className="merge-form__item-title">Us:</p>
          <div className="merge-form__control">
            <ReactSelect
              placeholder="Type or Select..."
              options={selectedItems}
              value={selectedItemMerge}
              onChange={(selectedOption) => onChange("selectedMerge", selectedOption)}
              theme={selectTheme}
              isDisabled={isDisabled}
            />
          </div>
        </div>
        <div className="merge-form__item">
          <Button
            type="submit"
            size="large"
            uppercase
            width={150}
            disabled={disableSubmit}
          >
            Merge
          </Button>
        </div>
      </form>
    </MergeFormStyle>
  )
}

export default MergeForm
