import styled from "styled-components"

export default styled.section`
  .navigate {
    padding: 30px 30px 0 30px;
  }

  .navigate-list {
    display: flex;
    margin-bottom: 0;
    padding-left: 0;
  }
  
  .navigate-item {
    display: flex;
    flex-direction: column;
    margin-right: 3px;
    list-style-type: none;
    
    &:last-child {
      margin-right: 0;
    }
  }
  
  .navigate-link {
    flex-grow: 1;
    padding: 15px 18px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #494949;
    text-transform: uppercase;
    text-decoration: none;
    border: 2px solid #494949;
    border-radius: 10px 10px 0 0;
    transition: all 0.3s;
    
    &:hover,
    &:focus {
      color: #fff;
      background-color:#5b5b5b;
    }
  }
  
  .navigate-link.current {
    color: #fff;
    border-color: #000;
    background-color:#333;
  }
  
  .navigate-routes {
    padding: 30px;
  }
`
