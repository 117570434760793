import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import { toastr } from 'react-redux-toastr';
import { getFullName, getToken, logoutRequest } from '../../../ducks/auth';

class AdminProfile extends Component {
  logout = () => {
    const logoutConfirmOptions = {
      onOk: () => this.props.logoutRequest(this.props.token),
      onCancel: () => {},
    };
    toastr.confirm('Are you sure about this?', logoutConfirmOptions);
  };

  render() {
    return (
      <div className="navigation-profile">
        <div className="navigation-profile-logo">
          <Link to="/" className="navigation-profile-logo-link">
            FURNISHR.
          </Link>
        </div>
        <div className="navigation-profile-avatar">
          <img src={this.props.profile.avatar} alt="avatar" />
        </div>
        <div className="navigation-profile-dropdown">
          <button onClick={this.logout}>
            {this.props.userName}
            <br />
            <sub>logout</sub>
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={faSortDown} alt="profile-options-button-image" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userName: getFullName(state),
  token: getToken(state),
});

const mapDispatchToProps = { logoutRequest };

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);
