import styled, { css } from "styled-components"

export default styled.div`
  .file-uploader__preview-box {
    ${({ width, height }) => css`
      width: ${width};
      height: ${height};
      min-height: ${height};
    `};
    
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    cursor: default;
  }
  
  .file-uploader__preview-box:not([aria-disabled="true"]){
    cursor: pointer;
  }
  
  .file-uploader__preview-box:not([aria-disabled="true"]):hover .file-uploader__hover-item {
    z-index: 10;
    bottom: 0;
  }
  
  .file-uploader__preview {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  
  .file-uploader__hover-item {
    z-index: -10;
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    padding: 15px;
    text-align: center;
    border-top: 1px solid #000;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.3s;
  }
  
  .file-uploader__hover-item:hover {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .file-uploader__hover-item:hover .file-uploader__link {
    color: rgba(255, 255, 255, 1);
  }
  
  .file-uploader__link {
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s;
  }
  
  .file-uploader__label {
    ${({ width }) => css`
      width: ${width}; 
    `};
    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 10px;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .file-uploader__label:hover {
    color: #000;
  }
  
  .file-uploader__label-icon {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 25px;
  }
  
  .file-uploader__messages-box {
    text-align: center;
    padding: 5px;
  }
  
  .file-uploader__message-invalid {
    font-weight: 400;
    font-size: 13px;
    color: #ff3232;
  }
`
