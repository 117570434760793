import { call, put, takeEvery, all } from "redux-saga/effects"
import { Record } from "immutable"
import { toastr } from "react-redux-toastr"
import { push } from "react-router-redux"

import {
  getProductData,
  updateStockStatusProductData,
  getEtlProductData,
  updateProductData,
  createProductData,
  getProductOptionsData,
  getFurnitureData,
  getProductDataClone
} from "../api"
import { appName } from "../constants"

/**
 * Constants
 * */
export const moduleName = "products"
export const subModuleName = "edit"
const prefix = `${appName}/${moduleName}/${subModuleName}`

export const PRODUCT_DATA_REQUEST = `${prefix}/PRODUCT_DATA_REQUEST`
export const PRODUCT_DATA_CLONE_REQUEST = `${prefix}/PRODUCT_DATA_CLONE_REQUEST`
export const PRODUCT_DATA_SUCCESS = "PRODUCT_DATA_SUCCESS"
export const PRODUCT_DATA_ERROR = "PRODUCT_DATA_ERROR"

export const PRODUCT_UPDATE_REQUEST = `${prefix}/PRODUCT_UPDATE_REQUEST`
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS"
export const PRODUCT_UPDATE_ERROR = "PRODUCT_UPDATE_ERROR"

export const PRODUCT_UPDATE_STOCK_STATUS_REQUEST = `${prefix}/PRODUCT_UPDATE_STOCK_STATUS_REQUEST`
export const PRODUCT_UPDATE_STOCK_STATUS_SUCCESS = "PRODUCT_UPDATE_STOCK_STATUS_SUCCESS"
export const PRODUCT_UPDATE_STOCK_STATUS_ERROR = "PRODUCT_UPDATE_STOCK_STATUS_ERROR"

export const PRODUCT_CREATE_REQUEST = `${prefix}/PRODUCT_CREATE_REQUEST`
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS"
export const PRODUCT_CREATE_ERROR = "PRODUCT_CREATE_ERROR"

export const PRODUCT_OPTIONS_DATA_REQUEST = `${prefix}/PRODUCT_OPTIONS_DATA_REQUEST`
export const PRODUCT_OPTIONS_DATA_SUCCESS = "PRODUCT_OPTIONS_DATA_SUCCESS"
export const PRODUCT_OPTIONS_DATA_ERROR = "PRODUCT_OPTIONS_DATA_ERROR"

export const FURNITURE_DATA_REQUEST = `${prefix}FURNITURE_DATA_REQUEST`
export const FURNITURE_DATA_SUCCESS = "FURNITURE_DATA_SUCCESS"
export const FURNITURE_DATA_ERROR = "FURNITURE_DATA_ERROR"

/**
 * Helpers
 */

const errorBuilder = (err, statusMessage, defaultMessage = "Product was not") => {
  const resultTitle = "Failed"

  let errorData = err.response.data

  return {
    invoke: () => {
      if (errorData && errorData.error_msg) {
        return toastr.error(resultTitle, errorData.error_msg)
      }

      toastr.error(resultTitle, `${defaultMessage} ${statusMessage}!`)
    }
    // another error-types handlers...
  }
}

/**
 * Reducer
 * */

export const ReducerRecord = Record({
  loading: true,
  product: null,
  productOptions: null,
  furniture: null
})

export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_DATA_REQUEST:
      return state
        .set("loading", false)
        .set("product", null)
        .set("furniture", null)
    case PRODUCT_DATA_SUCCESS:
      return state.set("loading", false).set("product", payload)
    case PRODUCT_DATA_ERROR:
      return state.set("loading", false)
    case PRODUCT_UPDATE_REQUEST:
      return state.set("loading", true)
    case PRODUCT_UPDATE_SUCCESS:
      return state.set("loading", false)
    case PRODUCT_UPDATE_ERROR:
      return state.set("loading", false)
    case PRODUCT_UPDATE_STOCK_STATUS_REQUEST:
      return state.set("loading", true)
    case PRODUCT_UPDATE_STOCK_STATUS_SUCCESS:
      return state.set("loading", false)
    case PRODUCT_UPDATE_STOCK_STATUS_ERROR:
      return state.set("loading", false)
    case PRODUCT_OPTIONS_DATA_REQUEST:
      return state.set("loading", true)
    case PRODUCT_OPTIONS_DATA_SUCCESS:
      return state.set("loading", false).set("productOptions", payload)
    case PRODUCT_OPTIONS_DATA_ERROR:
      return state.set("loading", false)
    case FURNITURE_DATA_REQUEST:
      return state.set("loading", true)
    case FURNITURE_DATA_SUCCESS:
      return state.set("loading", false).set("furniture", payload)
    case FURNITURE_DATA_ERROR:
      return state.set("loading", false)
    default:
      return state
  }
}

/**
 * Selectors
 * */

export const productSelector = (state) => state.product.product
export const productOptionsSelector = (state) => state.product.productOptions
export const furnitureSelector = (state) => state.product.furniture
export const loadingSelector = (state) => state.product.loading

/**
 * Action Creators
 * */

export const getProduct = (id) => ({ type: PRODUCT_DATA_REQUEST, payload: id })
const getProductSuccess = (data) => ({ type: PRODUCT_DATA_SUCCESS, payload: data })
const getProductError = (error) => ({ type: PRODUCT_DATA_ERROR, ...error })
export const getProductClone = (id) => {
  toastr.success("Cloned successfully")
  return { type: PRODUCT_DATA_CLONE_REQUEST, payload: id }
}

export const updateProduct = (id, data) => ({ type: PRODUCT_UPDATE_REQUEST, id: id, payload: data })
const updateProductSuccess = (data) => ({ type: PRODUCT_UPDATE_SUCCESS, payload: data })
const updateProductError = (error) => {
  toastr.error(error.message)
  return { type: PRODUCT_UPDATE_ERROR, ...error }
}

export const updateStockStatusProduct = (id) => ({ type: PRODUCT_UPDATE_STOCK_STATUS_REQUEST, payload: id })
const updateStockStatusProductSuccess = (data) => ({ type: PRODUCT_UPDATE_STOCK_STATUS_SUCCESS, payload: data })
const updateStockStatusProductError = (error) => ({ type: PRODUCT_UPDATE_STOCK_STATUS_ERROR, ...error })


export const createProduct = (data) => ({ type: PRODUCT_CREATE_REQUEST, payload: data })
const createProductSuccess = (data) => ({ type: PRODUCT_CREATE_SUCCESS, payload: data })
const createProductError = (error) => ({ type: PRODUCT_CREATE_ERROR, ...error })

export const getProductOptions = () => ({ type: PRODUCT_OPTIONS_DATA_REQUEST })
const getProductOptionsSuccess = (data) => ({ type: PRODUCT_OPTIONS_DATA_SUCCESS, payload: data })
const getProductOptionsError = (error) => ({ type: PRODUCT_OPTIONS_DATA_ERROR, ...error })

export const getFurniture = (id) => ({ type: FURNITURE_DATA_REQUEST, payload: id })
const getFurnitureSuccess = (data) => ({ type: FURNITURE_DATA_SUCCESS, payload: data })
const getFurnitureError = (error) => ({ type: FURNITURE_DATA_ERROR, ...error })

/**
 * Sagas
 **/

function* productData(action) {
  try {
    let result = {}
    if (action.payload !== "0" && !action.payload.includes('tmp')) {
      result = yield call(() => getProductData(action.payload))
    }else if(action.payload !== "0" && action.payload.includes('tmp')){
      const product_etl_id = action.payload.substring(4);
      result = yield call(() => getEtlProductData(product_etl_id))
    }
    yield put(getProductSuccess(result.data))
  } catch (error) {
    yield put(getProductError(error))
  }
}

function* productStockStatusData(action) {
  try {
    let result = {}
    if (action.payload !== "0" && !action.payload.includes('tmp')) {
      result = yield call(() => updateStockStatusProductData(action.payload))
    }else if(action.payload !== "0" && action.payload.includes('tmp')){
      const product_etl_id = action.payload.substring(4);
      result = yield call(() => getEtlProductData(product_etl_id))
    }
    toastr.success("Success", `Product successfully update stock status!`)
    yield put(updateStockStatusProductSuccess(result.data))
  } catch (error) {
    yield put(updateStockStatusProductError(error))
  }
}

function* productDataClone(action) {
  try {
    let result = {}
    if (action.payload !== "0") {
      result = yield call(getProductDataClone, action.payload)
    }
    yield put(getProductSuccess(result.data))
  } catch (error) {
    yield put(getProductError(error))
  }
}

function* productOptionsData() {
  try {
    const result = yield call(() => getProductOptionsData())
    yield put(getProductOptionsSuccess(result.data.data))
  } catch (error) {
    yield put(getProductOptionsError(error))
  }
}

function* furnitureData(action) {
  try {
    const result = yield call(() => getFurnitureData(action.payload))
    yield put(getFurnitureSuccess(result.data.data))
  } catch (error) {
    yield put(getFurnitureError(error))
  }
}

function* productUpdate(action) {
  const postfix = "updated"
  try {
    const result = yield call(() => updateProductData(action.id, action.payload))
    yield put(updateProductSuccess(result.data))

    toastr.success("Success", `Product successfully ${postfix}!`)
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(updateProductError(error))
  }
}

function* productCreate(action) {
  const postfix = "created"

  try {
    const result = yield call(() => createProductData(action.payload))
    yield put(createProductSuccess(result.data))

    toastr.success("Success", `Product successfully ${postfix}!`)

    yield put(push("/products"))
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(createProductError(error))
  }
}

export function* saga() {
  yield all([
    takeEvery(PRODUCT_DATA_REQUEST, productData),
    takeEvery(PRODUCT_UPDATE_STOCK_STATUS_REQUEST, productStockStatusData),
    takeEvery(PRODUCT_DATA_CLONE_REQUEST, productDataClone),
    takeEvery(PRODUCT_OPTIONS_DATA_REQUEST, productOptionsData),
    takeEvery(FURNITURE_DATA_REQUEST, furnitureData),
    takeEvery(PRODUCT_UPDATE_REQUEST, productUpdate),
    takeEvery(PRODUCT_CREATE_REQUEST, productCreate)
  ])
}
