import { appName, POSTFIX } from "../constants"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { formatDate } from "../utils/formatDate"
import { getLogsDataStatusReport } from "../api"


/**
 * Constants
 * */

export const moduleName = "dataStatusReport"
const prefix = `${appName}/${moduleName}`

const SET_PAGINATION_CALL = `${prefix}/SET_PAGINATION_CALL`
const TABLE_DATA_REQUEST = `${prefix}/TABLE_DATA_REQUEST`


/**
 * Reducer
 * */

const initialState = {
  parsedExcelData:[],
  totalEntries: 1000,
  currentPage: 1,
  perPage: 200,
  isLoading: false,
  error: null,
}

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case TABLE_DATA_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case TABLE_DATA_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        totalEntries: payload.totalEntries,
        parsedExcelData: payload.parsedExcelData,
      }
    case TABLE_DATA_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }
    case  SET_PAGINATION_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        currentPage: payload.page
      }
    default:
      return state
  }
}

/**
 * Selectors
 * */

export const excelDataStatus = (state) => state.dataStatusReducer.parsedExcelData

export const getCurrentPageDataStatus = (state) => state.dataStatusReducer.currentPage

export const getPerPageDataStatus = (state) => state.dataStatusReducer.perPage

export const getTotalEntriesDataStatus = (state) => state.dataStatusReducer.totalEntries

export const loadingExcelDataStatusSelector = (state) => state.dataStatusReducer.isLoading


/**
 * Action Creators
 **/


export const changeCurrentPageDataStatus = (page) => {
  return {
    type: SET_PAGINATION_CALL,
    payload: { page }
  }
}

export const getDataTableRequestDataStatus = (data) => {
  return {
    type: TABLE_DATA_REQUEST,
    payload: data
  }
}


/**
 * Sagas
 **/

function* changePaginationSaga({ payload: { page } }) {
  yield put({
    type: SET_PAGINATION_CALL + POSTFIX.SUCCESSFUL,
    payload: { page: +page }
  })
}

function* getDataTableRequestSaga(action) {
  yield put({ type: TABLE_DATA_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => getLogsDataStatusReport(action.payload))
    const newDataTable = JSON.parse(JSON.stringify(response.data.data))
    const formatedData = yield call( () => formatDate(newDataTable, ['created_at']))
    yield put({
      type: TABLE_DATA_REQUEST + POSTFIX.SUCCESSFUL,
      payload: {
        parsedExcelData: formatedData,
        totalEntries: response.data.meta.totalEntries,
        currentPage: response.data.meta.page
      }
    })
  }
  catch ({ message }) {
    yield call(() => toastr.error(message))
    yield put({
      type: TABLE_DATA_REQUEST + POSTFIX.FAILURE,
      error: message
    })
  }
}


export function* saga() {
  yield all([
    takeLatest(SET_PAGINATION_CALL, changePaginationSaga),
    takeLatest(TABLE_DATA_REQUEST, getDataTableRequestSaga),
  ])
}
