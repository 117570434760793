import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

ItemsList.propTypes = {
  // from PurgeForm component
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

function ItemsList({ items, selectedItems, onChange }) {
  const listElements = items.map(({ value, label }) => {
    const listElementClassName = classNames({
      "purge__list-item": true,
      "active": selectedItems.includes(value)
    })

    return (
      <li
        key={value}
        data-id={value}
        className={listElementClassName}
        onClick={onChange}
      >
        {label}
      </li>
    )
  })

  return (
    <ul className="purge__list">
      {!!items.length ? listElements : <p className="purge__list-item-none">No data to display</p>}
    </ul>
  )
}

export default ItemsList
