import styled from "styled-components"

export default styled.table`
  margin-right:auto;
  margin-left:auto;
 border-collapse: collapse;

  .table__cell {
    min-width: 150px;   
    padding: 0;
    font-size: 14px;
    border: 1px solid #b8b8b8;
  }
  
  .table__cell.small-size { min-width: 50px }
  
  .table__cell.th {
    padding: 10px;
    color: #434343;
    text-align: center;
    vertical-align: middle;
    background-color: #d9d9d9;
  }
  
  .table__cell.hidden { border: none; }
  
  .table__cell.td {
    padding: 10px;
    color: #434343;
    vertical-align: middle;
    background-color: #fff;
  }
  
  .table__cell.furniture { 
    background-color: #f7f7f7;
    cursor: pointer;
    transition: all 0.3s;
   }
  
  .table__cell.furniture:hover { background-color: #f1f1f1; }
  
  .categories-form__table-input {
    border: none;
    background-color: transparent;
    max-width: 150px;
  }
`
