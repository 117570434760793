import React, { PureComponent } from "react"
import PropTypes from "prop-types"

// components
import StyledButton from "./style"

class Button extends PureComponent {
  static propTypes = {
    // from parent component
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]), // button text
    size: PropTypes.string.isRequired, // "small", "middle" (default), "large"
    color: PropTypes.string.isRequired, // "primary" (default), "secondary", "white", "warning", "danger", "blue"
    type: PropTypes.string.isRequired, // "button" (default), "submit"
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    width: PropTypes.number,
    uppercase: PropTypes.bool // toUppercase()
  }

  static defaultProps = {
    size: "md",
    color: "primary",
    type: "button",
    onClick: () => {}
  }

  onButtonCLick = (event) => {
    const { onClick, disabled } = this.props

    if (disabled) {
      event && event.preventDefault && event.preventDefault()
    }

    onClick()
  }

  render() {
    const { children, size, type, uppercase, color, width, disabled } = this.props
    return (
      <StyledButton
        size={size}
        uppercase={uppercase}
        type={type}
        color={color}
        width={width}
        disabled={disabled}
        onClick={this.onButtonCLick}
      >
        {children}
      </StyledButton>
    )
  }
}

export default Button
