import { call, put, takeEvery, all } from "redux-saga/effects"
import { Record } from "immutable"
import { uploadExcel, getLogsData } from "../api"
import { appName } from "../constants"
import { toastr } from "react-redux-toastr"
import { formatDate } from "../utils/formatDate"

/**
 * Constants
 * */
export const moduleName = "products"
const prefix = `${appName}/${moduleName}`

export const CREATE_PRODUCT_MANY_REQUEST = `${prefix}/CREATE_MANY_REQUEST`
export const CREATE_PRODUCT_MANY_SUCCESS = "CREATE_MANY_SUCCESS"
export const CREATE_PRODUCT_MANY_ERROR = "CREATE_MANY_ERROR"

export const GET_ALL_LOGS_REQUEST = `${prefix}/GET_ALL_LOGS_REQUEST`
export const GET_ALL_LOGS_SUCCESS = "GET_ALL_LOGS_SUCCESS"
export const GET_ALL_LOGS_ERROR = "GET_ALL_LOGS_ERROR"

/**
 * Reducer
 * */

export const ReducerRecord = Record({
  loading: false,
  uploaded: false,
  parsedExcelData: null
})

export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload } = action

  switch (type) {
    case CREATE_PRODUCT_MANY_REQUEST:
      return state.set("loading", true)
    case CREATE_PRODUCT_MANY_SUCCESS:
      return state.set("loading", false).set("uploaded", true)
    case GET_ALL_LOGS_REQUEST:
      return state.set("loading", true)
    case GET_ALL_LOGS_SUCCESS:
      return state.set("parsedExcelData", payload).set("loading", false)
    default:
      return state
  }
}

/**
 * Selectors
 * */

export const excelProductsSelector = (state) => state.logTable.parsedExcelData
export const loadingExcelProductsSelector = (state) => state.logTable.loading
export const uploadingExcelProductsSelector = (state) => state.logTable.uploaded

/**
 * Action Creators
 * */

export const sendFile = (file, email) => {
  return {
    type: CREATE_PRODUCT_MANY_REQUEST,
    payload: {
      file,
      email
    }
  }
}

const fileSendingToSuccess = (data) => ({ type: CREATE_PRODUCT_MANY_SUCCESS, payload: data })

const fileSendingWithError = (error) => ({ type: CREATE_PRODUCT_MANY_ERROR, ...error })

export const getLogs = () => {
  return {
    type: GET_ALL_LOGS_REQUEST,
    payload: {}
  }
}

const logsToSuccess = (data) => ({ type: GET_ALL_LOGS_SUCCESS, payload: data })

const logsWithError = (error) => ({ type: GET_ALL_LOGS_ERROR, ...error })

/**
 * Sagas
 **/

function* excelProductsData(action) {
  try {
    const result = yield call(() => {
      return uploadExcel(action.payload)
    })

    yield put(fileSendingToSuccess(result.data))
    yield put(getLogs())
    toastr.success("Success", `File successfully uploaded!`)
  } catch (error) {
    yield put(fileSendingWithError(error))
  }
}

function* logsData() {
  try {
    const result = yield call(() => getLogsData())
    const copyDataTable = JSON.parse(JSON.stringify(result.data.data))
    const formatedDate = yield call( () => formatDate(copyDataTable, ['start_time', 'finish_time'] ))
    yield put(logsToSuccess(formatedDate))
  } catch (error) {
    yield put(logsWithError(error))
  }
}

export function* saga() {
  yield all([takeEvery(CREATE_PRODUCT_MANY_REQUEST, excelProductsData), takeEvery(GET_ALL_LOGS_REQUEST, logsData)])
}
