import React, { Component } from "react"
import PropTypes from "prop-types"
import Table from "../../../Components/Shared/Table"

const renderColumns = ["start_time", "finish_time", "import_status", "filename", "user", "error"]
const renderColumnsData = ['created_at', 'supplier_name', 'sku', 'product_attribute', 'attribute_error']

class LogTable extends Component {
  render() {
    const { loading, excelProducts, excelDataStatus, name, currentPage, perPage, totalEntries, paginationChange } = this.props

    const mappedProducts =
      excelProducts &&
      excelProducts.map((log) => ({
        start_time: log.start_time,
        finish_time: log.finish_time,
        import_status: log.import_status,
        filename: log.filename,
        user: log.user.first_name + " " + log.user.last_name,
        error: log.error
      }))

    const mappedDataStatus = excelDataStatus &&
      excelDataStatus.map( log => ({
        created_at: log.created_at,
        supplier_name: log.supplier_name,
        sku: log.sku,
        product_attribute: log.product_attribute,
        attribute_error: log.attribute_error,
      }) )

    return (
      <div>
        <legend className="log-table-title">
          {
            name === 'import' ? 'Import Log' : 'Data Status Report'
          }
        </legend>
        <Table
          type={
            name === "import" ? "products" : "dataStatus"
          }
          tableLoaded={!loading}
          renderColumns={
            name === "import" ? renderColumns : renderColumnsData
          }
          items={
            name === "import" ? mappedProducts : mappedDataStatus
          }
          onEditItem={() => {}}
          currentPage={currentPage}
          perPage={perPage}
          totalEntries={totalEntries}
          paginationChange={paginationChange}
        />
      </div>
    )
  }
}

LogTable.propTypes = {
  loading: PropTypes.bool,
  excelProducts: PropTypes.array,
}

export default LogTable
