import React, { Component } from "react"
import { Link } from "react-router-dom"
import StockCheckbox from "../StockCheckbox"

class ProductListOptions extends Component {
  render() {
    const {
      onRemoveItem,
      isDisabled,
      onViewAll,
      onAssignCategory,
      shouldCreate,
      onCreate,
      onStockAvailability,
      shouldImport,
      shouldInStock,
      shouldDataStatusReport,
      shouldAdd,
      onAddProducts,
      isAddDisabled
    } = this.props
    return (
      <div className="top-section-links">
        <div className="top-section-links-first">
          <StockCheckbox
            title={'In Stock'}
            value={shouldInStock}
            onStockAvailability={onStockAvailability}
          />
          {onViewAll && (
            <button onClick={onViewAll} className="top-section-links-first-view-all">
              VIEW ALL
            </button>
          )}
          {shouldCreate && (
            <button onClick={onCreate} className="top-section-links-first-create">
              CREATE
            </button>
          )}
          {shouldAdd && (
            <button
              className="top-section-links-first-add top-section-links-second-common"
              onClick={onAddProducts}
              disabled={isAddDisabled}
            >
              ADD
            </button>
          )}
        </div>
        <div className="top-section-links-second">
          {onViewAll && (
            <button onClick={onAssignCategory} className="top-section-links-first-assign-category">
              ASSIGN CATEGORY
            </button>
          )}
          {shouldDataStatusReport && (
            <Link className="top-section-links-second-import top-section-links-second-dataStatus" to="/products-logs">
              DATA STATUS REPORT
            </Link>
          )}
          {shouldImport && (
            <Link className="top-section-links-second-import" to="/products/import">
              IMPORT
            </Link>
          )}
          {onRemoveItem && (
            <button
              className="top-section-links-second-delete top-section-links-second-common"
              onClick={onRemoveItem}
              disabled={isDisabled}
            >
              DELETE
            </button>
          )}
        </div>
      </div>
    )
  }
}

export default ProductListOptions
