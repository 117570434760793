import React, { Fragment } from 'react';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';

import { connect } from 'react-redux';
import { cloneRoomVariantAction } from '../../../ducks/room';

import ImagesContainer from '../../Shared/ImagesContainer';
import { DOMAIN } from '../../../constants';
import RoomProductList from '../RoomProductList';
import snakeCaseToSplittedCap from '../../../utils/snakeCaseToSplittedCap';
import MoveForm from './MoveForm';
import MultiSelectTags from './MultiSelectTags';

function Variant({
  variant,
  variants,
  opened,
  title,
  renderContent,
  handleClose,
  onVariantsChange,
  onQuantityChange,
  onDesignRoomSwitcherChange,
  onMoveChecked,
  onTagsChange,
  getSelectedTagsList,
  onCalculateVariant,
  onVariantSwitcherChange,
  saveRoomTypeId,
  onImagesChange,
  getImagesList,
  onVariantValidate,
  onCsvPriceBreakdown,
  onSave,
  onDelete,
  cloneRoomVariant,
}) {
  const providePriceTooltip = (data, currency) => {
    if (!data) return '';

    const {
      product_costs, freight_costs, margin, last_mile_delivery, total_price,
    } = data;
    return `<ul>
                <li>${snakeCaseToSplittedCap('product_costs')}: ${product_costs[currency]}</li>
                <li>${snakeCaseToSplittedCap('freight_costs')}: ${freight_costs[currency]}</li>
                <li>${snakeCaseToSplittedCap('margin')}: ${margin[currency]}</li>
                <li>${snakeCaseToSplittedCap('last_mile_delivery')}: ${last_mile_delivery[currency]}</li>
                <li>${snakeCaseToSplittedCap('total_price')}: ${total_price[currency]}</li>
              </ul>`;
  };

  const {
    id, active, is_furniture_layout, name, price_breakdown, selected_tags, tags,
    msrp_usd, msrp_cad, sku, permalink, description, parent_room_id,
  } = variant;

  const isDefault = variant.default || false;
  return (
    <div className="room-attributes">

      <div className="row button-wrapper">
        <div className="col-lg-2">
          <button className="button" type="button" onClick={onSave}>
            Save Variant
          </button>
        </div>
        <div className="col-lg-2">
          <button className="button" onClick={cloneRoomVariant}>
            Clone
          </button>
        </div>
      </div>

      <div className="row room-row">
        <div className="col-lg-3">
          <p className="room-row-title">VARIANT NAME: *</p>
        </div>
        <div className="col-lg-7">
          <input
            name="name"
            value={name}
            className={`form-control ${onVariantValidate(name)}`}
            onChange={(e) => onVariantsChange(e.target.value, id, e.target.name)}
          />
          <div className="invalid-feedback">Please choose a variant name.</div>
        </div>

        <div className="col-lg-1">
          <p className="room-row-title">ACTIVE</p>
          <Switch
            id={id.toString()}
            checked={active}
            width={60}
            height={27}
            handleDiameter={20}
            onColor="#1ab394"
            onChange={(value, child, id) => onVariantSwitcherChange(value, child, id, 'active')}
          />
        </div>
        <div className="col-lg-1">
          <p className="room-row-title">DEFAULT</p>
          <Switch
            id={id.toString()}
            checked={isDefault}
            width={60}
            height={27}
            handleDiameter={20}
            onColor="#1ab394"
            onChange={(value, child, id) => onVariantSwitcherChange(value, child, id, 'default')}
          />
        </div>

      </div>
      <div className="row room-row">
        <div className="col-lg-3">
          <p className="room-row-title">PRICE:</p>
        </div>
        <div className="col-lg-7">
          <div className="row">
            <div className="col-lg-2">
              <p>CAD $</p>
            </div>
            <div className="col-lg-4">
              <input
                data-tip={providePriceTooltip(price_breakdown, 'cad')}
                data-html
                name="msrp_cad"
                value={(price_breakdown && price_breakdown.total_price.cad) || msrp_cad}
                onChange={(e) => onVariantsChange(e.target.value.trim(), id, e.target.name)}
              />
              <ReactTooltip className="variant-price-breakdown" />
            </div>
            <div className="col-lg-2">
              <p>USD $</p>
            </div>
            <div className="col-lg-4">
              <input
                data-tip={providePriceTooltip(price_breakdown, 'usd')}
                data-html
                name="msrp_usd"
                value={(price_breakdown && price_breakdown.total_price.usd) || msrp_usd}
                onChange={(e) => onVariantsChange(e.target.value.trim(), id, e.target.name)}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="row">
            <button className="button" onClick={() => onCalculateVariant(id)}>
              Calculate
            </button>
          </div>
          <div className="row">
            <a className="price-breakdown-link" onClick={() => onCsvPriceBreakdown(id)}>price breakdown</a>
          </div>
        </div>
      </div>
      <div className="row room-row">
        <div className="col-lg-3">
          <p className="room-row-title">SKU: *</p>
        </div>
        <div className="col-lg-5">
          <input
            name="sku"
            value={sku}
            className={`form-control sku ${onVariantValidate(sku)}`}
            onChange={(e) => onVariantsChange(e.target.value.trim(), id, e.target.name)}
          />
          <div className="invalid-feedback">Please choose a sku.</div>
          <div className="sku-feedback">Variant with the same sku already exists.</div>
        </div>
        <div className="col-lg-4">
          <MoveForm
            variant_id={variant.id}
            parent_room_id={parent_room_id}
          />
        </div>
      </div>
      <div className="row room-row">
        <div className="col-lg-3">
          <p className="room-row-title">PERMALINK: </p>
        </div>
        <div className="col-lg-7">
          <input
            name="permalink"
            value={permalink}
            className={`form-control sku ${onVariantValidate(permalink)}`}
            onChange={(e) => onVariantsChange(e.target.value, id, e.target.name)}
          />
        </div>
      </div>
      <div className="row room-row">
        <div className="col-12 col-md-3">
          <p className="room-row-title">DESCRIPTION: </p>
        </div>
        <div className="col-12 col-md-9 attributes-form__product_description">
          <textarea
            rows="7" cols="40"
            name="description"
            value={description}
            className={`form-control sku ${onVariantValidate(description)}`}
            onChange={(e) => onVariantsChange(e.target.value, id, e.target.name)}
          />
        </div>
      </div>
      <div className="row room-row">
        <div className="col-12 col-md-3">
          <p>TAGS: *</p>
        </div>
        <div className="col-12 col-md-9">
          <MultiSelectTags
            variant_id={variant.id}
            tags={tags}
            selected_tags={selected_tags}
            onTagsChange={onTagsChange}
            getSelectedTagsList={getSelectedTagsList}
          />
          <div className="invalid-feedback">Please choose a tag.</div>
        </div>
      </div>

      <div className="row room-images">
        <div className="col-lg-3">
          <p>IMAGES:</p>
        </div>
        <div className="col-lg-9">
          <div className="row">
            <ImagesContainer
              images={getImagesList(variant)}
              onImagesChange={(file, url, index) => onImagesChange(file, url, index, id)}
              imageSize="large"
              customStyles="room"
            />
          </div>
        </div>
      </div>
      {!!id && (
        <>
          <div className="row mb-5">
            <div className="col-lg-12">
              <p>PRODUCT SELECTION:</p>
            </div>
          </div>
          <div className="row room-products">
            <div className="col-lg-12">
              <RoomProductList
                variantId={id}
                variants={variants}
                imageSize="large"
                saveRoomTypeId={saveRoomTypeId}
                products={variant.products}
                onQuantityChange={onQuantityChange}
                onDesignRoomSwitcherChange={onDesignRoomSwitcherChange}
                onMoveChecked={onMoveChecked}
                onSave={onSave}
              />
            </div>
          </div>

          <div className="row button-wrapper">
            <div className="col-lg-8" />
            <div className="col-lg-2 col-layout">
              <span className="room-row-layout-title">Show Layout</span>
              <Switch
                id={id.toString()}
                checked={is_furniture_layout}
                width={60}
                height={27}
                handleDiameter={20}
                onColor="#1ab394"
                onChange={(value, child, id) => onVariantSwitcherChange(value, child, id, 'is_furniture_layout')}
              />
            </div>
            <div className="col-lg-2">
              <a
                className="button button-editor"
                href={`https://${DOMAIN}/room_editor/edit?preset=${variant.id}`}
                target="_blank"
              >
                Room Editor
              </a>
            </div>
          </div>
        </>
      )}
      {opened && (
        <div className="popup-background">
          <div className="popup-wrapper">
            <div className="popup-title">{title}</div>
            <button className="popup-close" onClick={(e) => handleClose(e)}>
              x
            </button>
            {renderContent()}
          </div>
        </div>
      )}
      <div className="row button-wrapper">
        <div className="col-lg-10">
        </div>
        <div className="col-lg-2">
          <button className="button-variant-danger" type="button" onClick={onDelete}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  cloneRoomVariant: () => dispatch(cloneRoomVariantAction(ownProps.variant.id, ownProps.roomId)),
});

export default connect(null, mapDispatchToProps)(Variant);
