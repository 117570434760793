import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const Spinner = () => (
  <div className="spinner">
    <div className="spinner-wrapper">
      <BeatLoader
        className="spinner-wrapper-content"
        sizeUnit="px"
        size={15}
        color="#69ae93"
        loading
      />
    </div>
  </div>
);

export default Spinner;
