import React, { Component } from "react"
import PropTypes from "prop-types"
import {
  getAllMergeItems,
  postAllMergeItems,
  getAllPurgeItems,
  deletePurgeItems,
  postSearchAllMergeItems,
  getProductsByTypeId
} from "../../../api/lt-api"
import { toastr } from "react-redux-toastr"
import _throttle from "lodash/fp/throttle"

// Import Components
import { Preloader } from "../../Shared"
import { MergeForm, PurgeForm, AllItems } from "../index"

class Colors extends Component {
  static propTypes = {
    // from parent component
    type: PropTypes.string.isRequired
  }

  static defaultProps = {
    type: ""
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isListLoading: false,
      products: [],
      items: [],
      itemsPurge: [],
      itemsFirst: [],
      searchInputFirst: "",
      selectedToMergeFirst: [],
      itemsSecond: [],
      searchInputSecond: "",
      selectedToMergeSecond: [],
      selectedMerge: [],
      selectedToPurge: [],
      selectedItem: null
    }

    this.searchAllMergeItemsThrottle = _throttle(500, this.searchAllMergeItemsThrottle)
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    getAllMergeItems(this.props.type)
      .then((items) => {
        this.setState({
          items,
          isLoading: false
        })
      })
      .catch((error) => {
        toastr.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  onSelectChange = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  onSelectPurge = ({ target: { dataset } }) => {
    const { selectedToPurge } = this.state
    const id = +dataset.id
    let selected = []

    if (selectedToPurge.includes(id)) {
      selected = selectedToPurge.filter((itemId) => itemId !== id)
    } else {
      selected = [...selectedToPurge, id]
    }

    this.setState({
      selectedToPurge: selected
    })
  }

  onButtonMergeClick = (event) => {
    event && event.preventDefault && event.preventDefault()
    const { type } = this.props

    this.setState({ isLoading: true })
    const { selectedMerge, selectedToMergeSecond, selectedToMergeFirst } = this.state
    const ids = [selectedToMergeFirst.value, selectedToMergeSecond.value]
    const childId = ids.filter((value) => value !== selectedMerge.value)[0]

    this.postAllMergeItemsRequest(type, selectedMerge.value, childId)
  }

  postAllMergeItemsRequest = (type, selectedMergeValue, childId) => {
    postAllMergeItems(type, selectedMergeValue, childId)
      .then((items) => {
        this.setState({
          isLoading: false,
          items: items,
          selectedToMergeFirst: [],
          selectedToMergeSecond: [],
          selectedMerge: []
        }, () => {
          toastr.success(`Successfully merged`)
          this.resetMergeItems()
        })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        toastr.error(error.message)
      })
  }

  onButtonPurgeClick = () => {
    deletePurgeItems(this.state.selectedToPurge, this.props.type)
      .then((items) => {
        toastr.success("Successfully purged")
        this.setState({
          selectedToPurge: [],
          itemsPurge: items
        })
      })
      .catch((error) => {
        this.setState({ isLoading: false })
        toastr.error(error.message)
      })
  }

  onButtonFindClick = () => {
    this.setState({ isLoading: true })
    getAllPurgeItems(this.props.type)
      .then((items) => {
        this.setState({
          itemsPurge: items,
          isLoading: false
        })
      })
      .catch((error) => {
        toastr.error(error.message)
        this.setState({ isLoading: false })
      })
  }

  searchMergeItems = (value, select) => {
    postSearchAllMergeItems(this.props.type, value)
      .then((data) => this.setState({
        [select]: data
      }))
      .catch((error) => toastr.error(error.message))
  }

  resetMergeItems = () => {
    const { searchInputFirst, searchInputSecond } = this.state
    this.searchMergeItems(searchInputFirst, "itemsFirst")
    this.searchMergeItems(searchInputSecond, "itemsSecond")
    this.setState({
      selectedToMergeFirst: [],
      selectedToMergeSecond: [],
      selectedMerge: []
    })
  }

  searchAllMergeItemsThrottle = (value, select) => {
    this.searchMergeItems(value, select)
  }

  onSelectItem = ({ target: { dataset } }) => {
    const itemId = +dataset.id
    this.setState({
      selectedItem: itemId
    }, () => {
      this.setState({ isListLoading: true })
      getProductsByTypeId(this.props.type, itemId)
        .then((item) => this.setState({
          isListLoading: false,
          products: item.products
        }))
        .catch(({ message }) => {
          this.setState({ isListLoading: false })
          toastr.error(message)
        })
    })
  }

  onSearchInputChange = ({ target: { name, value, dataset } }) => {
    this.setState({
      [name]: value
    }, () => {
      const dotValue = value.replace(/\s+/g, ":")
      this.searchAllMergeItemsThrottle(dotValue, dataset.name)
    })
  }

  render() {
    const { type } = this.props
    const {
      selectedMerge, selectedToMergeFirst, selectedToMergeSecond,
      selectedToPurge, items, isLoading, itemsPurge, itemsFirst, itemsSecond,
      searchInputFirst, searchInputSecond, selectedItem, products, isListLoading
    } = this.state
    const selected = [selectedToMergeFirst, selectedToMergeSecond]
    const disableSubmit = !!!selectedMerge.value

    return (
      <Preloader isLoading={isLoading}>
        <div className="merge-purge__item">
          <div className="merge-purge__item-column">
            <MergeForm
              itemsFirst={itemsFirst}
              itemsSecond={itemsSecond}
              searchInputFirst={searchInputFirst}
              searchInputSecond={searchInputSecond}
              selectedItemFirst={selectedToMergeFirst}
              selectedItemSecond={selectedToMergeSecond}
              selectedItemMerge={selectedMerge}
              selectedItems={selected}
              onChange={this.onSelectChange}
              onSearchInputChange={this.onSearchInputChange}
              disableSubmit={disableSubmit}
              onSubmit={this.onButtonMergeClick}
            />
            <PurgeForm
              items={itemsPurge}
              selectedItems={selectedToPurge}
              onChange={this.onSelectPurge}
              findButtonText={`Find ${type} to purge`}
              onButtonFindClick={this.onButtonFindClick}
              onButtonPurgeClick={this.onButtonPurgeClick}
            />
          </div>
          <div className="merge-purge__item-column">
            <AllItems
              isLoading={isListLoading}
              items={items}
              products={products}
              type={type}
              onSelectItem={this.onSelectItem}
              selectedItem={selectedItem}
            />
          </div>
        </div>
      </Preloader>
    )
  }
}

export default Colors
