import styled from "styled-components"

export default styled.section`
  .update-icon {
    width: 50px;
    height: auto;
  }

  .form-list {
    margin-bottom: 0;
    padding-left: 0;
  }
  
  .form-col {
    min-width: 300px;
    flex-basis: 25%;
    flex-shrink: 0;
    flex-grow: 0;
  }
  
  .form-col-icon {
    display: flex;
    flex-basis: 15%;
    min-width: 50px;
    min-height: 120px;
    justify-content: center;
    align-items: center;
  }
  
  .form-list-item-divider {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 30px;
  }
  
  .form-list-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px;
    list-style-type: none;
  }
  
  .form-col-title { margin-bottom: 5px; }
  
  .form-col-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 10px;
  }
  
  .form-col-links a { margin-right: 10px; }
  
  .form-list-buttons-wrapper {
    width: 50%;
    margin-left:auto;
    margin-right:auto;
    display: flex;
    justify-content: space-around;
  }
`
