import React from "react"
import PropTypes from "prop-types"
import Switch from "react-switch"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"
import { Button } from "../../Shared"

export const Header = ({ productName, checked, onReturn, onChangeSwitch, pushToClone, updateStockStatus }) => {

  return (
    <div className="container-fluid header pl-3 pl-md-5">
      <div className="row align-items-center justify-content-lg-start justify-content-center">
        <div className="col-1 back-arrow" onClick={onReturn}>
          <FontAwesomeIcon className="fa-2x" icon={faLongArrowAltLeft} />
        </div>
        <div className="col-12 col-lg-6 text-center product-name">{productName}</div>
        <div className="col-1">
          <p className="switch-name">ACTIVE</p>
          <Switch
            id="header-switch"
            checked={checked}
            width={60}
            height={27}
            handleDiameter={20}
            onColor="#1ab394"
            onChange={onChangeSwitch}
          />
        </div>
        <div className="col-2">
          {pushToClone && <Button
            onClick={updateStockStatus}
            size="large"
            width={200}
            uppercase
          >
            Stock Status
          </Button>}
        </div>
        <div className="col-2">
          {pushToClone && <Button
            onClick={pushToClone}
            size="large"
            width={150}
            uppercase
          >
            Clone
          </Button>}
        </div>
      </div>
    </div>
  )
}

export default Header

Header.propTypes = {
  pushToClone: PropTypes.func,
  productName: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onReturn: PropTypes.func.isRequired,
  onChangeSwitch: PropTypes.func.isRequired
}
