import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

import { DOMAIN_FE } from '../../../constants';
import { Input, Select } from '../../Shared';

import './index.scss';

export class Header extends Component {
  state = {
    isTypeMode: {
      name: false,
      permalink: false,
    },
  };

  componentDidUpdate() {
    this.refs.nameText.focus();
  }

  handleChangeEditableInputs = (e) => {
    if (e.which === 13) {
      this.fieldSwitcher(e.target.name);
      return;
    }
    if (this.props.onChangeEditableInputs) {
      this.props.onChangeEditableInputs(e);
    }
  };

  fieldSwitcher = (field) => {
    this.setState({ isTypeMode: { ...this.state.isTypeMode, [field]: !this.state.isTypeMode[field] } });
  };

  buttonOpenLink_clickHandler = () => {
    const { push, permalink } = this.props;
    push(`${DOMAIN_FE}/products/${permalink}`);
  };

  render() {
    const { isTypeMode } = this.state;
    const {
      name,
      active,
      onDelete,
      onDublicate,
      onActiveChange,
      onValidate,
      permalink,
      deliveryAgentsReducer,
      deliveryAgent,
      address,
      onChange,
      findAgents,
    } = this.props;

    const { deliveryAgents, isLoading, isLoaded } = deliveryAgentsReducer;

    const renderMessage = () => {
      if (!address || !isLoaded) {
        return 'Enter zip code';
      }

      if (address && !deliveryAgents?.data?.length && isLoaded) {
        return 'No delivery agents found';
      }

      return '';
    };

    return (
      <div className="container-fluid header room pl-5">
        <div className="row align-items-start justify-content-start pl-0 pl-md-5">
          <div className="col-12 col-lg-5">
            <div className="row justify-content-center justify-content-md-start">
              {isTypeMode.name ? (
                <input
                  type="text"
                  ref="nameText"
                  rows="1"
                  value={name}
                  className="room-name"
                  onKeyPress={(e) => this.handleChangeEditableInputs(e)}
                  onChange={(e) => this.handleChangeEditableInputs(e)}
                  onMouseLeave={this.fieldSwitcher.bind(this, 'name')}
                  name="name"
                />
              ) : (
                <div className="name-validation-wrapper">
                  <p
                    ref="nameText"
                    className={`room-name form-control ${onValidate(name)}`}
                    onClick={this.fieldSwitcher.bind(this, 'name')}
                  >
                    {name}
                  </p>
                  <div className="invalid-feedback">Please choose a name.</div>
                </div>
              )}
            </div>
            <div className="row justify-content-center justify-content-md-start room-breadcrumbs">
              <div className="col-3 col-lg-3">
                <a className="breadcrumbs__item breadcrumbs__item-link" href="/">
                  shoppe
                </a>
                <span className="breadcrumbs__item breadcrumbs__item-arrow">&nbsp;/&nbsp;</span>
              </div>
              <div className="col-3 col-lg-3">
                <a className="breadcrumbs__item breadcrumbs__item-link" href="/shoppe/rooms">
                  rooms
                </a>
                <span className="breadcrumbs__item breadcrumbs__item-arrow">&nbsp;/&nbsp;</span>
              </div>
              <div className="col-4 col-lg-4">
                {isTypeMode.permalink ? (
                  <input
                    type="text"
                    rows="1"
                    value={permalink}
                    className="breadcrumbs__item breadcrumbs__item-current breadcrumbs__permalink-editable"
                    onKeyPress={(e) => this.handleChangeEditableInputs(e)}
                    onChange={(e) => this.handleChangeEditableInputs(e)}
                    onMouseLeave={this.fieldSwitcher.bind(this, 'permalink')}
                    name="permalink"
                  />
                ) : (
                  <>
                    <p
                      className="breadcrumbs__item breadcrumbs__item-current"
                      onClick={this.fieldSwitcher.bind(this, 'permalink')}
                    >
                      {permalink}
                    </p>
                    <div className="invalid-feedback">Please choose a name.</div>
                  </>
                )}
              </div>
              <div className="breadcrumbs room-breadcrumbs" />
            </div>
          </div>
          <div className="col-12 col-lg-2 open-link-container">
            <div className="col-12 col-lg-12">
              <a className="button-link" href={`${DOMAIN_FE}/products/${permalink}`} target="_blank">
                Open link
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="row align-items-center">
              <div className="col-md-4 d-flex align-items-center align-items-md-start align-items-lg-end flex-column">
                <p className="switch-name">ACTIVE</p>
                <Switch
                  id="header-switch"
                  checked={active}
                  width={60}
                  height={27}
                  handleDiameter={20}
                  onColor="#1ab394"
                  onChange={onActiveChange}
                />
              </div>
              <div className="col-md-4 my-3 my-md-0 d-flex justify-content-center justify-content-md-end">
                <button className="button-danger" onClick={onDelete}>
                  DELETE ROOM
                </button>
              </div>
              <div className="col-md-4 mb-3 mb-md-0 d-flex justify-content-center">
                <button className="button" onClick={(e) => onDublicate(e)}>
                  DUPLICATE
                </button>
              </div>
            </div>
            {
              deliveryAgents && (
                <>
                  <div className="row mt-2">
                    <div className="header__text">
                      Customer Zipcode
                    </div>
                    <Input
                      name="searchText"
                      type="text"
                      className="header__input"
                      value={address}
                      placeholder="Type zip code"
                      onChange={onChange}
                    />
                    <button className="header__button button" onClick={findAgents}>
                      Find Agents
                    </button>
                  </div>
                  <div className="row mt-2">
                    <div className="header__text">
                      Delivery Agent
                    </div>
                    <Select
                      className="header__select"
                      name="deliveryAgent"
                      onChange={() => {}} // To do
                      disabled={!isLoaded}
                      placeholder={isLoading ? 'Loading...' : renderMessage()}
                      value={deliveryAgent}
                      options={deliveryAgents?.data?.map(({
                        company_name, id, distance, status,
                      }) => {
                        const renderStatus = {
                          inactive: '[I]',
                          preferred_partner: '[P]',
                          tier_2_partner: '[T2]',
                        };

                        return ({
                          text: `${company_name} (${distance?.toFixed(2)} km) ${renderStatus?.[status]}`,
                          value: id,
                        });
                      })}
                    />
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { push })(Header);

Header.propTypes = {
  active: PropTypes.bool.isRequired,
  name: PropTypes.string,
  onActiveChange: PropTypes.func.isRequired,
  onChangeEditableInputs: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
