import React from 'react';
import PropTypes from 'prop-types';

// Misc
import _get from 'lodash/fp/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, change } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import { DOMAIN_FE } from '../../../../../constants';
import { syncValidate } from '../../../../Shared/reduxForm/sync-validate';
import Http from '../../../../../api/design-templates';

// Redux

// Actions
import { deleteNewVariantAction, deleteVariantAction } from '../../../../../ducks/availableTemplates';

// HOCs

// Import Components
import TemplatePackageFormStyle from './style';
import ProductList from './Components/ProductList';
import { ReduxFormInput as Input, ReduxFormSwitch as Switch } from '../../../../Shared/reduxForm';
import { Button } from '../../../../Shared';

TemplatePackageForm.propTypes = {
  // from redux-form
  handleSubmit: PropTypes.func.isRequired,
  // from Templates Component
  formName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function TemplatePackageForm({
  change, match, invalid, deleteVariantAction, deleteNewVariantAction, handleSubmit, onSubmit, variant: { id, products, ...restVariantOptions },
}) {
  const buttonRoomEditorClick = () => {
    window.open(`${DOMAIN_FE}/room_editor/edit?preset=${id}`);
  };

  const buttonDeleteClick = () => {
    if (restVariantOptions.newVariant) {
      deleteNewVariantAction(id);
    } else {
      deleteVariantAction(id, match.params.id);
    }
  };

  const formatNumber = (number) => {
    if (Number.isInteger(number)) {
      return +number;
    }

    return (+number).toFixed(2);
  };

  const buttonCalculateClick = () => {
    Http.getCalculate(id)
      .then((data) => {
        change('cad_price', formatNumber(`${data.total_price.cad}`));
        change('usd_price', formatNumber(`${data.total_price.usd}`));
      })
      .catch(({ message }) => toastr.error(message));
  };

  return (
    <TemplatePackageFormStyle>
      <form
        className="template-package-form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="template-package-form__item part">
          <div className="template-package-form__control">
            <p className="template-package-form__control-title">Variant name: *</p>
            <Input name="name" />
          </div>
          <div className="template-package-form__control">
            <p className="template-package-form__control-title">Price:</p>
            <Input
              name="cad_price"
              label="cad $"
              validate={[
                syncValidate.onlyNumbers,
                syncValidate.maxLength5,
              ]}
            />
            <Input
              name="usd_price"
              label="usd $"
              validate={[
                syncValidate.onlyNumbers,
                syncValidate.maxLength5]}
            />
          </div>
        </div>
        <div className="template-package-form__item flags">
          <div className="template-package-form__control">
            <div className="template-package-form-switch">
              <p className="template-package-form__switch-title">Active</p>
              <Switch name="active" />
            </div>
            <div className="template-package-form-switch">
              <p className="template-package-form__switch-title">Default</p>
              <Switch name="default" />
            </div>
            {!restVariantOptions.newVariant && (
              <div className="template-package-form__button-wrapper">
                <Button
                  type="button"
                  size="large"
                  width={140}
                  onClick={buttonCalculateClick}
                >
                  Calculate
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="template-package-form__item">
          <div className="template-package-form__control">
            <p className="template-package-form__control-title">SKU: *</p>
            <Input name="sku" />
          </div>
        </div>
        <div className="template-package-form__item">
          {products && (
            <div className="template-package-form__control">
              <p className="template-package-form__control-title">Product selection:</p>
              <ProductList furniture={products} variantId={id} />
            </div>
          )}
        </div>
        <div className="template-package-form__item buttons">
          <Button
            type="submit"
            size="large"
            width={150}
            disabled={invalid}
          >
            Save
          </Button>
          <Button
            size="large"
            color="danger"
            width={150}
            onClick={buttonDeleteClick}
          >
            Delete
          </Button>
          {!restVariantOptions.newVariant && (
            <Button
              size="large"
              color="secondary"
              width={150}
              onClick={buttonRoomEditorClick}
            >
              Room Editor
            </Button>
          )}
        </div>
      </form>
    </TemplatePackageFormStyle>
  );
}

// Enhancer
const mapStateToProps = function (state, { formName, variant }) {
  const initialValues = {
    name: _get('name', variant),
    cad_price: _get('cad_price', variant),
    usd_price: _get('usd_price', variant),
    active: _get('active', variant),
    default: _get('default', variant),
    sku: _get('sku', variant),
  };

  return {
    form: formName,
    initialValues,
  };
};

const mapDispatchToProps = { deleteNewVariantAction, deleteVariantAction, change };

const enhancer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    enableReinitialize: true,
  })
);

export default enhancer(TemplatePackageForm);
