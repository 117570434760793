import React, { Component } from 'react';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// HOCs
import { withRouter } from 'react-router-dom';
// Import Components
import { DesignTemplatesLayout, DesignTemplatesNavigation } from '../../Components/DesignTemplates';

class DesignTemplates extends Component {
  constructor(props) {
    super(props);

    this.buttonGoBack_clickHandler = this.buttonGoBack_clickHandler.bind(this);
  }

  componentDidMount() {
    const type = JSON.parse(window.localStorage.getItem('url-name')) || 'templates';
    this.props.push(`/design-templates/detail/${type}`);
  }

  buttonGoBack_clickHandler() {
    this.props.push('/design-templates');
  }

  render() {
    return (
      <DesignTemplatesLayout
        isLoading={false}
        pageTitle="Design rule matching"
        buttonTitle="Back to Rule Matching"
        button_clickHandler={this.buttonGoBack_clickHandler}
      >
        <DesignTemplatesNavigation />
      </DesignTemplatesLayout>
    );
  }
}

// Enhance
const mapStateToProps = null;

const mapDispatchToProps = { push };

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DesignTemplates);
