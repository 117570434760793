import http from './axios.config';
import { API } from '../constants';

export default class Http {
  static getAllScrapers() {
    return http({
      method: 'GET',
      url: `${API}etl/scrapers`,
    })
      .then((res) => res.data);
  }

  static updateScraper(id, scraperData) {
    return http({
      data: scraperData,
      method: 'PUT',
      url: `${API}etl/scrapers/${id}`,
    })
      .then((res) => res.data);
  }

  static getScraperById(id) {
    return http({
      method: 'GET',
      url: `${API}etl/scrapers/${id}`,
    })
      .then((res) => res.data);
  }

  static runScraperById(id) {
    return http({
      method: 'GET',
      url: `${API}etl/scrapers/${id}/run`,
    })
      .then((res) => res.data);
  }

  static getOneScraperProducts(id) {
    return http({
      method: 'GET',
      url: `${API}etl/scrapers/${id}/get_one_scraper_products`,
    })
      .then((res) => res.data);
  }

  static moveProductsById(id) {
    return http({
      method: 'GET',
      url: `${API}etl/scrapers/${id}/move`,
    })
      .then((res) => res.data);
  }

  static getAllScraperSuppliers() {
    return http({
      method: 'GET',
      url: `${API}etl/scrapers/suppliers`,
    })
      .then((res) => res.data);
  }

  static getScraperProductByUrl(etl_product_sku) {
    return http({
      data: {
        etl_product_sku,
      },
      method: 'POST',
      url: `${API}etl/scrapers/product/create`,
    })
      .then((res) => res.data);
  }
}
