import axios from "axios"
import objectToFormData from "../utils/objectToFormData"
import { API } from "../constants"
import { apiUrl } from "./lt-api"

axios.defaults.baseURL = API

const defaultHeaders = {
  method: "get",
  crossDomain: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    withCredentials: true
  }
}


export function getTagData(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}tags/${id}`
  })
}

export function updateTagData(id, payload) {
  const formData = objectToFormData(payload)
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}tags/${id}`,
    method: "PUT",
    data: formData
  })
}

export function createTagData(payload) {
  const formData = objectToFormData(payload)
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}tags`,
    method: "POST",
    data: formData
  })
}


