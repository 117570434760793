import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _debounce from 'lodash/fp/debounce';
import { setSelectorValue } from '../../../ducks/selector';
import { setInputValue } from '../../../ducks/searchInput';
import { searchFilterRequest } from '../../../ducks/filters';

// components
import withModal from '../../HOCs/modal';
import StyledComponents from './style';
import { Select, Input, Button } from '../../Shared';
import { ModalFilterAttribute } from '../index';

class TableFilters extends Component {
  static propTypes = {
    // from HOC modal
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
    // from connect
    entriesPerPage: PropTypes.number,
    page: PropTypes.number,
    searchFilterRequest: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchText: this.props.inputSearchValue || '',
      tableEntriesPerPage: [
        { value: 25, text: 25 },
        { value: 50, text: 50 },
        { value: 100, text: 100 },
        { value: 200, text: 200 },
      ],
    };

    this.onSearch = _debounce(300, this.onSearch);
  }

  onFormFieldsValueChange = ({ target: { name, value } }) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.props.setInputValue(this.state.searchText, 'searchInputAttributeTagging');
      }
    );
  };

  onEntriesPerPageChange = ({ target: { name, value } }) => {
    this.props.setSelectorValue(value);
  };

  onSearch = () => {
    const { searchFilterRequest } = this.props;
    searchFilterRequest({
      page: 1,
      per_page: this.props.entriesPerPage,
      q: this.state.searchText,
    });
  };

  filtersFirstWorldStyle = { marginRight: '7px' };

  filtersSecondWorldStyle = { marginLeft: '7px' };

  render() {
    const {
      isModalOpen, closeModal, openModal, entriesPerPage,
    } = this.props;
    const { tableEntriesPerPage, searchText } = this.state;

    return (
      <>
        {isModalOpen && <ModalFilterAttribute closeModal={closeModal} />}
        <StyledComponents>
          <h2 className="visually-hidden">Product table filters</h2>
          <div className="filters__item">
            <span className="filters__word" style={this.filtersFirstWorldStyle}>
              Show
            </span>
            <Select
              name="entriesPerPage"
              options={tableEntriesPerPage}
              value={entriesPerPage}
              onChange={this.onEntriesPerPageChange}
            />
            <span className="filters__word" style={this.filtersSecondWorldStyle}>
              entries
            </span>
          </div>
          <div className="filters__item full-width">
            <Input
              name="searchText"
              type="search"
              placeholder="Search in Furniture"
              value={searchText}
              onChange={this.onFormFieldsValueChange}
              onInput={this.onSearch}
            />
            <Button width={200} color="tertiary" size="large" uppercase onClick={openModal}>
              Filter
            </Button>
          </div>
        </StyledComponents>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  page: state.paginationReducer.value,
  entriesPerPage: state.selectorReducer.value,
  inputSearchValue: state.searchInputReducer.searchInputAttributeTagging,
});

export default compose(
  withModal,
  connect(
    mapStateToProps,
    { setSelectorValue, searchFilterRequest, setInputValue }
  )
)(TableFilters);
