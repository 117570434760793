import React, { Fragment } from "react"
import PropTypes from "prop-types"

// components
import { Input } from "../../../Shared"

DynamicInputs.propTypes = {
  // from DynamicFormFields component
  onRulesChange: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  ruleId: PropTypes.string.isRequired
}

DynamicInputs.defaultProps = {
  fields: [],
  onAttributesChange: () => {}
}

function DynamicInputs({ fields, onRulesChange, ruleId }) {
  const renderFields = fields.map(({ id, name, value, label }, index) => {
    return (
      <Input
        key={id}
        name={name}
        label={label}
        value={value}
        onChange={(event) => onRulesChange(event, ruleId, index)}
        styleType="primary"
      />
    )
  })

  return <Fragment>{renderFields}</Fragment>
}

export default DynamicInputs
