import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

// Import Components
import ListItem from "./ListItem"

List.propTypes = {
  // from Colors component
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onSelectItem: PropTypes.func,
  selectedItem: PropTypes.number,
  isLoading: PropTypes.bool
}

function List({ items, title, active, onSelectItem, selectedItem, isLoading }) {
  const listElements = items.map(({ value, label, furniture_count }) => {
    const ListClassName = classnames({
      "merge-purge-all-items__list-item": true,
      "hovered": active,
      "current": selectedItem === value
    })

    return (
      <li
        data-id={value}
        key={value}
        className={ListClassName}
        onClick={onSelectItem}
      >
        <ListItem
          active={active}
          value={value}
          label={label}
          furnitureCount={furniture_count}
        />
      </li>
    )
  })

  const renderList = () => {
    if (isLoading) {
      return <p className="merge-purge-all-items__list merge-purge-all-items__none">Loading, please wait...</p>
    } else if (items.length) {
      return (
        <ul className="merge-purge-all-items__list">
          {listElements}
        </ul>
      )
    }
    return <p className="merge-purge-all-items__list merge-purge-all-items__none">No products to display</p>
  }

  return (
    <section className="merge-purge-all-items">
      <h2 className="merge-purge-all-items__title">
        {title}
      </h2>
      {renderList()}
    </section>
  )
}

export default List
