import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// Misc
import _get from 'lodash/fp/get';
import { change, reduxForm } from 'redux-form';
import { formName } from '../../../../../constants/forms';
import { syncValidate } from '../../../../Shared/reduxForm/sync-validate';
import {
  getAllFloorplansSelector,
  getAvailableTemplateSelector,
  makeCurrentFloorplanId,
} from '../../../../../ducks/availableTemplates';
// Import Component
import TemplateInfoFormStyle from './style.js';
import { Button } from '../../../../Shared';
import { ReduxFormFileUploader, ReduxFormInput, ReduxFormSelect } from '../../../../Shared/reduxForm';

class TemplateInfoForm extends Component {
  static propTypes = {
    // from redux-form
    handleSubmit: PropTypes.func,
    // from Templates/Detail
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.floorplansWidth !== this.props.floorplansWidth || prevProps.floorplansLength !== this.props.floorplansLength) {
      const { change, floorplansWidth, floorplansLength } = this.props;

      change('width', floorplansWidth);
      change('depth', floorplansLength);
    }
  }

  render() {
    const {
      floorplans, handleSubmit, onSubmit, invalid, initialValues,
    } = this.props;

    return (
      <TemplateInfoFormStyle>
        <form
          className="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form__row">
            <p className="form__row-title">SKU: *</p>
            <div className="form__control center">
              <ReduxFormInput name="sku" />
            </div>
          </div>
          <div className="form__row">
            <p className="form__row-title">Images:</p>
            <div className="form__row-images">
              <div className="form__file-control">
                <ReduxFormFileUploader
                  key="default_image"
                  name="default_image"
                  fileUrl={initialValues.default_image}
                  formName={formName.templateEditInfoForm}
                  width="175px"
                  height="175px"
                  validate={[
                    syncValidate.images,
                  ]}
                />
              </div>
              <div className="form__file-control">
                <ReduxFormFileUploader
                  key="image2"
                  name="image2"
                  fileUrl={initialValues.image2}
                  formName={formName.templateEditInfoForm}
                  width="175px"
                  height="175px"
                  validate={[
                    syncValidate.images,
                  ]}
                />
              </div>
              <div className="form__file-control">
                <ReduxFormFileUploader
                  key="image3"
                  name="image3"
                  fileUrl={initialValues.image3}
                  formName={formName.templateEditInfoForm}
                  width="175px"
                  height="175px"
                  validate={[
                    syncValidate.images,
                  ]}
                />
              </div>
              <div className="form__file-control">
                <ReduxFormFileUploader
                  key="image4"
                  name="image4"
                  fileUrl={initialValues.image4}
                  formName={formName.templateEditInfoForm}
                  width="175px"
                  height="175px"
                  validate={[
                    syncValidate.images,
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="form__row start">
            <p className="form__row-title">Floor plan:</p>
            <div className="form__column floor-plan">
              <div className="form__control">
                <ReduxFormSelect
                  key="floor_plan"
                  name="floor_plan"
                  options={floorplans}
                />
              </div>
            </div>
            <div className="form__column">
              <div className="form__control">
                <ReduxFormInput name="width" label="Width" />
              </div>
              <div className="form__control">
                <ReduxFormInput name="depth" label="Length" />
              </div>
            </div>
          </div>
          <div className="form__submit-button-box">
            <Button
              type="submit"
              size="large"
              width={200}
              disabled={invalid}
            >
              Save
            </Button>
          </div>
        </form>
      </TemplateInfoFormStyle>
    );
  }
}

// Enhance
const mapStateToProps = (state) => {
  const template = getAvailableTemplateSelector(state);
  const getCurrentFloorplan = makeCurrentFloorplanId();
  const floorplan = getCurrentFloorplan(state);

  const initialValues = {
    sku: _get('sku', template),
    depth: _get('depth', template),
    width: _get('width', template),
    default_image: _get('default_image.url', template),
    image2: _get('image2.url', template),
    image3: _get('image3.url', template),
    image4: _get('image4.url', template),
    floor_plan: floorplan,
  };

  return {
    initialValues,
    floorplans: getAllFloorplansSelector(state),
    floorplansWidth: _get('form.templateEditInfoForm.values.floor_plan.width', state),
    floorplansLength: _get('form.templateEditInfoForm.values.floor_plan.height', state),
  };
};

const enhance = compose(
  connect(mapStateToProps, { change }),
  reduxForm({
    form: formName.templateEditInfoForm,
    enableReinitialize: true,
  })
);

export default enhance(TemplateInfoForm);
