import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import equal from 'deep-equal';

import qs from '../../../modules/normalizingQs';
import { getInventories, inventoriesSelector, deleteItems } from '../../../ducks/inventories';
import { closeModal, showModal } from '../../../ducks/modal';

import FilteringSection from '../../Filtering';
import TopInventorySection from '../../Shared/TopInventorySection';
import Pagination from '../../Shared/Pagination';
import Spinner from '../../Shared/Spinner';
import InventoryTable from '../../Shared/InventoryTable';

import { WAIT_INTERVAL } from '../../../constants';

const renderColumns = [
  'checkbox',
  'id',
  'name',
  'google_drive_id',
  'url',
  'supplier_name',
  'inventory_status',
  'inventory_file_type',
  'created_at',
  'updated_at',
];

const breadcrumbsItems = [
  {
    to: '/etl/inventory-files',
    name: 'inventories',
  },
  {
    to: '/',
    name: 'files',
  },
];

const basicStateValues = {
  selected: [],
  q: undefined,
  sort: undefined,
};

export class Inventories extends Component {
  state = {
    ...basicStateValues,
    params: {
      page: this.props.history.location.search,
    },
    modalIsOpen: false,
    q: undefined,
    sort: undefined,
    isDisabled: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }
    const { getInventories } = this.props;

    const {
      history: {
        location: { search },
      },
    } = this.props;
    const { q } = this.getQueryParamsFromUrl();
    this.setState({ q });

    const supplier_id = this.props.match.params.id;

    const requestParams = `/etl/inventories/${supplier_id}/files${search || `?${qs.stringify({ page: 1 })}`}`;

    this.changeSelected([]);
    getInventories(requestParams);
  }

  getQueryParamsFromUrl = () => {
    const urlParse = qs.parse(this.props.history.location.search);

    return {
      page: urlParse.page || 1,
      per_page: urlParse.perPage || this.getPerPage(),
      q: urlParse.q,
      sort: urlParse.sort,
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilter(nextProps);
    this.setCurrentPage(nextProps);
  }

  getPerPage = () => {
    const { inventories } = this.props;

    return inventories.loaded && inventories.inventoriesData.meta.perPage;
  };

  onViewAll = () => {
    const { getInventories, push } = this.props;
    const perPage = this.getPerPage();

    this.setState({
      ...this.state,
      q: undefined,
      sort: undefined,
    });
    const supplier_id = this.props.match.params.id;
    push(`etl/inventories/${supplier_id}/files?page=1&per_page=${perPage}`);
    getInventories(`etl/inventories/${supplier_id}/files?page=1&per_page=${perPage}`);
  };

  onParamsChange = (params) => {
    const {
      push,
      location: { pathname, hash },
    } = this.props;
    const queryParams = qs.stringify({ ...this.getQueryParamsFromUrl(), ...params });
    push(`${pathname}?${queryParams}${hash}`);
  };

  handleSearchChange = (q) => {
    clearTimeout(this.timer);

    this.setState({ q: q.length ? q : undefined });

    this.timer = setTimeout(this.onSearchChange, WAIT_INTERVAL);
  };

  handleTagEdit = (inventoryId) => {
    const { push } = this.props;
    push(`/etl/inventories/${inventoryId}`);
  };

  onSearchChange = () => {
    const { q } = this.state;
    this.onParamsChange({ q });
  };

  onSortChange = (sort) => {
    this.setState(() => ({ sort }));
    this.onParamsChange({ sort });
  };

  onPageChange = (page) => {
    this.setState(() => ({ page }));
    this.onParamsChange({ page });
  };

  onPageAmountChange = (perPage) => {
    this.onParamsChange({ per_page: perPage, page: 1 });
  };

  changeSelected = (items) => {
    this.setState(() => ({ selected: items }));
  };

  onRemoveItem = () => {
    const { selected } = this.state;
    const { deleteItems, history } = this.props;

    const updatedSelects = selected.map((item) => `inventories_ids[]=${item}`).join('&');

    if (this.withConfirmation()) {
      deleteItems(updatedSelects, history.location.search);

      this.setState(() => ({ selected: [] }));
    }
  };

  withConfirmation = () => window.confirm('Remove element(s) ?');

  setFilter(nextProps) {
    const { search } = nextProps.history.location;
    this.setState(() => ({
      params: {
        page: search,
      },
      q: qs.parse(search).q || undefined,
    }));
  }

  setCurrentPage(nextProps) {
    const { page } = this.state.params;
    const { search } = nextProps.history.location;

    if (!equal(page, search)) {
      const supplier_id = this.props.match.params.id;
      const requestParams = `/etl/inventories/${supplier_id}/files${search}`;
      this.props.getInventories(requestParams);
    }
  }

  render() {
    const { params, selected, q } = this.state;
    const {
      history: {
        location: { search },
      },
      inventories,
    } = this.props;

    const pageLoaded = inventories.loaded;
    const tableLoaded = inventories.tableLoaded === true;
    const pages = pageLoaded && Math.ceil(inventories.inventoriesData.meta.totalEntries / inventories.inventoriesData.meta.perPage);
    const noResults = pageLoaded && inventories.inventoriesData.data.length === 0;

    if (!pageLoaded) {
      return (
        <div className="tags-page">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="tags-page">
        <div className="tags-page-content">
          <TopInventorySection
            pageTitle="Inventory"
            breadcrumbs={breadcrumbsItems}
            onRemoveItem={this.onRemoveItem}
            isDisabled={selected.length === 0}
            shouldCreate
          />
          <FilteringSection
            page={params}
            q={q || ''}
            productsLog={false}
            tags
            onModalOpen={this.openModal}
            onSearchChange={this.handleSearchChange}
            perPage={inventories.inventoriesData.meta.perPage}
            activePage={+qs.parse(search).page || 1}
            totalEntriesAmount={inventories.inventoriesData.meta.totalEntries}
            pagesAmount={inventories.inventoriesData.meta.perPage}
            onPageAmountChange={this.onPageAmountChange}
          />
          <InventoryTable
            type="inventories"
            noResults={noResults}
            tableLoaded={tableLoaded}
            renderColumns={renderColumns}
            items={inventories.inventoriesData.data}
            selected={selected}
            handleSelectItems={this.changeSelected}
            onEditItem={this.handleTagEdit}
            onSortChange={this.onSortChange}
          />
          <Pagination
            pages={pages}
            activePage={+qs.parse(search).page}
            onPageChange={(page) => this.onPageChange(page)}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ inventories, authReducer }) => ({
    inventories: {
      inventoriesData: inventoriesSelector(inventories.inventories),
      loading: inventories.loading,
      loaded: inventories.loaded,
      tableLoaded: inventories.tableLoaded,
    },
    isAuthenticated: authReducer.isAuthenticated,
  }),
  {
    getInventories,
    showModal,
    closeModal,
    deleteItems,
    push,
  }
)(Inventories);
