import React, { Component } from "react"
import PropTypes from "prop-types"
import Multiselect from "react-select"

class MultiSelectCategories extends Component {

  static propTypes = {
    categories: PropTypes.array,
    selected_categories: PropTypes.array,
    onCategoryChange: PropTypes.func,
    getSelectedCategoriesList: PropTypes.func,
    product_id: PropTypes.number
  }

  state = {
    categories: [],
    selected_categories: []
  }

  componentDidMount() {
  }

  onCategoriesChange = (categories) => {
    const categories_ids = categories.map((category) => { return category.value})
    this.props.onCategoryChange(categories_ids)
  }

  onSubmit = (event) => {
    return false;
  }

  render() {
    const { categories, selected_categories, getSelectedCategoriesList } = this.props
    return (
      <Multiselect
        isMulti={true}
        className={"select2"}
        value={getSelectedCategoriesList(categories, selected_categories)}
        options={categories}
        onChange={this.onCategoriesChange}
      />
    )
  }
}

export default MultiSelectCategories
