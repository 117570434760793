import styled from "styled-components"

export default styled.div`
  .floor-plan__form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    min-width: 1000px;
    max-width: 1200px;
    padding: 30px;
    margin-left:auto;
    margin-right:auto;
  }
  
  .floor-plan__form-column {
    flex-basis: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 30px;
  }
  
   .floor-plan__form-column .input__error {
      padding-left: 190px;
   }
  
  .floor-plan__form-column .redux-form__input {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .floor-plan__form-column .input__label {
    width: 35%;
  }
  
  .floor-plan__form-column .input__field {
    width: 65%;
  }
  
  .floor-plan__buttons {
    width: 100%;
    padding-top: 30px;
    text-align: center;
  }
`
