import http from "./axios.config"
import { API } from "../constants"

export default class Http {

  static login(user) {
    return http({
      method: "POST",
      url: `${API}users/sign_in`,
      data: user
    })
      .then((res) => res.data)
  }

  static logout(token) {
    return http({
      method: "DELETE",
      url: `${API}users/sign_out`,
      data: token
    })
      .then((res) => res.data)
  }

}
