import styled from "styled-components"

export default styled.div`
  .select {
    padding: 11px;
    font-family: inherit;
    font-weight: 100;
    font-size: 17px;
    line-height: 1;
    text-align: center;
    color: #595959;
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;

    &:hover,
    &:focus {
      border-color: #8e8e8e;
    }
  }

  .select__option {
    padding: 5px;
    font-family: inherit;
    font-weight: 100;
    font-size: 17px;
    line-height: 1;
    color: #595959;
    background-color: #fff;

    &:checked {
      background-color: #8e8e8e;
    }
  }
`
