import React from "react"
import PropTypes from "prop-types"

// Import Components
import AllItemsListStyle from "./style"
import List from "./List"

AllItems.propTypes = {
  // from Colors component
  items: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onSelectItem: PropTypes.func,
  selectedItem: PropTypes.number,
  products: PropTypes.array,
  isLoading: PropTypes.bool,
}

function AllItems({ items, type, onSelectItem, selectedItem, products, isLoading }) {
  return (
    <AllItemsListStyle>
      <List
        items={items}
        title={`All ${type}:`}
        active
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
      />
      <List items={products} title="Products:" isLoading={isLoading} />
    </AllItemsListStyle>
  )
}

export default AllItems
