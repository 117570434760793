import { all, call, put, takeEvery, select } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { push } from "react-router-redux"
import { appName } from "../constants"
import {
  addProductsToVariantData,
  calculateVariant,
  cloneRoomData,
  cloneRoomVariant,
  createRoomData,
  createRoomVariantData,
  deleteRoomData,
  deleteRoomVariantData,
  getRoomData,
  getRoomOptionsData,
  getRoomVariantData,
  updateRoomData,
  updateRoomVariantData,
} from "../api"
import objectRemoveExtraFields from "../utils/objectRemoveExtraFields"

/**
 * Constants
 * */
export const moduleName = "rooms"
export const subModuleName = "edit"
const prefix = `${appName}/${moduleName}/${subModuleName}`

export const ROOM_DATA_REQUEST = `${prefix}/ROOM_DATA_REQUEST`
export const ROOM_DATA_SUCCESS = "ROOM_DATA_SUCCESS"
export const ROOM_DATA_ERROR = "ROOM_DATA_ERROR"

export const ROOM_UPDATE_REQUEST = `${prefix}/ROOM_UPDATE_REQUEST`
export const ROOM_UPDATE_ERROR = "ROOM_UPDATE_ERROR"

export const ROOM_CREATE_REQUEST = `${prefix}/ROOM_CREATE_REQUEST`
export const ROOM_CREATE_SUCCESS = "ROOM_CREATE_SUCCESS"
export const ROOM_CREATE_ERROR = "ROOM_CREATE_ERROR"

export const ROOM_VARIANT_DATA_REQUEST = `${prefix}/ROOM_VARIANT_DATA_REQUEST`
export const ROOM_VARIANT_DATA_SUCCESS = `${prefix}/ROOM_VARIANT_DATA_SUCCESS`
export const ROOM_VARIANT_DATA_ERROR = `${prefix}/ROOM_VARIANT_DATA_ERROR`

export const ROOM_VARIANT_CREATE_REQUEST = `${prefix}/ROOM_VARIANT_CREATE_REQUEST`
export const ROOM_VARIANT_CREATE_SUCCESS = "ROOM_VARIANT_CREATE_SUCCESS"
export const ROOM_VARIANT_CREATE_ERROR = "ROOM_VARIANT_CREATE_ERROR"

export const ROOM_VARIANT_CLONE_REQUEST = `${prefix}/ROOM_VARIANT_CLONE_REQUEST`
export const ROOM_VARIANT_CLONE_SUCCESS = `${prefix}/ROOM_VARIANT_CLONE_SUCCESS`
export const ROOM_VARIANT_CLONE_ERROR = `${prefix}/ROOM_VARIANT_CLONE_ERROR`

export const ROOM_VARIANT_UPDATE_REQUEST = `${prefix}/ROOM_VARIANT_UPDATE_REQUEST`
export const ROOM_VARIANT_UPDATE_SUCCESS = "ROOM_VARIANT_UPDATE_SUCCESS"
export const ROOM_VARIANT_UPDATE_ERROR = "ROOM_VARIANT_UPDATE_ERROR"

export const ROOM_VARIANT_CALCULATE_REQUEST = `${prefix}/ROOM_VARIANT_CALCULATE_REQUEST`
export const ROOM_VARIANT_CALCULATE_SUCCESS = "ROOM_VARIANT_CALCULATE_SUCCESS"
export const ROOM_VARIANT_CALCULATE_ERROR = "ROOM_VARIANT_CALCULATE_ERROR"

export const ROOM_VARIANT_DELETE_REQUEST = `${prefix}/ROOM_VARIANT_DELETE_REQUEST`
export const ROOM_VARIANT_DELETE_SUCCESS = "ROOM_VARIANT_DELETE_SUCCESS"
export const ROOM_VARIANT_DELETE_ERROR = "ROOM_VARIANT_DELETE_ERROR"

export const ROOM_DELETE_REQUEST = `${prefix}/ROOM_DELETE_REQUEST`
export const ROOM_DELETE_SUCCESS = "ROOM_DELETE_SUCCESS"
export const ROOM_DELETE_ERROR = "ROOM_DELETE_ERROR"

export const ROOM_CLONE_REQUEST = `${prefix}/ROOM_CLONE_REQUEST`

export const ROOM_OPTIONS_DATA_REQUEST = `${prefix}/ROOM_OPTIONS_DATA_REQUEST`
export const ROOM_OPTIONS_DATA_SUCCESS = "ROOM_OPTIONS_DATA_SUCCESS"
export const ROOM_OPTIONS_DATA_ERROR = "ROOM_OPTIONS_DATA_ERROR"

export const ROOM_TYPE_SAVE_ID = `${prefix}/ROOM_TYPE_SAVE_ID`

/**
 * Helpers
 */

const errorBuilder = (err, statusMessage, defaultMessage = "Room was not") => {
  const resultTitle = "Failed"

  let errorData = err.response.data

  return {
    invoke: () => {
      if (errorData && errorData.result) {
        return toastr.error(errorData.result)
      }
      if (errorData && errorData.error_msg) {
        return toastr.error(resultTitle, errorData.error_msg)
      }

      toastr.error(resultTitle, `${defaultMessage} ${statusMessage}!`)
    }
    // another error-types handlers...
  }
}

/**
 * Reducer
 * */

const initialState = {
  room: { data: null, loading: true, error: null },
  roomOptions: { data: null, loading: true, error: null },
  currentRoomTypeId: null,
  currentRoomVariant: { data: null, loading: false, error: null }
}

export default function(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case ROOM_DATA_REQUEST:
      return { ...state, room: { data: null, loading: true, error: null } }

    case ROOM_UPDATE_REQUEST:
    case ROOM_CREATE_REQUEST:
      return { ...state, room: { ...state.room, loading: true } }

    case ROOM_VARIANT_DATA_REQUEST:
      return { ...state, currentRoomVariant: { data: null, loading: true, error: null } }

    case ROOM_DATA_SUCCESS: {
      const { categories: product_category_ids, room, ...product } = payload
      objectRemoveExtraFields(["image5", "image6", "url_image5", "url_image6"], product)
      const flattenedPayload = { product_category_ids, product, room }
      return { ...state, room: { ...state.room, loading: false, data: flattenedPayload } }
    }

    case ROOM_VARIANT_DATA_SUCCESS: {
      return { ...state, currentRoomVariant: { ...state.currentRoomVariant, loading: false, data: payload } }
    }

    case ROOM_DATA_ERROR:
    case ROOM_UPDATE_ERROR:
      return { ...state, room: { ...state.room, loading: false, ...payload } }

    case ROOM_VARIANT_DATA_ERROR:
      return { ...state, currentRoomVariant: { ...state.currentRoomVariant, loading: false, ...payload } }

    case ROOM_CREATE_SUCCESS:
      return { ...state, room: { ...state.room, loading: false } }

    case ROOM_OPTIONS_DATA_REQUEST:
      return { ...state, roomOptions: { ...state.roomOptions, loading: true } }

    case ROOM_OPTIONS_DATA_SUCCESS:
      return { ...state, roomOptions: { ...state.roomOptions, loading: false, data: payload } }

    case ROOM_OPTIONS_DATA_ERROR:
      return { ...state, room: { ...state.roomOptions, loading: false, ...payload } }

    case ROOM_VARIANT_CREATE_REQUEST:
    case ROOM_VARIANT_UPDATE_REQUEST:
    case ROOM_VARIANT_CALCULATE_REQUEST:
    case ROOM_VARIANT_DELETE_REQUEST:
    case ROOM_DELETE_REQUEST:
    case ROOM_VARIANT_CLONE_REQUEST:
      return { ...state, room: { ...state.room, loading: true } }

    case ROOM_VARIANT_CREATE_SUCCESS:
    case ROOM_VARIANT_UPDATE_SUCCESS:
    case ROOM_VARIANT_DELETE_SUCCESS:
    case ROOM_DELETE_SUCCESS:
    case ROOM_VARIANT_CLONE_SUCCESS:
      return { ...state, room: { ...state.room, loading: false } }

    case ROOM_VARIANT_CALCULATE_SUCCESS: {
      const { usd, cad } = payload.total_price
      const price_breakdown = payload
      return {
        ...state,
        currentRoomVariant: {
          ...state.currentRoomVariant,
          data: {
            ...state.currentRoomVariant.data,
            price_breakdown,
            msrp_usd: usd,
            msrp_cad: cad
          },
          loading: false
        },
        room: {
          ...state.room,
          loading: false
        }
      }
    }
    case ROOM_TYPE_SAVE_ID:
      return {
        ...state,
        currentRoomTypeId: payload
      }
    case ROOM_VARIANT_CREATE_ERROR:
    case ROOM_CREATE_ERROR:
    case ROOM_VARIANT_UPDATE_ERROR:
    case ROOM_VARIANT_CALCULATE_ERROR:
    case ROOM_VARIANT_DELETE_ERROR:
    case ROOM_VARIANT_CLONE_ERROR:
    case ROOM_DELETE_ERROR:
      return { ...state, room: { ...state.room, loading: false, ...payload } }
    default:
      return state
  }
}

/**
 * Selectors
 * */

export const roomSelector = (state) => state.room.room.data
export const roomOptionsSelector = (state) => state.room.roomOptions.data
export const loadingSelector = (state) => state.room.room.loading
export const loadingForVariantSelector = (state) => state.room.currentRoomVariant.loading
export const loadingRoomOptionsSelector = (state) => state.room.roomOptions.loading
export const currentRoomTypeIdSelector = (state) => state.currentRoomTypeId
export const currentRoomVariantSelector = (state) => state.room.currentRoomVariant.data

/**
 * Action Creators
 * */

export const getRoom = (id) => ({ type: ROOM_DATA_REQUEST, payload: id })
const getRoomSuccess = (data) => ({ type: ROOM_DATA_SUCCESS, payload: data })
const getRoomError = (error) => ({ type: ROOM_DATA_ERROR, ...error })

export const getRoomVariant = (id, variantId) => ({
  type: ROOM_VARIANT_DATA_REQUEST,
  payload: { id, variantId }
})
const getRoomVariantSuccess = (data) => ({ type: ROOM_VARIANT_DATA_SUCCESS, payload: data })
const getRoomVariantError = (error) => ({ type: ROOM_VARIANT_DATA_ERROR, ...error })

export const updateRoom = (id, data, isShowMessage) => ({ type: ROOM_UPDATE_REQUEST, id, payload: data, isShowMessage })
const updateRoomError = (error) => ({ type: ROOM_UPDATE_ERROR, ...error })

export const createRoom = (data) => ({ type: ROOM_CREATE_REQUEST, payload: data })
const createRoomSuccess = (data) => ({ type: ROOM_CREATE_SUCCESS, payload: data })
const createRoomError = (error) => ({ type: ROOM_CREATE_ERROR, ...error })

export const getRoomOptions = () => ({ type: ROOM_OPTIONS_DATA_REQUEST })
const getRoomOptionsSuccess = (data) => ({ type: ROOM_OPTIONS_DATA_SUCCESS, payload: data })
const getRoomOptionsError = (error) => ({ type: ROOM_OPTIONS_DATA_ERROR, ...error })

export const createRoomVariant = (id, data) => ({ type: ROOM_VARIANT_CREATE_REQUEST, id, payload: data })
const createRoomVariantError = (error) => ({ type: ROOM_VARIANT_CREATE_ERROR, ...error })

export const updateRoomVariant = (id, vid, data) => ({ type: ROOM_VARIANT_UPDATE_REQUEST, id, vid, payload: data })
const updateRoomVariantSuccess = (data) => ({ type: ROOM_VARIANT_UPDATE_SUCCESS, payload: data })
const updateRoomVariantError = (error) => ({ type: ROOM_VARIANT_UPDATE_ERROR, ...error })

export const calculateRoomVariant = (id) => ({ type: ROOM_VARIANT_CALCULATE_REQUEST, payload: id })

const calculateRoomVariantSuccess = (data) => ({
  type: ROOM_VARIANT_CALCULATE_SUCCESS,
  payload: data
})
const calculateRoomVariantError = (error) => ({ type: ROOM_VARIANT_CALCULATE_ERROR, ...error })

export const deleteRoomVariant = (id, vid) => ({ type: ROOM_VARIANT_DELETE_REQUEST, id, vid })
const deleteRoomVariantError = (error) => ({ type: ROOM_VARIANT_DELETE_ERROR, ...error })

export const deleteRoom = (id) => ({ type: ROOM_DELETE_REQUEST, id })
const deleteRoomSuccess = (data) => ({ type: ROOM_DELETE_SUCCESS, payload: data })
const deleteRoomError = (error) => ({ type: ROOM_DELETE_ERROR, ...error })

export const cloneRoom = (id, btn) => ({ type: ROOM_CLONE_REQUEST, id, btn })

export const cloneRoomVariantAction = (variantId, roomId) => ({
  type: ROOM_VARIANT_CLONE_REQUEST,
  payload: { variantId, roomId }
})
const cloneRoomVariantActionError = (error) => ({ type: ROOM_VARIANT_CLONE_ERROR, ...error })

export const saveRoomTypeId = (id) => ({ type: ROOM_TYPE_SAVE_ID, payload: id })

/**
 * Sagas
 **/

function* roomData(action) {
  try {
    let result = {}
    if (action.payload !== "0") {
      result = yield call(() => getRoomData(action.payload))
    }
    yield put(getRoomSuccess(result.data.data))
  } catch (error) {
    yield put(getRoomError(error))
  }
}

function* roomVariantData(action) {
  const { id, variantId } = action.payload
  try {
    const result = yield call(() => getRoomVariantData(variantId))
    yield put(getRoomVariantSuccess(result.data))
    yield put(push(`/rooms/${id}/variant/${variantId}`))
  } catch (error) {
    yield put(getRoomVariantError(error))
  }
}

function* roomOptionsData() {
  try {
    const result = yield call(() => getRoomOptionsData())
    yield put(getRoomOptionsSuccess(result.data.data))
  } catch (error) {
    yield put(getRoomOptionsError(error))
  }
}

function* roomUpdate(action) {
  const postfix = "updated"
  try {
    yield call(() => updateRoomData(action.id, action.payload))
    const createdRoom = yield call(() => getRoomData(action.id))
    yield put(getRoomSuccess(createdRoom.data.data))
    if (action.isShowMessage === false) {
      return false
    } else {
      toastr.success("Success", `Room successfully ${postfix}!`)
    }
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(updateRoomError(error))
  }
}

function* roomCreate(action) {
  const postfix = "created"

  try {
    const resultOfCreate = yield call(() => createRoomData(action.payload))
    const newRoomId = resultOfCreate.data.id
    yield put(createRoomSuccess(resultOfCreate.data))

    toastr.success("Success", `Room successfully ${postfix}!`)

    const createdRoom = yield call(() => getRoomData(newRoomId))

    yield put(getRoomSuccess(createdRoom.data.data))
    yield put(push(`/rooms/${newRoomId}`))
  } catch (error) {
    errorBuilder(error, postfix).invoke()
    if (error.response.data.error.permalink) toastr.warning("", `${error.response.data.error.permalink[0]}`)
    if (error.response.data.error.sku) toastr.warning("", `${error.response.data.error.sku[0]}`)
    yield put(createRoomError(error))
  }
}

function* roomVariantCreate(action) {
  const postfix = "created"

  try {
    const { id, payload } = action
    yield call(() => createRoomVariantData(id, payload))
    const {
      data: { data }
    } = yield call(() => getRoomData(id))
    yield put(getRoomSuccess(data))
    const createdVariant = data.room.room_variants.reduce((acc, el) => (el.id > acc.id ? (acc = el) : acc))
    const variantResult = yield call(() => getRoomVariantData(createdVariant.id))
    yield put(getRoomVariantSuccess(variantResult.data))
    yield put(push(`/rooms/${id}/variant/${createdVariant.id}`))
    toastr.success("Success", `Room Variant successfully ${postfix}!`)
  } catch (error) {
    errorBuilder(error, postfix).invoke()
    if (error.response.data.error.permalink) toastr.warning("", `${error.response.data.error.permalink[0]}`)
    if (error.response.data.error.sku) toastr.warning("", `${error.response.data.error.sku[0]}`)
    yield put(createRoomVariantError(error))
  }
}

function* roomVariantUpdate(action) {
  const postfix = "updated"
  const { vid, payload } = action

  try {
    const result = yield call(() => updateRoomVariantData(vid, payload))
    const result2 = yield call(() =>
      payload.products ? addProductsToVariantData({ products: payload.products, vid }) : {}
    )
    const variantResult = yield call(() => getRoomVariantData(vid))
    yield put(getRoomVariantSuccess(variantResult.data))
    yield put(updateRoomVariantSuccess(result.data && result2.data))

    toastr.success("Success", `Room Variant successfully ${postfix}!`)
    const updatedRoom = yield call(() => getRoomData(action.id))
    yield put(getRoomSuccess(updatedRoom.data.data))
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(updateRoomVariantError(error))
  }
}

function* roomVariantCalculate(action) {
  const postfix = "calculated"
  const { payload } = action
  try {
    const result = yield call(() => calculateVariant(payload))
    yield put(calculateRoomVariantSuccess(result.data))

    toastr.success("Success", `Room Variant successfully ${postfix}!`)
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(calculateRoomVariantError(error))
  }
}

function* roomDelete(action) {
  const postfix = "deleted"

  try {
    const result = yield call(() => deleteRoomData(action.id))
    yield put(deleteRoomSuccess(result.data))
    yield put(push("/rooms"))
    toastr.success("Success", `Room successfully ${postfix}!`)
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(deleteRoomError(error))
  }
}

function* roomClone(action) {
  const postfix = "cloned"
  try {
    toastr.warning("", `Cloning in progress ... Please wait and expect success message!`, { timeOut: 0 })
    yield call(() => cloneRoomData(action.id))
    toastr.clean()
    action.btn.disabled = false
    toastr.success("Success", `Room successfully ${postfix}!`)
  } catch (error) {
    toastr.clean()
    errorBuilder(error, postfix).invoke()
    toastr.error("ERROR", error)
    action.btn.disabled = false
  }
}

function* roomVariantDelete(action) {
  const postfix = "deleted"
  const vid = action.vid || window.location.pathname.split("/")[4]

  try {
    yield call(() => deleteRoomVariantData(vid))

    const updatedRoom = yield call(() => getRoomData(action.id))
    yield put(getRoomSuccess(updatedRoom.data.data))
    toastr.success("Success", `Room Variant successfully ${postfix}!`)
    const roomOptions = yield select(roomOptionsSelector)
    if (roomOptions === null) {
      const resultRoomOptions = yield call(() => getRoomOptionsData())
      yield put(getRoomOptionsSuccess(resultRoomOptions.data.data))
    }
    yield put(push(`/rooms/${action.id}`))
  } catch (error) {
    errorBuilder(error, postfix).invoke()

    yield put(deleteRoomVariantError(error))
  }
}

function* cloneRoomVariantSaga({ payload: { variantId, roomId } }) {
  const postfix = "cloned"
  const vid = variantId || window.location.pathname.split("/")[4]

  try {
    const result = yield call(() => cloneRoomVariant(vid))
    toastr.success("Success", `Room Variant successfully ${postfix}!`)
    const updatedRoom = yield call(() => getRoomData(roomId))
    const variantResult = yield call(() => getRoomVariantData(result.data.id))
    yield put(getRoomSuccess(updatedRoom.data.data))
    yield put(getRoomVariantSuccess(variantResult.data))
    yield put(push(`/rooms/${roomId}/variant/${result.data.id}`))
  } catch (error) {
    errorBuilder(error, postfix).invoke()
    yield put(cloneRoomVariantActionError(error))
  }
}

export function* saga() {
  yield all([
    takeEvery(ROOM_DATA_REQUEST, roomData),
    takeEvery(ROOM_VARIANT_DATA_REQUEST, roomVariantData),
    takeEvery(ROOM_UPDATE_REQUEST, roomUpdate),
    takeEvery(ROOM_CREATE_REQUEST, roomCreate),
    takeEvery(ROOM_VARIANT_CREATE_REQUEST, roomVariantCreate),
    takeEvery(ROOM_VARIANT_UPDATE_REQUEST, roomVariantUpdate),
    takeEvery(ROOM_VARIANT_CALCULATE_REQUEST, roomVariantCalculate),
    takeEvery(ROOM_VARIANT_DELETE_REQUEST, roomVariantDelete),
    takeEvery(ROOM_DELETE_REQUEST, roomDelete),
    takeEvery(ROOM_CLONE_REQUEST, roomClone),
    takeEvery(ROOM_OPTIONS_DATA_REQUEST, roomOptionsData),
    takeEvery(ROOM_VARIANT_CLONE_REQUEST, cloneRoomVariantSaga)
  ])
}
