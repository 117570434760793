import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static addTexture(textureData) {
    return http({
      method: "POST",
      url: `${API}design_templates/textures`,
      data: textureData
    })
      .then((res) => res.data)
  }

  static getTextures() {
    return http({
      method: "GET",
      url: `${API}design_templates/textures`
    })
      .then((res) => res.data)
  }

  static updateTexture(id, textureData) {
    return http({
      method: "PUT",
      url: `${API}design_templates/textures/${id}`,
      data: textureData
    })
      .then((res) => res.data)
  }

  static getTextureById(id) {
    return http({
      method: "GET",
      url: `${API}design_templates/textures/${id}/edit`
    })
      .then((res) => res.data)
  }

  static deleteTexture(id) {
    return http({
      method: "DELETE",
      url: `${API}design_templates/textures/${id}`
    })
      .then((res) => res.data)
  }
}
