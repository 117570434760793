import axios from "axios"
import { API } from "../constants"
import { apiUrl } from "./lt-api"

axios.defaults.baseURL = API

const defaultHeaders = {
  method: "GET",
  crossDomain: true,
  responseType: 'blob',
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "text/csv",
    withCredentials: true
  }
}

export function getPriceBreakdownCsvFile(room_variant_id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}room_variants/${room_variant_id}/price_breakdown`
  })
}