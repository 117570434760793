import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Input from "./Input"
import Select from "react-select"
import Http from "../../../../../api/design-templates-categories"
import { toastr } from "react-redux-toastr"
// Components
import { Button, DKCheckbox } from "../../../../Shared"

const CategoryTd = (props) => {
  const { category, categories, onChangeCategories, onChangeCategory, onChangeSelectCategories, onChangeCategoriesAverageCost } = props

  const onSelectChange = (selected) => {
    onChangeSelectCategories(props.category.value, selected)
  }

  const onButtonDeleteClick = () => props.onButtonDeleteClick(category.value, props.parentId)

  const options = [
    { value: "not_set", label: "Not set" },
    { value: "essential", label: "Essential" },
    { value: "other", label: "Other" }
  ]

  const value = options.find(({ value }) => value === (categories && categories[category.value] && categories[category.value].variation))

  const calculateAverageCost = () => {
    Http.calculateAverageCost(category.value)
      .then((res) => onChangeCategoriesAverageCost(category.value, res.average_cost))
      .catch((error) => toastr.error(error.message))
  }

  return (
    <Fragment>
      <td className="table__cell td">
        {category.label}
      </td>
      <td className="table__cell td">
        <Select
          value={value}
          options={options}
          onChange={onSelectChange}
        />
      </td>
      <td className="table__cell td">
        <Input
          id={category.value}
          name="quantity"
          value={categories && categories[category.value] && categories[category.value].quantity}
          onChange={onChangeCategories}
        />
      </td>
      <td className="table__cell td">
        <Input
          id={category.value}
          name="average_cost"
          value={categories && categories[category.value] && categories[category.value].average_cost}
          onChange={onChangeCategories}
        />
        <Button
          onClick={calculateAverageCost}
        >
          Calculate
        </Button>
      </td>
      <td className="table__cell td small-size">
        {category.is_backend ? (
          <DKCheckbox
            name={category.value}
            onChange={onChangeCategory}
          />
        ) : (
          <Button width={100} onClick={onButtonDeleteClick} color="danger">Delete</Button>
        )}
      </td>
    </Fragment>
  )
}

CategoryTd.propTypes = {
  category: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    variation: PropTypes.string,
    quantity: PropTypes.number,
    average_cost: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }).isRequired,
  onChangeCategory: PropTypes.func,
  onChangeCategories: PropTypes.func,
  categories: PropTypes.object,
  onChangeSelectCategories: PropTypes.func,
  onButtonDeleteClick: PropTypes.func,
  onChangeCategoriesAverageCost: PropTypes.func,
  parentId: PropTypes.any
}

export default CategoryTd
