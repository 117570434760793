import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { uid } from 'uid';
import _set from 'lodash/fp/set';
import { getAddAttributeRequest } from '../../../ducks/attribute';

// components
import StyledComponents from './style';
import { Button } from '../../Shared';
import DynamicFormFields from './DynamicFormFields';

class ModalAddAttributes extends Component {
  static propTypes = {
    // from parent component
    closeModal: PropTypes.func,
    onAttributesChange: PropTypes.func,
    onDeleteAttribute: PropTypes.func,
    onAddNewAttribute: PropTypes.func,
    attributes: PropTypes.array,
    // from connect
    tableSelected: PropTypes.array,
  };

  state = {
    attributes: [
      {
        id: 'first',
        fields: [
          {
            id: '1', name: 'attribute-1', value: '', label: 'name:',
          },
          {
            id: '2', name: 'attribute-2', value: '', label: 'value:',
          },
        ],
      },
    ],
  };

  // simple attribute change
  onAttributesChange = ({ target: { value } }, attributeId, fieldId) => {
    const { attributes } = this.state;

    const newAttributes = attributes.map((attribute) => {
      if (attribute.id === attributeId) {
        return _set(`fields[${fieldId}].value`, value, attribute);
      }
      return attribute;
    });

    this.setState({
      attributes: newAttributes,
    });
  };

  onAddNewAttribute = (event) => {
    event && event.preventDefault && event.preventDefault();
    const { attributes } = this.state;
    const id = uid('10');
    const newAttributes = attributes.concat([
      {
        id,
        fields: [
          {
            id: `${id}1`, name: `attribute-${id}1`, value: '', label: 'name:',
          },
          {
            id: `${id}2`, name: `attribute-${id}2`, value: '', label: 'value:',
          },
        ],
      },
    ]);

    this.setState({
      attributes: newAttributes,
    });
  };

  onDeleteAttribute = (event, attributeId) => {
    event && event.preventDefault && event.preventDefault();
    const { attributes } = this.state;

    const newAttributes = attributes.filter((item) => item.id !== attributeId);
    this.setState({
      attributes: newAttributes,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { attributes } = this.state;
    const { tableSelected, closeModal, getAddAttributeRequest } = this.props;

    const products = tableSelected.filter((obj) => !!obj.value).map((product) => +product.name);

    const attribute = attributes.map((attribute) => {
      const key = attribute.fields[0].value.trim();
      const value = attribute.fields[1].value.trim();

      return {
        key,
        value,
      };
    });

    const data = {
      products,
      attribute,
    };

    getAddAttributeRequest(data);
    closeModal();
  };

  buttonSaveDisabled = () => {
    const { attributes } = this.state;

    let disabled = true;

    attributes.forEach((attribute) => {
      const key = attribute.fields[0].value.trim();
      const value = attribute.fields[1].value.trim();
      disabled = !!(!key.length || !value.length);
    });

    return disabled;
  };

  render() {
    const { closeModal } = this.props;
    const { attributes } = this.state;

    return (
      <StyledComponents>
        <div className="add-attributes__header">
          <p className="add-attributes__title">Other attributes:</p>
          <button className="add-attributes__add-button button" onClick={this.onAddNewAttribute}>
            +
          </button>
        </div>
        <form noValidate className="add-attributes__form" onSubmit={this.onSubmit}>
          <DynamicFormFields
            attributes={attributes}
            onAttributesChange={this.onAttributesChange}
            onDeleteAttribute={this.onDeleteAttribute}
          />
          <div className="add-attributes__footer">
            <Button type="button" width={120} onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" width={120} disabled={this.buttonSaveDisabled()}>
              Save
            </Button>
          </div>
        </form>
      </StyledComponents>
    );
  }
}

const mapStateToProps = (state) => ({
  tableSelected: state.tableReducer.attributeTagging,
});

export default connect(
  mapStateToProps,
  { getAddAttributeRequest }
)(ModalAddAttributes);
