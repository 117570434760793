import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static addColor(colorData) {
    return http({
      method: "POST",
      url: `${API}design_templates/colors`,
      data: colorData
    })
      .then((res) => res.data)
  }

  static getColors() {
    return http({
      method: "GET",
      url: `${API}design_templates/colors`
    })
      .then((res) => res.data)
  }

  static updateColor(id, colorData) {
    return http({
      method: "PUT",
      url: `${API}design_templates/colors/${id}`,
      data: colorData
    })
      .then((res) => res.data)
  }

  static getColorById(id) {
    return http({
      method: "GET",
      url: `${API}design_templates/colors/${id}/edit`
    })
      .then((res) => res.data)
  }

  static deleteColor(id) {
    return http({
      method: "DELETE",
      url: `${API}design_templates/colors/${id}`
    })
      .then((res) => res.data)
  }
}
