import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static getRooms({ page, perPage, q, categoryIds }) {
    const categories = categoryIds.map((id) => `&categories[]=${id}`).join("")

    return http({
      method: "GET",
      url: `${API}rooms?page=${page}&per_page=${perPage}&q=${q}${categories}`
    })
      .then((res) => res.data)
  }

  static getCategories() {
    return http({
      method: "GET",
      url: `${API}rooms/room_list_options`
    })
      .then((res) => res.data)
  }

  static searchInRooms(data) {
    return http({
      url: `${API}rooms/search`,
      method: "POST",
      data: data
    })
      .then((res) => res.data)
  }

  static deleteRooms(roomIds) {
    return http({
      method: "DELETE",
      url: `${API}rooms/destroy_multiple`,
      data: {
        rooms_ids: roomIds
      }
    })
      .then((res) => res.data)
  }
}
