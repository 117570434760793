import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { fromJS } from 'immutable';

import { getTag, updateTag, createTag } from '../../ducks/tag';
import { loadingSelector, tagSelector } from '../../ducks/tag';

import Spinner from '../../Components/Shared/Spinner';

import { Header, GlobalButtons, AttributesForm } from '../../Components/Tag';

const defaultState = {
  id: 0,
  tag: {
    name: '',
    description: '',
    active: true,
    tag_attributes_array: [],
  },
};

export class Tag extends Component {
  state = {
    hasTag: false,
    missingRequiredFields: false,
    tagData: defaultState,
  };

  componentDidMount() {
    const { getTag, match } = this.props;
    getTag(match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tag) {
      this.updateTagData(nextProps);
    }
  }

  updateTagData = (nextProps) => {
    if (this.state.hasTag) return;
    this.setState({
      tagData: this.getTagData(nextProps),
      hasTag: true,
    });
  };

  getTagData = (data) => {
    let results = {
      ...defaultState,
    };
    if (data.tag) {
      results = {
        ...defaultState,
        id: data.tag.id,
        tag: {
          name: data.tag.name,
          description: data.tag.description,
          active: data.tag.active,
        },
      };
    }
    return results;
  };

  getUpdateData = (tagData) => {
    const result = {
      tag: {
        name: tagData.tag.name,
        description: tagData.tag.description,
        active: tagData.tag.active,
      },
    };
    return result;
  };

  handleReturn = () => {
    this.props.goBack();
  };

  handleSave = () => {
    if (!this.hasRequiredFields()) {
      this.setState({
        missingRequiredFields: true,
      });
      return;
    }
    const { tagData } = this.state;
    const newTag = { ...tagData };

    const { match } = this.props;
    const formData = this.getUpdateData(newTag);

    if (parseInt(match.params.id, 10)) {
      this.props.updateTag(tagData.id, formData);
    } else {
      this.props.createTag(formData);
    }
  };

  setNewTag = (name, value) => {
    const newTag = fromJS(this.state.tagData).toJS();
    const category = name.substring(4);
    newTag.tag[category] = value;
    this.setState({
      tagData: newTag,
    });
  };

  handleChangeAttributes = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    if (!name) {
      return;
    }

    if (name.substring(0, 3) === 'tag') {
      this.setNewTag(name, value);
    }
  };

  handleChangeOtherAttributes = (e) => {
    const { name } = e;
    const { value } = e;
    let newTag = fromJS(this.state.tagData);
    const itemNumber = name.split(':')[1];
    const itemKey = name.split(':')[0] === 'name ' ? 'key' : 'value';
    const newAttr = newTag.toJS().tag.tag_attributes_array;
    newAttr[itemNumber][itemKey] = value;
    newTag = newTag.setIn(['tag', 'tag_attributes_array'], newAttr);
    this.setState({
      tagData: newTag.toJS(),
    });
  };

  hasRequiredFields = () => {
    const { name } = this.state.tagData.tag;
    const requiredFields = name;
    return !!requiredFields;
  };

  handleChangeSwitch = (e) => {
    const newTag = fromJS(this.state.tagData).toJS();
    newTag.tag.active = e;
    this.setState({
      tagData: newTag,
    });
  };

  render() {
    if (this.props.loading || !this.state.tagData) {
      return <Spinner />;
    }
    const { tagData, missingRequiredFields } = this.state;
    return (
      <div className="product-edit-page">
        <div className="product-edit-page__wrapper">
          <div className="product-edit-page__header">
            <Header
              checked={tagData.tag.active}
              tagName={tagData.tag.name}
              onReturn={this.handleReturn.bind(this)}
              onChangeSwitch={this.handleChangeSwitch.bind(this)}
            />
          </div>

          <div className="product-edit-page__attributes-from">
            <AttributesForm
              missingRequiredFields={missingRequiredFields}
              onChangeAttribute={this.handleChangeAttributes.bind(this)}
              tagData={tagData}
            />
          </div>

          <div className="mt-md-5 mb-md-5  mt-4 mb-2 container">
            <GlobalButtons onSave={this.handleSave.bind(this)} onCancel={this.handleReturn.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    tag: tagSelector(state),
    loading: loadingSelector(state),
  }),
  {
    getTag,
    createTag,
    updateTag,
    push,
    goBack,
  }
)(Tag);
