import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';

// Misc
import objectToFormData from '../../../utils/objectToFormData';
import {
  createAvailableColorRequest,
  getIsLoading,
  updateAvailableColorRequest,
  getAvailableColorByIdRequest,
} from '../../../ducks/availableColors';

// Import Components
import { DesignTemplatesLayout } from '../index';
import DetailEditForm from './DetailEditForm';
import DetailCreateForm from './DetailCreateForm';

ColorsDetail.propTypes = {
  // from connect/react-router-redux
  goBack: PropTypes.func,
  // from connect/availableColorsReducer
  createAvailableColorRequest: PropTypes.func,
  updateAvailableColorRequest: PropTypes.func,
  isLoading: PropTypes.bool,
};

function ColorsDetail({
  getAvailableColorByIdRequest, goBack, createAvailableColorRequest, updateAvailableColorRequest, isLoading, match: { params: { id } },
}) {
  const isEdit = id !== 'new';

  const form_submitHandler = function (values) {
    const color = { color: { ...values } };
    if (isEdit) {
      updateAvailableColorRequest(id, objectToFormData(color));
    } else {
      createAvailableColorRequest(objectToFormData(color));
    }
  };

  const buttonGoBack_clickHandler = function () {
    goBack();
  };

  const getItem = function () {
    if (id !== 'new') {
      getAvailableColorByIdRequest(id);
    }
  };

  return (
    <DesignTemplatesLayout
      isLoading={isLoading}
      pageTitle="colors"
      buttonTitle="Go back"
      getItem={getItem}
      button_clickHandler={buttonGoBack_clickHandler}
    >
      {isEdit ? <DetailEditForm onSubmit={form_submitHandler} /> : <DetailCreateForm onSubmit={form_submitHandler} />}
    </DesignTemplatesLayout>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
});
const mapDispatchToProps = {
  goBack,
  createAvailableColorRequest,
  updateAvailableColorRequest,
  getAvailableColorByIdRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorsDetail);
