import React from "react"
import PropTypes from "prop-types"

const FurnitureTd = (props) => {
  return (
    <td className="table__cell td furniture" colSpan="4" onClick={props.openModal}>
      {props.furnitureName}
    </td>
  )
}

FurnitureTd.propTypes = {
  furnitureName: PropTypes.string.isRequired,
  openModal: PropTypes.func
}

export default FurnitureTd
