import React from 'react';
import PropTypes from 'prop-types';

// Misc

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { formName } from '../../../../constants/forms';
import { getAvailableRuleById } from '../../../../ducks/availableRules';

// Import Components
import DetailEditFormStyle from './style';
import { ReduxFormTextarea, ReduxFormInput } from '../../../Shared/reduxForm';
import { Button } from '../../../Shared';

DetailEditForm.propTypes = {
  // from parent component
  onSubmit: PropTypes.func,
  disabledSubmit: PropTypes.bool,
};

function DetailEditForm({
  handleSubmit, onSubmit, pristine, submitting, invalid,
}) {
  return (
    <DetailEditFormStyle>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="available-rules__form"
      >
        <div className="available-rules__column fields">
          <ReduxFormInput
            name="name"
            label="Color name:"
          />
          <ReduxFormTextarea
            name="explanation"
            label="Design rule description:"
          />
        </div>
        <div className="available-rules__button">
          <Button
            size="large"
            type="submit"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </DetailEditFormStyle>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  initialValues: {
    name: getAvailableRuleById(state).label,
    explanation: getAvailableRuleById(state).explanation,
  },
});

const enhancer = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName.availableDesignRulesEditForm,
  })
);

export default enhancer(DetailEditForm);
