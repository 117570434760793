import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _set from 'lodash/fp/set';
import _get from 'lodash/fp/get';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import objectToFormData from '../../../utils/objectToFormData';
import {
  getCategoriesValues,
  getFurnitureCategoriesSelector,
  getRoomTypeSelector,
  makeAddFurnitureCategoriesSelector,
  updateRoomTypeAction,
} from '../../../ducks/roomType';
import { Button } from '../../Shared';
import { EditFormStyle } from './style';
import Table from './Table';

class EditForm extends Component {
  static propTypes = {
    furnitureCategories: PropTypes.array.isRequired,
    addFurnitureCategories: PropTypes.array,
    updateRoomType: PropTypes.func,
    room: PropTypes.object,
    categoriesValues: PropTypes.object,
  };

  state = {
    furnitureCategory: {
      value: this.props.room.label || '',
      error: null,
    },
    categories: {},
    removeCategoryIds: [],
  };

  componentDidMount() {
    this.initializeCategories();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addFurnitureCategories !== this.props.addFurnitureCategories) {
      this.initializeCategories();
    }
    if (prevProps.room.label === void 0) {
      this.initValues();
      this.initializeCategories();
    }
  }

  initValues = () => {
    if (this.props.room.label) {
      this.setState((prevState) => ({
        ...prevState.furnitureCategory,
        value: this.props.room.label
      }));
    }
  };

  initializeCategories = () => {
    const { room, categoriesValues } = this.props;

    if (!room || !room.style_design_categories_with_parent) {
      return;
    }

    const categories = room.style_design_categories_with_parent.reduce((acc, category) => {
      if (category.children.length) {
        category.children.forEach((item) => {
          const quantity = _get('quantity', categoriesValues[item.value]);

          acc[item.value] = {
            id: item.relation_id || '',
            product_category_id: item.value,
            quantity: quantity !== undefined ? quantity : (item.quantity || 0),
          };
        });
      }
      return acc;
    }, {});

    this.setState({ categories });
  };


  onChangeCategories = (idCategory, event) => {

    const { target } = event;
    const { name, value } = target;

    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set(`${name}`, value, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeRemoveCategory = (event) => {
    const { target } = event;
    const { name, checked } = target;

    this.setState((prevState) => ({
      removeCategoryIds: {
        ...prevState.removeCategoryIds,
        [name]: checked,
      },
    }));
  };

  formOnSubmit = (event) => {
    event && event.preventDefault && event.preventDefault();
    const { furnitureCategory, categories } = this.state;

    const newCategories = Object.values(categories).filter(item => item.quantity > 0).map((item) => {
      if (this.state.removeCategoryIds[item.product_category_id]) {
        return { ...item, _destroy: true };
      }

      return { ...item, _destroy: false };
    });

    if (!this.disabledSubmit()) {
      const data = {
        room_type: {
          name: furnitureCategory.value,
          room_types_default_categories_attributes: newCategories.length ? [...newCategories] : [[]],
        },
      };

      if (newCategories.length) {
        data.room_type.room_types_default_categories_attributes = [...newCategories];
      }

      this.props.updateRoomType(this.props.match.params.id, objectToFormData(data));
    }
  };


  disabledSubmit = () => !this.state.furnitureCategory.value;

  inputOnChange = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        value: target.value,
      },
    }));
  };

  inputOnFocus = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        error: null,
      },
    }));
  };

  inputOnBlur = ({ target }) => {
    if (!target.value) {
      this.setState((prevState) => ({
        [target.name]: {
          ...prevState[target.name],
          error: 'This field is required',
        },
      }));
    }
  };

  inputOnFocusPosition = ({ target }) => {
    this.setState((prevState) => ({
      [target.position]: {
        ...prevState[target.name],
        error: null,
      },
    }));
  };

  render() {
    const { furnitureCategory: { value, error } } = this.state;
    const inputLabelClassNames = cn('form-control__label', { 'error': !!error });
    const inputClassNames = cn('form-control__input', { 'error': !!error });

    return (
      <EditFormStyle>
        <form noValidate className="form" onSubmit={this.formOnSubmit}>
          <div className="form-control__wrapper">
            <label htmlFor="furnitureCategory-id" className={inputLabelClassNames}>Room name</label>
            <div className="form-control__input-wrapper">
              <input
                className={inputClassNames}
                name="furnitureCategory"
                id="furnitureCategory-id"
                type="text"
                value={value}
                onChange={this.inputOnChange}
                onFocus={this.inputOnFocus}
                onBlur={this.inputOnBlur}
              />
              {error && <span className="form-control__error">{error}</span>}
            </div>
          </div>
          <div className="form__table">
            <Table
              data={this.props.furnitureCategories}
              onChangeCategory={this.onChangeRemoveCategory}
              onChangeCategories={this.onChangeCategories}
              onChangeSelectCategories={this.onChangeSelectCategories}
              categories={this.state.categories}
              furnitureCategories={this.props.room.style_design_categories_with_parent}
            />
          </div>
          <div className="form__button-wrapper">
            <Button
              type="submit"
              uppercase
              size="large"
              width={150}
              disabled={this.disabledSubmit()}
            >
              Save
            </Button>
          </div>
        </form>
      </EditFormStyle>
    );
  }
}

const mapStateToProps = (state) => {
  const addFurnitureCategoriesSelector = makeAddFurnitureCategoriesSelector();

  return {
    room: getRoomTypeSelector(state),
    furnitureCategories: getFurnitureCategoriesSelector(state),
    addFurnitureCategories: addFurnitureCategoriesSelector(state),
    categoriesValues: getCategoriesValues(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRoomType: (roomId, roomData) => dispatch(updateRoomTypeAction(roomId, roomData)),
});

const enhancer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhancer(EditForm);
