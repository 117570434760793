import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import Variant from "../Variant"
import RoomInfo from "../RoomInfo"
import Spinner from "../../Shared/Spinner"

export class VariantsList extends Component {
  state = {
    activeInput: false,
    id: null,
    ref: "name0"
  }

  onVariantNameClick = (id) => {
    this.setState({
      activeInput: true,
      id: id
    })
  }

  render() {
    const {
      getSelectedStyle,
      getSelectedList,
      updatedStyles,
      updatedCategories,
      roomOptions,
      createNewPlan,
      room,
      missingRequiredFields,
      variants,
      getFloorPlanImage,
      onSelectFloorPlan,
      editPlan,
      getImagesList,
      onAddNewVariant,
      onTabClick,
      roomVariant,
      saveRoomTypeId,
      onRoomImagesChange,
      onImagesChange,
      onCategoryChange,
      onStyleChange,
      onFloorImageChange,
      onVariantSwitcherChange,
      onDesignRoomSwitcherChange,
      onVariantsChange,
      onQuantityChange,
      onMoveChecked,
      onTagsChange,
      getSelectedTagsList,
      onCalculateVariant,
      onInputChange,
      onValidate,
      onVariantValidate,
      onCsvPriceBreakdown,
      onSave,
      onVariantSave,
      onVariantDelete,
      roomId
    } = this.props
    const vid = window.location.pathname.split("/")[4]
    return (
      <div className="nav">
        <div className="row tabs">
          <nav>
            <div className="nav nav-tabs flex-nowrap" id="nav-tab" role="tablist">
              <a
                className={`col-2 nav-item nav-link ${vid ? "" : "active"}`}
                id="nav-home-tab"
                data-toggle="tab"
                href="#nav-room"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
                onClick={() => onTabClick(vid)}
              >
                ROOM INFO
              </a>
              {variants.map((variant, i) => (
                <a
                  key={i}
                  className={`col-2 nav-item nav-link ${variant.id.toString() === vid ? "active" : ""}`}
                  id={`nav-variant_${i}-tab`}
                  data-toggle="tab"
                  href={`#nav-variant_${i}`}
                  role="tab"
                  aria-controls={`nav-variant_${i}`}
                  aria-selected="false"
                  onClick={() => onTabClick(variant.id)}
                >
                  <div
                    onDoubleClick={() => this.onVariantNameClick(variant.id)}
                    onMouseLeave={() => this.setState({ activeInput: false, id: null })}
                  >
                    {!this.state.activeInput || this.state.id !== variant.id ? (
                      <p>{variant.name || `VARIANT ${i + 1}`}</p>
                    ) : (
                      <input
                        name="name"
                        value={variant.name}
                        onChange={(e) => onVariantsChange(e.target.value, variant.id, e.target.name)}
                      />
                    )}
                  </div>
                </a>
              ))}
              <button className="col-2 nav-item nav-link button-add" type="button" onClick={onAddNewVariant}>
                + NEW
              </button>
            </div>
          </nav>
        </div>
        <div className="row content">
          <div className="tab-content" id="nav-tabContent">
            <div
              className={`tab-pane fade${vid ? "" : " show active"}`}
              id="nav-room"
              role="tabpanel"
              aria-labelledby="nav-room-tab"
            >
              {roomOptions ? (
                <RoomInfo
                  room_categories={updatedCategories(roomOptions.room_categories)}
                  floorPlans={roomOptions.floorplans}
                  createNewPlan={createNewPlan}
                  styles={updatedStyles(roomOptions.styles)}
                  getSelectedStyle={getSelectedStyle}
                  getSelectedList={getSelectedList}
                  room={room}
                  missingRequiredFields={missingRequiredFields}
                  getFloorPlanImage={getFloorPlanImage}
                  getImagesList={getImagesList}
                  onRoomImagesChange={onRoomImagesChange}
                  onImagesChange={onImagesChange}
                  onCategoryChange={onCategoryChange}
                  onStyleChange={onStyleChange}
                  onFloorImageChange={onFloorImageChange}
                  onInputChange={onInputChange}
                  onValidate={onValidate}
                  onSelectFloorPlan={onSelectFloorPlan}
                  editPlan={editPlan}
                  onSave={onSave}
                />
              ) : (
                <Spinner />
              )}
            </div>
            {roomVariant && (
              <div
                className={`tab-pane fade ${roomVariant.id.toString() === vid ? "active show" : ""}`}
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <Variant
                  roomId={roomId}
                  saveRoomTypeId={saveRoomTypeId}
                  variant={roomVariant}
                  variants={variants}
                  getImagesList={getImagesList}
                  onVariantSwitcherChange={onVariantSwitcherChange}
                  onImagesChange={onImagesChange}
                  onVariantsChange={onVariantsChange}
                  onQuantityChange={onQuantityChange}
                  onDesignRoomSwitcherChange={onDesignRoomSwitcherChange}
                  onMoveChecked={onMoveChecked}
                  onTagsChange={onTagsChange}
                  getSelectedTagsList={getSelectedTagsList}
                  onCalculateVariant={onCalculateVariant}
                  onVariantValidate={onVariantValidate}
                  onCsvPriceBreakdown={onCsvPriceBreakdown}
                  onSave={onVariantSave}
                  onDelete={onVariantDelete}
                />
              </div>
            )}
            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(VariantsList)
