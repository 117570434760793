import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { savePreviousUrlAction } from '../../../ducks/router';

class AddProduct extends Component {
  state = {
    activePopupIndex: 0,
    popupOpened: false,
  };

  togglePopup = (index, e) => {
    e.preventDefault();
    const { popupOpened } = this.state;
    this.setState({
      activePopupIndex: index,
      popupOpened: !popupOpened,
    });
  };

  goToAddProductPage = () => {
    const {
      variantId, push, match: { url, params: { id } }, savePreviousUrlAction,
    } = this.props;
    savePreviousUrlAction(url);
    variantId && push(`/rooms/${id}/variant/${variantId}/add-product`);
  };

  render() {
    const {
      variantId,
      products,
      onVariantsChange,
      imageSize,
    } = this.props;
    return (
      <div className="container-fluid mb-5 images-container">
        <div className="space-between">
          {products && products.map((product, productId) => (
            <div className="figure-wrapper">
              <div className={`images-wrapper-${imageSize}`}>
                <img className="photo rounded" alt={product.name} src={product.image.medium.url || product.url_image} />
              </div>
              <p className="quantity-handler">
                <span
                  onClick={() => onVariantsChange(
                    product.quantity > 0 ? product.quantity - 1 : product.quantity,
                    variantId,
                    'quantity',
                    productId
                  )}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </span>
                {product.quantity}
                <span onClick={() => onVariantsChange(product.quantity + 1, variantId, 'quantity', productId)}>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                {product.inventory_count !== undefined ? (
                  <span className="product__inventory_count">
                    {product.inventory_count}
                  </span>
                ) : null}
              </p>
              <p className="product__subtitle">{product.supplier_name} {product.sku}</p>
              <p>{product.name}</p>
            </div>
          ))}
          <div className={`add-image add-image-${imageSize}`} onClick={this.goToAddProductPage}>
            <p className="plus-sign">+</p>
            <p className="phrase">ADD PRODUCT</p>
          </div>
        </div>
      </div>
    );
  }
}

// Enhancer
const mapStateToProps = null;
const mapDispatchToProps = {
  savePreviousUrlAction,
  push,
};

const enhancer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhancer(AddProduct);

// TODO: setup the properties

// ImagesContainer.propTypes = {
//   images: PropTypes.array.isRequired,
//   onImagesChange: PropTypes.func.isRequired,
//   imageSize: PropTypes.oneOf(["small", "large"])
// }
