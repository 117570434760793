import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Spinner from "../../../Components/Shared/Spinner"

class Uploader extends Component {
  render() {
    let {
      file,
      email,
      error,
      loading,
      formatsTitle,
      onChoose,
      onUpload,
      onEmailEnter,
      onCheckEmailForValid
    } = this.props

    if (loading) {
      return <Spinner />
    }

    return (
      <Fragment>
        <div className="uploader">
          <label className="uploader-button uploader-common" title={formatsTitle}>
            Choose File
            <input name="file" type="file" className="uploader-field" onChange={(e) => onChoose(e)} />
          </label>
          <span className={`uploader-filename ${!file && "placeholder"}`}>{file ? file.name : formatsTitle}</span>
          <button
            name="import-button"
            className="uploader-import uploader-common"
            onClick={onUpload}
            title={formatsTitle}
          >
            Upload CSV
          </button>
        </div>
        <div className="email">
          <legend className="email-title">Email</legend>
          <input
            name="email"
            type="text"
            value={email}
            placeholder="Email for report:"
            className={`email-field ${error ? "email-error" : "email-common-border"}`}
            onChange={onEmailEnter}
            onBlur={onCheckEmailForValid}
          />
        </div>
      </Fragment>
    )
  }
}

Uploader.propTypes = {
  email: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  formatsTitle: PropTypes.string,
  onChoose: PropTypes.func,
  onUpload: PropTypes.func,
  onEmailEnter: PropTypes.func,
  onCheckEmailForValid: PropTypes.func
}

export default Uploader
