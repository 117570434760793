/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import equal from 'deep-equal';

import qs from '../../../modules/normalizingQs';
import {
  getProducts, productsSelector, deleteItems, moveItems,
} from '../../../ducks/etl_products';
import { getCategoriesMappingData } from '../../../ducks/categoriesMapping';
import {
  getAllAvailableScraperProductsAction,
  moveAllAvailableScraperProductsAction,
  getAllScraperProductsAction,
} from '../../../ducks/availableScrapers';
import { getFilterOptions, filterSelector } from '../../../ducks/etl_filter';
import { showModal, closeModal } from '../../../ducks/modal';

import FilteringSection from '../../Filtering';
import ETLTopSection from '../../Shared/ETLTopSection';
import Pagination from '../../Shared/Pagination';
import Spinner from '../../Shared/Spinner';

import { WAIT_INTERVAL } from '../../../constants';
import ProductView from '../../ProductView';

const renderColumns = [
  'checkbox',
  'thumbnail_image',
  'name',
  'supplier',
  'sku',
  'cost_usd',
  'cost_cad',
  'width',
  'depth',
  'height',
  'stock_number',
];

const breadcrumbsItems = [
  {
    name: 'ETL',
    to: '/',
  },
  {
    name: 'Scrapers',
    to: '/etl/scrapers',
  },
  {
    name: 'Products',
    to: '/products',
  },
];

const basicStateValues = {
  categories: [],
  colors: [],
  countries: [],
  expandedCategories: [],
  filter_currency: '',
  price_ranges: [0, 10000],
  q: undefined,
  selected: [],
  sort: '-stock_number',
  suppliers: [],
};

export class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...basicStateValues,
      checked: false,
      isSelectedAll: false,
      modalIsOpen: false,
      params: {
        page: this.props.history.location.search,
      },
      q: undefined,
      sort: '-stock_number',
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }
    window.scrollTo(0, 0);

    this.setState({
      price_ranges: this.convertPricesFromBE(this.props.price_ranges),
    });

    const { getProducts, getFilterOptions } = this.props;
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const actualFilters = this.getQueryParamsFromUrl();

    this.setState((prevState) => ({
      ...prevState,
      price_ranges: [parseInt(actualFilters.price_ranges?.[0], 10), parseInt(actualFilters.price_ranges?.[1], 10)],
      ...actualFilters,
    }));

    const scraper_id = this.props.match.params.id;
    const requestParams = `/etl/scrapers/${scraper_id}/products${search || `?${qs.stringify({
      page: 1,
      per_page: actualFilters?.per_page,
      sort: actualFilters?.sort,
    })}`}`;

    getFilterOptions();
    getProducts(requestParams);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilter(nextProps);
    this.setCurrentPage(nextProps);
  }

  getQueryParamsFromUrl = () => {
    const urlParse = qs.parse(this.props.history.location.search);

    return {
      categories: urlParse?.categories || [],
      colors: urlParse?.colors || [],
      countries: urlParse?.countries || [],
      filter_currency: urlParse?.filter_currency || undefined,
      page: urlParse?.page || 1,
      per_page: urlParse?.perPage || 100 || this.getPerPage(),
      price_ranges: urlParse?.price_ranges || [],
      q: urlParse?.q,
      sort: urlParse?.sort || '-stock_number',
      suppliers: urlParse?.suppliers || [],
    };
  };

  getPerPage = () => {
    const { products } = this.props;
    return products.loaded && products.productData.meta.perPage;
  };

  withConfirmation = () => window.confirm('Remove element(s) ?');

  withProductsConfirmation = () => window.confirm('Move product(s) ?');

  onRunScraper = () => {
    const scraper_id = this.props.match.params.id;
    const perPage = this.getPerPage();

    const { getAllScraperProductsAction, getProducts } = this.props;

    getAllScraperProductsAction(scraper_id);

    push(`/etl/scrapers/${scraper_id}/products?page=1&per_page=${perPage}`);
    getProducts(`/etl/scrapers/${scraper_id}/products?page=1&per_page=${perPage}`);
  };

  onMoveScraper = () => {
    const scraper_id = this.props.match.params.id;
    const perPage = this.getPerPage();

    const { moveAllAvailableScraperProductsAction, getProducts } = this.props;
    moveAllAvailableScraperProductsAction(scraper_id);

    push(`/etl/scrapers/${scraper_id}/products?page=1&per_page=${perPage}`);
    getProducts(`/etl/scrapers/${scraper_id}/products?page=1&per_page=${perPage}`);
  };

  onMapCategories = () => {
    const scraper_id = this.props.match.params.id;
    // const { getCategoriesMappingData } = this.props
    const { push } = this.props;

    push(`/etl/scrapers/${scraper_id}/map_categories`);
    // getCategoriesMappingData(scraper_id)
  };

  onParamsChange = (params) => {
    const { push } = this.props;
    const queryParams = qs.stringify({ ...this.getQueryParamsFromUrl(), ...params });
    const scraper_id = this.props.match.params.id;
    push(`/etl/scrapers/${scraper_id}/products?${queryParams}`);
    getProducts(`/etl/scrapers/${scraper_id}/products?${queryParams}`);
  };

  handleSearchChange = (q) => {
    clearTimeout(this.timer);

    this.setState({ q: q.length ? q : undefined });

    this.timer = setTimeout(this.onSearchChange, WAIT_INTERVAL);
  };

  onSearchChange = () => {
    const { q } = this.state;
    this.onParamsChange({ q });
  };

  onSortChange = (sort) => {
    this.setState(() => ({ sort }));
    this.onParamsChange({ sort });
  };

  onPageChange = (page) => {
    this.setState(() => ({ page }));
    this.onParamsChange({ page });
  };

  onPageAmountChange = (perPage) => {
    this.onParamsChange({ page: 1, per_page: perPage });
  };

  changeSelected = (items) => this.setState(() => ({
    selected: items,
  }));

  onRemoveItem = () => {
    const { selected } = this.state;
    const { deleteItems, history } = this.props;

    const updatedSelects = selected.map((item) => `products_ids[]=${item}`).join('&');
    const scraper_id = this.props.match.params.id;

    if (this.withConfirmation()) {
      deleteItems(updatedSelects, history.location.search, scraper_id);

      this.setState(() => ({ selected: [] }));
    }
  };

  onMoveScraperSelectedProducts = () => {
    const { selected } = this.state;
    const { moveItems, history } = this.props;

    const updatedSelects = selected.map((item) => `products_ids[]=${item}`).join('&');
    const scraper_id = this.props.match.params.id;

    if (this.withProductsConfirmation()) {
      moveItems(updatedSelects, history.location.search, scraper_id);

      this.setState(() => ({ selected: [] }));
    }
  };

  applyFilters = (val) => {
    const { filter_currency, price_ranges } = val;
    const newCurrency = price_ranges.length ? filter_currency : undefined;

    this.onParamsChange({ ...val, filter_currency: newCurrency, page: 1 });
  };

  setFilter(nextProps) {
    const { search } = nextProps.history.location;
    this.setState(() => ({
      params: {
        page: search,
      },
      q: qs.parse(search).q || undefined,
    }));
  }

  setCurrentPage(nextProps) {
    const { page } = this.state.params;
    const { search } = nextProps.history.location;

    if (!equal(page, search)) {
      this.props.getProducts(nextProps.location.pathname + search);
    }
  }

  convertPricesFromBE = (price_ranges) => {
    const results = [];
    let i = 0;

    if (price_ranges?.[i] === '900') {
      results.push(price_ranges?.[0]);
      i += 1;
    }
    for (i; i < price_ranges?.length; i += 2) {
      if (price_ranges[i]) {
        results.push(`${price_ranges[i]}-${price_ranges[i + 1]}`);
      }
    }
    return results;
  };

  handleProductEdit = (productId) => {
    const { push } = this.props;
    const scraper_id = this.props.match.params.id;
    push(`/etl/scrapers/${scraper_id}/products/${productId}`);
  };

  handleChange = () => {
    this.setState((prevState) => ({
      checked: !prevState.checked,
    }));
  };

  render() {
    const { params, selected, q } = this.state;

    const {
      history: { location: { search } },
      products,
      filters,
    } = this.props;

    const pageLoaded = products.loaded && filters.loaded;

    const pages = pageLoaded && Math.ceil(products.productData.meta.totalEntries / products.productData.meta.perPage);

    const { checked, isSelectedAll } = this.state;

    const handleSelect = (id, val) => {
      let selectedItems = selected;
      if (val) {
        selectedItems.push(id);
      } else {
        selectedItems = selectedItems.filter((user) => user !== id);
      }
      this.changeSelected(selectedItems);
    };

    const selectAll = () => {
      this.setState((prevState) => ({
        ...prevState,
        isSelectedAll: !prevState.isSelectedAll,
      }));

      if (!isSelectedAll) {
        products.productData.data.map((item) => handleSelect(item.id, !isSelectedAll));
      } else {
        this.changeSelected([]);
      }
    };

    const saveChanges = (state) => {
      const {
        categories, colors, countries, filter_currency, suppliers, price_ranges,
      } = state;
      const filterState = {
        categories,
        colors,
        countries,
        expandedCategories: [],
        filter_currency: filter_currency || 'USD',
        price_ranges: price_ranges?.[0] ? price_ranges : [],
        suppliers,
      };

      this.setState(filterState);
      this.applyFilters(filterState);
    };

    if (!pageLoaded) {
      return (
        <div className="products-page">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="products-page">
        <div className="products-page-content">
          <ETLTopSection
            pageTitle="products"
            breadcrumbs={breadcrumbsItems}
            onRemoveItem={this.onRemoveItem}
            isDisabled={selected.length === 0}
            shouldCreate={false}
            shouldImport={false}
            onRunScraper={this.onRunScraper}
            onMoveScraper={this.onMoveScraper}
            onMapCategories={this.onMapCategories}
            onMoveScraperSelectedProducts={this.onMoveScraperSelectedProducts}
            shouldDataStatusReport={false}
          />
          <FilteringSection
            page={params}
            q={q || ''}
            disableFilter
            onSearchChange={this.handleSearchChange}
            perPage={products.productData.meta.perPage}
            activePage={+qs.parse(search).page || 1}
            totalEntriesAmount={products.productData.meta.totalEntries}
            pagesAmount={products.productData.meta.perPage}
            onPageAmountChange={this.onPageAmountChange}
          />
          <ProductView
            {...this.props}
            state={this.state}
            saveChanges={saveChanges}
            checked={checked}
            filters={filters?.filter?.data}
            handleProductEdit={this.handleProductEdit}
            handleSelectItems={this.changeSelected}
            isSelectedAll={isSelectedAll}
            items={products.productData?.data}
            onChange={this.handleChange}
            onSortChange={this.onSortChange}
            renderColumns={renderColumns}
            selectAll={selectAll}
            selected={selected}
          />
          <Pagination
            pages={pages}
            activePage={+qs.parse(search).page}
            onPageChange={(page) => this.onPageChange(page)}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({
    productsETL: products, filter, authReducer,
  }) => ({
    filters: filterSelector(filter),
    isAuthenticated: authReducer.isAuthenticated,
    products: {
      loaded: products.loaded,
      loading: products.loading,
      productData: productsSelector(products.products),
      tableLoaded: products.tableLoaded,
    },
  }),
  {
    closeModal,
    deleteItems,
    getAllAvailableScraperProductsAction,
    getAllScraperProductsAction,
    getCategoriesMappingData,
    getFilterOptions,
    getProducts,
    moveAllAvailableScraperProductsAction,
    moveItems,
    push,
    showModal,
  }
)(Products);
