import { put, call, all, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { appName, POSTFIX } from "../constants"
import Http from "../api/design-templates-floor-plans"
import { goBack } from "react-router-redux"

/**
 * Constants
 **/
export const moduleName = "availableFloorPlans"
const prefix = `@@${appName}/${moduleName}`

const GET_FLOOR_PLANS_REQUEST = `${prefix}/GET_FLOOR_PLANS_REQUEST`
const GET_FLOOR_PLAN_BY_ID_REQUEST = `${prefix}/GET_FLOOR_PLAN_BY_ID_REQUEST`
const CREATE_FLOOR_PLAN_REQUEST = `${prefix}/CREATE_FLOOR_PLAN_REQUEST`
const UPDATE_FLOOR_PLAN_REQUEST = `${prefix}/UPDATE_FLOOR_PLAN_REQUEST`
const CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST = `${prefix}/CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST`
const DELETE_FLOOR_PLAN_REQUEST = `${prefix}/DELETE_FLOOR_PLAN_REQUEST`
const GET_CURRENT_ROOM_ID = `${prefix}/GET_CURRENT_ROOM_ID`
const GET_ROOM_INNER_DESIGN_ROOMS_REQUEST = `${prefix}/GET_ROOM_INNER_DESIGN_ROOMS_REQUEST`
const SET_PAGINATION_CALL = `${prefix}/SET_PAGINATION_CALL`
const SET_PER_PAGE_CALL = `${prefix}/SET_PER_PAGE_CALL`


/**
 * Reducer
 **/
const initialState = {
  floorPlans: [],
  floorPlan: {},
  designRooms: [],
  idCurrentRoom: null,
  isLoading: false,
  error: null,
  totalEntries: 0,
  currentPage: 1,
  perPage: 50,
}

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case GET_FLOOR_PLANS_REQUEST + POSTFIX.START:
    case GET_FLOOR_PLAN_BY_ID_REQUEST + POSTFIX.START:
    case CREATE_FLOOR_PLAN_REQUEST + POSTFIX.START:
    case UPDATE_FLOOR_PLAN_REQUEST + POSTFIX.START:
    case DELETE_FLOOR_PLAN_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_FLOOR_PLANS_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_FLOOR_PLAN_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        floorPlans: payload.floorPlans,
        totalEntries: payload.totalEntries
      }

    case CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        design_rooms: payload.data
      }

    case GET_ROOM_INNER_DESIGN_ROOMS_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        designRooms: payload.designRooms
      }

    case GET_FLOOR_PLAN_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        floorPlan: payload.floorPlan
      }

    case SET_PAGINATION_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        currentPage: payload.page
      }

    case SET_PER_PAGE_CALL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        perPage: payload.perPage
      }

    case GET_FLOOR_PLANS_REQUEST + POSTFIX.FAILURE:
    case GET_FLOOR_PLAN_BY_ID_REQUEST + POSTFIX.FAILURE:
    case CREATE_FLOOR_PLAN_REQUEST + POSTFIX.FAILURE:
    case UPDATE_FLOOR_PLAN_REQUEST + POSTFIX.FAILURE:
    case DELETE_FLOOR_PLAN_REQUEST + POSTFIX.FAILURE:
    case CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST + POSTFIX.FAILURE:
    case GET_ROOM_INNER_DESIGN_ROOMS_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }
    case GET_CURRENT_ROOM_ID:
      return {
        ...state,
        idCurrentRoom: payload.id
      }
    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableFloorPlansReducer
 **/
export const getAvailableFloorPlans = function(state) {
  return state.availableFloorPlansReducer.floorPlans
}

export const getAvailableFloorPlanById = function(state) {
  return state.availableFloorPlansReducer.floorPlan
}

export function getTotalEntries(state) {
  return state.availableFloorPlansReducer.totalEntries
}

export function getCurrentPage(state) {
  return state.availableFloorPlansReducer.currentPage
}

export function getPerPage(state) {
  return state.availableFloorPlansReducer.perPage
}

export const getAvailableFloorPlanRoomInner = function(state) {
  return state.availableFloorPlansReducer.floorPlans[0] && state.availableFloorPlansReducer.floorPlans[0].room_inner
}

export const getIsLoading = function(state) {
  return state.availableFloorPlansReducer.isLoading
}

export const getIdCurrentRoom = function(state) {
  return state.availableFloorPlansReducer.idCurrentRoom
}

export const checkFloorPlanDisabledDesignRoom = function(state) {
  return state.checkFloorPlanDisabledDesignRoomReducer.design_rooms
}

export const getFloorPlanRoomInnerDesignRooms = function(state) {
  return state.getFloorPlanRoomInnerDesignRoomsReducer.designRooms
}

/**
 * Action Creators
 **/

export function getIdCurrentRoomAction(id) {
  return { type: GET_CURRENT_ROOM_ID, payload: { id } }
}

export function getAvailableFloorPlansRequest(data) {
  return { type: GET_FLOOR_PLANS_REQUEST, payload: data }
}

export function getAvailableFloorPlanByIdRequest(id) {
  return {
    type: GET_FLOOR_PLAN_BY_ID_REQUEST,
    payload: { id }
  }
}

export function updateAvailableFloorPlanRequest(id, data) {
  return {
    type: UPDATE_FLOOR_PLAN_REQUEST,
    payload: { id, data }
  }
}

export function createAvailableFloorPlanRequest(data) {
  return {
    type: CREATE_FLOOR_PLAN_REQUEST,
    payload: { data }
  }
}

export function deleteAvailableFloorPlanRequest(id) {
  return {
    type: DELETE_FLOOR_PLAN_REQUEST,
    payload: { id }
  }
}

export function checkFloorPlanDisabledDesignRoomRequest(id, design_rooms) {
  return {
    type: CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST,
    payload: { id, design_rooms }
  }
}

export function getFloorPlanRoomInnerDesignRoomsRequest() {
  return { type: GET_ROOM_INNER_DESIGN_ROOMS_REQUEST }
}

/**
 * Sagas
 **/
function* getAvailableFloorPlansSaga({ payload }) {
  yield put({ type: GET_FLOOR_PLANS_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getFloorPlans(payload))
    yield put({
      type: GET_FLOOR_PLANS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { floorPlans: response.data, totalEntries: response.meta.totalEntries }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_FLOOR_PLANS_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* changePaginationSaga({ payload: { page } }) {
  yield put({
    type: SET_PAGINATION_CALL + POSTFIX.SUCCESSFUL,
    payload: { page: +page }
  })
}

export function changeCurrentPage(page) {
  return {
    type: SET_PAGINATION_CALL,
    payload: { page }
  }
}

export function changePerPage(page) {
  return {
    type: SET_PER_PAGE_CALL,
    payload: { page }
  }
}

function* getAvailableFloorPlanByIdSaga({ payload: { id } }) {
  yield put({ type: GET_FLOOR_PLAN_BY_ID_REQUEST + POSTFIX.START })
  try {
    const floorPlan = yield call(() => Http.getFloorPlanById(id))
    yield put({
      type: GET_FLOOR_PLAN_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { floorPlan }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_FLOOR_PLAN_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* createAvailableFloorPlanSaga({ payload: { data } }) {
  yield put({ type: CREATE_FLOOR_PLAN_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.addFloorPlan(data))
    yield call(() => toastr.success("Successfully created"))
    yield put(goBack())
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: CREATE_FLOOR_PLAN_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* updateAvailableFloorPlanSaga({ payload: { id, data } }) {
  yield put({ type: UPDATE_FLOOR_PLAN_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.updateFloorPlan(id, data))
    yield call(() => toastr.success("Successfully updated"))
    yield put(goBack())
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: UPDATE_FLOOR_PLAN_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* deleteAvailableFloorPlanSaga({ payload: { id } }) {
  yield put({ type: DELETE_FLOOR_PLAN_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.deleteFloorPlan(id))
    yield call(() => toastr.success("Successfully deleted"))
    yield put({
      type: DELETE_FLOOR_PLAN_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { floorPlans: response.data, totalEntries: response.meta.totalEntries }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: DELETE_FLOOR_PLAN_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* checkFloorPlanDisabledDesignRoomSaga({ payload: { id, design_rooms } }) {
  yield put({ type: CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST + POSTFIX.START })
  try {
    const data = yield call(() => Http.checkDesignRoomFloorPlanById(id, design_rooms))
    yield put({
      type: CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { data }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

function* floorPlanRoomInnerDesignRoomsSaga() {
  yield put({ type: GET_ROOM_INNER_DESIGN_ROOMS_REQUEST + POSTFIX.START })
  try {
    const designRooms = yield call(() => Http.getDesignRooms())
    yield put({
      type: GET_ROOM_INNER_DESIGN_ROOMS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { designRooms }
    })
  }
  catch (error) {
    yield call(() => toastr.error(error.message))
    yield put({
      type: GET_ROOM_INNER_DESIGN_ROOMS_REQUEST + POSTFIX.FAILURE,
      error: error.message
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_FLOOR_PLANS_REQUEST, getAvailableFloorPlansSaga),
    takeLatest(SET_PAGINATION_CALL, changePaginationSaga),
    takeLatest(CREATE_FLOOR_PLAN_REQUEST, createAvailableFloorPlanSaga),
    takeLatest(GET_FLOOR_PLAN_BY_ID_REQUEST, getAvailableFloorPlanByIdSaga),
    takeLatest(UPDATE_FLOOR_PLAN_REQUEST, updateAvailableFloorPlanSaga),
    takeLatest(DELETE_FLOOR_PLAN_REQUEST, deleteAvailableFloorPlanSaga),
    takeLatest(CHECK_FLOOR_PLAN_DESIGN_ROOM_REQUEST, checkFloorPlanDisabledDesignRoomSaga),
    takeLatest(GET_ROOM_INNER_DESIGN_ROOMS_REQUEST, floorPlanRoomInnerDesignRoomsSaga)
  ])
}
