import React from 'react';
import PropTypes from 'prop-types';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FieldArray, reduxForm } from 'redux-form';
import { getDesignRules } from '../../../ducks/designRuleMatching';
// Components
import DynamicTemplatesArray from './FieldsArray';
// Misc
import { formName } from '../../../constants/forms';

function IntroScreenForm({
  handleSubmit, onSubmit, disabledSubmit, initialValues, invalid,
}) {
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)} className="form">
      <FieldArray
        name="rules"
        component={DynamicTemplatesArray}
        disabledSubmit={disabledSubmit}
        rules={initialValues.rules}
        invalid={invalid}
      />
    </form>
  );
}

IntroScreenForm.propTypes = {
  // from parent component
  onSubmit: PropTypes.func,
};

// Enhancer
const mapStateToProps = (state) => ({ initialValues: getDesignRules(state) });

const enhancer = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName.templateForm,
    enableReinitialize: true,
  })
);

export default enhancer(IntroScreenForm);
