import React, { Fragment } from "react"
import PropTypes from "prop-types"

// components
import DynamicInputs from "./DynamicInputs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

DynamicFormFields.propTypes = {
  // from ModalAttribute component
  attributes: PropTypes.array,
  onAttributesChange: PropTypes.func,
  onDeleteAttribute: PropTypes.func
}

DynamicFormFields.defaultProps = {
  attributes: []
}

function DynamicFormFields({ attributes, onAttributesChange, onDeleteAttribute }) {
  const renderFormControl = attributes.map(({ id: attributeId, fields }) => {
    const onDeleteButtonClick = (event) => onDeleteAttribute(event, attributeId)

    return (
      <div key={attributeId} className="add-attributes__form-control">
        <DynamicInputs fields={fields} onAttributesChange={onAttributesChange} attributeId={attributeId} />
        <button type="button" className="add-attributes__delete-button button" onClick={onDeleteButtonClick}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    )
  })

  return <Fragment>{renderFormControl}</Fragment>
}

export default DynamicFormFields
