import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// components
import { Button } from '../../Shared';

ButtonsBlock.propTypes = {
  // from parent component
  addRule: PropTypes.func,
  // from connect
  selected: PropTypes.array,
};

function ButtonsBlock({ addRule }) {
  return (
    <Button size="large" width={200} uppercase onClick={addRule}>
      Add Rule
    </Button>
  );
}

const mapDispatchToProps = (state) => ({
  selected: state.tableReducer.attributeTagging,
});

export default connect(mapDispatchToProps)(ButtonsBlock);
