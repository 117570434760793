import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/fp/get';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// Selectors
import { change, reduxForm } from 'redux-form';
import { getAllFloorplansSelector } from '../../../../../ducks/availableTemplates';
// Misc
import { formName } from '../../../../../constants/forms';
import { syncValidate } from '../../../../Shared/reduxForm/sync-validate';
// Import Component
import TemplateInfoFormStyle from './style.js';
import { Button } from '../../../../Shared';
import { ReduxFormFileUploader, ReduxFormInput, ReduxFormSelect } from '../../../../Shared/reduxForm';

TemplateInfoForm.propTypes = {
  // from redux-form
  handleSubmit: PropTypes.func,
  // from Templates/Detail
  onSubmit: PropTypes.func.isRequired,
};

function TemplateInfoForm({
  change, floorplans, floorplansWidth, floorplansLength, handleSubmit, onSubmit, pristine, submitting, invalid,
}) {
  useEffect(() => {
    change('width', floorplansWidth);
    change('depth', floorplansLength);
  }, [floorplansWidth, floorplansLength]);

  return (
    <TemplateInfoFormStyle>
      <form
        className="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form__row">
          <p className="form__row-title">SKU: *</p>
          <div className="form__control center">
            <ReduxFormInput name="sku" />
          </div>
        </div>
        <div className="form__row">
          <p className="form__row-title">Images:</p>
          <div className="form__row-images">
            <div className="form__file-control">
              <ReduxFormFileUploader
                name="default_image"
                formName={formName.templateCreateInfoForm}
                width="175px"
                height="175px"
                validate={[
                  syncValidate.images,
                ]}
              />
            </div>
            <div className="form__file-control">
              <ReduxFormFileUploader
                name="image2"
                formName={formName.templateCreateInfoForm}
                width="175px"
                height="175px"
                validate={[
                  syncValidate.images,
                ]}
              />
            </div>
            <div className="form__file-control">
              <ReduxFormFileUploader
                name="image3"
                formName={formName.templateCreateInfoForm}
                width="175px"
                height="175px"
                validate={[
                  syncValidate.images,
                ]}
              />
            </div>
            <div className="form__file-control">
              <ReduxFormFileUploader
                name="image4"
                formName={formName.templateCreateInfoForm}
                width="175px"
                height="175px"
                validate={[
                  syncValidate.images,
                ]}
              />
            </div>
          </div>
        </div>
        <div className="form__row start">
          <p className="form__row-title">Floor plan:</p>
          <div className="form__column floor-plan">
            <div className="form__control">
              <ReduxFormSelect
                key="floor_plan"
                name="floor_plan"
                options={floorplans}
              />
            </div>
          </div>
          <div className="form__column">
            <div className="form__control">
              <ReduxFormInput name="width" label="Width" />
            </div>
            <div className="form__control">
              <ReduxFormInput name="depth" label="Length" />
            </div>
          </div>
        </div>
        <div className="form__submit-button-box">
          <Button
            type="submit"
            size="large"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </TemplateInfoFormStyle>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  floorplans: getAllFloorplansSelector(state),
  floorplansWidth: _get('values.floor_plan.width', state.form[formName.templateCreateInfoForm]),
  floorplansLength: _get('values.floor_plan.height', state.form[formName.templateCreateInfoForm]),
});

const enhancer = compose(
  connect(mapStateToProps, { change }),
  reduxForm({
    form: formName.templateCreateInfoForm,
  })
);

export default enhancer(TemplateInfoForm);
