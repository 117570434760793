import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import equal from 'deep-equal';
import qs from '../../modules/normalizingQs';
import { getRoomTypes, roomTypesSelector } from "../../ducks/roomTypes"
import { closeModal, showModal } from '../../ducks/modal';
import FilteringSection from '../../Components/Filtering';
import TopRoomTypeSection from '../../Components/Shared/TopRoomTypeSection'
import Pagination from '../../Components/Shared/Pagination';
import Spinner from '../../Components/Shared/Spinner';
import { WAIT_INTERVAL } from '../../constants';
import RoomTypesTable from "../../Components/Shared/RoomTypesTable"
import './index.scss';

const renderColumns = [
  'id',
  'name',
  'created_at',
  'updated_at',
];

const breadcrumbsItems = [
  {
    to: '/',
    name: 'rooms',
  },
  {
    to: '/types',
    name: 'types',
  },
];

const basicStateValues = {
  selected: [],
  q: undefined,
  sort: undefined,
};

export class RoomTypes extends Component {
  state = {
    ...basicStateValues,
    params: {
      page: this.props.history.location.search,
    },
    modalIsOpen: false,
    q: undefined,
    sort: undefined,
    isDisabled: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }
    const { getRoomTypes } = this.props;

    const {
      history: {
        location: { search },
      },
    } = this.props;
    const { q } = this.getQueryParamsFromUrl();
    this.setState({ q });
    const requestParams = `/room_types${search || `?${qs.stringify({ page: 1 })}`}`;
    this.changeSelected([]);
    getRoomTypes(requestParams);
  }

  getQueryParamsFromUrl = () => {
    const urlParse = qs.parse(this.props.history.location.search);

    return {
      page: urlParse.page || 1,
      per_page: urlParse.perPage || this.getPerPage(),
      q: urlParse.q,
      sort: urlParse.sort,
    };
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilter(nextProps);
    this.setCurrentPage(nextProps);
  }

  getPerPage = () => {
    const { roomTypes } = this.props;

    return roomTypes.loaded && roomTypes.roomTypesData.meta.perPage;
  };

  onViewAll = () => {
    const { getRoomTypes, push, location } = this.props;
    const perPage = this.getPerPage();

    this.setState({
      ...this.state,
      q: undefined,
      sort: undefined,
    });

    push(`${location.pathname}?page=1&per_page=${perPage}${location.hash}`);
    getRoomTypes(`/room_types?page=1&per_page=${perPage}`);
  };

  onParamsChange = (params) => {
    const {
      push,
      location: { pathname, hash },
    } = this.props;
    const queryParams = qs.stringify({ ...this.getQueryParamsFromUrl(), ...params });
    push(`${pathname}?${queryParams}${hash}`);
  };

  handleSearchChange = (q) => {
    clearTimeout(this.timer);

    this.setState({ q: q.length ? q : undefined });

    this.timer = setTimeout(this.onSearchChange, WAIT_INTERVAL);
  };

  handleTagEdit = (roomTypeId) => {
    const { push } = this.props;
    push(`/room-types/${roomTypeId}`);
  };

  onSearchChange = () => {
    const { q } = this.state;
    this.onParamsChange({ q });
  };

  onSortChange = (sort) => {
    this.setState(() => ({ sort }));
    this.onParamsChange({ sort });
  };

  onPageChange = (page) => {
    this.setState(() => ({ page }));
    this.onParamsChange({ page });
  };

  onPageAmountChange = (perPage) => {
    this.onParamsChange({ per_page: perPage, page: 1 });
  };

  changeSelected = (items) => {
    this.setState(() => ({ selected: items }));
  };

  withConfirmation = () => window.confirm('Remove element(s) ?');

  setFilter(nextProps) {
    const { search } = nextProps.history.location;
    this.setState(() => ({
      params: {
        page: search,
      },
      q: qs.parse(search).q || undefined,
    }));
  }

  setCurrentPage(nextProps) {
    const { page } = this.state.params;
    const { search } = nextProps.history.location;

    if (!equal(page, search)) {
      const requestParams = `/room_types${search}`;
      this.props.getRoomTypes(requestParams);
    }
  }

  render() {
    const { params, selected, q } = this.state;

    const {
      history: {
        location: { search },
      },
      roomTypes,
    } = this.props;

    const pageLoaded = roomTypes.loaded;

    const tableLoaded = roomTypes.tableLoaded === true;
    const pages = pageLoaded && Math.ceil(roomTypes.roomTypesData.meta.totalEntries / roomTypes.roomTypesData.meta.perPage);
    const noResults = pageLoaded && roomTypes.roomTypesData.data.length === 0;

    if (!pageLoaded) {
      return (
        <div className="room-types-page">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="room-types-page">
        <div className="room-types-content">
          <TopRoomTypeSection
            pageTitle="room types"
            breadcrumbs={breadcrumbsItems}
            isDisabled={selected.length === 0}
            shouldCreate
          />
          <FilteringSection
            page={params}
            q={q || ''}
            productsLog={false}
            room_types
            onModalOpen={this.openModal}
            onSearchChange={this.handleSearchChange}
            perPage={roomTypes.roomTypesData.meta.perPage}
            activePage={+qs.parse(search).page || 1}
            totalEntriesAmount={roomTypes.roomTypesData.meta.totalEntries}
            pagesAmount={roomTypes.roomTypesData.meta.perPage}
            onPageAmountChange={this.onPageAmountChange}
          />
          <RoomTypesTable
            type="room_types"
            noResults={noResults}
            tableLoaded={tableLoaded}
            renderColumns={renderColumns}
            items={roomTypes.roomTypesData.data}
            selected={selected}
            handleSelectItems={this.changeSelected}
            onEditItem={this.handleTagEdit}
            onSortChange={this.onSortChange}
          />
          <Pagination
            pages={pages}
            activePage={+qs.parse(search).page}
            onPageChange={(page) => this.onPageChange(page)}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  ({ roomTypes, authReducer, routeReducer }) => ({
    roomTypes: {
      roomTypesData: roomTypesSelector(roomTypes.roomTypes),
      loading: roomTypes.loading,
      loaded: roomTypes.loaded,
      tableLoaded: roomTypes.tableLoaded,
    },
    isAuthenticated: authReducer.isAuthenticated,
    prevUrl: routeReducer.url,
  }),
  {
    getRoomTypes,
    showModal,
    closeModal,
    push,
  }
)(RoomTypes);
