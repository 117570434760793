import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
// Components
import StyledDropZone from "./styles"

const DropZone = (props) => {
  const onDrop = useCallback((acceptedFiles) => {
    props.onFileUploaderChange(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const { fileUrl } = props

  const getImagePreview = () => {
    if (Array.isArray(fileUrl) && !fileUrl.length) return null
    if (typeof fileUrl === "string") return fileUrl
    if (fileUrl.name) return URL.createObjectURL(fileUrl)
  }

  return (
    <StyledDropZone {...getRootProps()}>
      <input {...getInputProps()} />
      {
        fileUrl && (
          <div className="image-preview">
            <img src={getImagePreview()} alt="fileUrl" />
          </div>
        )
      }
      {
        isDragActive ?
          <p>Drop the file here ...</p> :
          <p>Drag 'n' drop some file here, or click to select file</p>
      }
    </StyledDropZone>
  )
}

export default DropZone
