import styled from "styled-components"

export default styled.div`
  width: 1000px;

  .add-attributes__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 25px;
  }

  .add-attributes__title {
    margin-top: 20px;
    margin-right: 100px;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    text-align: left;
  }

  .button {
    border: none;
    background-color: transparent;
    font-weight: 700;
    color: #434343;
    cursor: pointer;
    transition: all 0.3s;

    &:active {
      opacity: 0.7;
    }
  }

  .add-attributes__add-button {
    font-size: 30px;

    &:hover,
    &:focus {
      color: #329932;
    }
  }

  .add-attributes__form {
    width: 100%;
    color: #595959;
  }

  .add-attributes__form-control {
    display: flex;
    justify-content: space-between;
    padding-left: 100px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .add-attributes__form fieldset {
    display: flex;
    justify-content: space-between;
  }

  .add-attributes__form .form-control-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 390px;
  }

  .add-attributes__form .input {
    width: 320px;
  }

  .add-attributes__delete-button {
    font-size: 22px;

    &:hover,
    &:focus {
      color: #ff3232;
    }
  }

  .add-attributes__footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 20px;
  }

  .add-attributes__footer button {
    margin-right: 200px;

    &:last-child {
      margin-right: 0;
    }
  }
`
