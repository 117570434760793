import { appName } from "../constants"
import { all, put, takeLatest } from "redux-saga/effects"

/**
 * Constants
 **/
export const moduleName = "pagination"
const prefix = `${appName}/${moduleName}`

const SET_PAGINATION_VALUE_CALL = `${prefix}/SET_PAGINATION_VALUE_CALL`
const SET_PAGINATION_VALUE = `${prefix}/SET_PAGINATION_VALUE`

/**
 * Reducer
 **/
const initialState = {
  value: 1
}

export default function reducer(state = initialState, { type, value }) {
  switch (type) {
    case SET_PAGINATION_VALUE:
      return {
        ...state,
        value: value
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/

/**
 * Action Creators
 **/
export function setPaginationValue(value) {
  return {
    type: SET_PAGINATION_VALUE_CALL,
    payload: { value }
  }
}

/**
 * Sagas
 **/
function* setPaginationValueSaga({ payload: { value } }) {
  yield put({
    type: SET_PAGINATION_VALUE,
    value: value
  })
}

export function* saga() {
  yield all([takeLatest(SET_PAGINATION_VALUE_CALL, setPaginationValueSaga)])
}
