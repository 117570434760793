import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';

class RoomTypeListOptions extends Component {
  render() {
    const { shouldCreate } = this.props;
    return (
      <div className="top-section-links">
        <div className="top-section-links-first">
          {shouldCreate && (
            <Link className="top-section-links-first-create" to="/room-types/0">
              CREATE
            </Link>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = { push };

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeListOptions);
