import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/fp/get';
// Misc
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import objectToFormData from '../../../utils/objectToFormData';
import { formName } from '../../../constants/forms';
// Selectors/Actions
import {
  updateRoomVariantAction,
  createRoomVariantAction,
  createTemplateAction,
  getAllFloorplansAction,
  getAvailableTemplateAction,
  getIsLoadingSelector,
  getProductDataToSend,
  getRoomVariantSelector,
  getRoomVariantsSelector,
  getTemplateIdSelector,
  updateAvailableTemplateAction,
} from '../../../ducks/availableTemplates';
// Import Components
import { DesignTemplatesLayout } from '../index';
import Tabs from './Tabs';
import Tab from './Tabs/Tab';
import TemplateHeader from './TemplateHeader';
import CreateTemplateInfoForm from './create/TemplateInfoForm';
import EditTemplateInfoForm from './edit/TemplateInfoForm';
import EditTemplatePackageForm from './edit/TemplatePackageForm';

const getDataEditTemplateInfo = (values, templateId, formInfoValues, id) => {
  const product = {};
  const template = {
    id: templateId,
    is_default: formInfoValues.is_default,
  };

  for (const [key, value] of Object.entries(values)) {
    if (
      (key === 'default_image'
        || key === 'image2'
        || key === 'image3'
        || key === 'image4')
      && value === ''
    ) {
      product[`remove_${key}`] = true;
    }
    if (
      (key === 'default_image'
        || key === 'image2'
        || key === 'image3'
        || key === 'image4')
      && (value !== null && value.match && value.match(/photos/g))
    ) {} else if (key !== 'floor_plan') {
      product[key] = value;
    } else {
      template.floorplan_id = value && value.value;
    }
  }
  product.id = id;
  product.name = formInfoValues.name;
  product.active = formInfoValues.active;

  return {
    product: { ...product },
    template: { ...template },
  };
};

TemplatesDetail.propTypes = {
  // from connect/react-router-redux
  goBack: PropTypes.func,
  floorplans: PropTypes.array,
};

function TemplatesDetail({
  createTemplateAction,
  getAvailableTemplateAction,
  createRoomVariantAction,
  dataToSend,
  roomVariant,
  templateId,
  getAllFloorplansAction,
  formInfoValues,
  updateAvailableTemplateAction,
  updateRoomVariantAction,
  isLoading,
  roomVariants = [],
  push,
  match: {
    params: {
      id,
    },
  },
}) {
  useEffect(() => {
    getAllFloorplansAction();

    return () => window.localStorage.removeItem('variantIndex');
  }, []);

  const isEditMode = id !== 'new';

  const buttonGoToTemplates_clickHandler = function () {
    push('/design-templates/detail/templates');
  };

  const infoForm_submitHandler = function (values) {
    if (isEditMode) {
      const data = getDataEditTemplateInfo(values, templateId, formInfoValues, id);
      updateAvailableTemplateAction(id, objectToFormData(data));
    } else {
      const product = { active: false };
      const template = { id: templateId };

      for (const [key, value] of Object.entries(values)) {
        if (key === 'floor_plan') {
          template.floorplan_id = value.value;
        } else {
          product[key] = value;
        }
      }

      const data = {
        product: { ...product },
        template: { ...template },
      };
      createTemplateAction(objectToFormData(data));
    }
  };

  const packageForm_submitHandler = function (values) {
    if (isEditMode) {
      const product = {};
      const room_variant = {};
      const template = { id: templateId };
      const products = [];
      const addProducts = [];
      const produtsList = [];
      const designRoomIdArr = [];

      for (const [key, value] of Object.entries(values)) {
        if (!isNaN(key)) {
          products.push({ id: key, quantity: value });
          addProducts.push({ [key]: value });
        } else if (key === 'default') {
          room_variant[key] = value;
        } else {
          product[key] = value;
        }
      }

      const newProducts = roomVariant.products && roomVariant.products.map((variant) => (
        variant.values.map((item) => {
          designRoomIdArr.push(variant.design_room_id);
          const foundProduct = products.find((product) => +product.id === item.id);
          if (foundProduct) {
            return { [foundProduct.id]: foundProduct.quantity };
          }
          return { [item.id]: item.quantity };
        })
      ));

      Array.isArray(newProducts) && newProducts[0] && newProducts[0].map((item, index) => {
        for (const [key, value] of Object.entries(item)) {
          produtsList.push({ product_id: key });
          produtsList.push({ product_count: value });
          produtsList.push({ design_room_id: designRoomIdArr[index] });
        }
        return produtsList;
      });

      product.products = produtsList;
      product.default_image = dataToSend.default_image;
      product.image2 = dataToSend.image2;
      product.image3 = dataToSend.image3;
      product.image4 = dataToSend.image4;

      product.id = roomVariant.id;
      product.permalink = roomVariant.permalink;
      product.room_variant_id = roomVariant.id;
      product.preset_id = roomVariant.preset_id;

      template.floorplan_id = dataToSend.template_floor_plan_id;

      const data = {
        product,
        room_variant,
        template,
      };

      if (roomVariant.newVariant) {
        const data = {};
        const room_variant = {};
        for (const [key, value] of Object.entries(values)) {
          if (key === 'default') {
            room_variant[key] = value;
          } else {
            data[key] = value;
          }
        }

        createRoomVariantAction(id, objectToFormData({ product: data, room_variant }));
      } else {
        updateRoomVariantAction(roomVariant.id, { products: produtsList }, data, id);
      }
    }
  };

  const renderRoomVariants = roomVariants.map((variant, index) => {
    if (!isEditMode) {
      return <Tab key={index} empty />;
    }

    return (
      <Tab title={variant.name} key={variant.name}>
        <EditTemplatePackageForm
          key={variant.name}
          variant={variant}
          formName={`${formName.templateEditPackageForm}-${variant.name}`}
          onSubmit={packageForm_submitHandler}
        />
      </Tab>
    );
  });

  const getTemplate = () => {
    if (id !== 'new') {
      getAvailableTemplateAction(id);
    }
  };

  const defaultIndex = +JSON.parse(window.localStorage.getItem('variantIndex')) || 0;

  return (
    <DesignTemplatesLayout
      isLoading={isLoading}
      pageTitle="templates"
      buttonTitle="Back to templates"
      getItem={getTemplate}
      button_clickHandler={buttonGoToTemplates_clickHandler}
    >
      <TemplateHeader />
      <Tabs defaultTabIndex={defaultIndex}>
        <Tab title="Template info">
          {
            isEditMode
              ? (
                <EditTemplateInfoForm
                  onSubmit={infoForm_submitHandler}
                />
              )
              : (
                <CreateTemplateInfoForm
                  onSubmit={infoForm_submitHandler}
                />
              )
          }
        </Tab>
        {renderRoomVariants}
      </Tabs>
    </DesignTemplatesLayout>
  );
}

// Enhance
const mapStateToProps = (state) => ({
  roomVariants: getRoomVariantsSelector(state),
  isLoading: getIsLoadingSelector(state),
  formInfoValues: _get(`form[${formName.templateEditPackageFormHeader}].values`, state),
  templateId: getTemplateIdSelector(state),
  roomVariant: getRoomVariantSelector(state),
  dataToSend: getProductDataToSend(state),
});
const mapDispatchToProps = {
  push,
  updateAvailableTemplateAction,
  getAllFloorplansAction,
  createRoomVariantAction,
  getAvailableTemplateAction,
  updateRoomVariantAction,
  createTemplateAction,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(TemplatesDetail);
