import React, { Component } from 'react';
import { Detail } from '../../Components/RoomType';


export class RoomType extends Component {

  render() {
    return (
      <div className="product-edit-page">
        <div className="product-edit-page__wrapper">
          <div>
            <Detail
              match={this.props.match}
              isLoading={this.props.loading}
              buttonGoBackOnClick={this.handleReturn}
              getRoomType={this.props.getRoomType}
              fetchFurnitureCategories={this.props.fetchFurnitureCategories}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RoomType;
