export const formName = {
  templateForm: "templateForm",
  availableColorsEditForm: "availableColorsEditForm",
  availableColorsCreateForm: "availableColorsCreateForm",
  availableTexturesEditForm: "availableTexturesEditForm",
  availableTexturesCreateForm: "availableTexturesCreateForm",
  availableFloorPlanEditForm: "availableFloorPlanEditForm",
  availableFloorPlanCreateForm: "availableFloorPlanCreateForm",
  availableDesignStylesEditForm: "availableDesignStylesEditForm",
  availableDesignStylesCreateForm: "availableDesignStylesCreateForm",
  availableDesignRulesCreateForm: "availableDesignRulesCreateForm",
  availableDesignRulesEditForm: "availableDesignRulesEditForm",
  templateCreateInfoForm: "templateCreateInfoForm",
  templateCreatePackageForm: "templateCreatePackageForm",
  templateEditInfoForm: "templateEditInfoForm",
  templateEditPackageForm: "templateEditPackageForm",
  templateEditPackageFormHeader: "templateEditPackageFormHeader",
  templateCreatePackageFormHeader: "templateCreatePackageFormHeader",
  login: "loginForm"
}
