import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

const propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      to: PropTypes.string
    })
  ])
}

export const BreadcrumbsItem = ({ name, to }) => (
  <Link to={to} className="breadcrumbs__item breadcrumbs__item-link" key="breadcrumbsLink">
    {name}
  </Link>
)

BreadcrumbsItem.propTypes = propTypes

export default BreadcrumbsItem
