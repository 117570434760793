import React, { Component } from "react"
import Spinner from "../../Components/Shared/Spinner"

import AdminProfile from "./AdminProfile"
import AdminCategories from "./AdminCategories"

class Navigation extends Component {
  render() {
    let { loading } = this.props

    if (loading) {
      return (
        <div className="navigation">
          <Spinner />
        </div>
      )
    }

    return (
      <section className="navigation">
        <AdminProfile profile={this.props.profile} />
        <AdminCategories categories={this.props.categories} />
      </section>
    )
  }
}

export default Navigation
