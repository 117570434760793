import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

class Td extends PureComponent {
  static propTypes = {
    // from parent component
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
    width: PropTypes.number,
    center: PropTypes.bool,
    id: PropTypes.number,
    rowspan: PropTypes.number
  }

  tdClassNames = classNames({
    table__cell: true,
    center: this.props.center
  })

  styleWidth = { width: this.props.width }

  render() {
    const { children, rowspan } = this.props
    return (
      <td className={this.tdClassNames} style={this.styleWidth} rowSpan={(rowspan > 1) ? rowspan: null}>
        <div className="table__cell-inner">{children}</div>
      </td>
    )
  }
}

export default Td
