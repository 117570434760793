import { all, call, put, takeLatest } from "redux-saga/effects"
import { appName, POSTFIX } from "../constants"
import Http from "../api/design-rule-matching"
import { toastr } from "react-redux-toastr"
import { errorFormatter } from "../utils/errorFormatter"
import _set from "lodash/fp/set"
import _get from "lodash/fp/get"

/**
 * Helpers
 */
const transformObjectToArray = (obj) => Array.from(obj, (value) => value)

/**
 * Constants
 **/
export const moduleName = "designTemplates"
const prefix = `${appName}/${moduleName}`

export const GET_DESIGN_RULES_REQUEST = `${prefix}/GET_DESIGN_RULES_REQUEST`
export const CREATE_DESIGN_RULES_REQUEST = `${prefix}/CREATE_DESIGN_RULES_REQUEST`
export const UPDATE_DESIGN_RULES_REQUEST = `${prefix}/UPDATE_DESIGN_RULES_REQUEST`
export const CLONE_DESIGN_RULES_REQUEST = `${prefix}/CLONE_DESIGN_RULES_REQUEST`
export const DELETE_DESIGN_RULE_REQUEST = `${prefix}/DELETE_DESIGN_RULE_REQUEST`
export const GET_DESIGN_RULE_OPTIONS_REQUEST = `${prefix}/GET_DESIGN_RULE_OPTIONS_REQUEST`
/**
 * Reducer
 **/
const initialState = Object.freeze({
  loading: false,
  rules: {
    rules: []
  },
  ruleOptions: {},
  error: null
})

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {

    case GET_DESIGN_RULES_REQUEST + POSTFIX.START:
    case CREATE_DESIGN_RULES_REQUEST + POSTFIX.START:
    case UPDATE_DESIGN_RULES_REQUEST + POSTFIX.START:
    case GET_DESIGN_RULE_OPTIONS_REQUEST + POSTFIX.START:
    case DELETE_DESIGN_RULE_REQUEST + POSTFIX.START:
    case CLONE_DESIGN_RULES_REQUEST + POSTFIX.START:
      return {
        ...state,
        loading: true,
        error: null
      }

    case GET_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL:
    case UPDATE_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_DESIGN_RULE_REQUEST + POSTFIX.SUCCESSFUL:
    case CLONE_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        loading: false,
        rules: { rules: payload.rules }
      }

    case GET_DESIGN_RULES_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        loading: false,
        rules: { rules: [] },
        error
      }

    case CREATE_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        loading: false,
        rules: _set("rules", [...state.rules.rules, payload.newRule], state.rules)
      }

    case CREATE_DESIGN_RULES_REQUEST + POSTFIX.FAILURE:
    case UPDATE_DESIGN_RULES_REQUEST + POSTFIX.FAILURE:
    case DELETE_DESIGN_RULE_REQUEST + POSTFIX.FAILURE:
    case CLONE_DESIGN_RULES_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        loading: false,
        error
      }

    case GET_DESIGN_RULE_OPTIONS_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        loading: false,
        ruleOptions: payload.ruleOptions
      }

    case GET_DESIGN_RULE_OPTIONS_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        loading: false,
        ruleOptions: {},
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/
export const getDesignRules = (state) => state[`${moduleName}Reducer`].rules
export const getIsLoading = (state) => state[`${moduleName}Reducer`].loading
export const getRuleOptions = (state) => state[`${moduleName}Reducer`].ruleOptions
export const getRuleErrors = (state) => state[`${moduleName}Reducer`].error

/**
 * Action Creators
 **/
export const getDesignRulesAction = () => ({ type: GET_DESIGN_RULES_REQUEST })
const getDesignRulesSuccessAction = (rules) => ({
  type: GET_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL,
  payload: { rules }
})
const getDesignRulesFailureAction = (error) => ({
  type: GET_DESIGN_RULES_REQUEST + POSTFIX.FAILURE,
  error
})

export const createDesignRulesAction = () => ({ type: CREATE_DESIGN_RULES_REQUEST })
const createDesignRulesSuccessAction = (newRule) => ({
  type: CREATE_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL,
  payload: { newRule }
})
const createDesignRulesFailureAction = (error) => ({
  type: CREATE_DESIGN_RULES_REQUEST + POSTFIX.FAILURE,
  error
})

export const updateDesignRulesAction = (data) => ({
  type: UPDATE_DESIGN_RULES_REQUEST,
  payload: { data }
})
const updateDesignRulesSuccessAction = (rules) => ({
  type: UPDATE_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL,
  payload: { rules }
})
const updateDesignRulesFailureAction = (error) => ({
  type: UPDATE_DESIGN_RULES_REQUEST + POSTFIX.FAILURE,
  error
})

export const deleteDesignRulesAction = (id) => ({
  type: DELETE_DESIGN_RULE_REQUEST,
  payload: { id }
})
const deleteDesignRulesSuccessAction = (rules) => ({
  type: DELETE_DESIGN_RULE_REQUEST + POSTFIX.SUCCESSFUL,
  payload: { rules }
})
const deleteDesignRulesFailureAction = (error) => ({
  type: DELETE_DESIGN_RULE_REQUEST + POSTFIX.FAILURE,
  error
})

export const getDesignRuleOptionsAction = () => ({ type: GET_DESIGN_RULE_OPTIONS_REQUEST })
const getDesignRuleOptionsSuccessAction = (ruleOptions) => ({
  type: GET_DESIGN_RULE_OPTIONS_REQUEST + POSTFIX.SUCCESSFUL,
  payload: { ruleOptions }
})
const getDesignRuleOptionsFailureAction = (error) => ({
  type: GET_DESIGN_RULE_OPTIONS_REQUEST + POSTFIX.FAILURE,
  error
})

export const cloneDesignRulesAction = (id) => ({
  type: CLONE_DESIGN_RULES_REQUEST,
  payload: { id }
})
const cloneDesignRulesSuccessAction = (rules) => ({
  type: CLONE_DESIGN_RULES_REQUEST + POSTFIX.SUCCESSFUL,
  payload: { rules }
})
const cloneDesignRulesFailureAction = (error) => ({
  type: CLONE_DESIGN_RULES_REQUEST + POSTFIX.FAILURE,
  error
})

/**
 * Sagas
 **/
function* getDesignRuleOptionsSaga() {
  yield put({ type: GET_DESIGN_RULE_OPTIONS_REQUEST + POSTFIX.START })
  try {
    const ruleOptions = yield call(() => Http.getRuleOptions())
    yield put(getDesignRuleOptionsSuccessAction(ruleOptions))
  }
  catch (error) {
    const err = errorFormatter(error)
    yield call(() => toastr.error(err))
    yield put(getDesignRuleOptionsFailureAction(err))
  }
}

function* getDesignRulesSaga() {
  yield put({ type: GET_DESIGN_RULES_REQUEST + POSTFIX.START })
  try {
    const rules = yield call(() => Http.getRules())
    const rulesArray = transformObjectToArray(rules)
    yield put(getDesignRulesSuccessAction(rulesArray))
  }
  catch (error) {
    const err = errorFormatter(error)
    yield call(() => toastr.error(err))
    yield put(getDesignRulesFailureAction(err))
  }
}

function* createDesignRulesSaga() {
  yield put({ type: CREATE_DESIGN_RULES_REQUEST + POSTFIX.START })
  try {
    const newRule = yield call(() => Http.creteNewRule())
    yield put(createDesignRulesSuccessAction(newRule))
    yield call(() => toastr.success("Rule created successfully"))
  }
  catch (error) {
    const err = errorFormatter(error)
    yield call(() => toastr.error(err))
    yield put(createDesignRulesFailureAction(err))
  }
}

function* deleteDesignRuleSaga({ payload: { id } }) {
  yield put({ type: DELETE_DESIGN_RULE_REQUEST + POSTFIX.START })
  try {
    const rules = yield call(() => Http.deleteRule(id))
    const rulesArray = transformObjectToArray(rules)
    yield put(deleteDesignRulesSuccessAction(rulesArray))
    yield call(() => toastr.success("Rule deleted successfully"))
  }
  catch (error) {
    const err = errorFormatter(error)
    yield call(() => toastr.error(err))
    yield put(deleteDesignRulesFailureAction(err))
  }
}

function* updateDesignRuleSaga({ payload: { data } }) {
  yield put({ type: UPDATE_DESIGN_RULES_REQUEST + POSTFIX.START })
  try {
    const rules = yield call(() => Http.updateRules(data))
    const rulesArray = transformObjectToArray(rules)
    yield put(updateDesignRulesSuccessAction(rulesArray))
    yield call(() => toastr.success("Rules updated successfully"))
  }
  catch (error) {
    const errors = _get("response.data.errors", error)
    errors.forEach((err) => {
      if (err.length) {
        toastr.error(err[0])
      }
    })
    yield put(updateDesignRulesFailureAction(errors))
  }
}

function* cloneDesignRuleSaga({ payload: { id } }) {
  yield put({ type: CLONE_DESIGN_RULES_REQUEST + POSTFIX.START })
  try {
    const rules = yield call(() => Http.cloneRule(id))
    const rulesArray = transformObjectToArray(rules)
    yield put(cloneDesignRulesSuccessAction(rulesArray))
    yield call(() => toastr.success("Rule cloned successfully"))
  }
  catch (error) {
    const err = errorFormatter(error)
    yield call(() => toastr.error(err))
    yield put(cloneDesignRulesFailureAction(err))
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_DESIGN_RULE_OPTIONS_REQUEST, getDesignRuleOptionsSaga),
    takeLatest(GET_DESIGN_RULES_REQUEST, getDesignRulesSaga),
    takeLatest(CREATE_DESIGN_RULES_REQUEST, createDesignRulesSaga),
    takeLatest(UPDATE_DESIGN_RULES_REQUEST, updateDesignRuleSaga),
    takeLatest(DELETE_DESIGN_RULE_REQUEST, deleteDesignRuleSaga),
    takeLatest(CLONE_DESIGN_RULES_REQUEST, cloneDesignRuleSaga)
  ])
}

