import styled from "styled-components"

export default styled.div`

.design-rule__list {
  padding-left: 0;
  margin-bottom: 25px;
}

.design-rule__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: 1px;
  background-color: #efefef;
  border-bottom: 1px solid #dbdbdb;
  list-style-type: none;
  transition:all 0.1s;
}

.design-rule__item:nth-child(2n) {
  background-color:#f9f9f9;
}

.design-rule__item:hover {
  color: #000;
  background-color: #b7b7b7;
  border-bottom-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

.design-rule__title {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 0;
  font-weight: 400;
  font-size: 18px;
  color: #333;
  transition:all 0.3s;
}

.design-rule__title span {
  position: relative;
}

.design-rule__title span::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color:#333;
  transform: scale3d(0, 0, 0);
  transition:all 0.3s;
}

.design-rule__title:hover span::before {
  transform: scale3d(1, 1, 1);
}

.design-rule__preview-box {
  width: 100px;
  margin-right: 10px;
  overflow: hidden;
}

.design-rule__preview {
  max-width: 100px;
  max-height: 100px;
}

.design-rule__item-buttons-box {
  min-width: 200px;
  text-align: right;
}

.design-rule__item-buttons-box button {
  margin-right: 10px;
}

.design-rule__item-buttons-box button:last-child {
  margin-right: 0;
}

.design-rule__item-info {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 60%;
}

.design-rule__info {
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  font-size: 14px;
  background-color:#ccccff;
}

.design-rule__info-name {
  display: inline-block;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 1px solid #000;
  color: #000;
  background-color: rgba(0, 0, 0, 0.3);
}

.design-rule__info-value {
  display: inline-block;
  padding: 5px 10px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: 500;
  color: #000;
}
`
