import React from 'react';
// Redux
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Selectors
import { getLoading } from '../../ducks/auth';
// Components
import { Button } from '../Shared';
import Input from '../Shared/reduxForm/input';
// Misc
import { syncValidate } from '../Shared/reduxForm/sync-validate';
import { formName } from '../../constants/forms';

function LoginForm({
  handleSubmit, pristine, submitting, invalid, onSubmit, isLoading,
}) {
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={submitting} className="auth-form__fieldset">
        <Input
          name="email"
          type="email"
          placeholder="Enter your email"
          validate={[
            syncValidate.required,
            syncValidate.email,
          ]}
        />
        <Input
          name="password"
          type="password"
          placeholder="Enter your password"
          validate={[
            syncValidate.required,
          ]}
        />
      </fieldset>
      <div className="auth-form__btn-wrap">
        <Button
          type="submit"
          width={150}
          size="large"
          disabled={invalid || pristine || submitting}
        >
          {isLoading ? 'Send' : 'Submit'}
        </Button>
      </div>
    </form>
  );
}

// Enhance
const mapStateToProps = (state) => ({
  isLoading: getLoading(state),
});
const mapDispatchToProps = {};

const enhance = compose(
  reduxForm({
    form: formName.login,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(LoginForm);
