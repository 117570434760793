import { put, call, takeLatest, all } from "redux-saga/effects"
import { appName } from "../constants"
import { postCopyFurnitureItems } from "../api/lt-api"
import { toastr } from "react-redux-toastr"

/**
 * Constants
 **/
export const moduleName = "copyFurnitureItems"
const prefix = `${appName}/${moduleName}`

const COPY_FURNITURE_ITEMS_REQUEST = `${prefix}/COPY_FURNITURE_ITEMS_REQUEST`
const COPY_FURNITURE_ITEMS_REQUEST_SUCCESSFUL = `${prefix}/COPY_FURNITURE_ITEMS_REQUEST_SUCCESSFUL`
const COPY_FURNITURE_ITEMS_REQUEST_FAILURE = `${prefix}/COPY_FURNITURE_ITEMS_REQUEST_FAILURE`

/**
 * Reducer
 **/
const initialState = {
  data: []
}

export default function reducer(state = initialState, { type, isLoading }) {
  switch (type) {
    case COPY_FURNITURE_ITEMS_REQUEST_SUCCESSFUL:
      return {
        ...state,
        data: isLoading
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/

/**
 * Action Creators
 **/
export function getCopyFurnitureItemsRequest(data) {
  return {
    type: COPY_FURNITURE_ITEMS_REQUEST,
    payload: { data }
  }
}

/**
 * Sagas
 **/
function* getCopyFurnitureItemsRequestSaga({ payload: { data } }) {
  try {
    const room_variant = yield call(postCopyFurnitureItems, data)
    yield put({
      type: COPY_FURNITURE_ITEMS_REQUEST_SUCCESSFUL,
      room_variant
    })
    const toastrSuccess = toastr.success
    yield call(toastrSuccess, "Furniture Items copied!")
  } catch (error) {
    const toastrError = toastr.error
    yield call(toastrError, error.message)
    yield put({ type: COPY_FURNITURE_ITEMS_REQUEST_FAILURE })
  }
}

export function* saga() {
  yield all([takeLatest(COPY_FURNITURE_ITEMS_REQUEST, getCopyFurnitureItemsRequestSaga)])
}
