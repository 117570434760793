import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';

// Misc
import objectToFormData from '../../../utils/objectToFormData';
import {
  createAvailableRuleRequest,
  getIsLoading,
  updateAvailableRuleRequest,
  getAvailableRuleByIdRequest,
} from '../../../ducks/availableRules';

// Import Components
import { DesignTemplatesLayout } from '../index';
import DetailEditForm from './DetailEditForm';
import DetailCreateForm from './DetailCreateForm';

RuleDetail.propTypes = {
  // from connect/react-router-redux
  goBack: PropTypes.func,
  // from connect/availableColorsReducer
  createAvailableRuleRequest: PropTypes.func,
  isLoading: PropTypes.bool,
};

function RuleDetail({
  getAvailableRuleByIdRequest, goBack, createAvailableRuleRequest, updateAvailableRuleRequest, isLoading, match: { params: { id } },
}) {
  const isEditMode = id !== 'new';

  const form_submitHandler = function (values) {
    const rule = { design_rule: { ...values } };
    if (isEditMode) {
      updateAvailableRuleRequest(id, objectToFormData(rule));
    } else {
      createAvailableRuleRequest(objectToFormData(rule));
    }
  };

  const buttonGoBack_clickHandler = function () {
    goBack();
  };

  const getItem = function () {
    if (id !== 'new') {
      getAvailableRuleByIdRequest(id);
    }
  };

  return (
    <DesignTemplatesLayout
      isLoading={isLoading}
      pageTitle="design-templates"
      buttonTitle="Go back"
      getItem={getItem}
      button_clickHandler={buttonGoBack_clickHandler}
    >
      {isEditMode
        ? <DetailEditForm onSubmit={form_submitHandler} />
        : <DetailCreateForm onSubmit={form_submitHandler} />}
    </DesignTemplatesLayout>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
});
const mapDispatchToProps = {
  goBack,
  createAvailableRuleRequest,
  updateAvailableRuleRequest,
  getAvailableRuleByIdRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleDetail);
