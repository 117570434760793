import React, { Component } from "react"
import PropTypes from "prop-types"

class Popup extends Component {
  handleClose = (e) => {
    this.props.onClose(this.props.activePopupIndex, e)
  }

  render() {
    return (
      <div className="popup-content-wrapper">
        {this.props.opened && (
          <div className="popup-background">
            <div className="popup-wrapper">
              <div className="popup-title">{this.props.title}</div>
              <button className="popup-close" onClick={(e) => this.handleClose(e)}>
                x
              </button>
              {this.props.renderContent()}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Popup

Popup.propTypes = {
  title: PropTypes.string.isRequired,
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired
}
