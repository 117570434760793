import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static getAllTemplates() {
    return http({
      method: "GET",
      url: `${API}design_templates/templates`
    })
      .then((res) => res.data)
  }

  static updateTemplate(id, templateData) {
    return http({
      method: "PUT",
      url: `${API}design_templates/templates/${id}`,
      data: templateData
    })
      .then((res) => res.data)
  }

  static updateRoomVariant(variantId, templateData) {
    return http({
      method: "PUT",
      url: `${API}room_variants/${variantId}`,
      data: templateData
    })
      .then((res) => res.data)
  }

  static deleteTemplate(id) {
    return http({
      method: "DELETE",
      url: `${API}design_templates/templates/${id}`
    })
      .then((res) => res.data)
  }

  static deleteRoomVariant(id) {
    return http({
      method: "DELETE",
      url: `${API}room_variants/${id}`
    })
      .then((res) => res.data)
  }

  static getTemplateById(id) {
    return http({
      method: "GET",
      url: `${API}design_templates/templates/${id}`
    })
      .then((res) => res.data)
  }

  static cloneTemplate(id) {
    return http({
      method: "GET",
      url: `${API}design_templates/templates/${id}/clone`
    })
      .then((res) => res.data)
  }

  static getAllFloorplans() {
    return http({
      method: "GET",
      url: `${API}design_templates/templates/template_options`
    })
      .then((res) => res.data)
  }

  static addProducts(variantId, products) {
    return http({
      method: "POST",
      url: `${API}room_variants/${variantId}/add_product`,
      data: products
    })
      .then((res) => res.data)
  }

  static createVariant(templateId, data) {
    return http({
      method: "POST",
      url: `${API}rooms/${templateId}/room_variants/create`,
      data: data
    })
      .then((res) => res.data)
  }

  static createTemplate(data) {
    return http({
      method: "POST",
      url: `${API}design_templates/templates`,
      data: data
    })
      .then((res) => res.data)
  }

  static getCalculate(variantId) {
    return http({
      method: "GET",
      url: `${API}room_variants/${variantId}/calculate_price`
    })
      .then((res) => res.data)
  }
}
