import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { compose } from 'redux';
import { debounce } from 'lodash';
import { push } from 'react-router-redux';

import {
  renderColumnsSuppliers,
} from '../../constants/createHelper';
import {
  historyShapes, suppliersReducerShape,
} from '../../constants/shapes';
import { filtersSuppliers } from '../../constants/filters';

import withModal from '../../Components/HOCs/modal';
import { Input, Table } from '../../Components/Shared';
import FilterSidebar from '../../Components/FilterSidebar';

import { getSuppliersRequest, getSuppliersSelector } from '../../ducks/suppliers';

import qs from '../../modules/normalizingQs';

import './index.scss';

const Suppliers = ({
  getSuppliersRequest: getSuppliers,
  suppliersReducer: {
    suppliers,
    isLoaded,
  },
  push: pushTo,
  history: { location: { search: searchUrl } },
}) => {
  const {
    country,
    search,
    page,
  } = qs.parse(searchUrl);

  const [filters, setFilters] = useState({
    country: country || [],
    page: parseInt(page, 10) || 1,
    search: search || '',
  });

  const handleGetDeliveryAgents = () => {
    const queryParams = qs.stringify({ ...filters });

    getSuppliers(filters);
    pushTo(`/suppliers?${queryParams}`);
  };

  const debounceGetDeliveryAgents = debounce(() => {
    handleGetDeliveryAgents();
  }, 500);

  useEffect(() => {
    debounceGetDeliveryAgents();

    if (!filters?.search) {
      const queryParams = qs.stringify({ ...filters, page: 1 });

      setFilters({
        ...filters,
        page: page || 1,
      });

      pushTo(`/suppliers?${queryParams}`);
    }

    return () => debounceGetDeliveryAgents.cancel();
  }, [filters?.search]);

  const { search: searchParam, ...filterSidebarParams } = filters;

  useEffect(() => {
    handleGetDeliveryAgents();
  }, [
    filters?.page,
    filters?.country,
  ]);

  return (
    <div className="suppliers">
      <div className="suppliers__title">
        Suppliers
      </div>
      <div className="suppliers__content">
        <Input
          inputClassName="suppliers__search-input"
          className="suppliers__search-suppliers"
          name="search-suppliers"
          value={filters?.search}
          placeholder="Search by supplier name, contact name, contact email address or phone number"
          onChange={(e) => setFilters({
            ...filters,
            search: e.target.value,
          })}
        />
        <div className="suppliers__table">
          <FilterSidebar
            filters={filtersSuppliers}
            state={filterSidebarParams}
            saveChanges={(e) => setFilters({
              ...e,
              search: searchParam,
            })}
          />
          <Table
            type="suppliersSummary"
            items={suppliers?.data}
            tableLoaded={isLoaded}
            noResults={isLoaded && !suppliers?.data?.length}
            totalEntries={suppliers?.meta?.totalEntries}
            currentPage={parseInt(suppliers?.meta?.page, 10)}
            perPage={parseInt(suppliers?.meta?.perPage, 10)}
            renderColumns={renderColumnsSuppliers}
            paginationChange={(actualPage) => setFilters({
              ...filters,
              page: actualPage,
            })}
          />
        </div>
      </div>
    </div>
  );
};

Suppliers.propTypes = {
  getSuppliersRequest: propTypes.func.isRequired,
  history: historyShapes.isRequired,
  push: propTypes.func.isRequired,
  suppliersReducer: suppliersReducerShape.isRequired,
};

const mapStateToProps = (state) => ({
  suppliersReducer: getSuppliersSelector(state),
});

const mapDispatchToProps = {
  getSuppliersRequest,
  push,
};

const enhance = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Suppliers);
