import React from "react"
import PropTypes from "prop-types"

// Import Components
import RuleListStyle from "./style"
import RuleListItem from "./RuleListItem"

RuleList.propTypes = {
  // Import Components
  type: PropTypes.string,
  buttonDelete_clickHandler: PropTypes.func
}

function RuleList({ type, items }) {
  const listElements = items.map(({ value, label, ...restProps }, index) => {
    return (
      <li className="design-rule__item" key={value || index}>
        <RuleListItem
          type={type}
          title={label}
          id={value}
          collection={restProps}
        />
      </li>
    )
  })

  const emptyItems = <p>No data to display</p>

  return (
    <RuleListStyle>
      <ul className="design-rule__list">
        {items.length ? listElements : emptyItems}
      </ul>
    </RuleListStyle>
  )
}

export default RuleList
