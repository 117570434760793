import { all, call, put, takeLatest } from "redux-saga/effects"
import { startSubmit, stopSubmit } from "redux-form"
import axios from "axios"
import { push } from "react-router-redux"
import { appName, POSTFIX } from "../constants"
import { formName } from "../constants/forms"
import { toastr } from "react-redux-toastr"
import Http from "../api/auth"
import _get from "lodash/fp/get"
import { getCategories } from "./categories"
import { errorFormatter } from "../utils/errorFormatter"

/**
 * Constants
 **/
export const moduleName = "auth"
const prefix = `${appName}/${moduleName}`

export const LOADING = `${prefix}/LOADING`
export const LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`
export const LOGOUT_REQUEST = `${prefix}/LOGOUT_REQUEST`

/**
 * Reducer
 **/
const INITIAL_STATE = Object.freeze({
  loading: false,
  userProfile: {},
  token: null,
  linkToken: null,
  isAuthenticated: false,
  error: null
})

export default function(state = INITIAL_STATE, { type, payload, error }) {
  switch (type) {
    case LOADING:
      return {
        ...state,
        loading: true,
        error: null
      }

    case LOGIN_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        userProfile: payload.userProfile,
        isAuthenticated: true,
        token: payload.token,
        linkToken: payload.linkToken
      }

    case LOGIN_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        loading: false,
        userProfile: {},
        isAuthenticated: false,
        token: null,
        linkToken: null,
        error
      }

    case LOGOUT_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        loading: false,
        userProfile: {},
        isAuthenticated: false,
        token: null,
        linkToken: null
      }

    case LOGOUT_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        loading: false
      }

    default:
      return state
  }
}

/**
 * Action Creators
 **/
export const loginRequest = (user) => ({
  type: LOGIN_REQUEST,
  payload: { user }
})

export const logoutRequest = (token) => ({
  type: LOGOUT_REQUEST,
  payload: { token }
})

/**
 * Selectors
 **/
export const getLoading = (state) => state.authReducer.loading

export const getIsAuthenticated = (state) => state.authReducer.isAuthenticated

export const getToken = (state) => state.authReducer.token

export const getLinkToken = (state) => state.authReducer.linkToken

export const getFullName = (state) => {
  const userProfile = state.authReducer.userProfile
  return `${_get("first_name", userProfile)} ${_get("last_name", userProfile)}`
}

/**
 * Sagas
 **/
function* loginSaga({ payload: { user } }) {
  try {
    yield put({ type: LOADING })
    yield put(startSubmit(formName.login))
    const login = Http.login
    const response = yield call(login, user)
    const fullName = `${_get("user.first_name", response)} ${_get("user.last_name", response)}`
    window.localStorage.setItem("token", JSON.stringify(`Bearer ${_get("user.authentication_token", response)}`))
    yield put({
      type: LOGIN_REQUEST + POSTFIX.SUCCESSFUL,
      payload: {
        userProfile: response.user,
        token: `Bearer ${_get("user.authentication_token", response)}`,
        linkToken: _get("user.authentication_token", response)
      }
    })
    yield put(stopSubmit(formName.login))
    yield put(push("/"))
    axios.defaults.headers.common["Authorization"] = `Bearer ${_get("user.authentication_token", response)}`
    yield put(getCategories())
    yield call(() => toastr.success(`Welcome, ${fullName}`))
  }
  catch (error) {
    yield put(stopSubmit(formName.login))
    yield put({
      type: LOGIN_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
    delete axios.defaults.headers.common["Authorization"]
    window.localStorage.removeItem("token")
    yield call(() => toastr.error(errorFormatter(error)))
  }
}

function* logoutSaga({ payload: { token } }) {
  try {
    yield put({ type: LOADING })
    const login = Http.logout
    yield call(login, token)

    yield put({ type: LOGOUT_REQUEST + POSTFIX.SUCCESSFUL })
    yield put(push("/login"))
    delete axios.defaults.headers.common["Authorization"]
    window.localStorage.removeItem("token")
  }
  catch (error) {
    yield put({
      type: LOGOUT_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
    yield call(() => toastr.error(errorFormatter(error)))
  }
}

export function* saga() {
  yield all([
    takeLatest(LOGIN_REQUEST, loginSaga),
    takeLatest(LOGOUT_REQUEST, logoutSaga)
  ])
}
