import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Multiselect from 'react-select';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import NumberFormat from 'react-number-format';

import MultiSelectCategories from '../MultiSelectCategories';
import ImagesContainer from '../../Shared/ImagesContainer';

import './index.scss';

class AttributesForm extends Component {
  state = {
    categories: this.props.categories,
    expanded: [],
    expandedCategories: [],
    roomPlannerImage: null,
  };

  componentDidMount() {
    this.updateDefaultImage(this.props);
  }

  getSelectedCategoriesList = (itemsList, itemsIds = []) => {
    if (itemsList !== undefined) {
      return itemsList.filter((item) => itemsIds.some((e) => item.value === e));
    }

    return [];
  };

  updateDefaultImage = (nextProps) => {
    let defaultImage = nextProps.productData.images.image6;
    if (defaultImage) {
      defaultImage = URL.createObjectURL(defaultImage);
    }
    defaultImage = defaultImage || nextProps.productData.product.image6;
    if (this.state.roomPlannerImage !== defaultImage) {
      this.setState({ roomPlannerImage: defaultImage });
    }
  };

  deleteAttribute = (item, i) => {
    if (this.props.onDeleteAttribute) {
      this.props.onDeleteAttribute(i, item.key);
    }
  };

  addAttribute = () => {
    if (this.props.onAddAttribute) {
      this.props.onAddAttribute();
    }
  };

  changeAttributes = (e) => {
    if (this.props.onChangeAttribute) {
      this.props.onChangeAttribute(e);
    }
  };

  changeDiscontinued = (e) => {
    if (this.props.onDiscontinuedChange) {
      this.props.onDiscontinuedChange(e);
    }
  };

  changeOtherAttribute = (e) => {
    if (this.props.onChangeOtherAttribute) {
      this.props.onChangeOtherAttribute(e.target);
    }
  };

  getValues = (data, firstPath, secondPath) => {
    const values = [];
    const { productData } = this.props;
    if (typeof secondPath === 'undefined') {
      productData[firstPath].forEach((id) => values.push(data.filter((e) => e.value === id || e.value === id)));
    } else {
      productData[firstPath][secondPath].forEach((id) => values.push(data.filter((e) => e.value === id || e.value === id.id)));
    }
    return values.map((e) => e[0]);
  };

  handlePlannerImageChange = (file, url) => {
    const fileData = file || '';
    if (this.props.onChangeRoomPlannerImage) {
      this.props.onChangeRoomPlannerImage(fileData, url);
    }
  };

  validate = (e) => {
    const value = Array.isArray(e) ? e[0] : e;
    const isNotValid = this.props.missingRequiredFields && !value;
    return isNotValid ? 'is-invalid' : '';
  };

  getRoomPlannerImage = (data) => {
    const url = data.product.url_image6;
    const file = data.product.image6;
    const isFile = file.url || file.type;
    return isFile ? [this.getImageData(file)] : [url];
  };

  getImageData = (file) => (file.type ? URL.createObjectURL(file) : file.url);

  onCategoryChange = (categories) => {
    const newProductData = this.props.productData;
    const categories_props = this.props.categories;
    const categoryValue = categories_props.filter((e) => e.value === categories[categories.length - 1]);

    newProductData.product_category_ids = categories;

    this.setState({
      productData: newProductData,
    });
  };

  render() {
    const {
      productData,
      categories,
      colors,
      materials,
      suppliers,
      onRoomVariantLinkClick,
    } = this.props;

    const colorsValues = this.getValues(colors, 'furniture', 'color_ids');
    const materialsValues = this.getValues(materials, 'furniture', 'material_ids');
    const supplierValue = suppliers.filter((e) => e.value === productData.product.supplier_id);

    return (
      <form className="container-fluid attributes-form pl-3 pl-lg-5">
        <div className="row pl-3 pl-lg-5">
          <div className="col-xl-8 order-xl-1 order-2 pl-0 pl-lg-5">
            <div className="row ">
              <div className="col-12 col-md-3">
                <p>NAME: *</p>
              </div>
              <div className="col-12 col-md-9">
                <input
                  className={`form-control ${this.validate(productData.product.name)}`}
                  name="product.name"
                  value={productData.product.name || ''}
                  onChange={(e) => this.changeAttributes(e)}
                />
                <div className="invalid-feedback">Please choose a name.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <p>DESCRIPTION: </p>
              </div>
              <div className="col-12 col-md-9 attributes-form__product_description">
                <textarea
                  name="product.description"
                  value={productData.product.description || ''}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3 attributes-form__categories">
                <p>CATEGORIES: *</p>
              </div>
              <div className="col-12 col-md-9">
                <div className={` ${this.validate(this.state.categories)}`}>
                  <MultiSelectCategories
                    product_id={productData.product.id}
                    categories={categories}
                    selected_categories={productData.product_category_ids}
                    onCategoryChange={this.onCategoryChange}
                    getSelectedCategoriesList={this.getSelectedCategoriesList.bind(this)}
                  />
                </div>
                <input className={`form-control invisible-input ${this.validate(this.state.categories)}`} />
                <div className="invalid-feedback">Please choose a category.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <p>SKU: *</p>
              </div>
              <div className="col-12 col-md-9">
                <input
                  className={`form-control ${this.validate(productData.product.sku)}`}
                  name="product.sku"
                  value={productData.product.sku || ''}
                  onChange={(e) => this.changeAttributes(e)}
                />
                <div className="invalid-feedback">Please choose a sku.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <p>SUPPLIER: *</p>
              </div>
              <div className="col-12 col-md-9">
                <Select
                  className={`select2 select2-single ${this.validate(supplierValue)}`}
                  value={supplierValue}
                  options={suppliers}
                  onChange={this.props.onSupplierChange}
                />
                <input className={`form-control invisible-input ${this.validate(supplierValue)}`} />
                <div className="invalid-feedback">Please choose a supplier.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <p>COLORS: </p>
              </div>
              <div className="col-12 col-md-9">
                <Multiselect
                  isMulti
                  className="select2"
                  value={colorsValues}
                  options={colors}
                  onChange={this.props.onColorsChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-3">
                <p>MATERIALS: </p>
              </div>
              <div className="col-12 col-md-9">
                <Multiselect
                  isMulti
                  className="select2"
                  value={materialsValues}
                  options={materials}
                  onChange={this.props.onMaterialsChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3">
                <p>MSRP CAD$:</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <NumberFormat
                  prefix="$ "
                  name="product.cad_price"
                  value={productData.product.cad_price}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <p>MSRP USD$:</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <NumberFormat
                  prefix="$ "
                  name="product.usd_price"
                  value={productData.product.usd_price}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3">
                <p>COST CAD$:</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <NumberFormat
                  prefix="$ "
                  name="furniture.cost_cad"
                  value={productData.furniture.cost_cad}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <p>COST USD$:</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <NumberFormat
                  prefix="$ "
                  name="furniture.cost_usd"
                  value={productData.furniture.cost_usd}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
            <div className="row mb-5 mb-sm-0">
              <div className="col-12 col-sm-6 col-lg-3">
                <p>STOCK:</p>
              </div>
              <div className="col-12 col-sm-6 col-lg-9">
                <select
                  className="stock-select"
                  name="product.stock_control"
                  value={productData.product.stock_control}
                  onChange={(e) => this.changeAttributes(e)}
                >
                  <option value>YES</option>
                  <option value={false}>NO</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <p>WIDTH:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="product.width"
                  value={productData.product.width}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <p>SEAT WIDTH:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="furniture.seat_width"
                  value={productData.furniture.seat_width}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <p>HEIGHT:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="product.height"
                  value={productData.product.height}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <p>SEAT HEIGHT:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="furniture.seat_height"
                  value={productData.furniture.seat_height}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <p>DEPTH:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="product.depth"
                  value={productData.product.depth}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <p>SEAT DEPTH:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="furniture.seat_depth"
                  value={productData.furniture.seat_depth}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <p>WEIGHT:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="furniture.weight"
                  value={productData.furniture.weight}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <p>ARM HEIGHT:</p>
              </div>
              <div className="col-12 col-md-6 col-lg-2">
                <input
                  name="furniture.arm_height"
                  value={productData.furniture.arm_height}
                  onChange={(e) => this.changeAttributes(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-4 order-xl-2 order-1 ">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-8 col-xl-12 text-center">
                <p>ROOM PLANNER IMAGE:</p>
              </div>
              <div className="col-12 col-md-6">
                <ImagesContainer
                  images={this.getRoomPlannerImage(productData)}
                  onImagesChange={(file, url) => this.handlePlannerImageChange(file, url)}
                  imageSize="small"
                  customStyles="product"
                />
              </div>
            </div>
            <div className="row justify-content-center p-1">
              <div className="col-12 col-sm-8 col-xl-12 text-center">
                <p>PRODUCT STATUS:</p>
              </div>
              <div className="row justify-content-center w-100">
                <div className="col-6 col-md-6 attributes-form__label">
                  <p>STOCK:</p>
                </div>
                <div className="col-6 col-md-6 attributes-form__input">
                  <NumberFormat
                    name="furniture.inventory_count"
                    value={productData.furniture.inventory_count}
                    onChange={(e) => this.changeAttributes(e)}
                  />
                  <div className="invalid-feedback">Please choose a correct stock number.</div>
                </div>
              </div>
              {productData.furniture.inventory_count === 0 && !productData.furniture.is_discounted && (
                <div className="row justify-content-center w-100">
                  <div className="col-6 col-md-6 attributes-form__label">
                    <p>ETA:</p>
                  </div>
                  <div className="col-6 col-md-6 attributes-form__input">
                    <input
                      type="date"
                      className="form-control"
                      name="furniture.arrival_date"
                      value={productData.furniture.arrival_date
                        ? new Date(productData.furniture.arrival_date).toISOString().slice(0, 10)
                        : ''}
                      onChange={(e) => this.changeAttributes(e)}
                    />
                  </div>
                </div>
              )}
              <div className="row justify-content-center w-100">
                <div className="col-6 col-md-6 attributes-form__label">
                  <p>DISCONTINUED:</p>
                </div>
                <div className="col-6 col-md-6 attributes-form__input">
                  <Select
                    className="select2 select2-single"
                    classNamePrefix="select-discounted"
                    value={{ label: productData.furniture.is_discounted ? 'Yes' : 'No', value: productData.furniture.is_discounted }}
                    options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
                    onChange={({ value }) => this.changeDiscontinued(value)}
                  />
                  <input className="form-control invisible-input" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-8 col-xl-12 text-center">
              <p>ROOM VARIANTS ({productData?.room_variants?.length || 0}):</p>
            </div>
            <div className="row justify-content-center p-1 attributes-form__product-used">
              {productData?.room_variants?.length ? (
                <div className="column justify-content-center w-100 attributes-form__product-used-list">
                  {
                    productData.room_variants?.map(({
                      room_id,
                      variant_id,
                      variant_sku,
                      updated_at,
                    }) => (
                      <div
                        key={variant_sku}
                        className="col-xl-12 attributes-form__link-container"
                        aria-hidden
                        onClick={() => onRoomVariantLinkClick(room_id, variant_id)}
                      >
                        <div className="attributes-form__link">
                          {variant_sku}
                        </div>
                        <div className="attributes-form__link-date">
                          {updated_at.slice(0, 10)}
                        </div>
                      </div>
                    ))
                  }
                </div>
              ) : (
                <div className="col-12 col-sm-8 col-xl-12 text-center">
                  <p>No room variants</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col-10 col-md-7">
            <div className="row justify-content-start pl-0 pl-lg-5">
              <div className="col-8 col-md-10 col-lg-6 pl-3 pl-lg-5">
                <p>OTHER ATTRIBUTES:</p>
              </div>
              <div className="col-2 custom-attribute-add" aria-hidden onClick={this.addAttribute.bind(this)}>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
          </div>
        </div>
        {productData.product.product_attributes_array.map((item, i) => (
          <div key={i} className="row pl-3 pl-lg-4">
            <div className="col-12 col-sm-3 col-lg-1 offset-0 offset-lg-1 mr-0 mr-lg-4 pl-0 pl-md-5">
              <p>NAME:</p>
            </div>
            <div className="col-12 col-sm-7 col-lg-3 mr-0 mr-lg-5">
              <input onChange={this.changeOtherAttribute.bind(this)} name={`name :${i}`} value={item.key || ''} />
            </div>
            <div className="col-12 col-sm-3 col-lg-1 mr-0 mr-lg-4 pl-0 pl-md-5">
              <p>VALUE:</p>
            </div>
            <div className="col-9 col-sm-5 col-sm-7 col-lg-3">
              <input onChange={this.changeOtherAttribute.bind(this)} name={`value:${i}`} value={item.value || ''} />
            </div>
            <div className="col-1 col-md-1 custom-attribute-remove" aria-hidden onClick={this.deleteAttribute.bind(this, item, i)}>
              <FontAwesomeIcon icon={faTrash} />
            </div>
          </div>
        ))}
      </form>
    );
  }
}

export default AttributesForm;

AttributesForm.propTypes = {
  categories: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  materials: PropTypes.array.isRequired,
  missingRequiredFields: PropTypes.bool.isRequired,
  onAddAttribute: PropTypes.func.isRequired,
  onChangeAttribute: PropTypes.func.isRequired,
  onChangeOtherAttribute: PropTypes.func.isRequired,
  onChangeRoomPlannerImage: PropTypes.func.isRequired,
  onColorsChange: PropTypes.func.isRequired,
  onDeleteAttribute: PropTypes.func.isRequired,
  onDiscontinuedChange: PropTypes.func,
  onMaterialsChange: PropTypes.func.isRequired,
  onSupplierChange: PropTypes.func.isRequired,
  productData: PropTypes.object.isRequired,
  suppliers: PropTypes.array.isRequired,
};
