import React from 'react';
import PropTypes from 'prop-types';

// components
import StyledComponents from './style';

const Select = ({
  disabled = false,
  className = '',
  name,
  placeholder = '',
  onChange = () => {},
  options = [],
  value,
}) => (
  <StyledComponents>
    <select
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      className={`select ${className}`}
    >
      {options.map(({ value: valueText, text }) => (
        <option key={valueText} value={valueText}>
          {text}
        </option>
      ))}
      {placeholder && (
        <option value="" selected disabled hidden>
          {placeholder}
        </option>
      )}
    </select>
  </StyledComponents>
);

Select.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Select;
