import styled from "styled-components"

export default styled.section`
  margin-bottom: 25px;
  width: 210px;
  min-height: 250px;
  max-height: 250px;
  overflow: scroll;

  .filter-item__heading {
    font-size: 18px;
    text-align: center;
  }

  .modal-filter__filters {
    overflow: auto;
  }

  .filter-item__loading {
    text-align: center;
    font-size: 15px;
  }

  .react-checkbox-tree {
    font-size: 15px;
  }

  .react-checkbox-tree ol {
    width: 195px;
    margin-bottom: 0;
    padding-left: 20px;
    list-style-type: none;
  }

  .rct-node.rct-node-parent {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .rct-text {
    display: flex;
    align-items: center;
  }

  .rct-text label {
    order: 1;
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  .rct-text input[type="checkbox"] {
    display: none;
  }
`
