import React, { Fragment } from "react"
import PropTypes from "prop-types"

// components
import DynamicInputs from "./DynamicInputs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

DynamicFormFields.propTypes = {
  // from ModalAttribute component
  rules: PropTypes.array,
  onRulesChange: PropTypes.func,
  onDeleteRule: PropTypes.func
}

DynamicFormFields.defaultProps = {
  rules: []
}

function DynamicFormFields({ rules, onRulesChange, onDeleteRule }) {
  const renderFormControl = rules.map(({ id: ruleId, fields }) => {
    const onDeleteButtonClick = (event) => onDeleteRule(event, ruleId)

    return (
      <div key={ruleId} className="add-attributes__form-control">
        <DynamicInputs fields={fields} onRulesChange={onRulesChange} ruleId={ruleId} />
        <button type="button" className="add-attributes__delete-button button" onClick={onDeleteButtonClick}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    )
  })

  return <Fragment>{renderFormControl}</Fragment>
}

export default DynamicFormFields
