import React from 'react';
import PropTypes from 'prop-types';

// Misc

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, FieldArray } from 'redux-form';
import { formName } from '../../../../constants/forms';

// Import Components
import DetailEditFormStyle from './style';
import { ReduxFormFileUploader, ReduxFormInput } from '../../../Shared/reduxForm';
import { Button } from '../../../Shared';
import RoomInnerArray from './RoomInner';
import { getAvailableFloorPlanById } from '../../../../ducks/availableFloorPlans';

DetailEditForm.propTypes = {
  // from DetailFloorPlans Component
  onSubmit: PropTypes.func.isRequired,
  // from reduxForm
  handleSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
};

function DetailEditForm({
  initialValues, handleSubmit, onSubmit, pristine, submitting, invalid,
}) {
  return (
    <DetailEditFormStyle>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="floor-plan__form"
      >
        <ReduxFormFileUploader
          name="image_url"
          fileUrl={initialValues.image_url}
          formName={formName.availableFloorPlanEditForm}
          width="400px"
          height="400px"
        />
        <div className="floor-plan__form-column">
          <ReduxFormInput
            name="name"
            label="Name"
          />
          <ReduxFormInput
            name="width"
            label="width"
          />
          <ReduxFormInput
            name="height"
            label="height"
          />
          <FieldArray
            name="floorplan_rooms_attributes"
            component={RoomInnerArray}
          />
        </div>
        <div className="floor-plan__buttons">
          <Button
            type="submit"
            size="large"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </DetailEditFormStyle>
  );
}

// Enhance
const mapStateToProps = (state) => {
  const floorPlan = getAvailableFloorPlanById(state);

  const initialValues = {
    image_url: floorPlan.image_url && floorPlan.image_url.url,
    name: floorPlan.label,
    height: floorPlan.height,
    width: floorPlan.width,
  };

  return {
    roomInner: floorPlan.room_inner,
    initialValues,
  };
};

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName.availableFloorPlanEditForm,
  })
);

export default enhance(DetailEditForm);
