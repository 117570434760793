import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import { fromJS } from 'immutable';
import diff from 'immutablediff';

import {
  getProductClone, updateProduct, createProduct, getProductOptions, getFurniture,
} from '../../ducks/product';
import {
  loadingSelector, productSelector, productOptionsSelector, furnitureSelector,
} from '../../ducks/product';

import Spinner from '../../Components/Shared/Spinner';
import {
  Header, AttributesForm, ImagesContainer, GlobalButtons,
} from '../../Components/Product';

const defaultState = {
  id: 0,
  product_category_ids: [],
  product: {
    name: '',
    sku: '',
    supplier_id: '',
    description: '',
    width: '',
    depth: '',
    height: '',
    url_default_image: '',
    url_image2: '',
    url_image3: '',
    url_image4: '',
    url_image5: '',
    url_image6: '',
    cad_price: '',
    usd_price: '',
    tax_rate_id: '',
    stock_control: '',
    active: true,
    default_image: { medium: { url: '' } },
    image2: { medium: { url: '' } },
    image3: { medium: { url: '' } },
    image4: { medium: { url: '' } },
    image5: { medium: { url: '' } },
    image6: { medium: { url: '' } },
    product_attributes_array: [],
  },
  furniture: {
    color_ids: [],
    material_ids: [],
    seat_width: '',
    seat_depth: '',
    seat_height: '',
    arm_height: '',
    cost_cad: '',
    cost_usd: '',
    weight: '',
  },
  images: {},
};

export class CloneProduct extends Component {
  state = {
    hasProduct: false,
    missingRequiredFields: false,
    productData: defaultState,
    categories: [],
    materials: [],
    colors: [],
    suppliers: [],
  };

  componentDidMount() {
    const { getProductClone, getProductOptions, match } = this.props;
    getProductOptions();
    getProductClone(match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const isNewProduct = nextProps.product !== this.props.product;
    if (isNewProduct) {
      this.updateProductData(nextProps);
    }

    if (nextProps.productOptions) {
      this.setState({
        categories: nextProps.productOptions.categories || [],
        materials: nextProps.productOptions.materials || [],
        colors: nextProps.productOptions.colors || [],
        suppliers: nextProps.productOptions.suppliers || [],
      });
    }
  }

  updateProductData = (nextProps) => {
    if (this.state.hasProduct) return;
    this.setState({
      productData: this.getProductDataClone(nextProps),
      hasProduct: true,
    });
  };

  getProductDataClone = (data) => {
    let results = {
      ...defaultState,
    };
    if (data.product) {
      results = {
        ...defaultState,
        id: data.product.id,
        product_category_ids: data.product.categories,
        product: {
          ...defaultState.product,
          name: data.product.name,
          sku: data.product.sku,
          supplier_id: data.product.supplier_id,
          description: data.product.description,
          product_attributes_array: data.product.product_attributes,
          cad_price: data.product.msrp_cad,
          usd_price: data.product.msrp_usd,
          stock_control: data.product.stock_control,
          active: data.product.active,
          width: data.product.width,
          height: data.product.height,
          depth: data.product.depth,
          url_default_image: data.product.url_default_image,
          url_image2: data.product.url_image2,
          url_image3: data.product.url_image3,
          url_image4: data.product.url_image4,
          url_image5: data.product.url_image5,
          url_image6: data.product.url_image6,
          default_image: data.product.default_image,
          image2: data.product.image2,
          image3: data.product.image3,
          image4: data.product.image4,
          image5: data.product.image5,
          image6: data.product.image6,
        },
        furniture: {
          ...defaultState.product.furniture,
          ...data.product.furniture,
          color_ids: data.product.furniture.colors,
          material_ids: data.product.furniture.materials,
        },
        images: {},
      };
    }
    return results;
  };

  getUpdateData = (productData) => {
    const result = {
      product_category_ids: productData.product_category_ids,
      product: {
        name: productData.product.name,
        sku: productData.product.sku,
        supplier_id: productData.product.supplier_id,
      },
      furniture: {},
      images: {},
    };
    const oldData = this.getProductDataClone(this.props);
    const updateFields = diff(fromJS(oldData), fromJS(productData)).toJS();
    for (const item of updateFields) {
      const path = item.path.split('/');
      const group = path[1];
      const key = path[2];
      if (['color_ids', 'material_ids'].indexOf(key) !== -1) {
        result[group][key] = productData[group][key];
      } else if (['product_attributes_array'].indexOf(key) !== -1) {
        result[group][key] = productData[group][key];
      } else if (['product_category_ids'].indexOf(group) !== -1) {
        result[group] = productData[group];
      } else {
        result[group][key] = item.value;
      }
    }
    if (result.images.default_image) {
      result.product.default_image = result.images.default_image;
    }
    delete result.images;
    if (!Object.keys(result.furniture).length) {
      delete result.furniture;
    }
    return result;
  };

  getUrlKey = (key) => (key === 1 ? 'url_default_image' : `url_image${key}`);

  getFileKey = (key) => (key === 1 ? 'default_image' : `image${key}`);

  getImagesList = (productData) => {
    const indexList = [1, 2, 3, 4, 5];
    return indexList.map((key) => {
      const url = productData.product[`${this.getUrlKey(key)}`];
      const file = productData.product[`${this.getFileKey(key)}`];
      const isFile = (file && file.type) || (file && file.medium.url);
      return isFile ? this.getImageData(file) : url;
    });
  };

  getImageData = (file) => (file.type ? URL.createObjectURL(file) : file.medium.url);

  handleCategoryChange = (category, event) => {
    const newProductData = fromJS(this.state.productData).toJS();
    newProductData.product_category_ids = event
      ? this.removeCategory(newProductData, category)
      : this.addCategory(newProductData.product_category_ids, category);

    this.setState({
      productData: newProductData,
    });
  };

  addCategory = (data, category) => [...data, category];

  removeCategory = (data, category) => data.product_category_ids.filter((id) => id !== category);

  handleImagesChange = (file, url, index) => {
    const newProductData = fromJS(this.state.productData).toJS();
    newProductData.product[this.getFileKey(index + 1)] = file || '';
    if (!file) {
      newProductData.product[this.getUrlKey(index + 1)] = url || '';
    }
    if (!file && !url) {
      newProductData.product[`remove_${this.getFileKey(index + 1)}`] = true;
    }
    this.setState({
      productData: newProductData,
    });
  };

  handleChangeRoomPlannerImage = (file, url) => {
    const newProduct = fromJS(this.state.productData).toJS();
    newProduct.product.image6 = file || '';
    if (!file) {
      newProduct.product.url_image6 = url || '';
    }
    if (!file && !url) {
      newProduct.product.remove_image6 = true;
    }
    this.setState({
      productData: newProduct,
    });
  };

  handleSupplierChange = (elements) => {
    const newProductData = this.state.productData;
    newProductData.product.supplier_id = elements.value;
    this.setState({
      productData: newProductData,
    });
  };

  handleColorsChange = (elements) => {
    const newProductData = this.state.productData;
    newProductData.furniture.color_ids = elements.map((el) => el.value);
    this.setState({
      productData: newProductData,
    });
  };

  handleMaterialsChange = (elements) => {
    const newProductData = this.state.productData;
    newProductData.furniture.material_ids = elements.map((el) => el.value);
    this.setState({
      productData: newProductData,
    });
  };

  handleChangeAttributes = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    const { files } = e.target;

    if (!name) {
      return;
    }

    if (files && files[0]) {
      this.setNewImage('image6', files[0]);
      return;
    }

    if (name === 'product.stock_control') {
      this.setNewProduct(name, value === 'true');
      return;
    }

    const prices = ['product.cad_price', 'product.usd_price', 'furniture.cost_cad', 'furniture.cost_usd'];
    if (prices.indexOf(name) !== -1 && value.indexOf('$') !== -1) {
      value = parseFloat(value.slice(2));
    }

    if (name.substring(0, 7) === 'product') {
      this.setNewProduct(name, value);
    } else {
      this.setNewFurniture(name, value);
    }
  };

  handleChangeOtherAttributes = (e) => {
    const { name } = e;
    const { value } = e;
    let newProduct = fromJS(this.state.productData);
    const itemNumber = name.split(':')[1];
    const itemKey = name.split(':')[0] === 'name ' ? 'key' : 'value';
    const newAttr = newProduct.toJS().product.product_attributes_array;
    newAttr[itemNumber][itemKey] = value;
    newProduct = newProduct.setIn(['product', 'product_attributes_array'], newAttr);
    this.setState({
      productData: newProduct.toJS(),
    });
  };

  handleReturn = () => {
    this.props.goBack();
  };

  handleSave = () => {
    if (!this.hasRequiredFields()) {
      this.setState({
        missingRequiredFields: true,
      });
      return;
    }
    const { productData } = this.state;
    const formData = this.getUpdateData(productData);
    if (parseInt(this.props.product.id, 10)) {
      this.props.updateProduct(this.props.product.id, formData);
      this.props.push(`/products/${this.props.product.id}`);
    }
  };

  hasRequiredFields = () => {
    const { product_category_ids } = this.state.productData;
    const { name, sku, supplier_id } = this.state.productData.product;
    const requiredFields = product_category_ids[0] && name && sku && supplier_id;
    return !!requiredFields;
  };

  handleAddAttribute = () => {
    let newProduct = fromJS(this.state.productData);
    const OtherAttr = this.state.productData.product.product_attributes_array.slice();
    const newItem = { key: '', value: '' };
    OtherAttr.push(newItem);
    newProduct = newProduct.setIn(['product', 'product_attributes_array'], OtherAttr);
    this.setState({
      productData: newProduct.toJS(),
    });
  };

  handleDeleteAttribute = (id) => {
    this.setState({
      product_attributes_array: this.state.productData.product.product_attributes_array.splice(id, 1),
    });
  };

  handleChangeSwitch = (e) => {
    const newProduct = fromJS(this.state.productData).toJS();
    newProduct.product.active = e;
    this.setState({
      productData: newProduct,
    });
  };

  setNewImage = (name, file) => {
    const newProduct = fromJS(this.state.productData).toJS();
    newProduct.images[name] = file;
    this.setState({
      productData: newProduct,
    });
  };

  setNewProduct = (name, value) => {
    const newProduct = fromJS(this.state.productData).toJS();
    const category = name.substring(8);
    newProduct.product[category] = value;
    this.setState({
      productData: newProduct,
    });
  };

  setNewFurniture = (name, value) => {
    const newFurniture = this.state.productData.furniture;
    const category = name.substring(10);
    newFurniture[category] = value;
    this.setState({
      furniture: newFurniture,
    });
  };

  render() {
    if (this.props.loading || !this.state.productData || !this.props.productOptions) {
      return <Spinner />;
    }
    const {
      productData, categories, colors, materials, suppliers, missingRequiredFields,
    } = this.state;

    return (
      <div className="product-edit-page">
        <div className="product-edit-page__wrapper">
          <div className="product-edit-page__header">
            <Header
              productName={productData.product.name}
              checked={productData.product.active}
              onReturn={this.handleReturn.bind(this)}
              onChangeSwitch={this.handleChangeSwitch.bind(this)}
            />
          </div>
          <div className="product-edit-page__images-container pl-0 pl-lg-5 ml-0 ml-lg-5">
            <ImagesContainer
              images={this.getImagesList(productData)}
              onImagesChange={this.handleImagesChange.bind(this)}
              imageSize="large"
              customStyles="product"
            />
          </div>
          <div className="product-edit-page__attributes-from">
            <AttributesForm
              missingRequiredFields={missingRequiredFields}
              productData={productData}
              categories={categories}
              colors={colors}
              materials={materials}
              suppliers={suppliers}
              onChangeAttribute={this.handleChangeAttributes.bind(this)}
              onCategoryChange={this.handleCategoryChange.bind(this)}
              onSupplierChange={this.handleSupplierChange.bind(this)}
              onColorsChange={this.handleColorsChange.bind(this)}
              onMaterialsChange={this.handleMaterialsChange.bind(this)}
              onChangeOtherAttribute={this.handleChangeOtherAttributes.bind(this)}
              onAddAttribute={this.handleAddAttribute.bind(this)}
              onDeleteAttribute={this.handleDeleteAttribute.bind(this)}
              onChangeRoomPlannerImage={this.handleChangeRoomPlannerImage.bind(this)}
            />
          </div>
          <div className="mt-md-5 mb-md-5 mt-4 mb-2 container">
            <GlobalButtons onSave={this.handleSave.bind(this)} onCancel={this.handleReturn.bind(this)} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    product: productSelector(state),
    productOptions: productOptionsSelector(state),
    furniture: furnitureSelector(state),
    loading: loadingSelector(state),
  }),
  {
    getProductClone,
    getProductOptions,
    getFurniture,
    createProduct,
    updateProduct,
    push,
    goBack,
  },

  null,

  { pure: false }
)(CloneProduct);
