import React, { Fragment, PureComponent } from "react"
import PropTypes from "prop-types"

// components
import { Spinner } from "../index"

class Preloader extends PureComponent {
  static propTypes = {
    // from parent component
    isLoading: PropTypes.bool
  }

  render() {
    const { isLoading, children } = this.props

    return <Fragment>{isLoading ? <Spinner /> : children}</Fragment>
  }
}

export default Preloader
