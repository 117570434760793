import axios from 'axios';

import { apiUrl } from './lt-api';

const deliveryAPI = axios.create({
  baseURL: apiUrl,
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    withCredentials: true,
  },
});

export const getDeliveryAgentsByLatLong = (
  lat,
  long,
  country_code,
  page = '',
  filters = {}
) => deliveryAPI.get(
  'delivery_agents/find',
  {
    params: {
      ...filters,
      country_code,
      latitude: lat,
      longitude: long,
      page: page || 1,
      per_page: filters?.map_view ? 300 : 10,
    },
  }
);

export const getLatitudeLongitude = (address) => axios({
  method: 'GET',
  url: `https://api.opencagedata.com/geocode/v1/json?q=${address}&key=8e4c2d1fb9fa48d5991189033c988cd0&countrycode=us,ca`,
});

export const getDeliveryAgents = ({ quality_rating, ...params }) => deliveryAPI.get('delivery_agents', {
  params: {
    ...params,
    per_page: params?.map_view ? 300 : 10,
    rating_max: quality_rating?.[1],
    rating_min: quality_rating?.[0],
  },
});

export const createDeliveryAgentApi = (payload) => deliveryAPI.post('delivery_agents', payload);

export const updateDeliveryAgentApi = (id, payload) => deliveryAPI.put(`delivery_agents/${id}`, payload);

export const getDeliveryAgentByIdApi = (id) => deliveryAPI.get(`delivery_agents/${id}`);
