import React, { Component } from "react"
import { createPortal } from "react-dom"
import PropTypes from "prop-types"

// components
import StyledComponents from "./style"

class Modal extends Component {
  static propTypes = {
    // from parent component
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    closeModal: PropTypes.func.isRequired
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handlerKeyUp)
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handlerKeyUp)
  }

  handlerKeyUp = (event) => {
    event && event.preventDefault && event.preventDefault()

    const { code } = event
    const { closeModal } = this.props

    if (code === "Escape") closeModal(event)
  }

  handlerOverlayClick = ({ target, currentTarget }) => {
    const { closeModal } = this.props

    if (target === currentTarget) {
      closeModal()
    }
  }

  render() {
    const { children, closeModal } = this.props

    return createPortal(
      <StyledComponents onClick={this.handlerOverlayClick}>
        <div className="modal-window__window">
          <button className="modal-window__button-close" type="button" onClick={closeModal}>
            <span className="visually-hidden">Close modal</span>
          </button>
          {children}
        </div>
      </StyledComponents>,
      document.getElementById("app-portal")
    )
  }
}

export default Modal
