import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

// Import Components
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'redux-form';
import FileUploaderStyle from './style';
import { filterUploaderClearRequest } from '../../../../ducks/fileUploader';
import { ReduxFormFile } from '../index';
import DefaultImage from './DefaultImage';

class ReduxFormFileUploader extends Component {
  static propTypes = {
    // from connect/fileUploader
    filterUploaderClearRequest: PropTypes.func,
    // from parent Component
    name: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    validate: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      src: props.fileUrl || '',
    };

    this.fileInputRef = null;

    this.img_changeSrcHandler = this.img_changeSrcHandler.bind(this);
    this.img_changeSrcInputHandler = this.img_changeSrcInputHandler.bind(this);
    this.img_deleteSrcHandler = this.img_deleteSrcHandler.bind(this);
    this.img_clickHandler = this.img_clickHandler.bind(this);
    this.setFileInputRef = this.setFileInputRef.bind(this);
  }

  setFileInputRef(node) {
    this.fileInputRef = node;
  }

  img_changeSrcInputHandler(value) {
    this.setState({ src: value });
  }

  img_changeSrcHandler(file) {
    this.setState({ src: file });
  }

  img_deleteSrcHandler() {
    const { name, formName, filterUploaderClearRequest } = this.props;
    this.setState({ src: '' });
    filterUploaderClearRequest(formName, name, '');
    this.fileInputRef.value = '';
  }

  img_clickHandler() {
    const toastrConfirmOptions = {
      onOk: () => this.img_deleteSrcHandler(),
      onCancel: () => {},
    };
    toastr.confirm('Are you sure?', toastrConfirmOptions);
  }

  renderImage = () => {
    const { src } = this.state;
    const { name } = this.props;

    return !src
      ? <DefaultImage width={150} height={150} color="#ccc" />
      : <img src={src} alt={name} className="file-uploader__preview" />;
  };

  render() {
    const { src } = this.state;
    const {
      name, width, height, validate,
    } = this.props;

    return (
      <FileUploaderStyle width={width} height={height}>
        <div className="file-uploader__preview-box" aria-disabled={!src}>
          {this.renderImage()}
          <div className="file-uploader__hover-item" onClick={this.img_clickHandler}>
            <div className="file-uploader__link">
              Delete
            </div>
          </div>
        </div>
        <label htmlFor={`${name}-id`} className="file-uploader__label">
          <FontAwesomeIcon icon={faCamera} className="file-uploader__label-icon" />
          Upload
        </label>
        <Field
          changeImgSrc={this.img_changeSrcHandler}
          name={name}
          setRef={this.setFileInputRef}
          component={ReduxFormFile}
          validate={validate}
          changeSrc={this.img_changeSrcInputHandler}
        />
      </FileUploaderStyle>
    );
  }
}

// Enhancer
const mapStateToProps = (state, ownProps) => ({});
const mapDispatchToProps = { filterUploaderClearRequest };

export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormFileUploader);
