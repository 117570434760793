import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Switch from 'react-switch';
import { savePreviousUrlAction } from '../../../ducks/router';
import MoveForm from './MoveForm';
import CopyItemsForm from '../Variant/CopyItemsForm';
import MoveCheckbox from './MoveCheckbox';

function RoomProductList({
  variantId,
  variants,
  products,
  onQuantityChange,
  onMoveChecked,
  onSave,
  imageSize,
  push,
  saveRoomTypeId,
  onDesignRoomSwitcherChange,
  match: {
    url,
    params: { id },
  },
  savePreviousUrlAction,
}) {
  const goToAddProductPage = (roomTypeId) => {
    saveRoomTypeId(roomTypeId);
    savePreviousUrlAction(url);
    variantId && push(`/rooms/${id}/variant/${variantId}/add-product`);
  };

  return (
    <div className="container-fluid mb-5 images-container">
      {(products
        && products.map((room, index) => (
          <div key={index} className="row mb-5 align-items-start">
            <div className="col-lg-1">
              <p className="room-row-title">Show Room</p>
              <Switch
                id={id.toString()}
                checked={room.active}
                width={50}
                height={20}
                handleDiameter={18}
                onColor="#1ab394"
                onChange={() => onDesignRoomSwitcherChange(
                  variantId,
                  room.design_room_id,
                  room.active
                )}
              />
            </div>
            <div className="col-lg 3 text-uppercase pl-0">
              {room.key}
              <MoveForm
                products={products}
                room={room}
                room_id={id}
                variant_id={variantId}
                design_room={room}
                design_room_id={room.design_room_id}
                onSave={onSave}
              />
              {Boolean(room.has_values)
              && (
                <CopyItemsForm
                  products={products}
                  room_variants={variants}
                  room={room}
                  room_id={id}
                  current_variant_id={variantId}
                  design_room={room}
                  design_room_id={room.design_room_id}
                />
              )}
            </div>
            <div className="col-lg-9 d-flex flex-wrap">
              {room.values
                && room.values.map((product) => product !== null ? (
                  <div key={product.id} className="figure-wrapper mr-5">
                    <div className={`images-wrapper-${imageSize}`}>
                      <img
                        className="photo rounded"
                        alt={product.name}
                        src={
                          product.image.url === null ? product.url_image : product.image.url
                        }
                      />
                    </div>
                    <div className="quantity-handler">
                      <span
                        onClick={() => onQuantityChange(
                          product.quantity > 0 ? product.quantity - 1 : product.quantity,
                          variantId,
                          'quantity',
                          product.id,
                          room.design_room_id,
                          product.isChanged = true
                        )}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </span>
                      {product.quantity}
                      <span
                        onClick={() => onQuantityChange(
                          product.quantity + 1,
                          variantId,
                          'quantity',
                          product.id,
                          room.design_room_id,
                          product.isChanged = true
                        )}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </span>
                      {product.inventory_count !== undefined ? (
                        <span className="product__inventory_count">
                          {product.inventory_count === null ? 0 : product.inventory_count}
                        </span>
                      ) : null}
                    </div>
                    <p className="product__subtitle px-2">
                      {product.supplier_name} {product.sku}
                    </p>
                    <p className="product__subtitle__price">${product.usd_cost}</p>
                    <p>{product.name}</p>
                    <div className="move-checkbox-content">
                      <MoveCheckbox
                        product={product}
                        room={room}
                        room_id={id}
                        variant_id={variantId}
                        design_room={room}
                        design_room_id={room.design_room_id}
                        onMoveChecked={onMoveChecked}
                      />
                    </div>
                  </div>
                ) : null)}

              <div
                className={`add-image add-image-${imageSize}`}
                onClick={() => goToAddProductPage(room.design_room_id)}
              >
                <p className="plus-sign">+</p>
                <p className="phrase">ADD PRODUCT</p>
              </div>
            </div>
          </div>
        )))
        || 'Please, choose a floor plan to view products'}
    </div>
  );
}

export default compose(withRouter, connect(null, { savePreviousUrlAction, push }))(RoomProductList);
