import { createStore, applyMiddleware, compose } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { routerMiddleware } from "react-router-redux"
import createSagaMiddleWare from "redux-saga"
import { createBrowserHistory } from "history"
import rootReducer from "./reducer"
import sagas from "./saga"

export const history = createBrowserHistory({
  basename: "/"
})

const initialState = {}
const enhancers = []
const sagaMiddleware = createSagaMiddleWare({
  onError: (err) => console.log("Unhandled saga error", err)
})
const middleware = [sagaMiddleware, routerMiddleware(history)]

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

const persistConfig = {
  key: "furnishr-redux",
  storage,
  whitelist: ["authReducer"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  const store = createStore(persistedReducer, initialState, composedEnhancers)
  sagaMiddleware.run(sagas)
  const persistor = persistStore(store)

  return { store, persistor }
}
