import React, { lazy } from "react"

// Import Components
const Scrapers = lazy(() => import("../Scrapers"))
const InventoryFiles = lazy(() => import("../InventoryFiles"))

export default [
  {
    path: "/etl/scrapers",
    component: () => <Scrapers />,
    data: {
      title: "Scrapers"
    }
  },
  {
    path: "/etl/inventory-files",
    component: () => <InventoryFiles />,
    data: {
      title: "Inventory Files"
    }
  }
]
