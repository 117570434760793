import React from "react"

// Import Components
import { MergePurgeContainer } from "../../../Components/MergePurge"

function Materials() {
  return <MergePurgeContainer type="materials" />
}

export default Materials
