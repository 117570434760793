import qs from "qs"

function parse(params) {
  return qs.parse(params, { arrayLimit: Infinity, ignoreQueryPrefix: true })
}

function stringify(params) {
  return qs.stringify(params, { arrayFormat: "brackets" })
}

export default { parse, stringify }
