import React from 'react';

// Redux
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Import Components
import { DesignTemplatesIntroScreen, DesignTemplatesLayout } from '../../Components/DesignTemplates';

function DesignTemplates({ push }) {
  const buttonEdit_clickHandler = function () {
    push('/design-templates/detail');
  };

  return (
    <DesignTemplatesLayout
      isLoading={false}
      pageTitle="Design rule matching"
      buttonTitle="Edit selections"
      button_clickHandler={buttonEdit_clickHandler}
    >
      <DesignTemplatesIntroScreen />
    </DesignTemplatesLayout>
  );
}

export default connect(null, { push })(DesignTemplates);
