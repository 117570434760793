import axios from "axios"
import objectToFormData from "../utils/objectToFormData"
import { API } from "../constants"
import { apiUrl } from "./lt-api"

axios.defaults.baseURL = API

const defaultHeaders = {
  method: "get",
  crossDomain: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    withCredentials: true
  }
}

export function getProductData(id, product_id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/${id}/products/${product_id}`
  })
}

export function getFilterData(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/filter_options`
  })
}


export function getProductDataClone(id) {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/64/products/${id}/clone`
  })
}

export function getProductOptionsData() {
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/64/product_options`
  })
}

export function getFurnitureData(id) {
  return axios({
    ...defaultHeaders,
    url: `${API}/furniture/${id}`
  })
}

export function updateProductData(id, payload) {
  const formData = objectToFormData(payload)
  return axios({
    ...defaultHeaders,
    url: `${apiUrl}etl/scrapers/64/products/${id}`,
    method: "PUT",
    data: formData
  })
}

