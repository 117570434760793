import styled from "styled-components"

export default styled.div`
  width: 100%;
  min-width: 1030px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  font-family: "Roboto", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 100;
  font-size: 17px;
  letter-spacing: 1px;
  color: #595959;
`
