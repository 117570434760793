import PropTypes from 'prop-types';

export const deliveryAgentsReducerShapes = PropTypes.shape({
  deliveryAgents:
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        address: PropTypes.string,
        admin_contact_name: PropTypes.string,
        company_name: PropTypes.string,
        delivery_quotes: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            quote_total: PropTypes.string,
            request_date: PropTypes.string,
          })
        ),
        shoppe_orders: PropTypes.arrayOf(
          PropTypes.shape({
            first_name: PropTypes.string,
            id: PropTypes.number,
            last_name: PropTypes.string,
          })
        ),
        website: PropTypes.string,
      })),
      meta: PropTypes.shape({
        page: PropTypes.string,
        perPage: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        totalEntries: PropTypes.number,
      }),
    }),
});

export const usersReducerShape = PropTypes.shape({
  adminUsers: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    value: PropTypes.string,
  })),
});

export const historyShapes = PropTypes.shape({
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  push: PropTypes.func,
});

export const suppliersReducerShape = PropTypes.shape({
  suppliers: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  }),
});
