import React, { Component } from 'react';
import { connect } from 'react-redux';

export class ProductCategories extends Component {
  componentDidMount() {
    this.props.user();
  }

  render() {
    return (
      <h1>Product Categories</h1>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapDispatchToProps = () => ({
  user: () => {},
});

// TODO: rewrite mapStateToProps/mapDispatchToProps (using reselect selectors and object-style mapDispatchToProps)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductCategories);
