import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// ImportComponents
import { Button } from '../../Shared';

RuleListItem.propTypes = {
  // from RuleListItem
  type: PropTypes.string,
  title: PropTypes.string,
  total_scraped_products: PropTypes.number,
  last_upload_date: PropTypes.string,
  can_parse: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  info: PropTypes.array,
  buttonDelete_clickHandler: PropTypes.func,
  push: PropTypes.func,
};

function RuleListItem({
  type, title, img, id, total_scraped_products,
  last_upload_date, can_parse, buttonDelete_clickHandler, push, collection,
}) {
  const buttonUpdate_clickHandler = function () {
    window.localStorage.setItem('url-name', JSON.stringify(type));
    push(`/etl/scrapers/${id}/products`);
  };
  const hasProducts = (total_scraped_products !== undefined && total_scraped_products > 0);

  return (
    <>
      <a
        onClick={buttonUpdate_clickHandler}
        className="design-rule__title"
      >
        {
          img && (
            <div className="design-rule__preview-box">
              <img src={img} alt={title} className="design-rule__preview" />
            </div>
          )
        }
        <span>{title}</span>
      </a>

      <div className="design-rule__item-info">
        {hasProducts
        && (
          <div key={`${id}-total_scraped_products`} className="design-rule__info">
            <span className="design-rule__info-name">Total Products:</span>
            <span className="design-rule__info-value"> {total_scraped_products}</span>
          </div>
        )}
        {last_upload_date
        && (
          <div key={`${id}-last_upload_date`} className="design-rule__info">
            <span className="design-rule__info-name">Last Upload Date:</span>
            <span className="design-rule__info-value"> {last_upload_date}</span>
          </div>
        )}
        <div key={`${id}-can_parse`} className="design-rule__info">
          <span className="design-rule__info-name">Can Upload:</span>
          <span className="design-rule__info-value"> {(can_parse) ? 'Yes' : 'No'}</span>
        </div>
      </div>

      <div className="design-rule__item-buttons-box">
        <Button
          size="small"
          color="primary"
          width={85}
          onClick={buttonUpdate_clickHandler}
        >
          Show
        </Button>
      </div>
    </>
  );
}

// Enhancer
const mapStateToProps = null;
const mapDispatchToProps = { push };

export default connect(mapStateToProps, mapDispatchToProps)(RuleListItem);
