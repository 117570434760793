import { appName, POSTFIX } from "../constants"
import { all, put, takeLatest } from "redux-saga/effects"
import { delay } from "redux-saga"

/**
 * Constants
 **/
export const moduleName = "router"
const prefix = `${appName}/${moduleName}`

const SAVE_URL = `${prefix}/SAVE_URL`
/**
 * Reducer
 **/
const initialState = {
  url: null,
  variantIndex: 0,
  error: null
}

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case SAVE_URL + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        url: payload.url,
        variantIndex: payload.variantIndex
      }

    case SAVE_URL + POSTFIX.FAILURE:
      return {
        ...state,
        url: null,
        variantIndex: null,
        error
      }
    default:
      return state
  }
}

/**
 * Selectors
 **/

/**
 * Action Creators
 **/
export function savePreviousUrlAction(url, variantIndex) {
  return {
    type: SAVE_URL,
    payload: { url, variantIndex }
  }
}

/**
 * Sagas
 **/
function* savePreviousUrlSaga({ payload: { url, variantIndex = "0" } }) {
  try {
    yield put({
      type: SAVE_URL + POSTFIX.SUCCESSFUL,
      payload: { url }
    })
    yield delay(200)
    window.localStorage.setItem("variantIndex", variantIndex)
  }
  catch ({ message }) {
    put({
      type: SAVE_URL + POSTFIX.FAILURE,
      error: message
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(SAVE_URL, savePreviousUrlSaga)
  ])
}
