import styled from "styled-components"

export default styled.section`
  width: 100%;
  padding-top: 30px;
  
  .move__box-button{
    margin-top: 20px;
  }
  
  .move__box-select{
    margin-top: 0px;
    margin-left: 0px;
    max-width: 100%;
  }
  
  .move_modal__title {
    display: block;
    width: 100%;
    font-size: 18px;
    margin: 15px 0 15px 0;
    text-align: center;
  }
  .modal__content{
    font-size: 16px;
    margin: 15px 0 15px 0;
    text-align: center;
  }
  
  .move__box-label{
    margin-right: 20px;
    text-transform: none;
  }
`
