import React, { Component } from 'react';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
// HOCs
import { withRouter } from 'react-router-dom';
// Import Components
import { ETLLayout, ETLNavigation } from '../../Components/ETL';

class ETL extends Component {
  constructor(props) {
    super(props);

    this.buttonGoBack_clickHandler = this.buttonGoBack_clickHandler.bind(this);
  }

  componentDidMount() {
    this.props.push('/etl/');
  }

  buttonGoBack_clickHandler() {
    this.props.push('/etl');
  }

  render() {
    return (
      <ETLLayout
        isLoading={false}
        pageTitle="ETL Page"
        buttonTitle="Back to ETL"
        button_clickHandler={this.buttonGoBack_clickHandler}
      >
        <ETLNavigation />
      </ETLLayout>
    );
  }
}

// Enhance
const mapStateToProps = null;

const mapDispatchToProps = { push };

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ETL);
