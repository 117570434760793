import styled from "styled-components"

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  .filters__item {
    display: flex;
    align-items: center;
  }

  .filters__item .form-control-wrapper {
    width: 100%;
    margin-right: 30px;
  }

  .filters__item .input {
    width: 100%;
  }

  .filters__item.full-width {
    width: 50%;
  }

  @media screen and (max-width: 1440px) {
    .filters__item.full-width {
      width: 70%;
    }
  }
`
