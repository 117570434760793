import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faAngleDown } from "@fortawesome/free-solid-svg-icons"

const $ = require("jquery")

class CustomMultiselect extends Component {
  state = {
    multiselectListOpen: false
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.toggleItemsList)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.toggleItemsList)
  }

  toggleItemsList = (e) => {
    const nodeType = this.getNodeType(e)

    if (e.target.className === "child" || e.target.className === "parent" || nodeType === "pseudo-remove") {
      return
    }
    const isPseudoElem = nodeType === "pseudo-select" || nodeType === "pseudo-icon"
    this.setState({
      multiselectListOpen: isPseudoElem && !this.state.multiselectListOpen
    })
  }

  handleItemAdd = (item) => {
    const hasItem = this.props.value.some((listItem) => item.value === listItem.value)
    if (!hasItem) {
      this.props.onChange(item.value)
    }
  }

  handleItemRemove = (item, e) => {
    if (this.props.onChange) {
      this.props.onChange(item.value, "remove")
    }
  }

  getNodeType = (event) => {
    let nodeType =
      typeof event.target.className === "object"
        ? $(event.target)
            .parents("div, p")
            .get(0).className
        : event.target.className

    return nodeType.split(" ").slice(-1)[0]
  }

  getMultiselectList = (itemsList) => {
    const Deep = (arr, name) => {
      return (
        <div key={name} className="parent">
          <p className="parent">{name}</p>
          {arr.map((e) => {
            if (e.children) {
              return Deep(e.children, e.label)
            } else {
              return (
                <span key={e.label} className="child" onClick={() => this.handleItemAdd(e)}>
                  {e.label}
                </span>
              )
            }
          })}
        </div>
      )
    }
    return Deep(itemsList, "")
  }

  render() {
    const { options, value, typeFirstIcon, typeSecondIcon } = this.props

    return (
      <div className="pseudo-select">
        {!value.length
          ? "Select..."
          : value.map((e, i) => {
              return (
                <p key={i} className="pseudo-select-inner pseudo-remove">
                  {e ? e.label : ""}
                  <span onClick={(event) => this.handleItemRemove(e, event)}>
                    <FontAwesomeIcon icon={typeFirstIcon || faTimes} />
                  </span>
                </p>
              )
            })}
        {this.state.multiselectListOpen && <div className="pseudo-option">{this.getMultiselectList(options)}</div>}
        <div className="pseudo-arrow pseudo-icon">
          <i className="pseudo-icon">
            <FontAwesomeIcon className="pseudo-icon" icon={typeSecondIcon || faAngleDown} />
          </i>
        </div>
      </div>
    )
  }
}

export default CustomMultiselect
