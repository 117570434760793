import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static addRule(ruleData) {
    return http({
      method: "POST",
      url: `${API}design_templates/design_rules`,
      data: ruleData
    })
      .then((res) => res.data)
  }

  static getRules() {
    return http({
      method: "GET",
      url: `${API}design_templates/design_rules`
    })
      .then((res) => res.data)
  }

  static updateRule(ruleId, ruleData) {
    return http({
      method: "PUT",
      url: `${API}design_templates/design_rules/${ruleId}`,
      data: ruleData
    })
      .then((res) => res.data)
  }

  static getRuleById(ruleId) {
    return http({
      method: "GET",
      url: `${API}design_templates/design_rules/${ruleId}/edit`
    })
      .then((res) => res.data)
  }

  static deleteRule(ruleId) {
    return http({
      method: "DELETE",
      url: `${API}design_templates/design_rules/${ruleId}`
    })
      .then((res) => res.data)
  }
}
