import React from "react"

export const BreadcrumbArrow = () => {
  return (
    <span key="breadcrumbsArrow" className="breadcrumbs__item breadcrumbs__item-arrow">
      &nbsp;/&nbsp;
    </span>
  )
}

export const CurrentChild = ({ name }) => {
  return <span className="breadcrumbs__item breadcrumbs__item-current">{name}</span>
}
