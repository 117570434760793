import React, {
  useCallback, useEffect, useState,
} from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { compose } from 'redux';
import { debounce } from 'lodash';

import {
  getDeliveryAgentsSelector,
  checkAddressRequest,
  updateDeliveryAgentRequest,
  getDeliveryAgentByIdRequest,
} from '../../ducks/deliveryAgents';
import { getAdminUsersRequest, usersReducerSelector } from '../../ducks/users';
import { getToken } from '../../ducks/auth';

import {
  updateDeliveryAgentComponents,
  deliveryAgentObject,
  numberAtributesOnCreateDeliveryAgent,
  modalPropsDeliveryAgents,
} from '../../constants/createHelper';
import { deliveryAgentsReducerShapes, usersReducerShape } from '../../constants/shapes';

import withModal from '../../Components/HOCs/modal';
import {
  Button,
  Input,
  Select,
  Spinner,
  Table,
} from '../../Components/Shared';

import './index.scss';

const DeliveryAgent = ({
  deliveryAgentsReducer: {
    isLoading,
    address,
    addressIsLoading,
    deliveryAgent,
  },
  usersReducer: { adminUsers },
  checkAddressRequest: checkAddress,
  updateDeliveryAgentRequest: updateDeliveryAgent,
  getDeliveryAgentByIdRequest: getDeliveryAgentById,
  getAdminUsersRequest: getAdminUsers,
  match: {
    params: { id },
  },
  token = '',
}) => {
  const [updateDeliveryAgentData, setUpdateDeliveryAgentData] = useState(deliveryAgentObject);

  useEffect(() => {
    getAdminUsers();
    getDeliveryAgentById(id);
  }, []);

  const debounceCheckAddress = debounce(() => {
    checkAddress(updateDeliveryAgentData.address);
  }, 500);

  useEffect(() => {
    if (deliveryAgent) {
      setUpdateDeliveryAgentData({
        ...deliveryAgent,
        ops_owner: deliveryAgent?.ops_owner?.id || '',
      });
    }
  }, [deliveryAgent]);

  useEffect(() => {
    if (updateDeliveryAgentData.address && deliveryAgent.address !== updateDeliveryAgentData.address) {
      debounceCheckAddress();
    }

    return () => {
      debounceCheckAddress.cancel();
    };
  }, [updateDeliveryAgentData.address]);

  useEffect(() => {
    const {
      address: addressFromState,
      country,
      state_or_province,
      longitude,
      latitude,
      currency,
      metropolitan_city,
    } = address;

    setUpdateDeliveryAgentData({
      ...updateDeliveryAgentData,
      address: addressFromState,
      country,
      currency,
      latitude,
      longitude,
      metropolitan_city,
      state_or_province,
    });
  }, [address]);

  const handleSubmit = useCallback(() => {
    updateDeliveryAgent({
      callback: () => {
      },
      data: {
        ...updateDeliveryAgentData,
        ops_owner_id: updateDeliveryAgentData.ops_owner,
      },
    });
  }, [updateDeliveryAgentData]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="delivery-agent-view">
      <form className="delivery-agent-view__form">
        {
          updateDeliveryAgentComponents.map(({
            component,
            name,
            label,
            required,
            disabled,
            placeholder,
            type,
            options,
          }) => {
            const InputComponent = component === 'input' ? Input : Select;

            return (
              <div
                className="delivery-agent-view__form-element"
                key={name}
              >
                {
                  component === 'select' && (
                    <div className="delivery-agent-view__form-select">
                      <label className="delivery-agent-view__form-label" htmlFor={name}>
                        {label}
                        {required && <span className="delivery-agent-view__form-required">*</span>}
                      </label>
                    </div>
                  )
                }
                <InputComponent
                  disabled={disabled}
                  name={name}
                  label={label}
                  value={updateDeliveryAgentData[name]}
                  onChange={(e) => setUpdateDeliveryAgentData({
                    ...updateDeliveryAgentData,
                    [name]: (
                      numberAtributesOnCreateDeliveryAgent.includes(name)
                      && (e.target.value < 0 || e.target.value)
                        ? Math.abs(e.target.value, 10)
                        : e.target.value),
                  })}
                  {...(name === 'address' && addressIsLoading && { disabled: true })}
                  {...type && { type }}
                  {...(type === 'number') && { min: 0 }}
                  {...placeholder && { placeholder }}
                  {...(options && {
                    options,
                    placeholder: 'Select an option',
                  })}
                  {...(name === 'ops_owner') && { options: adminUsers }}
                  {...(required && { required })}
                />
              </div>
            );
          })
        }
        <div className="delivery-agent-view__tables">
          <div className="delivery-agent-view__table">
            Delivery Quotes
            <Table
              token={token}
              type="deliveryAgentorders"
              items={deliveryAgent?.shoppe_orders}
              tableLoaded={!isLoading}
              noResults={!deliveryAgent?.shoppe_orders?.length}
              renderColumns={modalPropsDeliveryAgents.orders.columns}
            />
          </div>
          <div className="delivery-agent-view__table">
            Delivery Orders
            <Table
              token={token}
              type="deliveryAgentquotes"
              items={deliveryAgent?.delivery_quotes}
              tableLoaded={!isLoading}
              noResults={!deliveryAgent?.delivery_quotes?.length}
              renderColumns={modalPropsDeliveryAgents.quotes.columns}
            />
          </div>
        </div>
        <div className="delivery-agent-view__form-buttons">
          <Button
            color="blue"
            type="button"
            size="large"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </form>
    </div>
  );
};

DeliveryAgent.propTypes = {
  checkAddressRequest: propTypes.func.isRequired,
  deliveryAgentsReducer: deliveryAgentsReducerShapes.isRequired,
  getAdminUsersRequest: propTypes.func.isRequired,
  getDeliveryAgentByIdRequest: propTypes.func.isRequired,
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.string,
    }),
  }).isRequired,
  token: propTypes.string,
  updateDeliveryAgentRequest: propTypes.func.isRequired,
  usersReducer: usersReducerShape.isRequired,
};

const mapStateToProps = (state) => ({
  deliveryAgentsReducer: getDeliveryAgentsSelector(state),
  token: getToken(state),
  usersReducer: usersReducerSelector(state),
});

const mapDispatchToProps = {
  checkAddressRequest,
  getAdminUsersRequest,
  getDeliveryAgentByIdRequest,
  updateDeliveryAgentRequest,
};

const enhance = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(DeliveryAgent);
