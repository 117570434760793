import styled from "styled-components"

export default styled.div`
  padding: 30px;
  
  .available-textures__form {
    min-width: 810px;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right:auto;
  }
  
  .available-textures__column {
    padding: 10px;
    flex-basis: 50%;
    flex-shrink: 0;
    flex-grow: 0;
  }
  
  .available-textures__column.preview {
    flex-basis: 30%;
  }
  
  .available-textures__column.fields {
    flex-basis: 70%;
  }
  
  .available-textures__button {
    flex-basis: 100%;
    padding: 10px;
    text-align: center;
  }
`
