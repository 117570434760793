import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTableSelected, setTableSelected } from '../../../ducks/table';

// components
import StyledComponents from './style';
import TableHead from './TableHead';
import TableBody from './TableBody';
import { Pagination } from '../index';

class Table extends Component {
  static propTypes = {
    // from parent component
    tableHead: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired,
    onCheckboxSelectedAll: PropTypes.func,
    onCheckboxSelected: PropTypes.func,
    selectedAllValue: PropTypes.bool,
    selected: PropTypes.array,
    screenHeight: PropTypes.number,
    rowKey: PropTypes.string,
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    totalEntries: PropTypes.number,
    paginationChange: PropTypes.func,
    name: PropTypes.string,
    // from connect
    setTableSelected: PropTypes.func,
  };

  static defaultProps = {
    tableHead: [],
    tableData: [],
  };

  state = {
    selectAll: false,
    selected: [],
  };

  componentDidMount() {
    const { tableData } = this.props;
    this.setCheckbox(tableData);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.tableData !== this.props.tableData) {
      this.setCheckbox(this.props.tableData);
    }
  }

  // set default checkboxes
  setCheckbox = (data) => {
    const array = data.map(({ id }) => ({
      name: id,
      value: false,
    }));

    this.setState({
      selected: array,
    });
  };

  // select all checkbox
  onCheckboxSelectedAll = async ({ target: { checked } }) => {
    const { selected } = this.state;
    const { setTableSelected } = this.props;

    const selectedArray = await selected.map(({ name }) => ({ name, value: checked }));
    setTableSelected(selectedArray, this.props.name);

    this.setState({
      selectAll: checked,
      selected: selectedArray,
    });
  };

  // select one checkbox
  onCheckboxSelected = ({ target: { name, checked } }) => {
    const { selected } = this.state;
    const { setTableSelected } = this.props;

    const selectedArray = selected.map((item) => {
      if (name.toString() === item.name.toString()) {
        return {
          name,
          value: checked,
        };
      }
      return item;
    });

    setTableSelected(selectedArray, this.props.name);
    this.setState({ selected: selectedArray });
  };

  render() {
    const {
      tableHead, tableData, perPage, currentPage, totalEntries, screenHeight, rowKey, paginationChange,
    } = this.props;
    const { selectedAllValue, selected } = this.state;

    const showing = (currentPage - 1) * perPage + 1;
    const calculateEntries = currentPage * perPage;
    const entries = calculateEntries > totalEntries ? totalEntries : calculateEntries;
    const pages = Math.ceil(totalEntries / perPage);

    return (
      <StyledComponents>
        <table className="table">
          <caption className="table__title">
            Showing {showing || 1} to {entries} of {totalEntries} entries
          </caption>
          <TableHead
            tableHead={tableHead}
            selectedAllValue={selectedAllValue}
            onCheckboxSelectedAll={this.onCheckboxSelectedAll}
          />
          <TableBody
            tableData={tableData}
            selected={selected}
            selectedAll={selectedAllValue}
            onCheckboxSelected={this.onCheckboxSelected}
            screenHeight={screenHeight}
            rowKey={rowKey}
          />
        </table>
        <Pagination activePage={currentPage} pages={pages} onPageChange={(page) => paginationChange(page)} />
      </StyledComponents>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  tableSelected: getTableSelected(state)[ownProps.name],
});

export default connect(
  mapStateToProps,
  { setTableSelected }
)(Table);
