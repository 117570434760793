import {
  call, put, all, takeEvery,
} from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  createDeliveryAgentApi,
  getDeliveryAgentByIdApi,
  getDeliveryAgents,
  getDeliveryAgentsByLatLong,
  getLatitudeLongitude,
  updateDeliveryAgentApi,
} from '../api/deliveryAgents';

/**
 * Reducer
 * */
const initialState = {
  address: {},
  addressIsLoading: false,
  deliveryAgent: {},
  deliveryAgents: {
    data: [],
  },
  error: null,
  isLoaded: false,
  isLoading: false,
};

const FIND_DELIVERY_AGENTS_BY_LOCATION_SUCCESSFUL = 'FIND_DELIVERY_AGENTS_BY_LOCATION_SUCCESSFUL';
const FIND_DELIVERY_AGENTS_BY_LOCATION_FAILURE = 'FIND_DELIVERY_AGENTS_BY_LOCATION_FAILURE';
const FIND_DELIVERY_AGENTS_BY_LOCATION_REQUEST = 'FIND_DELIVERY_AGENTS_BY_LOCATION_REQUEST';

const GET_DELIVERY_AGENTS_SUCCESSFUL = 'GET_DELIVERY_AGENTS_SUCCESSFUL';
const GET_DELIVERY_AGENTS_FAILURE = 'GET_DELIVERY_AGENTS_FAILURE';
const GET_DELIVERY_AGENTS_REQUEST = 'GET_DELIVERY_AGENTS_REQUEST';

const CHECK_ADDRESS_SUCCESSFUL = 'CHECK_ADDRESS_SUCCESSFUL';
const CHECK_ADDRESS_FAILURE = 'CHECK_ADDRESS_FAILURE';
const CHECK_ADDRESS_REQUEST = 'CHECK_ADDRESS_REQUEST';

const CREATE_DELIVERY_AGENT_SUCCESSFUL = 'CREATE_DELIVERY_AGENT_SUCCESSFUL';
const CREATE_DELIVERY_AGENT_FAILURE = 'CREATE_DELIVERY_AGENT_FAILURE';
const CREATE_DELIVERY_AGENT_REQUEST = 'CREATE_DELIVERY_AGENT_REQUEST';

const UPDATE_DELIVERY_AGENT_SUCCESSFUL = 'UPDATE_DELIVERY_AGENT_SUCCESSFUL';
const UPDATE_DELIVERY_AGENT_FAILURE = 'UPDATE_DELIVERY_AGENT_FAILURE';
const UPDATE_DELIVERY_AGENT_REQUEST = 'UPDATE_DELIVERY_AGENT_REQUEST';

const GET_DELIVERY_AGENT_BY_ID_SUCCESSFUL = 'GET_DELIVERY_AGENT_BY_ID_SUCCESSFUL';
const GET_DELIVERY_AGENT_BY_ID_FAILURE = 'GET_DELIVERY_AGENT_BY_ID_FAILURE';
const GET_DELIVERY_AGENT_BY_ID_REQUEST = 'GET_DELIVERY_AGENT_BY_ID_REQUEST';

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case UPDATE_DELIVERY_AGENT_REQUEST:
    case GET_DELIVERY_AGENT_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_DELIVERY_AGENT_SUCCESSFUL:
    case GET_DELIVERY_AGENT_BY_ID_SUCCESSFUL:
      return {
        ...state,
        deliveryAgent: payload,
        isLoading: false,
      };
    case GET_DELIVERY_AGENTS_REQUEST:
    case FIND_DELIVERY_AGENTS_BY_LOCATION_REQUEST:
      return {
        ...state,
        deliveryAgents: {
          data: [],
        },
        error: null,
        isLoaded: false,
        isLoading: true,
      };
    case CHECK_ADDRESS_REQUEST:
      return {
        ...state,
        addressIsLoading: true,
      };
    case GET_DELIVERY_AGENTS_SUCCESSFUL:
    case FIND_DELIVERY_AGENTS_BY_LOCATION_SUCCESSFUL:
      return {
        ...state,
        address: payload?.location,
        addressIsLoading: false,
        deliveryAgents: payload?.data,
        error: null,
        isLoaded: true,
        isLoading: false,
      };
    case CHECK_ADDRESS_SUCCESSFUL:
      return {
        ...state,
        address: payload,
        addressIsLoading: false,
        error: null,
      };
    case CREATE_DELIVERY_AGENT_SUCCESSFUL:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
      };
    case UPDATE_DELIVERY_AGENT_FAILURE:
    case GET_DELIVERY_AGENT_BY_ID_FAILURE:
    case GET_DELIVERY_AGENTS_FAILURE:
    case FIND_DELIVERY_AGENTS_BY_LOCATION_FAILURE:
    case CHECK_ADDRESS_FAILURE:
      return {
        ...state,
        addressIsLoading: false,
        error,
        isLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}

/**
 * Selectors
 * */
export const getDeliveryAgentsSelector = (state) => state.deliveryAgentsReducer;

/**
 * Action Creators
 * */
export const getDeliveryAgentsRequest = (payload) => ({
  payload,
  type: FIND_DELIVERY_AGENTS_BY_LOCATION_REQUEST,
});

export const getDeliveryAgentsSummaryRequest = (payload) => ({
  payload,
  type: GET_DELIVERY_AGENTS_REQUEST,
});

export const checkAddressRequest = (payload) => ({
  payload,
  type: CHECK_ADDRESS_REQUEST,
});

export const createDeliveryAgentRequest = (payload) => ({
  payload,
  type: CREATE_DELIVERY_AGENT_REQUEST,
});

export const updateDeliveryAgentRequest = (payload) => ({
  payload,
  type: UPDATE_DELIVERY_AGENT_REQUEST,
});

export const getDeliveryAgentByIdRequest = (payload) => ({
  payload,
  type: GET_DELIVERY_AGENT_BY_ID_REQUEST,
});

/**
 * Sagas
 * */

function* getDeliveryAgentsSummary({ payload }) {
  try {
    const { data } = yield call(() => getDeliveryAgents(payload));
    yield put({
      payload: { data, location: {} },
      type: GET_DELIVERY_AGENTS_SUCCESSFUL,
    });
  } catch (error) {
    yield put({
      error,
      type: GET_DELIVERY_AGENTS_FAILURE,
    });
  }
}

function* getDeliveryAgentById({ payload }) {
  try {
    const response = yield call(() => getDeliveryAgentByIdApi(payload));
    yield put({
      payload: response.data,
      type: GET_DELIVERY_AGENT_BY_ID_SUCCESSFUL,
    });
  } catch (error) {
    yield call(() => toastr.error('Somenthing went wrong, please try again'));
    yield put({
      error,
      type: GET_DELIVERY_AGENT_BY_ID_FAILURE,
    });
  }
}

function* checkAddress({ payload }) {
  try {
    const response = yield call(() => getLatitudeLongitude(payload));

    const {
      formatted,
      components: {
        city: metropolitan_city,
        state: state_or_province,
        country_code: country,
      },
      geometry: {
        lat: latitude,
        lng: longitude,
      },
    } = response.data.results[0];

    if (!country || !latitude || !longitude) {
      throw new Error('Invalid address');
    }

    yield put({
      payload: {
        address: formatted,
        country,
        currency: country === 'us' ? 'usd' : 'cad',
        latitude,
        longitude,
        metropolitan_city,
        state_or_province,
      },
      type: CHECK_ADDRESS_SUCCESSFUL,
    });
    yield call(() => toastr.success('Address is valid'));
  } catch (error) {
    yield call(() => toastr.error(
      'Error',
      error.message === 'Invalid address'
        ? 'Somenthing went wrong with the address, please try again or try another address'
        : error.message,
    ));
    yield put({
      error,
      type: CHECK_ADDRESS_FAILURE,
    });
  }
}

function* getDeliveryAgentsByAddress({
  payload: {
    address,
    page = 1,
    ...filters
  },
}) {
  try {
    const response = yield call(() => getLatitudeLongitude(address));
    const { geometry: { lat, lng }, components: { country_code } } = response.data.results[0];
    const { data } = yield call(() => getDeliveryAgentsByLatLong(lat, lng, country_code, page, filters));

    yield put({
      payload: {
        data,
        location: {
          lat,
          lng,
        },
      },
      type: FIND_DELIVERY_AGENTS_BY_LOCATION_SUCCESSFUL,
    });
  } catch ({ message }) {
    yield call(() => toastr.error('Somenthing went wrong with the address, please try again or try another address'));
    yield put({
      error: message,
      type: FIND_DELIVERY_AGENTS_BY_LOCATION_FAILURE,
    });
  }
}

function* createDeliveryAgent({ payload: { data, callback } }) {
  try {
    const response = yield call(() => createDeliveryAgentApi(data));

    yield put({
      payload: response,
      type: CREATE_DELIVERY_AGENT_SUCCESSFUL,
    });

    yield call(() => callback());
    yield call(() => toastr.success('Delivery Agent Created Successfully'));
  } catch ({ message }) {
    yield call(() => toastr.error(message));
    yield put({
      error: message,
      type: CREATE_DELIVERY_AGENT_FAILURE,
    });
  }
}

function* updateDeliveryAgent({ payload: { data, callback } }) {
  try {
    const { status, data: responseData } = yield call(() => updateDeliveryAgentApi(data.id, data));

    if (status === 200) {
      yield call(() => callback());
      yield call(() => toastr.success('Delivery Agent Updated Successfully'));
      yield put({
        payload: responseData,
        type: UPDATE_DELIVERY_AGENT_SUCCESSFUL,
      });
    } else {
      throw new Error('Somenthing went wrong, please try again');
    }
  } catch ({ message }) {
    yield call(() => toastr.error(message));
    yield put({
      error: message,
      type: UPDATE_DELIVERY_AGENT_FAILURE,
    });
  }
}

export function* saga() {
  yield all([
    takeEvery(GET_DELIVERY_AGENTS_REQUEST, getDeliveryAgentsSummary),
    takeEvery(FIND_DELIVERY_AGENTS_BY_LOCATION_REQUEST, getDeliveryAgentsByAddress),
    takeEvery(CHECK_ADDRESS_REQUEST, checkAddress),
    takeEvery(CREATE_DELIVERY_AGENT_REQUEST, createDeliveryAgent),
    takeEvery(UPDATE_DELIVERY_AGENT_REQUEST, updateDeliveryAgent),
    takeEvery(GET_DELIVERY_AGENT_BY_ID_REQUEST, getDeliveryAgentById),
  ]);
}
