import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push, goBack } from 'react-router-redux';
import qs from '../../../modules/normalizingQs';
import Spinner from '../../Shared/Spinner';
import MapCategory from './MapCategory';
import {
  getCategoriesMappingData,
  categoriesMappingSelector,
  updateCategoriesMappingItems,
  deleteCategoriesMappingItems,
} from '../../../ducks/categoriesMapping';
import ScrapersStyle from './style';
import { Button } from '../../Shared';

const basicStateValues = {
  selected: [],
  q: undefined,
  sort: undefined,
};

export class MapCategories extends Component {
  state = {
    ...basicStateValues,
    params: {
      page: this.props.history.location.search,
    },
    modalIsOpen: false,
    q: undefined,
    sort: undefined,
    isDisabled: false,
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }
    const { getCategoriesMappingData } = this.props;

    const { q } = this.getQueryParamsFromUrl();
    this.setState({ q });

    const supplier_id = this.props.match.params.id;

    getCategoriesMappingData(supplier_id);
  }

  getQueryParamsFromUrl = () => {
    const urlParse = qs.parse(this.props.history.location.search);

    return {
      page: urlParse.page || 1,
      per_page: urlParse.perPage,
      q: urlParse.q,
      sort: urlParse.sort,
    };
  };

  onShoppeCategoriesChange = (shoppe_category, remote_category_id, selected_categories) => {
    const { updateCategoriesMappingItems, deleteCategoriesMappingItems } = this.props;
    const supplier_id = this.props.match.params.id;

    if (selected_categories.length === 1 && shoppe_category.length === 0) {
      deleteCategoriesMappingItems(supplier_id, remote_category_id);
    } else {
      updateCategoriesMappingItems(supplier_id, remote_category_id, shoppe_category);
    }
  };

  getSelectedShoppeCategoriesList = (itemsList, itemsIds = []) => {
    const shoppe_categories_ids = itemsIds.map((shoppe_category) => shoppe_category.value);
    if (itemsList !== undefined) {
      return itemsList.filter((item) => shoppe_categories_ids.some((e) => item.value === e));
    }
  };

  button_clickHandler = () => {
  };

  handleReturn = () => {
    this.props.goBack();
  };

  render() {
    const {
      categoriesMapping,
    } = this.props;

    const pageLoaded = categoriesMapping.loaded;

    if (!pageLoaded) {
      return (
        <div className="tags-page">
          <Spinner />
        </div>
      );
    }

    return (
      <ScrapersStyle>
        <div>
          <header className="page-header">
            <h1 className="page-title">CATEGORIES MAPPING</h1>
            <Button
              type="button"
              size="large"
              uppercase
              width={200}
              onClick={this.handleReturn}
            >
              Back to Products
            </Button>
          </header>
          <div className="row categories-mapping-header">
            <div className="col-md-6">
              <p>Supplier Categories</p>
              <span>Categories from this supplier for new products in ETL tables</span>
            </div>
            <div className="col-md-6">
              <p>Furnishr Categories</p>
              <span>List of Furnishr Categories</span>
            </div>
          </div>
          {categoriesMapping.categoriesMappingData.data !== undefined && categoriesMapping.categoriesMappingData.data.scraper_categories.map((category, i) => (
            <MapCategory
              key={i}
              category={category}
              shoppe_categories={categoriesMapping.categoriesMappingData.data.shoppe_categories}
              selected_shoppe_categories={category.selected_shoppe_categories}
              onShoppeCategoriesChange={this.onShoppeCategoriesChange.bind(this)}
              getSelectedShoppeCategoriesList={this.getSelectedShoppeCategoriesList.bind(this)}
            />
          ))}
        </div>
      </ScrapersStyle>
    );
  }
}

export default connect(
  ({ categoriesMapping, authReducer }) => ({
    categoriesMapping: {
      categoriesMappingData: categoriesMappingSelector(categoriesMapping.categoriesMapping),
      loading: categoriesMapping.loading,
      loaded: categoriesMapping.loaded,
      tableLoaded: categoriesMapping.tableLoaded,
    },
    isAuthenticated: authReducer.isAuthenticated,
  }),
  {
    getCategoriesMappingData,
    updateCategoriesMappingItems,
    deleteCategoriesMappingItems,
    push,
    goBack,
  }
)(MapCategories);
