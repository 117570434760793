import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static getRuleOptions() {
    return http({
      method: "GET",
      url: `${API}design_templates/rules/options`
    })
      .then((res) => res.data)
  }

  static getRules() {
    return http({
      method: "GET",
      url: `${API}design_templates/rules`
    })
      .then((res) => res.data)
  }

  static creteNewRule() {
    return http({
      method: "POST",
      url: `${API}design_templates/rules`
    })
      .then((res) => res.data)
  }

  static updateRules(rules) {
    return http({
      method: "PUT",
      url: `${API}design_templates/rules`,
      data: rules
    })
      .then((res) => res.data)
  }

  static deleteRule(id) {
    return http({
      method: "DELETE",
      url: `${API}design_templates/rules/${id}`
    })
      .then((res) => res.data)
  }

  static cloneRule(id) {
    return http({
      method: "GET",
      url: `${API}design_templates/rules/${id}/clone`
    })
      .then((res) => res.data)
  }
}
