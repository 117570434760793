function convertItemToObject(item) {
  const tempObj = {}

  for (const [objKey, objValue] of Object.entries(item)) {
    if (Array.isArray(objValue)) {
      tempObj[objKey] = objValue.map(({ value }) => value)
    }
    else {
      tempObj[objKey] = objValue.value
    }
  }

  return tempObj
}

function convertValuesToObject(values) {
  const tempObj = {}

  for (const [objKey, objValue] of Object.entries(values)) {
    if (Array.isArray(objValue)) {
      tempObj[objKey] = objValue.map(({ value, ...rest }) => {

        if ((rest._destroy === false) || (rest._destroy === true)) {

          if (rest.color_id) {
            return {
              id: value,
              _destroy: rest._destroy,
              color_id: rest.color_id
            }
          }
          else if (rest.texture_id) {
            return {
              id: value,
              _destroy: rest._destroy,
              texture_id: rest.texture_id
            }
          }
          return {
            id: value,
            _destroy: rest._destroy,
            design_rule_id: rest.design_rule_id
          }

        }
        else if (objKey === "design_style_colors_attributes") {
          return { color_id: value }
        }
        else if (objKey === "design_style_textures_attributes") {
          return { texture_id: value }
        }
        return { design_rule_id: value }

      })
    }
    else {
      tempObj[objKey] = objValue
    }
  }

  const rulesSR = window.localStorage.getItem("_destroy-design_style_rules_attributes") ?
    JSON.parse(window.localStorage.getItem("_destroy-design_style_rules_attributes")) : []

  const rulesSC = window.localStorage.getItem("_destroy-design_style_colors_attributes") ?
    JSON.parse(window.localStorage.getItem("_destroy-design_style_colors_attributes")) : []

  const rulesST = window.localStorage.getItem("_destroy-design_style_textures_attributes") ?
    JSON.parse(window.localStorage.getItem("_destroy-design_style_textures_attributes")) : []

  const dataRules = rulesSR.map(({ value, _destroy, design_rule_id }) => ({ id: value, _destroy, design_rule_id }))
  const dataColors = rulesSC.map(({ value, _destroy, color_id }) => ({ id: value, _destroy, color_id }))
  const dataTextures = rulesST.map(({ value, _destroy, texture_id }) => ({
    id: value,
    _destroy,
    texture_id
  }))

  tempObj.design_style_rules_attributes = [...tempObj.design_style_rules_attributes, ...dataRules]
  tempObj.design_style_colors_attributes = [...tempObj.design_style_colors_attributes, ...dataColors]
  tempObj.design_style_textures_attributes = [...tempObj.design_style_textures_attributes, ...dataTextures]

  return tempObj
}

export default function transformValueReduxForm(values) {
  if (Array.isArray(values)) {
    return values.map((item) => convertItemToObject(item))
  }
  else {
    return convertValuesToObject(values)
  }
}
