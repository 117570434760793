import axios from 'axios';

import { apiUrl } from './lt-api';

const suppliersAPI = axios.create({
  baseURL: apiUrl,
  crossDomain: true,
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: localStorage?.getItem('token')?.replace(/"/g, ''),
    'Content-Type': 'application/json',
  },
});

export const getSuppliersApi = (params) => suppliersAPI.get('/suppliers', { params });

export const updateSuppliersApi = (id, payload) => suppliersAPI.put(`/suppliers/${id}`, payload);

export const getSupplierByIdApi = (id) => suppliersAPI.get(`/suppliers/${id}`);

export const getZonesApi = () => suppliersAPI.get('/zones');
