import React from 'react';
import PropTypes from 'prop-types';

// Misc
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import objectToFormData from '../../../utils/objectToFormData';
import transformDataCreate from '../../../utils/transformFloorPlanDataCreate';
import transformDataEdit from '../../../utils/transformFloorPlanDataEdit';

// Redux
import {
  createAvailableFloorPlanRequest,
  updateAvailableFloorPlanRequest,
  getAvailableFloorPlanByIdRequest,
  getFloorPlanRoomInnerDesignRoomsRequest,
  getAvailableFloorPlanRoomInner,
  getAvailableFloorPlanById,
  getFloorPlanRoomInnerDesignRooms,
  getIsLoading,
  getIdCurrentRoom,
} from '../../../ducks/availableFloorPlans';

// Import Components
import { DesignTemplatesLayout } from '../index';
import CreateForm from './DetailCreateForm';
import UpdateForm from './DetailEditForm';
import { convertStatusValueToNumberValue } from '../../../utils/converStatusValueToNumberValue';

FloorPlansDetail.propTypes = {
  // from connect/react-router-redux
  goBack: PropTypes.func,
  // from connect/availableFloorPlansReducer
  createAvailableFloorPlanRequest: PropTypes.func,
  updateAvailableFloorPlanRequest: PropTypes.func,
  getIsLoading: PropTypes.func,
  isLoading: PropTypes.bool,
  roomInner: PropTypes.array,
  roomInnerEdit: PropTypes.object,
};

function FloorPlansDetail({
  getAvailableFloorPlanByIdRequest, goBack, isLoading, roomInner, roomInnerEdit, designRooms,
  createAvailableFloorPlanRequest, updateAvailableFloorPlanRequest, idRoom,
  match: { params: { id } },
}) {
  const isEditMode = id !== 'new';

  const buttonGoBack_clickHandler = function () {
    goBack();
  };

  const form_submitHandler = function (values) {
    if (isEditMode) {
      const editData = transformDataEdit(values, roomInnerEdit.room_inner);
      const editFloorplan = { floorplan: { ...editData } };
      updateAvailableFloorPlanRequest(id, objectToFormData(editFloorplan));
    } else {
      const transformValues = convertStatusValueToNumberValue(values);
      const createData = transformDataCreate(transformValues, designRooms);
      const createFloorplan = { floorplan: { ...createData, room_id: idRoom || null } };
      createAvailableFloorPlanRequest(objectToFormData(createFloorplan));
    }
  };

  const getItem = () => {
    if (id !== 'new') {
      getAvailableFloorPlanByIdRequest(id);
    }
  };

  return (
    <DesignTemplatesLayout
      isLoading={isLoading}
      pageTitle="floor plan"
      getItem={getItem}
      button_clickHandler={buttonGoBack_clickHandler}
      buttonTitle="Go back"
    >
      {isEditMode ? <UpdateForm onSubmit={form_submitHandler} /> : <CreateForm onSubmit={form_submitHandler} />}
    </DesignTemplatesLayout>
  );
}

// Enhancer
const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
  idRoom: getIdCurrentRoom(state),
  roomInner: getAvailableFloorPlanRoomInner(state),
  designRooms: getFloorPlanRoomInnerDesignRooms(state),
  roomInnerEdit: getAvailableFloorPlanById(state),
});
const mapDispatchToProps = {
  goBack,
  createAvailableFloorPlanRequest,
  updateAvailableFloorPlanRequest,
  getAvailableFloorPlanByIdRequest,
  getFloorPlanRoomInnerDesignRoomsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlansDetail);
