import React from 'react';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
// Actions
import { loginRequest } from '../../ducks/auth';
// Utils
import transformObjectToFormData from '../../utils/objectToFormData';
// Components
import AuthPageStyle from './style';
import LoginForm from '../../Components/Auth/LoginForm';

function AuthPage({ loginRequest }) {
  const onSubmitLoginForm = (values) => {
    const user = { user: values };
    loginRequest(transformObjectToFormData(user));
  };

  return (
    <AuthPageStyle>
      <div className="auth__box">
        <h1 className="auth__title">Who you are?</h1>
        <LoginForm onSubmit={onSubmitLoginForm} />
      </div>
    </AuthPageStyle>
  );
}

// Enhance
const mapStateToProps = null;
const mapDispatchToProps = { loginRequest };

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(AuthPage);
