import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import {
  calculateRoomVariant,
  cloneRoom,
  createRoom,
  createRoomVariant,
  currentRoomVariantSelector,
  deleteRoom,
  deleteRoomVariant,
  getRoom,
  getRoomOptions,
  getRoomVariant,
  loadingForVariantSelector,
  loadingRoomOptionsSelector,
  loadingSelector,
  roomOptionsSelector,
  roomSelector,
  saveRoomTypeId,
  updateRoom,
  updateRoomVariant,
} from '../../ducks/room';

import Spinner from '../../Components/Shared/Spinner';
import Header from '../../Components/Room/Header';
import VariantsList from '../../Components/Room/VariantsList';
import snakeCaseToSplittedCap from '../../utils/snakeCaseToSplittedCap';
import objectRemoveExtraFields from '../../utils/objectRemoveExtraFields';
import {
  getAvailableFloorPlans,
  getAvailableFloorPlansRequest,
  getFloorPlanRoomInnerDesignRooms,
  getFloorPlanRoomInnerDesignRoomsRequest,
  getIdCurrentRoomAction,
} from '../../ducks/availableFloorPlans';
import { newVariantMockData } from '../../__mocks__/newRoomVariantData';
import { getPriceBreakdownCsvFile } from '../../api/download-file';
import { getDeliveryAgentsRequest, getDeliveryAgentsSelector } from '../../ducks/deliveryAgents';

export class Room extends Component {
  state = {
    address: '',
    alreadyExistSameSkuPerVariant: false,
    createNewVariant: { ...newVariantMockData },
    currentPlan: {},
    deliveryAgent: null,
    missingRequiredFields: false,
    missingRequiredFieldsPerVariant: false,
    room: {
      product: {
        active: false,
      },
      product_category_ids: [],
      room: {
        room_variants: [],
      },
    },
    roomVariant: null,
    room_categories: null,
    room_tag_ids: null,
    styles: null,
  };

  static getDerivedStateFromProps(props, state) {
    /**
     this condition need for prevent overwriting the new state with old props
     */
    if (state.isMounted === true) {
      /**
       the condition necessary so that it does not start before mounting
       */
      if (state.initialFloorPlanId === undefined && state.initialRoomVariants === undefined) {
        if (props.room) {
          return {
            initialFloorPlanId: props.room.room.floorplan_id,
            initialRoomVariants: props.room.room.room_variants,
            room: props.room || [],
          };
        }
      }
      if (
        state.initialRoomCategories === undefined
        && state.initialStyles === undefined
        && state.initialFloorPlan === undefined
      ) {
        if (props.roomOptions) {
          return {
            initialFloorPlan: props.roomOptions.floorplans,
            initialRoomCategories: props.roomOptions.room_categories,
            initialStyles: props.roomOptions.styles,
            room_categories: props.roomOptions.room_categories || [],
            styles: props.roomOptions.styles || [],
          };
        }
      }
      if (state.initialRoomVariant === undefined) {
        if (props.roomVariant) {
          return {
            initialRoomVariant: props.roomVariant,
            roomVariant: props.roomVariant,
          };
        }
      }
    }
    return null;
  }

  componentDidMount() {
    const {
      getRoom,
      getRoomOptions,
      getFloorPlanRoomInnerDesignRoomsRequest,
      getRoomVariant,
      match: { params },
    } = this.props;

    if (params.vid) {
      getRoom(params.id);
      getRoomVariant(+params.id, +params.vid);
      if (+params.vid === 0) {
        this.setState({
          roomVariant: this.state.createNewVariant,
        });
      }
    } else {
      getRoom(params.id);
      getRoomOptions();
      getFloorPlanRoomInnerDesignRoomsRequest();
    }
    this.setState({
      isMounted: true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      initialRoomVariants,
      initialRoomVariant,
      room: {
        room: { room_variants },
      },
    } = this.state;
    if (
      initialRoomVariants !== undefined
      && room_variants !== this.props.room.room.room_variants
      && room_variants.length === this.props.room.room.room_variants.length
      && prevState.room.room.room_variants === room_variants
    ) {
      this.setState({
        room: {
          ...this.state.room,
          room: {
            ...this.state.room.room,
            room_variants: this.props.room.room.room_variants,
          },
        },
      });
    } else if (
      initialRoomVariants !== undefined
      && room_variants.length !== this.props.room.room.room_variants.length
    ) {
      /**
       This condition need for update the page when user cloned the room
       */
      const isNewVariant = this.getCheckForNewVariant(room_variants);
      if (!isNewVariant) {
        this.setState({
          room: {
            ...this.state.room,
            room: {
              ...this.state.room.room,
              room_variants: this.props.room.room.room_variants,
            },
          },
        });
      }
    } else if (
      initialRoomVariant !== undefined
      && this.state.roomVariant !== this.props.roomVariant
      && prevState.roomVariant === this.state.roomVariant
    ) {
      this.setState({
        roomVariant: this.props.roomVariant,
      });
    }
    if (
      initialRoomVariant !== undefined
      && this.props.match.params.vid !== '0'
      && prevProps.match.params.vid !== this.props.match.params.vid
      && this.state.roomVariant !== this.props.roomVariant
    ) {
      this.setState({
        roomVariant: this.props.roomVariant,
      });
    }
  }

  getCheckForNewVariant = (variants) => variants.some((variant) => variant.id === 0);

  handleDelete = () => {
    const { id } = this.props.match.params;
    const toastrConfirmOptions = {
      onCancel: () => {},
      onOk: () => this.props.deleteRoom(id),
    };
    toastr.confirm('Are you sure?', toastrConfirmOptions);
  };

  handleDublicate = (e) => {
    const { id } = this.props.match.params;
    const btn = e.target;
    btn.disabled = true;
    this.props.cloneRoom(id, btn);
  };

  // Handle Active change
  handleActiveChange = () => {
    this.setState({
      room: {
        ...this.state.room,
        product: { ...this.state.room.product, active: !this.state.room.product.active },
      },
    });
  };

  // handle Input Change
  handleInputChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    this.setState({
      room: {
        ...this.state.room,
        product: { ...this.state.room.product, [`${name}`]: value },
      },
    });
  };

  // Manage Variants
  // Handle Variant Switcher change
  handleVariantSwitcherChange = (value, child, id, type) => {
    const variant = this.state.roomVariant;
    const newVariant = () => {
      if (variant.id !== +id) {
        variant[type] = false;
      } else {
        variant[type] = !variant[type];
      }
      return variant;
    };

    this.setState({
      roomVariant: newVariant(),
    });
  };

  handleAddNewVariant = () => {
    this.setState(
      {
        room: {
          ...this.state.room,
          room: {
            ...this.state.room.room,
            room_variants: [...this.state.room.room.room_variants, { id: 0, name: '' }],
          },
        },
        roomVariant: this.state.createNewVariant,
      },
      () => this.props.push(`/rooms/${this.state.room.room.product_id}/variant/${this.state.roomVariant.id}`)
    );
  };

  updateImage = (file, url, index) => {
    const images = {};
    images[this.getFileKey(index + 1)] = file || '';
    if (!file) {
      images[this.getUrlKey(index + 1)] = url || '';
    }
    return images;
  };

  handleImagesChange = (file, url, index, id) => {
    let { product } = this.state.room;
    const variant = this.state.roomVariant;
    const updatedImages = this.updateImage(file, url, index);
    if (id !== undefined) {
      const newVariant = { ...variant, ...updatedImages };
      this.setState({
        roomVariant: newVariant,
      });
    } else {
      product = { ...product, ...updatedImages };
      this.setState({
        room: {
          ...this.state.room,
          product,
        },
      });
    }
  };

  // Manage categories
  updatedCategories = (room_categories) => room_categories.map(({ id, name }) => ({ label: name, value: id }));

  getSelectedList = (itemsList, itemsIds = []) => itemsList.filter((item) => itemsIds.some((e) => item.value === e));

  addCategory = (category) => this.setState({
    room: {
      ...this.state.room,
      product_category_ids: [...this.state.room.product_category_ids, category],
    },
  });

  removeCategory = (category) => this.setState({
    room: {
      ...this.state.room,
      product_category_ids: [...this.state.room.product_category_ids.filter((id) => id !== category)],
    },
  });

  handleCategoryChange = (category, event) => {
    event ? this.removeCategory(category) : this.addCategory(category);
  };

  // Manage styles
  updatedStyles = (styles) => styles.map(({ value }) => ({ label: snakeCaseToSplittedCap(value), value }));

  getSelectedStyle = (itemsList, itemsIds = []) => itemsList.filter((item) => item.value === itemsIds);

  handleStyleChange = (element) => {
    this.setState({
      room: {
        ...this.state.room,
        product: {
          ...this.state.room.product,
          style: element.value,
        },
      },
    });
  };

  onSelectFloorPlan = async (plan) => {
    await this.setState({
      room: {
        ...this.state.room,
        room: {
          ...this.state.room.room,
          floorplan_id: plan.value,
        },
      },
    });
    await this.handleSave('update');
  };

  // Handle Floor Image Change
  handleFloorImageChange = (file, url) => {
    let { room } = this.state.room;
    room = { ...room, background_image: file || '' };
    if (!file) {
      room = { ...room, url_background_image: url || '' };
    }
    this.setState({
      room: {
        ...this.state.room,
        room,
      },
    });
  };

  // Handle Variants change
  handleVariantsChange = (value, id, type) => {
    if (type === 'sku') {
      this.setState({
        alreadyExistSameSkuPerVariant: false,
      });
    }

    const variant = this.state.roomVariant;
    const { room_variants } = this.state.room.room;

    const newVariant = () => {
      if (variant.id === id) {
        variant[type] = value;
      }
      return variant;
    };

    let newVariants = [];

    if (type === 'name') {
      newVariants = room_variants.map((item) => {
        if (item.id === id) {
          item.name = value;
        }
        return item;
      });
    }

    if (type === 'active') {
      const variant_id = parseInt(id, 10);
      newVariants = room_variants.map((item) => {
        if (item.id === variant_id) {
          item.active = value;
        }
        return item;
      });
    }

    if (type === 'default') {
      const variant_id = parseInt(id, 10);
      newVariants = room_variants.map((item) => {
        if (item.id === variant_id) {
          item.default = value;
        }
        return item;
      });
    }

    this.setState({
      room: {
        ...this.state.room,
        room: {
          ...this.state.room.room,
          room_variants: newVariants.length !== 0 ? newVariants : room_variants,
        },
      },
      roomVariant: newVariant(),
    });
  };

  handleQuantity = (value, id, type, productId, roomId, isChanged) => {
    const variant = this.state.roomVariant;
    const getNewVariant = () => {
      if (variant.id === id) {
        variant.products.map((room) => {
          room.values.map((product) => {
            if (product !== null && room.design_room_id === roomId && product.id === productId) {
              product.quantity = value;
              product.isChanged = isChanged;
            }
            return product;
          });
          return room;
        });
      }
      return variant;
    };

    const newVariant = getNewVariant();

    this.setState({
      roomVariant: newVariant,
    });
  };

  onDesignRoomSwitcherChange = (id, roomId, active) => {
    const variant = this.state.roomVariant;
    const getNewVariant = () => {
      if (variant.id === id) {
        variant.products.map((room) => {
          if (room.design_room_id === roomId) {
            room.active = !active;
          }
          return room;
        });
      }
      return variant;
    };

    const newVariant = getNewVariant();

    this.setState({
      roomVariant: newVariant,
    });
  };

  handleMoveChecked = (id, type, productId, roomId, is_checked_for_move) => {
    const variant = this.state.roomVariant;
    const getNewVariant = () => {
      if (variant.id === id) {
        variant.products.map((room) => {
          room.values.map((product) => {
            if (product !== null && room.design_room_id === roomId && product.id === productId) {
              product.is_checked_for_move = is_checked_for_move;
            }
            return product;
          });
          return room;
        });
      }
      return variant;
    };

    const newVariant = getNewVariant();

    this.setState({
      roomVariant: newVariant,
    });
  };

  getSelectedTagsList = (itemsList, itemsIds = []) => {
    const tags_ids = itemsIds.map((tag) => tag.value);
    if (itemsList !== undefined) {
      return itemsList.filter((item) => tags_ids.some((e) => item.value === e));
    }
  };

  handleTagsChange = (tag, variant_id) => {
    const value = tag;
    const type = 'selected_tags';
    const variant = this.state.roomVariant;
    const { room_variants } = this.state.room.room;

    const newVariant = () => {
      if (variant.id === variant_id) {
        variant[type] = value;
      }
      return variant;
    };

    const newVariants = [];

    this.setState({
      room: {
        ...this.state.room,
        room: {
          ...this.state.room.room,
          room_variants: newVariants.length !== 0 ? newVariants : room_variants,
        },
      },
      roomVariant: newVariant(),
    });
  };

  handleCalculateVariant = (id) => {
    this.props.calculateRoomVariant(id);
  };

  handleTabClick = (tabIndex) => {
    const {
      match: {
        params: { id, vid },
      },
      push,
    } = this.props;
    if (typeof tabIndex === 'string') {
      if (this.state.isRoomsOptions === undefined) {
        this.props.getRoomOptions();
        this.props.getFloorPlanRoomInnerDesignRoomsRequest();
        this.setState({ isRoomsOptions: true });
      }
      push(`/rooms/${id}`);
    } else {
      if (vid === '0') {
        this.setState({
          room: {
            ...this.state.room,
            room: {
              ...this.state.room.room,
              room_variants: this.props.room.room.room_variants,
            },
          },
        });
      }
      this.props.getRoomVariant(+id, tabIndex);
    }
  };

  // Manage Images
  // Get Images List for the Images Component
  getUrlKey = (key) => (key === 1 ? 'url_default_image' : `url_image${key}`);

  getFileKey = (key) => (key === 1 ? 'default_image' : `image${key}`);

  getImageData = (file) => (file.type ? URL.createObjectURL(file) : file.medium.url);

  getImagesList = (data) => {
    const indexList = [1, 2, 3, 4];
    return indexList.map((key) => {
      const url = data[`${this.getUrlKey(key)}`];
      const file = data[`${this.getFileKey(key)}`];
      const isFile = (file && file.type) || (file && file.medium.url);
      return isFile ? this.getImageData(file) : url;
    });
  };

  getCurrentFloorPlanImage = () => {
    const currentPlan = this.props.fullFloorPlans
      ? this.props.fullFloorPlans.filter((plan) => plan.value === this.state.room.room.floorplan_id)
      : [];
    if (currentPlan.length === 0) return false;
    return currentPlan[0].image_url.url;
  };

  getFloorPlanImage = (data) => {
    const newUrl = this.getCurrentFloorPlanImage();
    const file = data.background_image;
    const isFile = (file && file.type) || (file && file.medium.url);
    const defaultPicture = isFile && this.getImageData(file);
    return newUrl || defaultPicture;
  };

  // Prepare for saving
  // Update the products room part with added/removed images
  getProductObject = (data) => {
    const productToUpdate = { ...data };
    const indexList = [1, 2, 3, 4, 5, 6];
    const result = {};
    indexList.map((key) => {
      const urlKey = `${this.getUrlKey(key)}`;
      const fileKey = `${this.getFileKey(key)}`;
      const url = productToUpdate[urlKey];
      const file = productToUpdate[fileKey];
      // This IF is triggered for the image,
      // which was not changed and though could be deleted
      // from the payload of the api put request on room save
      if (file && file.url) {
        objectRemoveExtraFields([urlKey, fileKey], productToUpdate);
      }
      // This IF is triggered for the image,
      // uploaded with local file
      else if (file && file.type) {
        result[fileKey] = file;
        result[urlKey] = null;
      }
      // This IF is triggered for the image,
      // uploaded with url
      else if (url && url.length) {
        result[urlKey] = url;
        result[fileKey] = null;
      }
      // This IF is triggered if none of the above cases happened
      // so user wan't to remove the image
      else {
        result[`remove_${this.getFileKey(key)}`] = true;
        result[fileKey] = null;
        result[urlKey] = null;
      }
      return true;
    });

    return { ...productToUpdate, ...result };
  };

  // Update the rooms room part with added/removed images
  getRoomObject = (data) => {
    const result = {
      floorplan_id: data.floorplan_id,
      room_variants: data.room_variants,
    };
    const file = data.background_image;
    const url = data.url_background_image;
    // This IF is triggered for the image,
    // which was not changed and though could be deleted
    // from the payload of the api put request on room save
    if (file && file.url) {
      return result;
    }
    // This IF is triggered for the image,
    // uploaded with local file
    if (file && file.type) {
      result.background_image = file;
    }
    // This IF is triggered for the image,
    // uploaded with url
    else if (url && url.length) {
      result.url_background_image = url;
    }
    // This IF is triggered if none of the above cases happened
    // so user wan't to remove the image
    else {
      result.remove_background_image = true;
      result.background_image = null;
      result.url_background_image = null;
    }
    return result;
  };

  // Update the variant with prices in the correct keys
  getPriceObject = (data) => ({
    cad_price: data.msrp_cad,
    usd_price: data.msrp_usd,
  });

  validate = (e) => {
    const value = Array.isArray(e) ? e[0] : e;
    const isNotValid = this.state.missingRequiredFields && !value;
    return isNotValid ? 'is-invalid' : '';
  };

  validateVariant = (e) => {
    const isNotValid = this.state.missingRequiredFieldsPerVariant && !e;
    const isNotUniqueSku = this.state.alreadyExistSameSkuPerVariant;

    if (isNotValid) {
      return 'is-invalid';
    }

    if (isNotUniqueSku) {
      return 'is-not-unique';
    }

    return '';
  };

  csvPriceBreakdown = (id) => {
    getPriceBreakdownCsvFile(id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const today = new Date();
      const date_time = `${today.getFullYear()}_${today.getMonth() + 1}_${today.getDate()
      }-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
      const file_name = `price_breakdown-${date_time}.csv`;
      link.href = url;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    });
  };

  hasRequiredFields = () => {
    const { product_category_ids } = this.state.room;
    const { name, sku } = this.state.room.product;
    const requiredFields = product_category_ids[0] && name && sku;
    return !!requiredFields;
  };

  variantHasRequiredFields = () => {
    const { name, sku } = this.state.roomVariant;
    const requiredFields = name && sku;
    return !!requiredFields;
  };

  getCurrentFloorPlanData = () => {
    const currentPlan = this.state.initialFloorPlan
      ? this.props.roomOptions.floorplans.filter((plan) => plan.value === this.state.room.room.floorplan_id)
      : [];
    if (currentPlan.length === 0) return false;
    return currentPlan[0];
  };

  handleSave = async (typeOfUpdate, isShowMessage) => {
    const floorPlanData = this.getCurrentFloorPlanData();
    await this.setState({
      room: {
        ...this.state.room,
        product: {
          ...this.state.room.product,
          depth: floorPlanData ? floorPlanData.height : this.state.room.product.depth,
          width: floorPlanData ? floorPlanData.width : this.state.room.product.width,
        },
        room: {
          ...this.state.room.room,
          floorplan_id: floorPlanData ? this.state.room.room.floorplan_id : null,
        },
      },
    });
    if (!this.hasRequiredFields()) {
      this.setState({
        missingRequiredFields: true,
      });
      return;
    }
    const { room } = this.state;
    const { id } = this.props.match.params;
    const { updateRoom, createRoom } = this.props;
    if (room.room.product_id === undefined && typeOfUpdate === 'update') {
      return false;
    }
    const formData = {
      ...room,
      product: this.getProductObject(room.product),
      room: this.getRoomObject(room.room),
    };

    if (parseInt(id, 10)) {
      updateRoom(id, formData, isShowMessage);
    } else {
      createRoom(formData);
    }
  };

  handleVariantSave = () => {
    if (!this.variantHasRequiredFields()) {
      this.setState({
        missingRequiredFieldsPerVariant: true,
      });
      return;
    }

    const variantToSave = this.state.roomVariant;
    const { updateRoomVariant, createRoomVariant, match: { params: { id } } } = this.props;
    const productsList = [];
    const designRoomList = [];

    variantToSave?.products?.map((room) => {
      if (room) {
        designRoomList.push({ design_room_id: room.design_room_id });
        designRoomList.push({ active: room.active });
      }
      room.values.map((product) => {
        if (product) {
          productsList.push({ product_id: product.id });
          productsList.push({ product_count: product.quantity });
          productsList.push({ design_room_id: room.design_room_id });
          if (!product.isChanged) {
            productsList.push({ is_changed: false });
          } else {
            productsList.push({ is_changed: product.isChanged });
          }
        }
        return productsList;
      });
      return room;
    });

    const formData = {
      design_rooms: designRoomList,
      product: {
        ...this.getProductObject({ ...variantToSave, products: null, tags: null }),
        ...this.getPriceObject(variantToSave),
      },
      products: variantToSave.products && variantToSave.products.length ? productsList : null,
      room_variant: {
        default: this.state.roomVariant.default,
        is_furniture_layout: this.state.roomVariant.is_furniture_layout,
        use_room_preset: false,
      },
      tags: null,
    };

    if (parseInt(variantToSave.id, 10)) {
      updateRoomVariant(id, variantToSave.id, formData);
    } else {
      createRoomVariant(id, formData);
      this.setState({
        createNewVariant: { ...newVariantMockData },
      });
    }
  };

  handleVariantDelete = () => {
    const { id, vid } = this.props.match.params;
    const toastrConfirmOptions = {
      onCancel: () => {},
      onOk: () => this.props.deleteRoomVariant(id, vid),
    };
    toastr.confirm('Are you sure?', toastrConfirmOptions);
  };

  createNewPlan = async (isCreateFloorPlan) => {
    const { match, push, getIdCurrentRoomAction } = this.props;
    const isShowMessage = !isCreateFloorPlan;
    this.handleSave('update', isShowMessage);
    getIdCurrentRoomAction(+match.params.id);
    push('/design-templates/detail/floor-plans/new');
  };

  editPlan = () => {
    const { match, push, getIdCurrentRoomAction } = this.props;
    this.handleSave('update', false);
    getIdCurrentRoomAction(+match.params.id);
    const currentIdPlan = this.state.room.room.floorplan_id;
    push(`/design-templates/detail/floor-plans/${currentIdPlan}`);
  };

  render() {
    const {
      getDeliveryAgentsRequest,
      match: { params },
      loadingVariant,
      loading,
      loadingRoomOptions,
    } = this.props;

    const {
      address,
      missingRequiredFields,
      room: {
        product: { name, active, permalink },
        room: { room_variants },
      },
      roomVariant,
    } = this.state;

    const handleChangeAddress = (e) => this.setState({
      address: e.target.value,
    });

    const findAgents = () => getDeliveryAgentsRequest({
      address,
    });

    if (loadingVariant || loading || (loadingRoomOptions && !params.vid)) {
      return <Spinner />;
    }

    return (
      <div className="room-page">
        <div className="room-page__header">
          <Header
            name={name}
            permalink={permalink}
            active={active}
            findAgents={findAgents}
            address={this.state.address}
            onChange={handleChangeAddress}
            deliveryAgent={this.props.deliveryAgent}
            deliveryAgentsReducer={this.props.deliveryAgentsReducer}
            onDelete={this.handleDelete.bind(this)}
            onDublicate={this.handleDublicate.bind(this)}
            onActiveChange={this.handleActiveChange.bind(this)}
            onChangeEditableInputs={this.handleInputChange.bind(this)}
            onValidate={this.validate.bind(this)}
          />
        </div>
        <div className="room-page__list-container">
          <VariantsList
            roomId={params.id}
            roomOptions={this.props.roomOptions}
            editPlan={this.editPlan}
            createNewPlan={this.createNewPlan}
            onSelectFloorPlan={this.onSelectFloorPlan}
            getSelectedList={this.getSelectedList}
            getSelectedStyle={this.getSelectedStyle}
            updatedCategories={this.updatedCategories}
            updatedStyles={this.updatedStyles}
            room={this.state.room}
            saveRoomTypeId={this.props.saveRoomTypeId}
            missingRequiredFields={missingRequiredFields}
            variants={room_variants}
            roomVariant={roomVariant}
            getImagesList={this.getImagesList}
            getFloorPlanImage={this.getFloorPlanImage}
            onImagesChange={this.handleImagesChange.bind(this)}
            onVariantSwitcherChange={this.handleVariantSwitcherChange.bind(this)}
            onCategoryChange={this.handleCategoryChange.bind(this)}
            onStyleChange={this.handleStyleChange.bind(this)}
            onFloorImageChange={this.handleFloorImageChange.bind(this)}
            onVariantsChange={this.handleVariantsChange.bind(this)}
            onQuantityChange={this.handleQuantity.bind(this)}
            onDesignRoomSwitcherChange={this.onDesignRoomSwitcherChange.bind(this)}
            onMoveChecked={this.handleMoveChecked.bind(this)}
            onTagsChange={this.handleTagsChange.bind(this)}
            getSelectedTagsList={this.getSelectedTagsList.bind(this)}
            onCalculateVariant={this.handleCalculateVariant.bind(this)}
            onAddNewVariant={this.handleAddNewVariant.bind(this)}
            onTabClick={this.handleTabClick.bind(this)}
            onInputChange={this.handleInputChange.bind(this)}
            onValidate={this.validate.bind(this)}
            onVariantValidate={this.validateVariant.bind(this)}
            onCsvPriceBreakdown={this.csvPriceBreakdown.bind(this)}
            onSave={this.handleSave.bind(this)}
            onVariantDelete={this.handleVariantDelete.bind(this)}
            onVariantSave={this.handleVariantSave.bind(this)}
            push={this.props.push}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    deliveryAgentsReducer: getDeliveryAgentsSelector(state),
    designRooms: getFloorPlanRoomInnerDesignRooms(state),
    fullFloorPlans: getAvailableFloorPlans(state),
    loading: loadingSelector(state),
    loadingRoomOptions: loadingRoomOptionsSelector(state),
    loadingVariant: loadingForVariantSelector(state),
    room: roomSelector(state),
    roomOptions: roomOptionsSelector(state),
    roomVariant: currentRoomVariantSelector(state),
  }),
  {
    calculateRoomVariant,
    cloneRoom,
    createRoom,
    createRoomVariant,
    deleteRoom,
    deleteRoomVariant,
    getAvailableFloorPlansRequest,
    getDeliveryAgentsRequest,
    getFloorPlanRoomInnerDesignRoomsRequest,
    getIdCurrentRoomAction,
    getPriceBreakdownCsvFile,
    getRoom,
    getRoomOptions,
    getRoomVariant,
    push,
    saveRoomTypeId,
    updateRoom,
    updateRoomVariant,
  }
)(Room);
