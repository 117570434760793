import styled from "styled-components"

export default styled.section`
  padding: 10px;
  
  .filter__title {
    margin-bottom: 15px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
  }
  
  .filter__categories {
    max-height: 350px;
    overflow-x: auto;
  }
  
  .filter__button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 15px;
  }
  .room_filter_content{
    width: 950px;
  }
  .categories-form__table-input-budget-from{
    width: inherit;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-left: 8px;
  }
  .categories-form__table-input-budget-to{
    width: inherit;
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    min-height: 38px;
    padding-left: 8px;
  }
  .pseudo-select {
    min-height: 55px;
    background-color: #f7f7f7;
    margin-bottom: 18px;
    position: relative;
    padding: 6px 50px 6px 10px;
    font-size: 14px;
    color: grey;
  }
`