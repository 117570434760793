import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { push } from 'react-router-redux';

class TagListOptions extends Component {
  render() {
    const {
      onRemoveItem,
      isDisabled,
      shouldCreate,
    } = this.props;
    return (
      <div className="top-section-links">
        <div className="top-section-links-first">
          {shouldCreate && (
            <Link className="top-section-links-first-create" to="/tags/0">
              CREATE
            </Link>
          )}
          {onRemoveItem && (
            <button
              className="top-section-links-second-delete top-section-links-second-common"
              onClick={onRemoveItem}
              disabled={isDisabled}
            >
              DELETE
            </button>
          )}
        </div>
      </div>
    );
  }
}

// Enhancer
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { push };

export default connect(mapStateToProps, mapDispatchToProps)(TagListOptions);
