import React, { Component } from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons"

import Checkbox from "../Checkbox"
import Spinner from "../../../Components/Shared/Spinner"
import NotFound from "../../../Components/Shared/NotFound"
import tableTitles from "../../../constants/tableHeaders"
import { Pagination } from "../index"
import ClassNames from "classnames"


class InventoryTable extends Component {
  state = {
    isSelectedAll: false,
    screenHeight: 0
  }

  componentDidMount() {
    this.setState({
      screenHeight: document.documentElement.clientHeight
    })
  }

  titles = () => tableTitles[this.props.type]


  showSortBtn = (col) => this.props.withSorting && col !== "action" && col !== "checkbox"

  handleSort = (val) => {
    if (this.props.sortBy === val && this.props.changeSortDirection === "asc") {
      this.props.changeSortDirection("desc")
    } else if (this.props.sortBy === val) {
      this.props.changeSortDirection("asc")
    } else {
      this.props.changeSortBy(val)
      this.props.changeSortDirection("asc")
    }
  }

  handleSelect = (id, val) => {
    let selectedItems = this.props.selected
    if (val) {
      selectedItems.push(id)
    } else {
      selectedItems = selectedItems.filter((user) => user !== id)
    }
    this.props.handleSelectItems(selectedItems)
  }

  renderOneColumnHeader = (col, i) => {
    const { isSelectedAll } = this.state
    const { type } = this.props
    return col === "checkbox" ? (
      <Checkbox key={i} controlFunc={this.selectAll} value={isSelectedAll} />
    ) : (
      <th key={i} className={this.renderClassNames(col)}>
        <div className="furnishr-table__header-cell-content-wrapper">
          <span>{this.titles()[col]}</span>

          {type !== "dataStatus" && col !== "action" && (
            <div className="furnishr-table__sorting-toggle-arrows">
              <FontAwesomeIcon
                onClick={() => this.props.onSortChange(col)}
                icon={faSortUp}
                alt="profile-options-button-image"
              />
              <FontAwesomeIcon
                onClick={() => this.props.onSortChange(`-${col}`)}
                icon={faSortDown}
                alt="profile-options-button-image"
              />
            </div>
          )}
        </div>
      </th>
    )
  }

  renderOneCell = (col, item) => {
    if (col === "checkbox") {
      return (
        <div className={"furnishr-table__checkbox-cell"}>
          <Checkbox
            controlFunc={(val) => this.handleSelect(item.id, val)}
            value={Boolean(this.props.selected.find((s) => s === item.id))}
          />
        </div>
      )
    } else if (col === "active") {
      return item.active ? "Yes" : "No"
    }
    else {
      return item[col] ? item[col] : <span>&mdash;</span>
    }
  }

  renderClassNames = (col) => {
    const hasFields = col === "colors" || col === "materials" || col === "stock_availability"
    return ClassNames({
      "furnishr-table__cell": true,
      "dataStatus-cell": this.props.type === "dataStatus",
      "d-none": hasFields,
      "d-xl-table-cell": hasFields,
      "furnishr-table__cell_thumbnail": col === "thumbnail_image"
    })
  }

  selectAll = () => {
    let { isSelectedAll } = this.state
    let { items, handleSelectItems } = this.props

    this.setState({
      isSelectedAll: !isSelectedAll
    })

    if (!isSelectedAll) {
      items.map((item) => this.handleSelect(item.id, !isSelectedAll))
    } else {
      handleSelectItems([])
    }
  }

  render() {
    const {
      currentPage,
      totalEntries,
      perPage,
      renderColumns,
      tableLoaded,
      noResults,
      items,
      type,
      paginationChange
    } = this.props
    const pages = Math.ceil(totalEntries / perPage)

    if (!tableLoaded) {
      return <Spinner />
    }
    if (noResults) {
      return <NotFound />
    }

    return (
      <section className={"table-responsive"}>
        <table className={"table"}>
          <thead>
          <tr className={"furnishr-table__header"}>
            {renderColumns.map((col, i) => this.renderOneColumnHeader(col, i))}
          </tr>
          </thead>
          <tbody>
          {items.map((item, id) => (
            <tr key={id} className={"furnishr-table__row"}>
              {renderColumns.map((col, i) => (
                <td key={i} className={this.renderClassNames(col)}>
                  {this.renderOneCell(col, item)}
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
        {type === "dataStatus" && (
          <Pagination activePage={currentPage} pages={pages} onPageChange={(page) => paginationChange(page)} />
        )}
      </section>
    )
  }
}

InventoryTable.propTypes = {
  items: PropTypes.array,
  renderColumns: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  withSorting: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  changeSortBy: PropTypes.func,
  changeSortDirection: PropTypes.func,
  selected: PropTypes.array,
  handleSelectItems: PropTypes.func,
  onEditItem: PropTypes.func.isRequired,
  onSortChange: PropTypes.func
}

export default InventoryTable
