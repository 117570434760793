/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import equal from 'deep-equal';

import { toastr } from 'react-redux-toastr';
import CheckboxTree from 'react-checkbox-tree';
import qs from '../../modules/normalizingQs';
import {
  getProducts, productsSelector, deleteItems, assignCategoryToItems,
} from '../../ducks/products';
import { getFilterOptions, filterSelector } from '../../ducks/filter';
import { showModal, closeModal } from '../../ducks/modal';

import FilteringSection from '../../Components/Filtering';
import TopSection from '../../Components/Shared/TopSection';
import Pagination from '../../Components/Shared/Pagination';
import Spinner from '../../Components/Shared/Spinner';

import StyledComponent from './style';
import ScraperForm from '../../Components/Products/ScraperForm';

import { WAIT_INTERVAL } from '../../constants';
import { Button, ModalWindow } from '../../Components/Shared';
import { getScraperProductByUrl, getScraperManyProductsByUrl } from '../../api';
import ScraperSkuForm from '../../Components/Products/ScraperSkuForm';
import ProductView from '../../Components/ProductView';

import './index.scss';

const renderColumns = [
  'checkbox',
  'thumbnail_image',
  'name',
  'supplier',
  'sku',
  'cost_usd',
  'cost_cad',
  'width',
  'depth',
  'height',
  'last_updated_date',
  'stock_number',
];

const breadcrumbsItems = [
  {
    name: 'shoppe',
    to: '/',
  },
  {
    name: 'products',
    to: '/products',
  },
];

const basicStateValues = {
  categories: [],
  colors: [],
  countries: [],
  expandedCategories: [],
  filter_currency: '',
  in_stock: false,
  isAssignCategoriesModalOpen: false,
  isScraperManyProductsModalOpen: false,
  isScraperModalOpen: false,
  price_ranges: [0, 10000],
  q: undefined,
  remote_product_link: null,
  selected: [],
  selected_categories: [],
  sort: '-stock_number',
  suppliers: [],
};

export class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...basicStateValues,
      checked: false,
      isSelectedAll: false,
      modalIsOpen: false,
      params: {
        page: this.props.history.location.search,
      },
      q: undefined,
      sort: '-stock_number',
    };
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.push('/login');
    }

    this.setState({
      price_ranges: this.convertPricesFromBE(this.props.price_ranges),
    });

    const { getProducts, getFilterOptions } = this.props;
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const actualFilters = this.getQueryParamsFromUrl();

    this.setState((prevState) => ({
      ...prevState,
      price_ranges: [parseInt(actualFilters.price_ranges?.[0], 10), parseInt(actualFilters.price_ranges?.[1], 10)],
      ...actualFilters,
    }));

    const requestParams = `/products${search || `?${qs.stringify({
      page: 1,
      per_page: actualFilters.per_page,
      sort: actualFilters.sort,
    })}`}`;

    getFilterOptions();
    getProducts(requestParams);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setFilter(nextProps);
    this.setCurrentPage(nextProps);
  }

  getQueryParamsFromUrl = () => {
    const urlParse = qs.parse(this.props.history.location.search);

    return {
      categories: urlParse?.categories || [],
      colors: urlParse?.colors || [],
      countries: urlParse?.countries || [],
      filter_currency: urlParse?.filter_currency || undefined,
      page: urlParse?.page || 1,
      per_page: urlParse?.perPage || 100 || this.getPerPage(),
      price_ranges: urlParse?.price_ranges || [],
      q: urlParse?.q,
      sort: urlParse?.sort || '-stock_number',
      suppliers: urlParse?.suppliers || [],
    };
  };

  handleChange = () => {
    this.setState((prevState) => ({
      checked: !prevState.checked,
    }));
  };

  getPerPage = () => {
    const { products } = this.props;
    return products.loaded && products.productData.meta.perPage;
  };

  withConfirmation = () => window.confirm('Remove element(s) ?');

  withAssignmentCategoryConfirmation = () => window.confirm('Do you want to assign categories ?');

  onViewAll = () => {
    const { getProducts, push } = this.props;

    const perPage = this.getPerPage();

    this.setState(() => basicStateValues);

    push(`/products?page=1&per_page=${perPage}`);
    getProducts(`/products?page=1&per_page=${perPage}`);
  };

  onAssignCategory = () => {
    this.openAssignCategoryModal();
  };

  onAssignCategoryMultipleProducts = () => {
    const { selected, selected_categories } = this.state;
    const { assignCategoryToItems, history } = this.props;

    const updatedSelects = selected?.map((item) => `products_ids[]=${item}`).join('&');
    const updatedSelectsCategories = selected_categories?.map((item) => `categories_ids[]=${item}`).join('&');
    const products_categories_assignment = `${updatedSelects}&${updatedSelectsCategories}`;

    if (this.withAssignmentCategoryConfirmation()) {
      assignCategoryToItems(products_categories_assignment, history.location.search);
      this.setState(() => ({ selected: [], selected_categories: [] }));
      this.closeAssignCategoryModal();
    }
  };

  changeValuesAssignCategory = (key, val) => {
    this.setState({ [key]: val });

    this.setState(() => ({
      selected_categories: val,
    }));
  };

  onCreate = () => {
    this.openScraperModal();
  };

  onStockAvailability = (event) => {
    this.setState(() => ({ in_stock: event }));

    this.onParamsChange({ in_stock: event });
  };

  inStockParamsUrl = () => {
    const { in_stock } = this.getQueryParamsFromUrl();

    return (in_stock === 'true');
  };

  handleChangeProductLinkInput = (event) => {
    this.setState({
      remote_product_link: event.target.value,
    });
  };

  handleChangeRemoteProductSkuSelect = (event) => {
    this.setState({
      remote_product_sku: event.target.value,
    });
  };

  onButtonAddClick = (target) => {
    target && target.preventDefault && target.preventDefault();
    const product_link = this.state.remote_product_link;
    const supplier_id = this.state.scraper_supplier_id;

    const toasterSuccess = 'Success, the new product successfully parsed';
    const toasterError = "Error, the new product wasn't parsed";

    if (product_link !== undefined) {
      getScraperManyProductsByUrl(product_link, supplier_id)
        .then((response) => {
          const remote_products = response.data.products;

          if (remote_products.length > 1 && product_link.includes('https')) {
            const remote_products_sku = remote_products?.map((item) => ({ name: item.itemNumber, text: item.itemNumber, value: item.itemNumber }));

            this.setState({
              remote_product_link: product_link,
              remote_product_sku: remote_products_sku[0].value,
              remote_products: remote_products_sku,
            });

            this.closeScraperModal();
            this.openManyProductsScraperModal();
          } else if (remote_products.length > 1 && !product_link.includes('https')) {
            const remote_products_sku = remote_products?.map((item) => ({ name: item.sku, text: item.sku, value: item.sku }));

            this.setState({
              remote_product_link: product_link,
              remote_product_sku: remote_products_sku[0].value,
              remote_products: remote_products_sku,
            });

            this.closeScraperModal();
            this.openManyProductsScraperModal();
          } else if (remote_products.length === 1) {
            getScraperProductByUrl(product_link, supplier_id)
              .then((response) => {
                const tmp_id = `tmp-${response.data.id}`;
                this.props.push(`/products/${tmp_id}`);

                toastr.success(toasterSuccess);
                this.closeScraperModal();
              })
              .catch(() => toastr.error(toasterError));
          }
        }).catch(() => toastr.error(toasterError));
    }
  };

  onButtonAddBySkuClick = (target) => {
    target && target.preventDefault && target.preventDefault();
    const product_sku = this.state.remote_product_sku;
    const { remote_product_link } = this.state;
    const supplier_url = 'https://marketplace.fourhands.com/product/';
    const product_link = (remote_product_link.includes('https')) ? (supplier_url + product_sku) : product_sku;
    const supplier_id = this.state.scraper_supplier_id;

    const toasterSuccess = 'Success, the new product successfully parsed';
    const toasterError = "Error, the new product wasn't parsed";

    if (product_link !== undefined) {
      getScraperProductByUrl(product_link, supplier_id)
        .then((response) => {
          const tmp_id = `tmp-${response.data.id}`;
          this.props.push(`/products/${tmp_id}`);

          toastr.success(toasterSuccess);
          this.closeScraperModal();
        })
        .catch(() => toastr.error(toasterError));
    }
  };

  onButtonAddScratchClick = (event) => {
    event && event.preventDefault && event.preventDefault();

    this.props.push('/products/0');
    this.closeScraperModal();
  };

  openManyProductsScraperModal = () => {
    this.setState({
      isScraperManyProductsModalOpen: true,
    });
  };

  closeManyProductsScraperModal = () => {
    this.setState({
      isScraperManyProductsModalOpen: false,
    });
  };

  openAssignCategoryModal = () => {
    this.setState({
      isAssignCategoriesModalOpen: true,
    });
  };

  closeAssignCategoryModal = () => {
    this.setState({
      isAssignCategoriesModalOpen: false,
    });
  };

  openScraperModal = () => {
    this.setState({
      isScraperModalOpen: true,
    });
  };

  closeScraperModal = () => {
    this.setState({
      isScraperModalOpen: false,
    });
  };

  onParamsChange = (params) => {
    const { push } = this.props;

    const queryParams = qs.stringify({ ...this.getQueryParamsFromUrl(), ...params });
    push(`/products?${queryParams}`);
  };

  handleSearchChange = (q) => {
    clearTimeout(this.timer);

    this.setState({ q: q.length ? q : undefined });

    this.timer = setTimeout(this.onSearchChange, WAIT_INTERVAL);
  };

  onSearchChange = () => {
    const { q } = this.state;
    this.onParamsChange({ q });
  };

  onSortChange = (sort) => {
    this.setState(() => ({ sort }));
    this.onParamsChange({ sort });
  };

  onPageChange = (page) => {
    this.setState(() => ({ page }));
    this.onParamsChange({ page });
  };

  onPageAmountChange = (perPage) => {
    this.onParamsChange({ page: 1, per_page: perPage });
  };

  changeSelected = (items) => this.setState(() => ({
    selected: items,
  }));

  onRemoveItem = () => {
    const { selected } = this.state;
    const { deleteItems, history } = this.props;

    const updatedSelects = selected?.map((item) => `products_ids[]=${item}`).join('&');

    if (this.withConfirmation()) {
      deleteItems(updatedSelects, history.location.search);

      this.setState(() => ({ selected: [] }));
    }
  };

  applyFilters = (val) => {
    const { filter_currency, price_ranges } = val;
    const newCurrency = price_ranges.length ? filter_currency : undefined;

    this.onParamsChange({ ...val, filter_currency: newCurrency, page: 1 });
  };

  setFilter(nextProps) {
    const { search } = nextProps.history.location;
    this.setState(() => ({
      params: {
        page: search,
      },
      q: qs.parse(search).q || undefined,
    }));
  }

  setCurrentPage(nextProps) {
    const { page } = this.state.params;
    const { search } = nextProps.history.location;

    if (!equal(page, search)) {
      this.props.getProducts(nextProps.location.pathname + search);
    }
  }

  convertPricesFromBE = (price_ranges) => {
    const results = [];
    let i = 0;

    if (price_ranges?.[i] === '900') {
      results.push(price_ranges?.[0]);
      i += 1;
    }
    for (i; i < price_ranges?.length; i += 2) {
      if (price_ranges[i]) {
        results.push(`${price_ranges[i]}-${price_ranges[i + 1]}`);
      }
    }
    return results;
  };

  handleProductEdit = (productId) => {
    const { push } = this.props;
    push(`/products/${productId}`);
  };

  render() {
    const {
      params, selected, q, isScraperModalOpen, isScraperManyProductsModalOpen, isAssignCategoriesModalOpen,
      remote_products, remote_product_sku,
    } = this.state;
    const {
      history: {
        location: { search },
      },
      products,
      filters,
    } = this.props;

    const pageLoaded = products.loaded && filters.loaded;

    const in_stock_params_url = this.inStockParamsUrl();

    const pages = pageLoaded && Math.ceil(products.productData.meta.totalEntries / products.productData.meta.perPage);

    const categories = filters.loaded && filters.filter.data.categories;

    const { checked, isSelectedAll } = this.state;

    const handleSelect = (id, val) => {
      let selectedItems = selected;
      if (val) {
        selectedItems.push(id);
      } else {
        selectedItems = selectedItems.filter((user) => user !== id);
      }
      this.changeSelected(selectedItems);
    };

    const selectAll = () => {
      this.setState((prevState) => ({
        ...prevState,
        isSelectedAll: !prevState.isSelectedAll,
      }));

      if (!isSelectedAll) {
        products.productData.data?.map((item) => handleSelect(item.id, !isSelectedAll));
      } else {
        this.changeSelected([]);
      }
    };

    const saveChanges = (state) => {
      const {
        categories: filterCategories, colors, countries, filter_currency, suppliers, price_ranges,
      } = state;

      const filterState = {
        categories: filterCategories,
        colors,
        countries,
        expandedCategories: [],
        filter_currency: filter_currency || 'USD',
        price_ranges: price_ranges?.[0] ? price_ranges : [],
        suppliers,
      };

      this.setState(filterState);
      this.applyFilters(filterState);
    };

    if (!pageLoaded) {
      return (
        <div className="products-page">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="products-page">
        <div className="products-page-content">
          <TopSection
            pageTitle="products"
            breadcrumbs={breadcrumbsItems}
            onRemoveItem={this.onRemoveItem}
            isDisabled={selected.length === 0}
            onViewAll={this.onViewAll}
            onAssignCategory={this.onAssignCategory}
            shouldCreate
            onCreate={this.onCreate}
            onStockAvailability={this.onStockAvailability}
            shouldInStock={this.state.in_stock || in_stock_params_url}
            shouldImport
            shouldDataStatusReport
          />
          <FilteringSection
            page={params}
            disableFilter
            q={q || ''}
            onModalOpen={this.openModal}
            onSearchChange={this.handleSearchChange}
            perPage={products.productData.meta.perPage}
            activePage={+qs.parse(search).page || 1}
            totalEntriesAmount={products.productData.meta.totalEntries}
            pagesAmount={products.productData.meta.perPage}
            onPageAmountChange={this.onPageAmountChange}
          />
          <ProductView
            {...this.props}
            state={this.state}
            saveChanges={saveChanges}
            checked={checked}
            filters={filters?.filter?.data}
            handleProductEdit={this.handleProductEdit}
            handleSelectItems={this.changeSelected}
            isSelectedAll={isSelectedAll}
            items={products.productData?.data}
            onChange={this.handleChange}
            onSortChange={this.onSortChange}
            renderColumns={renderColumns}
            selectAll={selectAll}
            selected={selected}
          />
          <Pagination
            pages={pages}
            activePage={+qs.parse(search).page}
            onPageChange={(page) => this.onPageChange(page)}
          />
          <StyledComponent>
            {isScraperModalOpen && (
              <ModalWindow closeModal={this.closeScraperModal}>
                <h1 className="modal-filter__title">Adding Product From Supplier Website</h1>
                <div className="modal-filter__filters">
                  <ScraperForm
                    remote_product_link={this.state.remote_product_link}
                    onSubmitProductUrl={this.onButtonAddClick}
                    handleChangeProductLinkInput={this.handleChangeProductLinkInput.bind(this)}
                  />
                </div>
                <div className="modal-filter__footer">
                  <div className="row mb-5pl-0 copy-item__box-select">
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.onButtonAddClick}>
                        ADD
                      </Button>
                    </div>
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.onButtonAddScratchClick}>
                        ADD FROM SCRATCH
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalWindow>
            )}

            {isScraperModalOpen && (
              <ModalWindow closeModal={this.closeScraperModal}>
                <h1 className="modal-filter__title">Adding Product From Supplier Website</h1>
                <div className="modal-filter__filters">
                  <ScraperForm
                    remote_product_link={this.state.remote_product_link}
                    onSubmitProductUrl={this.onButtonAddClick}
                    handleChangeProductLinkInput={this.handleChangeProductLinkInput.bind(this)}
                  />
                </div>
                <div className="modal-filter__footer">
                  <div className="row mb-5pl-0 copy-item__box-select">
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.onButtonAddClick}>
                        ADD
                      </Button>
                    </div>
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.onButtonAddScratchClick}>
                        ADD FROM SCRATCH
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalWindow>
            )}

            {isScraperManyProductsModalOpen && (
              <ModalWindow closeModal={this.closeManyProductsScraperModal}>
                <h1 className="modal-filter__title">Adding Selected Product From Supplier Website</h1>
                <div className="modal-filter__filters">
                  <ScraperSkuForm
                    remote_products={remote_products}
                    remote_product_sku={remote_product_sku}
                    onSubmitProductUrl={this.onButtonAddClick}
                    handleChangeRemoteProductSkuSelect={this.handleChangeRemoteProductSkuSelect.bind(this)}
                  />
                </div>
                <div className="modal-filter__footer">
                  <div className="row mb-5pl-0 copy-item__box-select">
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.onButtonAddBySkuClick}>
                        ADD
                      </Button>
                    </div>
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.onButtonAddScratchClick}>
                        ADD FROM SCRATCH
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalWindow>
            )}
            {isAssignCategoriesModalOpen && (
              <ModalWindow closeModal={this.closeAssignCategoryModal}>
                <h1 className="modal-filter__title">Category Assignment</h1>
                <div className="modal-filter__filters">
                  <section className="filters">
                    {categories && (
                      <div className="filters__one-column" style={{ height: '700px', width: '640px' }}>
                        <CheckboxTree
                          nodes={categories}
                          checked={this.state.categories}
                          expanded={this.state.expandedCategories}
                          expandOnClick
                          onlyLeafCheckboxes={false}
                          onClick={() => {}}
                          onCheck={(value) => this.changeValuesAssignCategory('categories', value)}
                          onExpand={(value) => this.changeValuesAssignCategory('expandedCategories', value)}
                        />
                      </div>
                    )}
                  </section>
                </div>
                <div className="modal-filter__footer">
                  <div className="row mb-5pl-0 copy-item__box-select">
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.onAssignCategoryMultipleProducts}>
                        ASSIGN
                      </Button>
                    </div>
                    <div className="col-lg-6 pl-2 flex">
                      <Button width={150} onClick={this.closeAssignCategoryModal}>
                        CLOSE
                      </Button>
                    </div>
                  </div>
                </div>
              </ModalWindow>
            )}
          </StyledComponent>
        </div>
      </div>
    );
  }
}

export default connect(
  ({ products, filter, authReducer }) => ({
    filters: filterSelector(filter),
    isAuthenticated: authReducer.isAuthenticated,
    products: {
      loaded: products.loaded,
      loading: products.loading,
      productData: productsSelector(products.products),
      tableLoaded: products.tableLoaded,
    },
  }),
  {
    assignCategoryToItems,
    closeModal,
    deleteItems,
    getFilterOptions,
    getProducts,
    push,
    showModal,
  }
)(Products);
