export const createDeliveryAgent = [
  {
    component: 'input',
    label: 'Company Name*',
    name: 'company_name',
    required: true,
    type: 'text',
  },
  {
    component: 'input',
    label: 'Website',
    name: 'website',
    type: 'url',
  },
  {
    component: 'input',
    label: 'Address*',
    name: 'address',
    required: true,
    type: 'text',
  },
  {
    component: 'input',
    label: 'Admin Contact Name',
    name: 'admin_contact_name',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Admin Contact Email',
    name: 'admin_contact_email',
    type: 'email',
  },
  {
    component: 'input',
    label: 'Admin Contact Number',
    name: 'admin_contact_number',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Warehouse Contact Name',
    name: 'warehouse_contact_name',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Warehouse Contact Email',
    name: 'warehouse_contact_email',
    type: 'email',
  },
  {
    component: 'input',
    label: 'Warehouse Contact Phone Number',
    name: 'warehouse_contact_phone_number',
    type: 'text',
  },
  {
    component: 'select',
    label: 'Status',
    name: 'status',
    options: [
      { text: 'Preferred partner', value: 'preferred_partner' },
      { text: 'Tier 2 partner', value: 'tier_2_partner' },
      { text: 'Inactive', value: 'inactive' },
    ],
    required: true,
  },
  {
    component: 'input',
    label: 'Portal Url',
    name: 'portal_url',
    type: 'url',
  },
  {
    component: 'select',
    label: 'Ops Owner',
    name: 'ops_owner',
    options: [],
  },
  {
    component: 'input',
    label: 'Invoices Sent To',
    name: 'invoices_sent_to',
  },
  {
    component: 'input',
    label: 'Inventory Check Method',
    name: 'inventory_check_method',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Warehouse Instructions',
    name: 'warehouse_instructions',
    type: 'text',
  },
  {
    component: 'select',
    label: 'Quality Rating',
    name: 'quality_rating',
    options: [
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
    ],
    required: true,
  },
  {
    component: 'input',
    label: 'Notes',
    name: 'notes',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Man per crew',
    name: 'man_per_crew',
    type: 'number',
  },
  {
    component: 'input',
    label: 'Truck rate per truck',
    name: 'truck_rate_per_truck',
    type: 'number',
  },
  {
    component: 'input',
    label: 'Storage fee per month',
    name: 'storage_fee_per_month',
    type: 'number',
  },
  {
    component: 'input',
    label: 'Disposal fee',
    name: 'disposal_fee',
    type: 'number',
  },
  {
    component: 'input',
    label: 'Receiving cost per shipment',
    name: 'receiving_cost_per_shipment',
    type: 'number',
  },
  {
    component: 'input',
    label: 'Fuel charge per mile',
    name: 'fuel_charge_per_mile',
    type: 'number',
  },
];

export const updateDeliveryAgentComponents = [
  ...createDeliveryAgent.slice(0, 3),
  {
    component: 'input',
    disabled: true,
    label: 'Latitude*',
    name: 'latitude',
    required: true,
    type: 'number',
  },
  {
    component: 'input',
    disabled: true,
    label: 'Longitude*',
    name: 'longitude',
    required: true,
    type: 'number',
  },
  {
    component: 'input',
    disabled: true,
    label: 'Country*',
    name: 'country',
    required: true,
    type: 'text',
  },
  {
    component: 'input',
    disabled: true,
    label: 'State or Province*',
    name: 'state_or_province',
    required: true,
    type: 'text',
  },
  {
    component: 'input',
    disabled: true,
    label: 'Metropolitan City*',
    name: 'metropolitan_city',
    required: true,
    type: 'text',
  },
  ...createDeliveryAgent.slice(3),
];

export const numberAtributesOnCreateDeliveryAgent = [
  'quality_rating',
  'man_per_crew',
  'truck_rate_per_truck',
  'storage_fee_per_month',
  'disposal_fee',
  'receiving_cost_per_shipment',
  'fuel_charge_per_mile',
];

export const deliveryAgentObject = {
  address: '',
  admin_contact_email: '',
  admin_contact_name: '',
  admin_contact_number: '',
  company_name: '',
  disposal_fee: '',
  fuel_charge_per_mile: '',
  inventory_check_method: '',
  invoices_sent_to: '',
  man_per_crew: '',
  notes: '',
  ops_owner: '',
  portal_url: '',
  quality_rating: 1,
  receiving_cost_per_shipment: '',
  status: 'preferred_partner',
  storage_fee_per_month: '',
  truck_rate_per_truck: '',
  warehouse_contact_email: '',
  warehouse_contact_name: '',
  warehouse_contact_phone_number: '',
  warehouse_instructions: '',
  website: '',
};

export const customStylesModal = {
  content: {
    bottom: 'auto',
    left: '50%',
    marginRight: '-50%',
    maxHeight: '80vh',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    zIndex: '100000',
  },
  overlay: { zIndex: 100000 },
};

export const renderColumnsDeliveryAgent = [
  'company_name',
  'address',
  'admin_contact_name',
  'admin_contact_number',
  'delivery_quotes',
  'shoppe_orders',
  'status',
];

export const renderColumnsSuppliers = [
  'name',
  'currency',
  'contact_name',
  'phone',
  'view_more',
];

export const modalPropsDeliveryAgents = {
  orders: {
    columns: [
      'first_name',
      'last_name',
      'id',
    ],
    title: 'View Orders',
  },
  quotes: {
    columns: [
      'id',
      'quote_total',
    ],
    title: 'View Quotes',
  },
};

export const updateSupplier = [
  {
    component: 'input',
    label: 'Company name*',
    name: 'name',
    required: true,
    type: 'text',
  },
  {
    component: 'input',
    label: 'Email address',
    name: 'email',
    type: 'email',
  },
  {
    component: 'input',
    label: 'Contact name',
    name: 'contact_name',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Phone number',
    name: 'phone',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Hours',
    name: 'hours',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Multipler',
    name: 'multipler',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Sale type',
    name: 'sale_type',
    type: 'text',
  },
  {
    component: 'input',
    label: 'Markup',
    name: 'markup',
    type: 'decimal',
  },
  {
    component: 'input',
    label: 'Freight cost',
    name: 'freight_cost',
    type: 'decimal',
  },
  {
    component: 'input',
    label: 'Website',
    name: 'website',
    type: 'website',
  },
  {
    component: 'input',
    label: 'Notes',
    name: 'notes',
    type: 'text',
  },
  {
    component: 'select',
    label: 'Country:',
    name: 'country',
    options: [
      { text: 'United States', value: 'USA' },
      { text: 'Canada', value: 'CA' },
    ],
  },
  {
    component: 'multiselect',
    label: 'Zones:',
    name: 'zones',
  },
];

export const supplierObject = {
  contact_name: '',
  country: {},
  email: '',
  freight_cost: '',
  hours: '',
  markup: '',
  multipler: '',
  name: '',
  notes: '',
  phone: '',
  sale_type: '',
  website: '',
  zones: [],
};
