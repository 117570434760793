function convertItemToObject(item) {
  const tempObj = {}

  for (const [objKey, objValue] of Object.entries(item)) {
    if (Array.isArray(objValue)) {
      tempObj[objKey] = objValue.map(({ value }) => value)
    }
    else {
      tempObj[objKey] = objValue.value
    }
  }

  return tempObj
}

function convertValuesToObject(values) {
  const tempObj = {}

  for (const [objKey, objValue] of Object.entries(values)) {
    if (Array.isArray(objValue)) {

      tempObj[objKey] = objValue.map(({ value }) => {

        switch (objKey) {
          case("design_style_colors_attributes"):
            return { color_id: value }

          case("design_style_textures_attributes"):
            return { texture_id: value }

          case("design_style_rules_attributes"):
            return { design_rule_id: value }

          default:
            return []
        }

      })
    }
    else {
      tempObj[objKey] = objValue
    }
  }

  return tempObj
}

export default function transformValueReduxForm(values) {
  if (Array.isArray(values)) {
    return values.map((item) => convertItemToObject(item))
  }
  else {
    return convertValuesToObject(values)
  }
}
