import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

Tab.propTypes = {
  // from Tabs Component
  onClick: PropTypes.func,
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  empty: PropTypes.bool
}

function Tab({ onClick, tabIndex, isActive, title, empty }) {
  const navigationItemClassName = classNames(
    "tabs__navigation-item",
    {
      "active": isActive,
      "empty": empty
    }
  )

  return (
    <li className={navigationItemClassName} onClick={onClick} data-index={tabIndex}>
      {title}
    </li>
  )
}

export default Tab
