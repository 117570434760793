import styled from "styled-components"

export default styled.div`
  min-height: 300px;
  padding: 20px;
  background-color:#f7f7f7;
  border: 1px solid #ccc;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.3s;
  
  &:hover { border-color: #8e8e8e; }
  
  .image-preview {
    width: 150px;
    height: 150px;
    
    img {
      max-width: 150px;
      max-height: 150px;
    }
  }
`
