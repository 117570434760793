import React from "react"
import PropTypes from "prop-types"

// Misc
import { getImageUrl } from "../utils"

// Import Components
import RuleListStyle from "./style"
import RuleListItem from "./RuleListItem"

RuleList.propTypes = {
  // Import Components
  type: PropTypes.string,
  clone: PropTypes.bool,
  buttonDelete_clickHandler: PropTypes.func
}

function RuleList({ type, clone, items, buttonDelete_clickHandler }) {
  const listElements = items.map(({ value, label, image_url, ...restProps }, index) => {
    const imageUrl = getImageUrl(image_url)
    return (
      <li className="design-rule__item" key={value || index}>
        <RuleListItem
          type={type}
          clone={clone}
          title={label}
          img={imageUrl}
          id={value}
          info={restProps && restProps.room_inner}
          designStylesInfo={restProps}
          buttonDelete_clickHandler={buttonDelete_clickHandler}
        />
      </li>
    )
  })

  const emptyItems = <p>No data to display</p>

  return (
    <RuleListStyle>
      <ul className="design-rule__list">
        {items.length ? listElements : emptyItems}
      </ul>
    </RuleListStyle>
  )
}

export default RuleList
