import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import { getLinkToken } from '../../../ducks/auth';

const separator = '/';

class AdminCategories extends Component {
  render() {
    return (
      <div className="navigation-categories">
        {this.props.categories.map((cat, idx) => {
          if (!cat.new_admin) {
            return (
              <a
                key={cat.description + idx}
                href={`${cat.url}?auth_token=${this.props.linkToken}`}
                className="navigation-categories-item"
              >
                {cat.description}
              </a>
            );
          }

          const segments = cat.url.split(separator);
          const lastLinkSegment = segments[segments.length - 1];
          return (
            <Link key={cat.description + idx} className="navigation-categories-item" to={`/${lastLinkSegment}`}>
              {cat.description}
            </Link>
          );
        })}
      </div>
    );
  }
}

// Enhance
const mapStateToProps = (state) => ({
  linkToken: getLinkToken(state),
});

export default connect(mapStateToProps)(AdminCategories);
