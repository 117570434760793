import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { getDataTableRequest } from '../../ducks/categoryRules';
import { getFiltersDataRequest, searchFilterRequest } from '../../ducks/filters';
import { setPaginationValue } from '../../ducks/pagination';
import { breadcrumbs } from '../../constants/breadcrumbs';
import { categoryRules as tableHeaders } from '../../constants/tableHeaders';
import withModal from '../../Components/HOCs/modal';
import {
  Layout, PageHeader, TableConstructor, Preloader, ModalWindow,
} from '../../Components/Shared';
import { ButtonsBlock, TableFilters, ModalAddRule as AddRule } from '../../Components/CategoryRules';
import { getIsAuthenticated } from '../../ducks/auth';

class CategoryRules extends Component {
  static propTypes = {
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
    getDataTableRequest: PropTypes.func,
    getFiltersDataRequest: PropTypes.func,
    tableData: PropTypes.array,
    isLoading: PropTypes.bool,
    perPage: PropTypes.number,
    numberPage: PropTypes.number,
    searchInputValue: PropTypes.string,
  };

  state = {
    screenHeight: 0,
  };

  componentDidMount() {
    const {
      getDataTableRequest, getFiltersDataRequest, perPage, numberPage, searchInputValue, isAuthenticated,
    } = this.props;

    if (!isAuthenticated) {
      this.props.push('/login');
    }

    getDataTableRequest(numberPage, perPage, searchInputValue);
    getFiltersDataRequest();
    this.getScreenHeight();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.perPage !== this.props.perPage || prevProps.numberPage !== this.props.numberPage) {
      this.updatePaginationValue(this.props.totalEntries, this.props.perPage, this.props.numberPage);
      this.props.getDataTableRequest(this.props.numberPage, this.props.perPage, this.props.searchInputValue);
    }
  }

  getScreenHeight = () => {
    this.setState({
      screenHeight: document.documentElement.clientHeight,
    });
  };

  updatePaginationValue = (totalEntries, perPage, numberPage) => {
    const pages = Math.ceil(totalEntries / perPage);
    const pageNumber = numberPage > pages ? pages : numberPage;
    this.props.setPaginationValue(pageNumber);
  };

  openModal = () => {
    const { openModal } = this.props;
    openModal();
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  render() {
    const {
      isModalOpen, tableData, filtersData, isLoading, numberPage,
      perPage, totalEntries, setPaginationValue, getDataTableRequest,
    } = this.props;
    const { screenHeight } = this.state;
    const buttons = <ButtonsBlock addRule={this.openModal} />;

    return (
      <>
        {isModalOpen && (
          <ModalWindow closeModal={this.closeModal}>
            <AddRule
              categories={filtersData.categories}
              closeModal={this.closeModal}
              getDataTableRequest={getDataTableRequest}
              perPage={perPage}
              numberPage={numberPage}
            />
          </ModalWindow>
        )}
        <Layout>
          <Preloader isLoading={isLoading}>
            <PageHeader title="Category Rules" breadcrumbs={breadcrumbs.categoryRules} buttonsBlock={buttons} />
            <TableFilters />
            {tableData.length ? (
              <TableConstructor
                name="CategoryRules"
                tableHead={tableHeaders}
                tableData={tableData}
                rowKey="categoryRules"
                screenHeight={screenHeight}
                currentPage={numberPage}
                perPage={perPage}
                totalEntries={totalEntries}
                paginationChange={setPaginationValue}
              />
            ) : (
              <p style={{ textAlign: 'center', fontWeight: 700 }}>No data to display</p>
            )}
          </Preloader>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
  tableData: state.categoryRulesReducer.tableData,
  filtersData: state.filtersReducer.filtersData,
  isLoading: state.categoryRulesReducer.isLoading,
  numberPage: state.paginationReducer.value,
  perPage: state.selectorReducer.value,
  totalEntries: state.categoryRulesReducer.totalEntries,
  searchInputValue: state.searchInputReducer.value,
});

const mapDispatchToProps = {
  getDataTableRequest,
  getFiltersDataRequest,
  setPaginationValue,
  searchFilterRequest,
  push,
};

export default compose(
  withModal,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CategoryRules);
