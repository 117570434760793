import styled, { css } from "styled-components"

export default styled.div`
  label {
    ${(props) =>
      props.small
        ? css`
            width: 15px;
            height: 15px;
            border-radius: 2px;
          `
        : css`
            width: 25px;
            height: 25px;
            border-radius: 5px;
          `};

    margin-bottom: 0;
    background-color: #fff;
    border: 1px solid #cdcdcd;
    transition: all 0.3s;
    cursor: pointer;
  }

  input {
    display: none;
  }

  input:checked + label {
    position: relative;
    background-color: #434343;
    border-color: #434343;

    &::before {
      ${(props) =>
        props.small
          ? css`
              top: 3px;
              left: 3px;
            `
          : css`
              top: 10px;
              left: 2px;
            `};

      content: "";
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      transition: all 0.3s;
      transform: rotate(45deg);
    }

    &::after {
      ${(props) =>
        props.small
          ? css`
              top: 3px;
              left: 3px;
            `
          : css`
              top: 10px;
              left: 2px;
            `};

      content: "";
      position: absolute;
      width: 20px;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      transition: all 0.3s;
      transform: rotate(-45deg);
    }
  }
`
