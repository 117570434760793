import React from "react"
import PropTypes from "prop-types"
import { reduxForm } from "redux-form"
import { formName } from "../../../../constants/forms"

// Import Components
import DetailCreateFormStyle from "./style"
import { ReduxFormFileUploader, ReduxFormInput } from "../../../Shared/reduxForm"
import { Button } from "../../../Shared"
import { syncValidate } from "../../../Shared/reduxForm/sync-validate"

DetailCreateForm.propTypes = {
  // from parent component
  onSubmit: PropTypes.func,
  disabledSubmit: PropTypes.bool
}

function DetailCreateForm({ handleSubmit, onSubmit, pristine, submitting, invalid }) {

  return (
    <DetailCreateFormStyle>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="available-textures__form"
      >
        <div className="available-textures__column preview">
          <ReduxFormFileUploader
            name="file"
            formName={formName.availableTexturesCreateForm}
            width={"100px"}
            height={"100px"}
            validate={[
              syncValidate.images
            ]}
          />
        </div>
        <div className="available-textures__column fields">
          <ReduxFormInput
            name="name"
            label="Texture name"
          />
        </div>
        <div className="available-textures__button">
          <Button
            size="large"
            type="submit"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </DetailCreateFormStyle>
  )
}

export default reduxForm({
  form: formName.availableTexturesCreateForm
})(DetailCreateForm)
