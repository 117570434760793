import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Input from "./Input"
import { Button, DKCheckbox } from "../../../Shared"

const CategoryTd = (props) => {
  const { category, categories, onChangeCategories, onChangeCategory } = props
  const onButtonDeleteClick = () => props.onButtonDeleteClick(category.value, props.parentId)

  return (
    <Fragment>
      <td className="table__cell td">
        {category.label}
      </td>
      <td className="table__cell td">
        <Input
          id={category.value}
          name="quantity"
          value={categories && categories[category.value] && categories[category.value].quantity !== undefined ? categories[category.value].quantity : ''}
          onChange={onChangeCategories}
        />
      </td>
      <td className="table__cell td small-size">
        {category.is_backend ? (
          <DKCheckbox
            name={category.value}
            onChange={onChangeCategory}
          />
        ) : (
          <Button width={100} onClick={onButtonDeleteClick} color="danger">Delete</Button>
        )}
      </td>
    </Fragment>
  )
}

CategoryTd.propTypes = {
  category: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  onChangeCategory: PropTypes.func,
  onChangeCategories: PropTypes.func,
  categories: PropTypes.object,
  onChangeSelectCategories: PropTypes.func,
  onButtonDeleteClick: PropTypes.func,
  parentId: PropTypes.any
}

export default CategoryTd
