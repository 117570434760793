import styled from "styled-components"

export default styled.section`
  z-index: 200000;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #2c2c2c;

  .auth__box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 750px;
    min-height: 150px;
    padding: 25px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .auth__title {
    text-align: center;
    margin-bottom: 25px;
    color: #434343;
  }
  
  .auth-form__fieldset .redux-form__input {
    width: 100%;
    margin-bottom: 25px;
  }  
  
  .auth-form__fieldset .input__field {
    width: 100%;
  }
  
  .auth-form__btn-wrap {
    text-align: center;
  }
`
