import { put, call, takeLatest, all } from "redux-saga/effects"
import { appName } from "../constants"
import { postAddAttribute } from "../api/lt-api"
import { toastr } from "react-redux-toastr"

/**
 * Constants
 **/
export const moduleName = "selector"
const prefix = `${appName}/${moduleName}`

const ADD_ATTRIBUTE_REQUEST = `${prefix}/ADD_ATTRIBUTE_REQUEST`
const ADD_ATTRIBUTE_REQUEST_SUCCESSFUL = `${prefix}/ADD_ATTRIBUTE_REQUEST_SUCCESSFUL`
const ADD_ATTRIBUTE_REQUEST_FAILURE = `${prefix}/ADD_ATTRIBUTE_REQUEST_FAILURE`

/**
 * Reducer
 **/
const initialState = {
  data: []
}

export default function reducer(state = initialState, { type, attribute }) {
  switch (type) {
    case ADD_ATTRIBUTE_REQUEST_SUCCESSFUL:
      return {
        ...state,
        data: attribute
      }

    default:
      return state
  }
}

/**
 * Selectors
 **/

/**
 * Action Creators
 **/
export function getAddAttributeRequest(data) {
  return {
    type: ADD_ATTRIBUTE_REQUEST,
    payload: { data }
  }
}

/**
 * Sagas
 **/
function* getAddAttributeRequestSaga({ payload: { data } }) {
  try {
    const attribute = yield call(postAddAttribute, data)
    yield put({
      type: ADD_ATTRIBUTE_REQUEST_SUCCESSFUL,
      attribute
    })
    const toastrSuccess = toastr.success
    yield call(toastrSuccess, "Attribute added")
  } catch (error) {
    const toastrError = toastr.error
    yield call(toastrError, error.message)
    yield put({ type: ADD_ATTRIBUTE_REQUEST_FAILURE })
  }
}

export function* saga() {
  yield all([takeLatest(ADD_ATTRIBUTE_REQUEST, getAddAttributeRequestSaga)])
}
