import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"

const readAsDataUrl = function(file, func) {
  const reader = new FileReader()

  if (file) {
    reader.readAsDataURL(file)
  }

  reader.onloadend = function() {
    func(reader.result)
  }
}

File.propTypes = {
  // from parent Component
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  }).isRequired,
  changeImgSrc: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired
}

function File({
                input: { name, value, onChange },
                meta: { error, dirty, submitting, invalid },
                changeImgSrc,
                setRef,
                changeSrc
              }) {

  useEffect(() => {
    changeSrc(value)
  }, [value])

  const files_changeHandler = function(event) {
    onChange(event.target.files[0])
    readAsDataUrl(event.target.files[0], changeImgSrc)
  }

  const messagesBox = (
    <div className="file-uploader__messages-box">
      {error && <span className="file-uploader__message-invalid">{error}</span>}
    </div>
  )

  const showMessagesBox = (dirty && error) && messagesBox

  return (
    <Fragment>
      <input
        id={`${name}-id`}
        hidden
        name={name}
        type="file"
        ref={setRef}
        onChange={files_changeHandler}
      />
      {showMessagesBox}
    </Fragment>
  )
}

export default File
