import React from 'react';
import PropTypes from 'prop-types';

// Misc

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

import { reduxForm } from 'redux-form';
import {
  colorSelectorOptions, designRulesSelectorOptions,
  getAvailableDesignStyleById,
  textureSelectorOptions,
} from '../../../../ducks/availableDesignStyles';
import { formName } from '../../../../constants/forms';
// Import Components
import DetailEditFormStyle from './style';
import { ReduxFormFileUploader, ReduxFormInput, ReduxFormSelect } from '../../../Shared/reduxForm';
import { Button } from '../../../Shared';

DetailEditForm.propTypes = {
  // from DetailFloorPlans Component
  onSubmit: PropTypes.func.isRequired,
  // from reduxForm
  handleSubmit: PropTypes.func,
  // from connect/availableDesignStylesReducer
  styleOptionColors: PropTypes.array,
  styleOptionTextures: PropTypes.array,
  styleOptionDesignRules: PropTypes.array,
};

function DetailEditForm({
  initialValues, styleOptionDesignRules, handleSubmit, onSubmit, pristine, submitting, invalid, styleOptionColors, styleOptionTextures,
}) {
  return (
    <DetailEditFormStyle>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="design-styles__form"
      >
        <ReduxFormFileUploader
          name="image_url"
          fileUrl={initialValues.image_url}
          formName={formName.availableDesignStylesEditForm}
          width="400px"
          height="400px"
        />
        <div className="design-styles__form-column">
          <ReduxFormInput
            name="name"
            label="Name"
          />
          <div className="design-styles__select-box">
            <p className="design-styles__select-label">Design Rules</p>
            <div className="design-styles__select">
              <ReduxFormSelect
                deleteFlag
                name="design_style_rules_attributes"
                isMulti
                options={styleOptionDesignRules}
              />
            </div>
          </div>
          <div className="design-styles__select-box">
            <p className="design-styles__select-label">Colors</p>
            <div className="design-styles__select">
              <ReduxFormSelect
                deleteFlag
                name="design_style_colors_attributes"
                isMulti
                options={styleOptionColors}
              />
            </div>
          </div>
          <div className="design-styles__select-box">
            <p className="design-styles__select-label">Textures</p>
            <div className="design-styles__select">
              <ReduxFormSelect
                deleteFlag
                name="design_style_textures_attributes"
                isMulti
                options={styleOptionTextures}
              />
            </div>
          </div>
        </div>
        <div className="design-styles__buttons">
          <Button
            type="submit"
            size="large"
            width={200}
            disabled={pristine || submitting || invalid}
          >
            Save
          </Button>
        </div>
      </form>
    </DetailEditFormStyle>
  );
}

// Enhance
const mapStateToProps = (state) => {
  const getColorSelectorOptions = colorSelectorOptions();
  const getTextureSelectorOptions = textureSelectorOptions();
  const getDesignRulesSelectorOptions = designRulesSelectorOptions();

  return {
    styleOptionColors: getColorSelectorOptions(state),
    styleOptionTextures: getTextureSelectorOptions(state),
    styleOptionDesignRules: getDesignRulesSelectorOptions(state),
    initialValues: {
      name: getAvailableDesignStyleById(state).label,
      image_url: getAvailableDesignStyleById(state).image_url && getAvailableDesignStyleById(state).image_url.url,
      design_style_colors_attributes: getAvailableDesignStyleById(state).design_style_colors,
      design_style_textures_attributes: getAvailableDesignStyleById(state).design_style_textures,
      design_style_rules_attributes: getAvailableDesignStyleById(state).design_style_rules,
    },
  };
};

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: formName.availableDesignStylesEditForm,
  })
);

export default enhance(DetailEditForm);
