import React, { Component } from "react"
import Select from "react-select"
import { push } from "react-router-redux"
import { getListRoomsPermalinks,
  moveRoomVariantToDestinationRoom,
  checkEqualsDesignRoomsRequest} from "../../../../api/lt-api"
import MoveFormStyle from "./style"
import { Button, ModalWindow } from "../../../Shared"
import { toastr } from "react-redux-toastr"
import PropTypes from "prop-types"
import StyledComponents from "../MoveModal/style"
import { put } from "redux-saga/effects"

class MoveForm extends Component {
  static propTypes = {
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
    parent_room_id: PropTypes.number,
    destination_room_id: PropTypes.number
  }

  state = {
    rooms: [],
    roomVariant: null,
    room_link: null,
    room: this.props.room,
    room_id: this.props.room_id,
    variant_id: this.props.variant_id,
    parent_room_id: this.props.parent_room_id,
    destination_room_id: null,
    selectedRoom: [],
    isModalOpen: false,
    query: '',
    typing: false,
    typingTimeout: 0
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      push("/login")
    }

    this.setState({
      isMounted: true,
      query: '',
      typing: false,
      typingTimeout: 0,
      rooms: [{permalink: '', id: 123}]
    })
  }

  handleChangeRoomPermalinkThrottled = (event) => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      query: event,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.handleChangeRoomPermalink(self.state.query);
      }, 600)
    });
  }


  validate = (e) => {
    const value = Array.isArray(e) ? e[0] : e
    const isNotValid = this.props.missingRequiredFields && !value
    return isNotValid ? "is-invalid" : ""
  }

  getListRoomPermalinks = (rooms) => {
    return rooms.map((room) => (
      {label: room.permalink, value: room.product_id}
    ))
  }

  getSelectedListPermalinks = (itemsList, itemsIds = []) => {
    return itemsList.filter((item) => item.value === itemsIds)
  }

  openModal = () => {
    this.setState({
      isModalOpen: true
    })
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false
    })
  }

  handleChangeRoomPermalink = (query) => {
    const toasterSuccess = "Success, the room's permalink successfully found"
    const toasterError = "Error, the room's permalink wasn't found"

    if (query.length > 0){
      getListRoomsPermalinks(query)
        .then((response) => {
          toastr.success(toasterSuccess)

          const  rooms_links = response.data

          this.setState({
            rooms: rooms_links,
            room_id: rooms_links[0].id,
            destination_room_id: rooms_links[0].product_id
          })
        })
        .catch((error) => {
          return toastr.error(toasterError)
        })
    }

  }

  handleSubmitPermalinkRoom = (event) => {

    const toasterSuccess = "Success, the room variant successfully moved"
    const toasterError = "Error, the room variant wasn't moved"

    const current_room_id = this.state.parent_room_id
    const destination_room_id = this.state.destination_room_id
    const room_variant_id = window.location.pathname.split("/")[4]

    checkEqualsDesignRoomsRequest(current_room_id, destination_room_id ,room_variant_id)
      .then((response) => {
        const  isMoved = response.data.isMove
        if (!isMoved){
          const  design_rooms = response.data.data
          const design_rooms_names = design_rooms.map((design_room) => {
            return " " + design_room.label
          })
          const design_rooms_names_string = design_rooms_names.toString()
          const message = 'd' + design_rooms_names_string +' in this room are not in the new room.' +
            ' Furniture in the ( '+ design_rooms_names_string +' ) will be moved to Entire Unit. '

          this.setState({
            design_rooms: design_rooms,
            message: message,
            design_rooms_names_string: design_rooms_names_string,
            isModalOpen: true
          })
        }else{
          this.setState({
            isModalOpen: false
          })
          moveRoomVariantToDestinationRoom([], destination_room_id, room_variant_id)
            .then((response) => {
              put(push(`/rooms/${current_room_id}`))
              window.location.href = `/rooms/${current_room_id}`
              toastr.success(toasterSuccess)
            })
            .catch((error) => {
              return toastr.error(toasterError)
            })
        }
      })
      .catch((error) => {
        return toastr.error(toasterError)
      })

    event.preventDefault()
  }

  handleChangeRoomId = (target) =>{
    if (this.state.rooms.length !== 0){
      this.setState({
        room_id: target.value,
        destination_room_id: target.value,
      })
      const listDesignRooms = this.getListRoomPermalinks(this.state.rooms)
      const room_value = this.getSelectedListPermalinks(listDesignRooms, target.value)
      this.setState({
          selectedRoom: room_value
        }
      )
    }
  }

  onSubmitModal = (event) => {
    const design_rooms_ids = this.state.design_rooms.map((design_room) => {
      return design_room.value
    })
    const toasterSuccess = "Success, the room variant successfully moved"
    const toasterError = "Error, the room variant wasn't moved"

    const current_room_id = this.state.parent_room_id
    const destination_room_id = this.state.destination_room_id
    const move_room_variant_id = this.state.variant_id

    moveRoomVariantToDestinationRoom(design_rooms_ids, destination_room_id, move_room_variant_id)
      .then((response) => {
        put(push(`/rooms/${current_room_id}`))
        window.location.href = `/rooms/${current_room_id}`
        toastr.success(toasterSuccess)
      })
      .catch((error) => {
        return toastr.error(toasterError)
      })
    this.closeModal()
  }

  render() {
    const {rooms, selectedRoom } = this.state
    const listDesignRooms = this.getListRoomPermalinks(rooms)
    const value = this.getSelectedListPermalinks(listDesignRooms, this.state.room_id)
    const { isModalOpen, design_rooms_names_string } = this.state

    return (
      <MoveFormStyle>
        <form className="container-fluid" onSubmit={this.handleSubmitPermalinkRoom}>
          <div className="col-lg-9 pl-2 flex move__box-label">
            Move to:
          </div>
          <div className="col-lg-9 pl-2 flex move__box-select">
            <Select
              placeholder="Type and Select..."
              className={"select2-single"}
              options={listDesignRooms}
              onChange={this.handleChangeRoomId}
              onInputChange={this.handleChangeRoomPermalinkThrottled}
              value={selectedRoom || value}
            />
          </div>
          <div className="move__box-button">
            <Button type="submit" size="medium" width={150} >
              Move Variant
            </Button>
          </div>
        </form>
        {isModalOpen && (
          <ModalWindow closeModal={this.closeModal}>
            <StyledComponents>
              <div className="modal__header">
                <h1 className="modal__title">Move Room Variant</h1>
              </div>
              <div className="row">
                <span className="modal__content">
                  <strong> {design_rooms_names_string} </strong>
                  in this room are not in the new room.
                  Furniture in the <strong> {design_rooms_names_string} </strong>
                  will be moved to Entire Unit.
                </span>
                <br />
                <p>Are you sure you want to move this variant to the new room?</p>
              </div>
              <form noValidate className="add-attributes__form" onSubmit={this.onSubmitModal}>
                <div className="add-attributes__footer">
                  <Button type="button" width={120} onClick={this.closeModal}>
                    Cancel
                  </Button>
                  <Button type="submit" width={120}>
                    Continue
                  </Button>
                </div>
              </form>
            </StyledComponents>
          </ModalWindow>
        )}
      </MoveFormStyle>
    )
  }
}

export default MoveForm