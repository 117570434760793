import styled from 'styled-components';

export default styled.div`
  .input {
    display: inline-block;
    padding: 11px;
    font-family: inherit;
    font-weight: 100;
    font-size: 17px;
    line-height: 1;
    text-align: left;
    vertical-align: middle;
    color: #595959;
    border: 1px solid #ccc;
    background-color: transparent;
    outline: none;
    cursor: text;
    transition: all 0.3s;

    &:disabled {
      opacity: 0.5;
    }

    &:hover,
    &:focus {
      border-color: #8e8e8e;
    }
  }

  .input-primary {
    background-color: #f7f7f7;
    border: 1px solid transparent;

    &:hover,
    &:focus {
      border: 1px solid #ccc;
    }
  }

  .label-primary {
    margin-bottom: 0;
    margin-right: 20px;
  }
`;
