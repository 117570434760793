import {
  call, put, all, takeEvery,
} from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  getSupplierByIdApi,
  getSuppliersApi,
  getZonesApi,
  updateSuppliersApi,
} from '../api/suppliers';

/**
 * Reducer
 * */
const initialState = {
  error: null,
  isLoaded: false,
  isLoading: false,
  supplier: {},
  suppliers: {
    data: [],
  },
  zones: [],
};

const GET_SUPPLIERS_REQUEST = 'GET_SUPPLIERS_REQUEST';
const GET_SUPPLIERS_SUCCESSFUL = 'GET_SUPPLIERS_SUCCESSFUL';
const GET_SUPPLIERS_FAILURE = 'GET_SUPPLIERS_FAILURE';

const GET_SUPPLIER_BY_ID_REQUEST = 'GET_SUPPLIER_BY_ID_REQUEST';
const GET_SUPPLIER_BY_ID_SUCCESSFUL = 'GET_SUPPLIER_BY_ID_SUCCESSFUL';
const GET_SUPPLIER_BY_ID_FAILURE = 'GET_SUPPLIER_BY_ID_FAILURE';

const UPDATE_SUPPLIER_REQUEST = 'UPDATE_SUPPLIER_REQUEST';
const UPDATE_SUPPLIER_SUCCESSFUL = 'UPDATE_SUPPLIER_SUCCESSFUL';
const UPDATE_SUPPLIER_FAILURE = 'UPDATE_SUPPLIER_FAILURE';

const GET_ZONES_REQUEST = 'GET_ZONES_REQUEST';
const GET_ZONES_SUCCESSFUL = 'GET_ZONES_SUCCESSFUL';
const GET_ZONES_FAILURE = 'GET_ZONES_FAILURE';

export default function reducer(state = initialState, { type, payload, error }) {
  switch (type) {
    case GET_ZONES_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    case GET_SUPPLIER_BY_ID_REQUEST:
    case UPDATE_SUPPLIER_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        supplier: {},
      };
    case GET_SUPPLIER_BY_ID_SUCCESSFUL:
    case UPDATE_SUPPLIER_SUCCESSFUL:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        supplier: payload,
      };
    case GET_ZONES_SUCCESSFUL:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        zones: payload,
      };
    case GET_SUPPLIERS_REQUEST:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        suppliers: {
          data: [],
        },
      };
    case GET_SUPPLIERS_SUCCESSFUL:
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        suppliers: payload,
      };
    case GET_ZONES_FAILURE:
    case GET_SUPPLIERS_FAILURE:
    case GET_SUPPLIER_BY_ID_FAILURE:
    case UPDATE_SUPPLIER_FAILURE:
      return {
        ...state,
        error,
        isLoaded: true,
        isLoading: false,
      };
    default:
      return state;
  }
}

/**
 * Selectors
 * */
export const getSuppliersSelector = (state) => state.suppliersReducer;

/**
 * Action Creators
 * */
export const getSuppliersRequest = (payload) => ({
  payload,
  type: GET_SUPPLIERS_REQUEST,
});

export const getSupplierByIdRequest = (payload) => ({
  payload,
  type: GET_SUPPLIER_BY_ID_REQUEST,
});

export const updateSupplierRequest = (payload) => ({
  payload,
  type: UPDATE_SUPPLIER_REQUEST,
});

export const getZonesRequest = () => ({
  type: GET_ZONES_REQUEST,
});

/**
 * Sagas
 * */

function* getSuppliers({ payload }) {
  try {
    const { data } = yield call(() => getSuppliersApi(payload));

    yield put({
      payload: data,
      type: GET_SUPPLIERS_SUCCESSFUL,
    });
  } catch (error) {
    yield call(() => toastr.error('Somenthing went wrong, please try again'));
    yield put({
      error,
      type: GET_SUPPLIERS_FAILURE,
    });
  }
}

export function* getSupplierById({ payload }) {
  try {
    const { data } = yield call(() => getSupplierByIdApi(payload));

    yield put({
      payload: {
        ...data,
        zones: data.suppliers_zones.map(({ zone: { name, id } }) => ({ label: name, value: id })),
      },
      type: GET_SUPPLIER_BY_ID_SUCCESSFUL,
    });
  } catch (error) {
    yield call(() => toastr.error('Somenthing went wrong, please try again'));
    yield put({
      error,
      type: GET_SUPPLIER_BY_ID_FAILURE,
    });
  }
}

export function* updateSupplier({ payload }) {
  try {
    const { data } = yield call(() => updateSuppliersApi(payload.id, payload));

    yield put({
      payload: {
        ...data,
        zones: data.suppliers_zones.map(({ zone: { name, id } }) => ({ label: name, value: id })),
      },
      type: UPDATE_SUPPLIER_SUCCESSFUL,
    });

    yield call(() => toastr.success('Supplier updated successfully'));
  } catch (error) {
    yield call(() => toastr.error('Somenthing went wrong, please try again'));
    yield put({
      error,
      type: UPDATE_SUPPLIER_FAILURE,
    });
  }
}

export function* getZones() {
  try {
    const { data } = yield call(() => getZonesApi());

    yield put({
      payload: data,
      type: GET_ZONES_SUCCESSFUL,
    });
  } catch (error) {
    yield call(() => toastr.error(error));
    yield put({
      error,
      type: GET_ZONES_FAILURE,
    });
  }
}

export function* saga() {
  yield all([
    takeEvery(GET_SUPPLIERS_REQUEST, getSuppliers),
    takeEvery(GET_SUPPLIER_BY_ID_REQUEST, getSupplierById),
    takeEvery(UPDATE_SUPPLIER_REQUEST, updateSupplier),
    takeEvery(GET_ZONES_REQUEST, getZones),
  ]);
}
