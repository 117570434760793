import React, { Fragment } from "react"
import PropTypes from "prop-types"
// Components

const Th = (props) => {
  return (
    <Fragment>
      <th className="table__cell th">Categories</th>
      <th className="table__cell th">Variations</th>
      <th className="table__cell th">Quantity</th>
      <th className="table__cell th">Average Cost</th>
      <th className="table__cell small-size hidden" />
    </Fragment>
  )
}

Th.propTypes = {
  checkedAll: PropTypes.bool

}

export default Th
