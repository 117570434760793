function toSnakeCase(param) {
  return param.replace(/([A-Z])/g, function($1) {
    return "_" + $1.toLowerCase()
  })
}

function objectToSnakeCasedKeys(param) {
  const outputObject = {}
  Object.keys(param).map((key) => {
    return (outputObject[toSnakeCase(key)] = param[`${key}`])
  })
  return outputObject
}

module.exports = objectToSnakeCasedKeys
