import styled from "styled-components"

export default styled.section`
  width: 100%;
  padding-top: 50px;
  
  .purge__item-title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .purge__box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .purge__item {
    flex-basis: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    
    &:first-child {
      text-align: center;
    }
  }
  
  .purge__list {
    max-height: 300px;
    padding-left: 0;
    margin-bottom: 0;
    border: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .purge__list-item {
    display: block;
    padding: 10px;
    list-style-type: none;
    cursor: pointer;
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
    
    &:hover,
    &:focus {
      background-color:#ccc;  
    }
    
    &:active {
      background-color:#7a7a7a;
    }
  }
  
  .purge__list-item.active {
    color: #fff;
    background-color:#8a8a8a;
    border-bottom-color: #ccc;
  }
  
  .purge__list-item-none {
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  } 
`
