import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

// Import Components
import LinkStyle from "./style"

Link.propTypes = {
  // from parent Component
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]), // link text
  type: PropTypes.string.isRequired, // "link" (default), "button"
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

Link.defaultProps = {
  type: "link",
  href: "#",
  onClick: function() {}
}

function Link({ children, type, onClick, href }) {
  const lickClassNames = classnames({
    link: type !== "button",
    button: type === "button"
  })

  const link_clickHandler = (event) => {
    event && event.preventDefault && event.preventDefault()
    onClick()
  }

  return (
    <LinkStyle href={href} onClick={link_clickHandler} className={lickClassNames}>
      {children}
    </LinkStyle>
  )
}

export default Link
