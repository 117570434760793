import React, { Fragment, Component } from "react"

import ReactSwitch from "react-switch"

class Switch extends Component {

  componentDidMount() {
    const { input, roomName } = this.props

    if (roomName === "Entire Unit") {
      input.onChange(input.value = 1)
    }
  }

  render() {
    const { input, roomName } = this.props

    return (
      <Fragment>
        <ReactSwitch
          checked={
            roomName && roomName === "Entire Unit" ? true : !!input.value
          }
          disabled={roomName === "Entire Unit"}
          onChange={input.onChange}
          onColor="#1ab394"
        />
        <input
          hidden
          {...input}
          checked={
            roomName && roomName === "Entire Unit" ? true : !!input.value
          }
          id={`${input.name}-id`}
          type="checkbox"
        />
      </Fragment>
    )
  }
}

export default Switch
