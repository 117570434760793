import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import CheckBoxTree from "react-checkbox-tree"
import _snakeCase from "lodash/fp/snakeCase"

// components
import StyledComponent from "./style"

class FilterItem extends PureComponent {
  static propTypes = {
    // from ModalFilterRule
    title: PropTypes.string,
    data: PropTypes.array,
    dataChecked: PropTypes.array,
    dataExpanded: PropTypes.array,
    onChangeFilter: PropTypes.func
  }

  static defaultProps = {
    data: [],
    dataChecked: [],
    dataExpanded: []
  }

  onCheck = (checked) => this.props.onChangeFilter(`${_snakeCase(this.props.title)}Checked`, checked)
  onExpand = (expanded) => this.props.onChangeFilter(`${_snakeCase(this.props.title)}Expanded`, expanded)

  render() {
    const { data, dataChecked, dataExpanded, title } = this.props

    return (
      <StyledComponent>
        <h2 className="filter-item__heading">{title}</h2>
        {!!data.length ? (
          <CheckBoxTree
            nodes={data}
            checked={dataChecked}
            expanded={dataExpanded}
            onCheck={this.onCheck}
            onExpand={this.onExpand}
          />
        ) : (
          <p className="filter-item__loading">Loading...</p>
        )}
      </StyledComponent>
    )
  }
}

export default FilterItem
