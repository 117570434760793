import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import { Route } from "react-router-dom"
import { Provider } from "react-redux"
import { ConnectedRouter } from "react-router-redux"
import ReduxToastr from "react-redux-toastr"
import { PersistGate } from "redux-persist/integration/react"
import configureStore, { history } from "./Redux/store"
import App from "./App/App"

const { store, persistor } = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Fragment>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          closeButton
          closeOnToastrClick
        />
      </Fragment>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
)
