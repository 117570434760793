import React, { useEffect } from "react"
import PropTypes from "prop-types"

// Import Components
import LayoutStyle from "./style"
import { Button, Preloader } from "../../Shared"

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  button_clickHandler: PropTypes.func,
  pageTitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  getItem: PropTypes.func
}

function Layout({ getItem, children, button_clickHandler, pageTitle, buttonTitle, isLoading }) {
  useEffect(() => {
    if (!getItem) return
    getItem()
  }, [])

  return (
    <LayoutStyle>
      <Preloader isLoading={isLoading}>
        <header className="page-header">
          <h1 className="page-title">{pageTitle}</h1>
          <Button
            type="button"
            size="large"
            uppercase
            width={200}
            onClick={button_clickHandler}
          >
            {buttonTitle}
          </Button>
        </header>
        <div className="page-body">
          {children}
        </div>
      </Preloader>
    </LayoutStyle>
  )
}

export default Layout
