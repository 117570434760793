import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';
import {
  addRoomTypeCategory,
  checkedFurnitureCategoriesAction,
  getFurnitureCategoriesSelector,
  getRoomTypeSelector,
} from '../../../../../ducks/availableCategories';
// Components
import StyledTable from './style';
import StyledModal from './modalStyle';
import Th from './Th';
import FurnitureTd from './FurnitureTd';
import CategoryTd from './CategoryTd';
import { Button, ModalWindow } from '../../../../Shared';
import withModal from '../../../../HOCs/modal';

class Table extends PureComponent {
  static propTypes = {
    checkedFurnitureCategories: PropTypes.func,
    furnitureCategories: PropTypes.array,
    onChangeCategory: PropTypes.func,
    onChangeCategories: PropTypes.func,
    categories: PropTypes.object,
    onChangeSelectCategories: PropTypes.func,
    room: PropTypes.object,
    onChangeCategoriesAverageCost: PropTypes.func,
  };

  state = {
    checked: [],
    expanded: [],
  };

  componentDidMount() {
    this.setState({ expanded: [], checked: [] });
  }

  onCheck = (checked) => this.setState({ checked });

  onExpand = (expanded) => this.setState({ expanded });

  onButtonAddCategoryClick = () => {
    this.props.closeModal();
    this.setState({ expanded: [] });

    const newCategoriesFurniture = this.props.categoriesFurniture.reduce((acc, category) => {
      if (category.children) {
        category.children.forEach((item) => {
          if (this.state.checked.includes(`${item.value}`)) {
            acc[item.parent_id]
              ? acc[item.parent_id] = [...acc[item.parent_id], item]
              : acc[item.parent_id] = [item];
          }
        });
      }

      return acc;
    }, {});

    const newRoomTypeCategory = this.props.room.style_design_categories_with_parent.reduce((acc, parent) => {
      if (newCategoriesFurniture[parent.value]) {
        acc.push({
          ...parent,
          children: newCategoriesFurniture[parent.value],
        });
      } else {
        acc.push(parent);
      }

      return acc;
    }, []);

    this.props.addRoomTypeCategory(newRoomTypeCategory);
  };

  onButtonDeleteCategoryClick = (categoryId, parentId) => {
    const roomCategoriesWithParent = this.props.room.style_design_categories_with_parent;

    const newRoomCategories = roomCategoriesWithParent.map((roomType) => {
      if (parseInt(roomType.value, 10) === parseInt(parentId, 10)) {
        return {
          ...roomType,
          children: roomType.children.filter((category) => category.value !== categoryId),
        };
      }

      return roomType;
    });

    this.props.addRoomTypeCategory(newRoomCategories);
  };

  onButtonDeleteClick = (categoryId, parentId) => {
    const newChecked = this.state.checked.filter((id) => parseInt(id, 10) !== parseInt(categoryId, 10));
    this.setState(
      { checked: newChecked },
      () => {
        this.onButtonDeleteCategoryClick(categoryId, parentId);
      }
    );
  };

  render() {
    const {
      data, furnitureCategories, isModalOpen, onChangeCategory, categories, onChangeCategories,
    } = this.props;
    return (
      <>
        <StyledTable>
          <thead>
            <tr>
              <Th />
            </tr>
          </thead>
          <tbody>
            {furnitureCategories && furnitureCategories.map((item) => (
              <Fragment key={item.value}>
                <tr>
                  <FurnitureTd furnitureName={item.label} openModal={this.props.openModal} categories={categories} />
                </tr>
                {item.children.map((category) => (
                  <tr key={category.value}>
                    <CategoryTd
                      parentId={item.value}
                      category={category}
                      onChangeCategory={onChangeCategory}
                      onChangeCategories={onChangeCategories}
                      onButtonDeleteClick={this.onButtonDeleteClick}
                      categories={categories}
                      onChangeSelectCategories={this.props.onChangeSelectCategories}
                      onChangeCategoriesAverageCost={this.props.onChangeCategoriesAverageCost}
                    />
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </StyledTable>
        {isModalOpen && (
          <ModalWindow closeModal={this.props.closeModal}>
            <StyledModal>
              <h1 className="modal-categories__title">Categories</h1>
              <CheckboxTree
                nodes={data}
                checked={this.state.checked}
                expanded={this.state.expanded}
                onCheck={this.onCheck}
                onExpand={this.onExpand}
              />
              <div className="modal-categories__button">
                <Button
                  width={200}
                  size="large"
                  onClick={this.onButtonAddCategoryClick}
                >
                  Add categories
                </Button>
              </div>
            </StyledModal>
          </ModalWindow>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  room: getRoomTypeSelector(state),
  categoriesFurniture: getFurnitureCategoriesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  checkedFurnitureCategories: (checkedFurnitureCategories) => dispatch(checkedFurnitureCategoriesAction(checkedFurnitureCategories)),
  addRoomTypeCategory: (categoriesWithParents) => dispatch(addRoomTypeCategory(categoriesWithParents)),
});

const enhancer = compose(
  withModal,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhancer(Table);
