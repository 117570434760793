import styled from "styled-components"

export default styled.div`
  width: 100%;
  padding-bottom: 50px;
  border-bottom: 2px solid rgba(89, 89, 89, 0.7);
  
  .merge-form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  } 
  
  .merge-form__item {
    flex-basis: 30%;
    flex-grow: 0;
    flex-shrink: 0;
  }
  
  .merge-form__control {
    margin-bottom: 25px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .merge-form__control .input {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .merge-form__item {
    &:nth-child(3) {
      padding-top: 44px;
    }
  }
  
  .merge-form__item-title {
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
  }
`
