import styled, { css } from "styled-components"

export default styled.div`
  ${({ width }) => css`width: ${width}px`};

  .input-counter__control {
    display: flex;
    align-items: center;
  }

  .input-counter__button {
    width: 40px;
    height: 40px;
    padding: 5px;
    font-weight: 400;
    font-size: 30px;
    line-height: 1;
    color: #4c4c4c;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .input-counter__button:hover,
  .input-counter__button:focus {
    color: #000;
  }
  
  .input-counter__button:active {
    opacity: 0.7;
  }
  
  .input-counter__button[disabled],
  .input-counter__button[disabled]:hover,
  .input-counter__button[disabled]:focus,
  .input-counter__button[disabled]:active {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .input-counter__text-field {
    max-width: calc(100% - 80px);
    flex-basis: 75px;
    flex-shrink: 0;
    flex-grow: 1;
    text-align: center;
    border: none;
    background-color: transparent;
  }
  
  .input-counter__messages-box {
    text-align: center;
    padding: 5px;
  }
  
  .input-counter__message-invalid {
    font-weight: 400;
    font-size: 13px;
    color: #ff3232;
  }
`
