import React, { useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Import Components
import { withRouter } from 'react-router-dom';
import { addNewVariantAction } from '../../../../ducks/availableTemplates';
import TabsStyle from './style';

Tabs.propTypes = {
  // from parent component
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  defaultTabIndex: PropTypes.number.isRequired,
  addNewVariantAction: PropTypes.func,
};

Tabs.defaultProps = {
  defaultTabIndex: 0,
};

function Tabs({
  children, defaultTabIndex, addNewVariantAction, match,
}) {
  const [activeTabIndex, activeTabIndex_handler] = useState(defaultTabIndex);

  const isEditMode = match.params.id !== 'new';

  const tab_clickHandler = function ({ target: { dataset: { index } } }) {
    window.localStorage.setItem('variantIndex', JSON.stringify(index));
    activeTabIndex_handler(index);
  };

  const renderNavigationWithTabsApiAsProps = function () {
    return Children.map(children, (child, index) => cloneElement(child, {
      onClick: tab_clickHandler,
      tabIndex: index,
      isActive: index === Number(activeTabIndex),
    }));
  };

  const renderActiveTabContent = function () {
    if (children[activeTabIndex] && !Array.isArray(children[activeTabIndex])) {
      return children[activeTabIndex].props.children;
    }

    return children[1][activeTabIndex - 1] && children[1][activeTabIndex - 1].props.children;
  };

  const addNewVariantHandler = () => {
    const { length } = children[1];
    addNewVariantAction();
    activeTabIndex_handler(length + 1);
  };

  return (
    <TabsStyle>
      <ul className="tabs__navigation-list">
        {renderNavigationWithTabsApiAsProps()}
        {isEditMode
        && <button type="button" className="tabs__navigation-item button" onClick={addNewVariantHandler}>Add new</button>}
      </ul>
      <div className="tabs__content">
        {renderActiveTabContent()}
      </div>
    </TabsStyle>
  );
}

// Enhance
const enhance = compose(
  withRouter,
  connect(null, { addNewVariantAction, push })
);

export default enhance(Tabs);
