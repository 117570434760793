import React, { Component } from "react"
import Select from "react-select"

import ImagesContainer from "../../Shared/ImagesContainer"
import CustomMultiselect from "../../Shared/CustomMultiselect"

import { faAngleDown, faTimes } from "@fortawesome/free-solid-svg-icons"

export default class RoomInfo extends Component {
  state = {
    selectValue: {
      label: "Select..."
    }
  }

  componentDidMount() {
    const currentValue = this.setCurrentValue()
    if (!currentValue) return false
    if (this.props.room.room.floorplan_id) {
      this.setState({
        selectValue: currentValue
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.floorPlans.length !== prevProps.floorPlans.length) {
      const currentValue = this.setCurrentValue()
      if (this.props.room.room.floorplan_id) {
        this.setState({
          selectValue: currentValue
        })
      }
    }
  }

  setCurrentValue = () => {
    const currentPlan = this.props.floorPlans
      ? this.props.floorPlans.filter((plan) => plan.value === this.props.room.room.floorplan_id)
      : []
    if (currentPlan.length === 0) return false
    else return currentPlan[0]
  }

  showUpload = () => {
    const isCreateFloorPlan = true
    this.props.createNewPlan(isCreateFloorPlan)
  }

  handleSelectFloorPlan = (e) => {
    this.setState({
      selectValue: e
    })
    this.props.onSelectFloorPlan(e)
  }

  render() {
    const {
      getSelectedList,
      getSelectedStyle,
      room_categories,
      styles,
      floorPlans,
      room,
      getFloorPlanImage,
      editPlan,
      onImagesChange,
      onInputChange,
      onCategoryChange,
      onStyleChange,
      getImagesList,
      onValidate,
      onSave
    } = this.props

    const { selectValue } = this.state
    const { style, description, sku, width, depth, permalink } = room.product

    return (
      <div className="room-attributes">
        <div className="row room-row">
          <div className="col-lg-3">
            <p className="variant-name">ROOM CATEGORY: *</p>
          </div>
          <div className="col-lg-9">
            <div className={`${onValidate(room.product_category_ids)}`}>
              <CustomMultiselect
                value={getSelectedList(room_categories, room.product_category_ids)}
                options={room_categories}
                onChange={onCategoryChange}
                typeFirstIcon={faTimes}
                typeSecondIcon={faAngleDown}
              />
            </div>
            <input className={`form-control invisible-input ${onValidate(room.product_category_ids)}`} />
            <div className="invalid-feedback">Please choose a category.</div>
          </div>
        </div>
        <div className="row room-row">
          <div className="col-12 col-md-3">
            <p>STYLE:</p>
          </div>
          <div className="col-12 col-md-9">
            <Select
              className={"select2-single"}
              value={getSelectedStyle(styles, style)}
              options={styles}
              onChange={onStyleChange}
            />
          </div>
        </div>
        <div className="row room-row">
          <div className="col-lg-3">
            <p className="variant-name">DECRIPTION:</p>
          </div>
          <div className="col-lg-9">
            <textarea name="description" value={description || ""} onChange={onInputChange} />
          </div>
        </div>
        <div className="row room-row">
          <div className="col-lg-3">
            <p className="room-row-title">SKU: *</p>
          </div>
          <div className={"col-lg-9"}>
            <input
              name="sku"
              value={sku}
              className={`form-control ${onValidate(sku)}`}
              onChange={(e) => onInputChange(e)}
            />
            <div className="invalid-feedback">Please choose a sku.</div>
          </div>
        </div>
        <div className="row room-row">
          <div className="col-lg-3">
            <p className="room-row-title">PERMALINK: </p>
          </div>
          <div className={"col-lg-9"}>
            <input
              name="permalink"
              value={permalink}
              className={`form-control ${onValidate(permalink)}`}
              onChange={(e) => onInputChange(e)}
            />
          </div>
        </div>
        <div className="row room-images">
          <div className="col-lg-3">
            <p>IMAGES:</p>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <ImagesContainer
                images={getImagesList(room.product)}
                onImagesChange={(file, url, index) => onImagesChange(file, url, index)}
                imageSize="large"
                customStyles="room"
              />
            </div>
          </div>
        </div>
        <div className="row room-products">
          <div className="col-lg-3">
            <p>FLOOR PLAN:</p>
            <span className="plan-text" onClick={this.showUpload}>
              or create your plan
            </span>
          </div>
          <div className="col-lg-9">
            <div className="row justify-content-around">
              <div className="col-lg-5 pl-0 flex">
                <Select
                  options={floorPlans}
                  onChange={this.handleSelectFloorPlan}
                  value={{ label: selectValue.label, value: selectValue.value }}
                />
              </div>
              <div className="col-lg-5">
                <div className="row room-row">
                  <div className="col-lg-4">
                    <p className="variant-name">WIDTH:</p>
                  </div>
                  <div className="col-lg-8">
                    <input
                      name="width"
                      disabled
                      value={this.state.selectValue.label !== "Select..." ? selectValue.width : width}
                    />
                  </div>
                </div>
                <div className="row room-row">
                  <div className="col-lg-4">
                    <p className="variant-name">HEIGHT:</p>
                  </div>
                  <div className="col-lg-8">
                    <input
                      name="depth"
                      disabled
                      value={this.state.selectValue.label !== "Select..." ? selectValue.height : depth}
                    />
                  </div>
                </div>
                {this.state.selectValue.label !== "Select..." && (
                  <div className="row room-row">
                    <div className="col-lg-8 p-0 mt-4">
                      <ImagesContainer
                        images={[getFloorPlanImage(room.room)]}
                        link={true}
                        toEditPlan={editPlan}
                        imageSize="large"
                        customStyles="room"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="room-page__save-wrapper">
          <button className="button" type="button" onClick={() => onSave()}>
            SAVE
          </button>
        </div>
      </div>
    )
  }
}
