import http from "./axios.config"
import { API } from "../constants"

export default class Http {
  static getRoomTypes() {
    return http({
      method: "GET",
      url: `${API}room_types`
    })
      .then((res) => res.data)
  }

  static getFurnitureCategories() {
    return http({
      method: "GET",
      url: `${API}room_types/design_categories_options`
    })
      .then((res) => res.data)
  }

  static createRoomType(roomData) {
    return http({
      method: "POST",
      url: `${API}room_types`,
      data: roomData
    })
      .then((res) => res.data)
  }

  static getFurnitureCategory(idCategory) {
    return http({
      method: "GET",
      url: `${API}room_types/${idCategory}/edit`
    })
      .then((res) => res.data)
  }

  static updateRoomType(idCategory, roomData) {
    return http({
      method: "PUT",
      url: `${API}room_types/${idCategory}`,
      data: roomData
    })
      .then((res) => res.data)
  }
}
