import React, { Fragment } from "react"
import PropTypes from "prop-types"

Counter.propTypes = {
  // from ReduxFormInputCounter
  input: PropTypes.object.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool
}

function Counter({
                   input: { onChange, value, ...restInput },
                   meta: { error, touched, active },
                   defaultValue
                 }) {
  let initValue = (value || value === 0) ? +value : +defaultValue

  if (active) {
    initValue = +value
  }

  const buttonIncrement_clickHandler = function() {
    onChange(initValue + 1)
  }

  const buttonDecrement_clickHandler = function() {
    if (initValue <= 0) {
      initValue = 0
    }
    else {
      initValue -= 1
    }
    onChange(initValue)
  }

  const messagesBox = (
    <div className="input-counter__messages-box">
      {
        error && (
          <span className="input-counter__message-invalid">
            {error}
          </span>
        )
      }
    </div>
  )

  const showMessagesBox = (touched && (error)) && messagesBox

  return (
    <Fragment>
      <div className="input-counter__control">
        <button
          onClick={buttonDecrement_clickHandler}
          type="button"
          className="input-counter__button"
          disabled={initValue <= 0}
        >
          -
        </button>
        <input
          {...restInput}
          value={initValue}
          onChange={onChange}
          type="text"
          className="input-counter__text-field"
        />
        <button
          type="button"
          onClick={buttonIncrement_clickHandler}
          className="input-counter__button"
        >
          +
        </button>
      </div>
      {showMessagesBox}
    </Fragment>
  )
}

export default Counter
