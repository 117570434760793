import { call, put, takeEvery, all } from "redux-saga/effects"
import { getFilterData } from "../api"
import { appName } from "../constants"

/**
 * Constants
 * */
export const moduleName = "filter"
const prefix = `${appName}/${moduleName}`

export const FILTER_DATA_REQUEST = `${prefix}/FILTER_DATA_REQUEST`
export const FILTER_DATA_SUCCESS = "FILTER_DATA_SUCCESS"
export const FILTER_DATA_ERROR = "FILTER_DATA_ERROR"

/**
 * Reducer
 * */

const initState = {
  filter: {},
  loading: false,
  loaded: false
}

export default function reducer(state = initState, action) {
  const { type, payload } = action

  switch (type) {
    case FILTER_DATA_REQUEST:
      return {
        filter: {},
        loading: true,
        loaded: false
      }

    case FILTER_DATA_SUCCESS:
      return {
        filter: payload,
        loading: false,
        loaded: true
      }

    default:
      return state
  }
}

/**
 * Selectors
 * */

export const filterSelector = (state) => state

/**
 * Action Creators
 * */

export const getFilterOptions = () => {
  return {
    type: FILTER_DATA_REQUEST
  }
}

const filterToSuccess = (data) => ({
  type: FILTER_DATA_SUCCESS,
  payload: data
})

const filterWithError = (error) => ({ type: FILTER_DATA_ERROR, ...error })

/**
 * Sagas
 **/

function* filterData() {
  try {
    const result = yield call(() => getFilterData())
    yield put(filterToSuccess(result.data))
  } catch (error) {
    yield put(filterWithError(error))
  }
}

export function* saga() {
  yield all([takeEvery(FILTER_DATA_REQUEST, filterData)])
}
