import React from "react"
import PropTypes from "prop-types"

// components
import Th from "./Th"

TableHead.propTypes = {
  // from TableConstructor component
  tableHead: PropTypes.array.isRequired,
  onCheckboxSelectedAll: PropTypes.func,
  selectedAllValue: PropTypes.bool
}

TableHead.defaultProps = {
  tableHead: []
}

function TableHead({ tableHead, onCheckboxSelectedAll, selectedAllValue }) {
  const thTags = tableHead.map(({ sort, text, filter }, index) => {
    return (
      <Th
        key={index}
        text={text}
        sort={sort}
        filter={filter}
        selectedAllValue={selectedAllValue}
        onCheckboxSelectedAll={onCheckboxSelectedAll}
      />
    )
  })

  return (
    <thead className="table__header">
      <tr className="table__row">{thTags}</tr>
    </thead>
  )
}

export default TableHead
