import React from 'react';
import PropTypes from 'prop-types';

// Misc
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import transformValueForSubmit from '../../../utils/transformValuesReduxForm';
import transformValueForSubmitEdit from '../../../utils/transformValuesReduxFormEdit';
import objectToFormData from '../../../utils/objectToFormData';

// Redux
import {
  createAvailableDesignStyleRequest,
  updateAvailableDesignStyleRequest,
  getAvailableDesignStyleByIdRequest,

  getIsLoading,
} from '../../../ducks/availableDesignStyles';

// Import Components
import { DesignTemplatesLayout } from '../index';
import CreateForm from './DetailCreateForm';
import EditForm from './DetailEditForm';

DesignStylesDetail.propTypes = {
  // from connect/react-router-redux
  goBack: PropTypes.func,
  // from connect/availableDesignStylesReducer
  createAvailableDesignStyleRequest: PropTypes.func,
  updateAvailableDesignStyleRequest: PropTypes.func,
  getIsLoading: PropTypes.func,
  isLoading: PropTypes.bool,
};

function DesignStylesDetail({
  getAvailableDesignStyleByIdRequest, goBack, createAvailableDesignStyleRequest, updateAvailableDesignStyleRequest, isLoading, match: { params: { id } },
}) {
  const isEditMode = id !== 'new';

  const form_submitHandler = function (values) {
    if (isEditMode) {
      const data = transformValueForSubmitEdit(values);
      const designStyle = { design_style: { ...data } };
      updateAvailableDesignStyleRequest(id, objectToFormData(designStyle));
    } else {
      const data = transformValueForSubmit(values);
      const designStyle = { design_style: { ...data } };
      createAvailableDesignStyleRequest(objectToFormData(designStyle));
    }
  };

  const buttonGoBack_clickHandler = function () {
    goBack();
  };

  const getItem = function () {
    if (id !== 'new') {
      getAvailableDesignStyleByIdRequest(id);
    }
  };

  return (
    <DesignTemplatesLayout
      isLoading={isLoading}
      pageTitle="Styles Detail"
      buttonTitle="Go back"
      getItem={getItem}
      button_clickHandler={buttonGoBack_clickHandler}
    >
      {isEditMode ? <EditForm onSubmit={form_submitHandler} /> : <CreateForm onSubmit={form_submitHandler} />}
    </DesignTemplatesLayout>
  );
}

// Enhance
const mapStateToProps = (state) => ({
  isLoading: getIsLoading(state),
});
const mapDispatchToProps = {
  goBack,
  createAvailableDesignStyleRequest,
  updateAvailableDesignStyleRequest,
  getAvailableDesignStyleByIdRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(DesignStylesDetail);
