import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _set from 'lodash/fp/set';
import _get from 'lodash/fp/get';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import objectToFormData from '../../../../utils/objectToFormData';
// Routing
// Redux
import {
  getCategoriesValues,
  getFurnitureCategoriesSelector,
  getRoomTypeSelector,
  makeAddFurnitureCategoriesSelector,
  updateRoomTypeAction,
} from '../../../../ducks/availableCategories';
// Components
import { Button } from '../../../Shared';
import { EditFormStyle } from './style';
import Table from './Table';
import DropZone from '../DropZone';

class EditForm extends Component {
  static propTypes = {
    furnitureCategories: PropTypes.array.isRequired,
    addFurnitureCategories: PropTypes.array,
    updateRoomType: PropTypes.func,
    room: PropTypes.object,
    categoriesValues: PropTypes.object,
  };

  state = {
    furnitureCategory: {
      value: this.props.room.label || '',
      error: null,
    },
    is_public: this.props.room.is_public || false,
    position: this.props.room.position,
    icon_url: _get('room.icon_url.url', this.props) || [],
    categories: {},
    removeCategoryIds: [],
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addFurnitureCategories !== this.props.addFurnitureCategories) {
      this.initializeCategories();
    }
    if (prevProps.room.label === void 0) {
      this.initValues();
      this.initializeCategories();
    }
  }

  // Init values
  initValues = () => {
    if (this.props.room.label) {
      this.setState((prevState) => ({
        ...prevState.furnitureCategory,
        value: this.props.room.label,
        position: this.props.room.position,
      }));
    }
  };
  // End Init values

  // Categories
  initializeCategories = () => {
    const { room, categoriesValues } = this.props;

    const categories = room.style_design_categories_with_parent.reduce((acc, category) => {
      if (category.children.length) {
        category.children.forEach((item) => {
          const quantity = _get('quantity', categoriesValues[item.value]);
          const average_cost = _get('average_cost', categoriesValues[item.value]);
          const variation = _get('variation', categoriesValues[item.value]);

          acc[item.value] = {
            id: item.relation_id || '',
            product_category_id: item.value,
            quantity: quantity || item.quantity || 0,
            average_cost: average_cost || item.average_cost || 0,
            variation: variation || item.variation || 'not_set',
          };
        });
      }

      return acc;
    }, {});

    this.setState({ categories });
  };

  onChangeCategories = (idCategory, event) => {
    const { target } = event;
    const { name, value } = target;

    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set(`${name}`, value, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeCategoriesAverageCost = (idCategory, averageCost) => {
    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set('average_cost', averageCost, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeSelectCategories = (idCategory, selected) => {
    const { value } = selected;

    this.setState((prevState) => ({
      categories: {
        ...prevState.categories,
        [idCategory]: _set('variation', value, prevState.categories[idCategory]),
      },
    }));
  };

  onChangeRemoveCategory = (event) => {
    const { target } = event;
    const { name, checked } = target;

    this.setState((prevState) => ({
      removeCategoryIds: {
        ...prevState.removeCategoryIds,
        [name]: checked,
      },
    }));
  };
  // End Category

  // Submit form
  formOnSubmit = (event) => {
    event && event.preventDefault && event.preventDefault();
    const {
      furnitureCategory, categories, is_public, position, icon_url,
    } = this.state;

    const newCategories = Object.values(categories).map((item) => {
      if (this.state.removeCategoryIds[item.product_category_id]) {
        return { ...item, _destroy: true };
      }

      return { ...item, _destroy: false };
    });

    if (!this.disabledSubmit()) {
      const data = {
        design_room: {
          name: furnitureCategory.value,
          icon_url: icon_url[0],
          is_public,
          position,
          style_design_categories_attributes: newCategories.length ? [...newCategories] : [[]],
        },
      };

      if (newCategories.length) {
        data.design_room.style_design_categories_attributes = [...newCategories];
      }

      this.props.updateRoomType(this.props.match.params.id, objectToFormData(data));
    }
  };

  disabledSubmit = () => !this.state.furnitureCategory.value;
  // End Submit form

  // Input "Room name"
  inputOnChange = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        value: target.value,
      },
    }));
  };

  inputOnFocus = ({ target }) => {
    this.setState((prevState) => ({
      [target.name]: {
        ...prevState[target.name],
        error: null,
      },
    }));
  };

  inputOnBlur = ({ target }) => {
    if (!target.value) {
      this.setState((prevState) => ({
        [target.name]: {
          ...prevState[target.name],
          error: 'This field is required',
        },
      }));
    }
  };

  // Input "Room position"
  inputOnChangePosition = ({ target }) => {
    this.setState({
      position: target.value,
    });
  };

  inputOnFocusPosition = ({ target }) => {
    this.setState((prevState) => ({
      [target.position]: {
        ...prevState[target.name],
        error: null,
      },
    }));
  };

  inputOnBlurPosition = ({ target }) => {
    if (!target.value) {
      this.setState((prevState) => ({
        [target.position]: {
          ...prevState[target.name],
          error: 'This field is required',
        },
      }));
    }
  };

  // End Input "Room name"

  // Switch "Is public"
  onSwitchChange = () => this.setState((prevState) => ({ is_public: !prevState.is_public }));
  // End switch

  // FileUploader "Is public"
  onFileUploaderChange = (files) => this.setState({ icon_url: files });

  // End FileUploader

  render() {
    const { furnitureCategory: { value, error }, position } = this.state;
    const inputLabelClassNames = cn('form-control__label', { 'error': !!error });
    const inputClassNames = cn('form-control__input', { 'error': !!error });

    return (
      <EditFormStyle>
        <form noValidate className="form" onSubmit={this.formOnSubmit}>
          <div className="form-control__wrapper">
            <label htmlFor="furnitureCategory-id" className={inputLabelClassNames}>Room name</label>
            <div className="form-control__input-wrapper">
              <input
                className={inputClassNames}
                name="furnitureCategory"
                id="furnitureCategory-id"
                type="text"
                value={value}
                onChange={this.inputOnChange}
                onFocus={this.inputOnFocus}
                onBlur={this.inputOnBlur}
              />
              {error && <span className="form-control__error">{error}</span>}
            </div>
          </div>

          <div className="form-control__wrapper">
            <label htmlFor="position-id" className={inputLabelClassNames}>Room position</label>
            <div className="form-control__input-wrapper">
              <input
                className={inputClassNames}
                name="position"
                id="position-id"
                type="text"
                value={position}
                onChange={this.inputOnChangePosition}
                onFocus={this.inputOnFocusPosition}
                onBlur={this.inputOnBlurPosition}
              />
              {error && <span className="form-control__error">{error}</span>}
            </div>
          </div>

          <div className="form-control__row">
            <div className="form-control__switch-wrapper">
              <p className="form-control__label no-padding">Is public</p>
              <Switch onChange={this.onSwitchChange} checked={this.state.is_public} />
            </div>
            <div className="form-control__file-uploader-wrapper">
              <DropZone
                fileUrl={this.state.icon_url}
                onFileUploaderChange={this.onFileUploaderChange}
              />
            </div>
          </div>
          <div className="form__table">
            <Table
              data={this.props.furnitureCategories}
              onChangeCategory={this.onChangeRemoveCategory}
              onChangeCategories={this.onChangeCategories}
              onChangeSelectCategories={this.onChangeSelectCategories}
              categories={this.state.categories}
              furnitureCategories={this.props.room.style_design_categories_with_parent}
              onChangeCategoriesAverageCost={this.onChangeCategoriesAverageCost}
            />
          </div>
          <div className="form__button-wrapper">
            <Button
              type="submit"
              uppercase
              size="large"
              width={150}
              disabled={this.disabledSubmit()}
            >
              Save
            </Button>
          </div>
        </form>
      </EditFormStyle>
    );
  }
}

const mapStateToProps = (state) => {
  const addFurnitureCategoriesSelector = makeAddFurnitureCategoriesSelector();

  return {
    room: getRoomTypeSelector(state),
    furnitureCategories: getFurnitureCategoriesSelector(state),
    addFurnitureCategories: addFurnitureCategoriesSelector(state),
    categoriesValues: getCategoriesValues(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRoomType: (roomId, roomData) => dispatch(updateRoomTypeAction(roomId, roomData)),
});

const enhancer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhancer(EditForm);
