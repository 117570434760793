import PropTypes from 'prop-types';

export const productShape = PropTypes.shape({
  cost_cad: PropTypes.string.isRequired,
  cost_usd: PropTypes.string.isRequired,
  default_image: PropTypes.shape({
    catalog: PropTypes.shape({
      url: PropTypes.string,
    }),
    large: PropTypes.shape({
      url: PropTypes.string,
    }),
    medium: PropTypes.shape({
      url: PropTypes.string,
    }),
    thumb: PropTypes.shape({
      url: PropTypes.string,
    }),
    url: PropTypes.string,
  }).isRequired,
  depth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  id: PropTypes.number,
  last_updated_date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  stock_number: PropTypes.number,
  supplier: PropTypes.string.isRequired,
  url_default_image: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}).isRequired;
