import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

// Import Components
import { DKCheckbox, Link } from '../../Shared';
import Td from '../../Shared/TableConstructor/Td';

TableTr.propTypes = {
  // from TableConstructor
  onCheckboxSelected: PropTypes.func,
  selected: PropTypes.array,
  item: PropTypes.object,
};

function TableTr({
  item, checkbox, onCheckboxSelected, selected, push,
}) {
  const rowClassName = classNames({
    table__row: true,
    checked: checkbox.value,
  });

  const renderItem = (item) => {
    if (Array.isArray(item) && !!item.length) {
      return item.map((text) => <span key={text}>{text} </span>);
    }
    if (item === null || item.length === 0) {
      return <span className="item-null">&mdash;</span>;
    }

    return item;
  };

  const renderItemRoomLabels = (item) => {
    if (Array.isArray(item.design_rooms) && !!item.design_rooms.length) {
      if (item.design_rooms.length > 1) {
        return item.design_rooms.map((room_label) => room_label.label).join(', ');
      }
      return item.design_rooms.map((room_label) => <span key={room_label.label}>{room_label.label} </span>);
    }
    if (item.design_rooms === null || item.design_rooms.length === 0) {
      return <span className="item-null">&mdash;</span>;
    }
  };

  const renderItemTags = (item) => {
    if (Array.isArray(item.tags) && !!item.tags.length) {
      if (item.tags.length > 1) {
        return item.tags.map((tag) => tag.name).join(', ');
      }
      return item.tags.map((tag) => <span key={tag.name}>{tag.name} </span>);
    }
    if (item.tags === null || item.tags.length === 0) {
      return <span className="item-null">&mdash;</span>;
    }
  };

  const link_click = function () {
    push(`/rooms/${item.product_id}`);
  };

  return (
    <Fragment key={item.id}>
      <tr className={rowClassName}>
        <Td center rowspan={item.room_variants.length + 1}>
          {!!selected.length && <DKCheckbox name={item.id} value={checkbox.value} onChange={onCheckboxSelected} />}
        </Td>
        <Td rowspan={item.room_variants.length + 1}>{renderItem(item.permalink)}</Td>
        <Td rowspan={item.room_variants.length + 1}>
          <Link type="link" href="#" onClick={link_click}>
            {renderItem(item.name)}
          </Link>
        </Td>
        <Td>{renderItem('')}</Td>
        <Td>${renderItem(item.cad)}</Td>
        <Td>${renderItem(item.usd)}</Td>
        <Td>{JSON.stringify(renderItem(item.active))}</Td>
        <Td>{JSON.stringify(renderItem(item.featured))}</Td>
        <Td>{renderItem('')}</Td>
        <Td>{renderItem('')}</Td>
      </tr>
      {
        !!item.room_variants.length && item.room_variants.map((variant) => (
          <tr className={rowClassName} key={variant.id}>
            {item.room_variants.length ? null : <Td center>{renderItem('')}</Td>}
            {item.room_variants.length ? null : <Td center>{renderItem('')}</Td>}
            {item.room_variants.length ? null : <Td center>{renderItem('')}</Td>}
            <Td>{renderItem(variant.name)}</Td>
            <Td>${renderItem(variant.cad)}</Td>
            <Td>${renderItem(variant.usd)}</Td>
            <Td>{JSON.stringify(renderItem(variant.active))}</Td>
            <Td>{JSON.stringify(renderItem(variant.featured))}</Td>
            <Td>{renderItemRoomLabels(variant)}</Td>
            <Td>{renderItemTags(variant)}</Td>
          </tr>
        ))
      }
    </Fragment>

  );
}

// Enhance
const mapDispatchToProps = { push };

const enhance = compose(
  connect(null, mapDispatchToProps)
);

export default enhance(TableTr);
