import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { reducer as toastrReducer } from 'react-redux-toastr';
import { reducer as formReducer } from 'redux-form';

import productsDataReducer from '../ducks/products';
import productsETLReducer from '../ducks/etl_products';
import tagsDataReducer from '../ducks/tags';
import inventoriesDataReducer from '../ducks/inventories';
import categoriesMappingDataReducer from '../ducks/categoriesMapping';
import productsAmountReducer from '../ducks/products';
import productDataReducer from '../ducks/product';
import tagDataReducer from '../ducks/tag';
import excelUploadReducer from '../ducks/productMany';
import filterDataReducer from '../ducks/filter';
import categoriesReducer from '../ducks/categories';
import modalReducer from '../ducks/modal';
import roomReducer from '../ducks/room';
import productsLTReducer from '../ducks/productsLT';
import categoryRulesReducer from '../ducks/categoryRules';
import selectorReducer from '../ducks/selector';
import paginationReducer from '../ducks/pagination';
import filtersReducer from '../ducks/filters';
import searchInputReducer from '../ducks/searchInput';
import tableReducer from '../ducks/table';
import attributeReducer from '../ducks/attribute';
import ruleReducer from '../ducks/rule';
import copyFurnitureItemsReducer from '../ducks/copyFurnitureItems';
import moveDesignRoomReducer from '../ducks/moveDesignRoom';
import designTemplatesReducer from '../ducks/designRuleMatching';
import availableColorsReducer from '../ducks/availableColors';
import availableTexturesReducer from '../ducks/availableTextures';
import availableRulesReducer from '../ducks/availableRules';
import availableFloorPlansReducer from '../ducks/availableFloorPlans';
import checkFloorPlanDisabledDesignRoomReducer from '../ducks/availableFloorPlans';
import getFloorPlanRoomInnerDesignRoomsReducer from '../ducks/availableFloorPlans';
import availableDesignStylesReducer from '../ducks/availableDesignStyles';
import roomsReducer from '../ducks/rooms';
import availableTemplatesReducer from '../ducks/availableTemplates';
import availableScrapersReducer from '../ducks/availableScrapers';
import availableInventorySuppliersReducer from '../ducks/availableInventorySuppliers';
import availableCategoriesReducer from '../ducks/availableCategories';
import authReducer from '../ducks/auth';
import routeReducer from '../ducks/router';
import dataStatusReducer from '../ducks/dataStatusReport';
import deliveryAgentsReducer from '../ducks/deliveryAgents';
import usersReducer from '../ducks/users';
import suppliersReducer from '../ducks/suppliers';
import roomTypesDataReducer from '../ducks/roomTypes';
import roomTypeDataReducer from '../ducks/roomType';

export default combineReducers({
  attributeReducer,
  authReducer,
  availableCategoriesReducer,
  availableColorsReducer,
  availableDesignStylesReducer,
  availableFloorPlansReducer,
  availableInventorySuppliersReducer,
  availableRulesReducer,
  availableScrapersReducer,
  availableTemplatesReducer,
  availableTexturesReducer,
  categories: categoriesReducer,
  categoriesMapping: categoriesMappingDataReducer,
  categoryRulesReducer,
  checkFloorPlanDisabledDesignRoomReducer,
  copyFurnitureItemsReducer,
  dataStatusReducer,
  deliveryAgentsReducer,
  designTemplatesReducer,
  filter: filterDataReducer,
  filtersReducer,
  form: formReducer,
  getFloorPlanRoomInnerDesignRoomsReducer,
  inventories: inventoriesDataReducer,
  logTable: excelUploadReducer,
  modal: modalReducer,
  moveDesignRoomReducer,
  paginationReducer,
  product: productDataReducer,
  products: productsDataReducer,
  productsAmount: productsAmountReducer,
  productsETL: productsETLReducer,
  productsLTReducer,
  room: roomReducer,
  roomsReducer,
  routeReducer,
  routing: routerReducer,
  ruleReducer,
  searchInputReducer,
  selectorReducer,
  suppliersReducer,
  tableReducer,
  tag: tagDataReducer,
  tags: tagsDataReducer,
  roomType: roomTypeDataReducer,
  roomTypes: roomTypesDataReducer,
  toastr: toastrReducer,
  usersReducer,
});
