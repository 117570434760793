import React from "react"
import PropTypes from "prop-types"

// Import Components
import { Field } from "redux-form"
import TextareaField from "./textarea"
import TextareaStyle from "./style"

Textarea.propTypes = {
  // from parent Components
  name: PropTypes.string.isRequired,
  label: PropTypes.string
}

function Textarea({ name, label }) {
  return (
    <TextareaStyle className="redux-form__input">
      {label && <label htmlFor={`${name}-id`} className="input__label">{label}</label>}
      <Field
        name={name}
        component={TextareaField}
      />
    </TextareaStyle>
  )
}

export default Textarea
