import axios from "axios"
import API_URL from "../config/env"
import { API } from "../constants"


const headers = {
  crossDomain: true,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
}

export const apiUrl = API_URL.apiUrl

export function getProductsRequest(pageNumber, perPage, search = "") {
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}product_attributes/search`,
    data: {
      page: pageNumber,
      per_page: perPage,
      q: search
    }
  }).then((response) => response.data)
}

export function getCategoryRulesRequest(pageNumber, perPage, search = "") {
  return axios({
    ...headers,
    method: "GET",
    url: `${apiUrl}categories_rules?page=${pageNumber}&per_page=${perPage}`,
  }).then((response) => response.data)
}

export function getListRoomsPermalinks(search = ""){
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}rooms/permalinks`,
    data: {
      query: search
    }
  }).then((response) => response.data)
}

export function moveRoomVariantToDestinationRoom(design_rooms_ids, destination_room_id, move_room_variant_id){
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}rooms/move`,
    data: {
      design_rooms_ids: design_rooms_ids,
      destination_room_id: destination_room_id,
      move_room_variant_id: move_room_variant_id
    }
  })
}

export function checkEqualsDesignRoomsRequest(move_room_id, destination_room_id, room_variant_id){
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}rooms/check_equals_design_rooms`,
    data: {
      move_room_id: move_room_id,
      destination_room_id: destination_room_id,
      room_variant_id: room_variant_id
    }
  })
}

export function getFiltersRequest() {
  return axios({
    ...headers,
    method: "GET",
    url: `${apiUrl}product_attributes/filter_options`
  }).then((response) => response.data)
}

export function postInputFilter(data) {
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}product_attributes/search`,
    data
  }).then((response) => response.data)
}

export function postAddAttribute(data) {
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}product_attributes`,
    data
  }).then((response) => response.data)
}

export function postAddRule(data) {
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}categories_rules`,
    data
  }).then((response) => response.data)
}

export function postCopyFurnitureItems(data) {
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}room_variants/copy_furniture`,
    data
  }).then((response) => response.data)
}

export function getAllMergeItems(type) {
  return axios({
    ...headers,
    method: "GET",
    url: `${apiUrl}${type}`
  }).then((response) => response.data)
}

export function getAllPurgeItems(type) {
  return axios({
    ...headers,
    method: "GET",
    url: `${apiUrl}${type}/purge_list`
  }).then((response) => response.data)
}

export function postAllMergeItems(type, parentId, childId) {
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}${type}/${parentId}/merge`,
    data: {
      child_id: childId
    }
  }).then((response) => response.data)
}

export function deletePurgeItems(ids, type) {
  return axios({
    ...headers,
    method: "DELETE",
    url: `${apiUrl}${type}`,
    data: {
      delete_ids: ids
    }
  }).then((response) => response.data)
}

export function postSearchAllMergeItems(type, text) {
  return axios({
    ...headers,
    method: "POST",
    url: `${apiUrl}${type}/search`,
    data: {
      q: text
    }
  }).then((response) => response.data)
}

export function getProductsByTypeId(type, id) {
  return axios({
    ...headers,
    method: "GET",
    url: `${apiUrl}${type}/${id}`
  }).then((response) => response.data)
}

export function updateRoomVariantActivity(id, type, value){
  return axios({
    ...headers,
    method: "PUT",
    url: `${API}room_variant_activity/${id}`,
    data: {type: type, value: value}
  })
}