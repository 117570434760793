import React, { Component } from 'react';
import { connect } from 'react-redux';

import { push } from 'react-router-redux';
import Spinner from '../../Components/Shared/Spinner';
import LogTable from '../../Components/CreateMany/LogTable';

import {
  changeCurrentPageDataStatus,
  excelDataStatus,
  getCurrentPageDataStatus,
  getDataTableRequestDataStatus,
  getPerPageDataStatus,
  getTotalEntriesDataStatus,
  loadingExcelDataStatusSelector,
} from '../../ducks/dataStatusReport';
import qs from '../../modules/normalizingQs';
import { showModal } from '../../ducks/modal';
import { filterSelector } from '../../ducks/filter';

class DataStatusReport extends Component {
  state = {
    params: {
      page: this.props.history.location.search,
    },
    modalIsOpen: false,
    q: undefined,
  };

  componentDidMount() {
    this.getQueryParams();
  }

  getQueryParams = () => {
    const {
      history: {
        location: { search },
      },
      getDataTableRequestDataStatus,
    } = this.props;
    const { q } = this.getQueryParamsFromUrl();
    this.setState({ q });
    const requestParams = `${search || `?${qs.stringify({ page: 1 })}`}`;
    getDataTableRequestDataStatus(requestParams);
  };

  getQueryParamsFromUrl = () => {
    const { currentPage, perPage } = this.props;
    return {
      page: currentPage,
      per_page: perPage,
    };
  };

  onParamsChange = (params) => {
    const { push, getDataTableRequestDataStatus } = this.props;
    const queryParams = qs.stringify({ ...this.getQueryParamsFromUrl(), ...params });
    const url = `?${queryParams}`;
    push(`/products-logs${url}`);
    getDataTableRequestDataStatus(url);
  };

  onPageChange = (page) => {
    this.props.changeCurrentPageDataStatus(page);
    this.onParamsChange({ page });
  };

  render() {
    const {
      loading, perPage, totalEntries, excelDataStatus,
    } = this.props;
    const {
      history: {
        location: { search },
      },
    } = this.props;

    const pageLoading = loading || excelDataStatus === null;

    if (pageLoading) {
      return (
        <div className="import-products">
          <Spinner />
        </div>
      );
    }

    return (
      <div className="import-products">
        <div className="import-products-wrapper">
          <LogTable
            loading={loading}
            excelDataStatus={excelDataStatus}
            name="data-status"
            currentPage={+qs.parse(search).page}
            perPage={perPage}
            totalEntries={totalEntries}
            paginationChange={this.onPageChange}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    excelDataStatus: excelDataStatus(state),
    loading: loadingExcelDataStatusSelector(state),
    currentPage: getCurrentPageDataStatus(state),
    perPage: getPerPageDataStatus(state),
    totalEntries: getTotalEntriesDataStatus(state),
    filters: filterSelector(state.filter),
  }),
  {
    changeCurrentPageDataStatus,
    getDataTableRequestDataStatus,
    showModal,
    push,
  }
)(DataStatusReport);
