import styled from "styled-components"

export default styled.header`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 160px;
  margin-bottom: 35px;

  .breadcrumbs {
    letter-spacing: 1px;
    color: #434343;
  }

  .page-header__item.buttons {
    align-self: flex-end;
  }

  .page-header__title {
    font-weight: 700;
    font-size: 45px;
    text-transform: uppercase;
    color: #434343;
  }
`
