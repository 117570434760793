import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { push } from 'react-router-redux';
import { getDataTableRequest } from '../../ducks/productsLT';
import { setPaginationValue } from '../../ducks/pagination';
import { getFiltersDataRequest, searchFilterRequest } from '../../ducks/filters';

import { breadcrumbs } from '../../constants/breadcrumbs';
import { attributeTagging as tableHeaders } from '../../constants/tableHeaders';

// components
import withModal from '../../Components/HOCs/modal';
import {
  Layout, PageHeader, TableConstructor, Preloader, ModalWindow,
} from '../../Components/Shared';
import { ButtonsBlock, TableFilters, ModalAddAttribute as AddAttributes } from '../../Components/AttributeTagging';
import { getIsAuthenticated } from '../../ducks/auth';

class AttributeTagging extends Component {
  static propTypes = {
    // from HOC modal
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    isModalOpen: PropTypes.bool,
    // from connect
    getDataTableRequest: PropTypes.func, // productsLTReducer
    tableData: PropTypes.array, // productsLTReducer
    isLoading: PropTypes.bool, // productsLTReducer
    perPage: PropTypes.number, // selectorReducer
    numberPage: PropTypes.number, // paginationReducer
    searchInputValue: PropTypes.string, // searchInputValue
  };

  state = {
    screenHeight: 0,
  };

  componentDidMount() {
    const {
      getDataTableRequest, getFiltersDataRequest, perPage, numberPage, searchInputValue, isAuthenticated,
    } = this.props;

    if (!isAuthenticated) {
      this.props.push('/login');
    }

    getDataTableRequest(numberPage, perPage, searchInputValue);
    getFiltersDataRequest();

    this.getScreenHeight();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.perPage !== this.props.perPage || prevProps.numberPage !== this.props.numberPage) {
      this.updatePaginationValue(this.props.totalEntries, this.props.perPage, this.props.numberPage);
      this.props.getDataTableRequest(this.props.numberPage, this.props.perPage, this.props.searchInputValue);
    }
  }

  getScreenHeight = () => {
    this.setState({
      screenHeight: document.documentElement.clientHeight,
    });
  };

  updatePaginationValue = (totalEntries, perPage, numberPage) => {
    const pages = Math.ceil(totalEntries / perPage);
    const pageNumber = numberPage > pages ? pages : numberPage;
    this.props.setPaginationValue(pageNumber);
  };

  openModal = () => {
    const { openModal } = this.props;
    openModal();
  };

  closeModal = () => {
    const { closeModal } = this.props;
    closeModal();
  };

  render() {
    const {
      isModalOpen, tableData, isLoading, numberPage, perPage, totalEntries, setPaginationValue,
    } = this.props;
    const { screenHeight } = this.state;
    const buttons = <ButtonsBlock addProduct={this.openModal} />;

    return (
      <>
        {isModalOpen && (
          <ModalWindow closeModal={this.closeModal}>
            <AddAttributes closeModal={this.closeModal} />
          </ModalWindow>
        )}
        <Layout>
          <Preloader isLoading={isLoading}>
            <PageHeader title="products" breadcrumbs={breadcrumbs.attributeTagging} buttonsBlock={buttons} />
            <TableFilters />
            {tableData.length ? (
              <TableConstructor
                name="attributeTagging"
                tableHead={tableHeaders}
                tableData={tableData}
                screenHeight={screenHeight}
                currentPage={numberPage}
                perPage={perPage}
                totalEntries={totalEntries}
                paginationChange={setPaginationValue}
              />
            ) : (
              <p style={{ textAlign: 'center', fontWeight: 700 }}>No data to display</p>
            )}
          </Preloader>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
  tableData: state.productsLTReducer.tableData,
  isLoading: state.productsLTReducer.isLoading,
  numberPage: state.paginationReducer.value,
  perPage: state.selectorReducer.value,
  totalEntries: state.productsLTReducer.totalEntries,
  searchInputValue: state.searchInputReducer.value,
});

const mapDispatchToProps = {
  getDataTableRequest,
  setPaginationValue,
  getFiltersDataRequest,
  searchFilterRequest,
  push,
};

export default compose(
  withModal,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AttributeTagging);
