import React, { Fragment } from "react"
import PropTypes from "prop-types"

ListItem.propTypes = {
  // from List Component
  active: PropTypes.bool,
  value: PropTypes.number,
  label: PropTypes.string,
  furnitureCount: PropTypes.number,
}

function ListItem({ value, label, furnitureCount, active }) {
  if (active) {
    return (
      <Fragment>
        {label} ({furnitureCount})
      </Fragment>
    )
  }
  return (
    <Fragment>
      {label}
    </Fragment>
  )

}

export default ListItem
