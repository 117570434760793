import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import ScraperFormStyle from './style';

class ScraperForm extends Component {
  state = {
    remote_product_link: '',
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      push('/login');
    }
  }

  handleSubmitProductUrl = (event) => {
    event.preventDefault();
  };

  render() {
    const { remote_product_link, handleChangeProductLinkInput } = this.props;

    return (
      <ScraperFormStyle>
        <form className="container-fluid product-scraper-form" onSubmit={this.handleSubmitProductUrl}>
          <div className="row mb-5pl-0 copy-item__box-select">
            <div className="col-lg-9 pl-2 flex copy-item__box-label">
              SKU of Product:
            </div>
            <div className="col-lg-12 pl-2 flex">
              <input
                type="text"
                onChange={handleChangeProductLinkInput}
                className="filter-left-side-search-field col-12"
                placeholder="Paste a SKU"
                value={(remote_product_link === undefined || remote_product_link === null) ? '' : remote_product_link}
              />
            </div>
          </div>
        </form>
      </ScraperFormStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roomsReducer.isLoading,
});

export default connect(
  mapStateToProps,
)(ScraperForm);
