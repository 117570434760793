import { call, put, takeEvery, all } from "redux-saga/effects"
import { Record } from "immutable"
import { getAllCategories } from "../api"
import { appName } from "../constants"
import { push } from "react-router-redux"

/**
 * Constants
 * */

export const moduleName = "categories"
const prefix = `${appName}/${moduleName}`

export const GET_ALL_CATEGORIES_REQUEST = `${prefix}/GET_ALL_CATEGORIES_REQUEST`
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS"
export const GET_ALL_CATEGORIES_ERROR = "GET_ALL_CATEGORIES_ERROR"

/**
 * Reducer
 * */

export const ReducerRecord = Record({
  loading: true,
  categories: null
})

export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload } = action

  switch (type) {
    case GET_ALL_CATEGORIES_REQUEST:
      return state.set("loading", true)
    case GET_ALL_CATEGORIES_SUCCESS:
      return state.set("loading", false).set("categories", payload)
    default:
      return state
  }
}

/**
 * Selectors
 * */

export const categoriesSelector = (state) => state.categories.categories
export const loadingCategoriesSelector = (state) => state.categories.loading

/**
 * Action Creators
 * */

export const getCategories = () => {
  return {
    type: GET_ALL_CATEGORIES_REQUEST
  }
}

const categoriesToSuccess = (data) => ({ type: GET_ALL_CATEGORIES_SUCCESS, payload: data })

const categoriesWithError = (error) => ({ type: GET_ALL_CATEGORIES_ERROR, ...error })

/**
 * Sagas
 **/

function* categoriesData() {
  const token = JSON.parse(window.localStorage.getItem("token"))

  if (!token) {
    yield put(push("/login"))
  } else {
    try {
      const result = yield call(() => {
        return getAllCategories()
      })
      yield put(categoriesToSuccess(result.data.data))
    }
    catch (error) {
      yield put(categoriesWithError(error))
    }
  }
}

export function* saga() {
  yield all([takeEvery(GET_ALL_CATEGORIES_REQUEST, categoriesData)])
}
