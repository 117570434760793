import React, { Component } from "react"
import PropTypes from "prop-types"
import Multiselect from "react-select"

class MultiSelectShoppeCategories extends Component {

  static propTypes = {
    shoppe_categories: PropTypes.array,
    selected_shoppe_categories: PropTypes.array,
    onShoppeCategoriesChange: PropTypes.func,
    getSelectedShoppeCategoriesList: PropTypes.func,
    remote_category_id: PropTypes.number
  }

  state = {
    shoppe_categories: [],
    selected_shoppe_categories: []
  }

  componentDidMount() {
  }

  onShoppeCategoriesChange = (shoppe_category) => {
    this.props.onShoppeCategoriesChange(shoppe_category, this.props.remote_category_id, this.props.selected_shoppe_categories)
  }

  onSubmit = (event) => {
    return false;
  }

  render() {
    const { shoppe_categories, selected_shoppe_categories, getSelectedShoppeCategoriesList } = this.props
    return (
      <Multiselect
        isMulti={true}
        className={"select2"}
        value={getSelectedShoppeCategoriesList(shoppe_categories, selected_shoppe_categories)}
        options={shoppe_categories}
        onChange={this.onShoppeCategoriesChange}
      />
    )
  }
}

export default MultiSelectShoppeCategories
