import { call, put, takeEvery, all } from "redux-saga/effects"
import { Record } from "immutable"
import { appName } from "../constants"

import {
  getCategoriesMapping,
  updateCategoriesMappingDataRequest,
  deleteCategoriesMappingDataRequest
} from "../api/etl-categories-mapping"

/**
 * Constants
 * */
export const moduleName = "categoriesMapping"
const prefix = `${appName}/${moduleName}`

export const REMOTE_CATEGORIES_ITEMS_REQUEST = `${prefix}/REMOTE_CATEGORIES_ITEMS_REQUEST`
export const REMOTE_CATEGORIES_ITEMS_SUCCESS = "REMOTE_CATEGORIES_ITEMS_SUCCESS"
export const REMOTE_CATEGORIES_ITEMS_ERROR = "REMOTE_CATEGORIES_ITEMS_ERROR"

export const UPDATE_CATEGORIES_ITEMS_REQUEST = `${prefix}/UPDATE_CATEGORIES_ITEMS_REQUEST`
export const UPDATE_CATEGORIES_ITEMS_SUCCESS = "UPDATE_CATEGORIES_ITEMS_SUCCESS"
export const UPDATE_CATEGORIES_ITEMS_ERROR = "UPDATE_CATEGORIES_ITEMS_ERROR"

export const DELETE_CATEGORIES_ITEMS_REQUEST = `${prefix}/DELETE_CATEGORIES_ITEMS_REQUEST`
export const DELETE_CATEGORIES_ITEMS_SUCCESS = "DELETE_CATEGORIES_ITEMS_SUCCESS"
export const DELETE_CATEGORIES_ITEMS_ERROR = "DELETE_CATEGORIES_ITEMS_ERROR"

/**
 * Reducer
 * */

export const ReducerRecord = Record({
  categoriesMapping: null,
  loading: false,
  loaded: false,
  tableLoaded: true
})

export default function reducer(state = new ReducerRecord(), action) {
  const { type, payload } = action

  switch (type) {
    case REMOTE_CATEGORIES_ITEMS_REQUEST:
      return state.set("loading", true).set("loaded", false)
    case REMOTE_CATEGORIES_ITEMS_SUCCESS:
      return state.set("loading", false).set("loaded", true).set("categoriesMapping", payload)
    case DELETE_CATEGORIES_ITEMS_REQUEST:
      return state.set("loading", false).set("loaded", true).set("categoriesMapping", payload)

    default:
      return state
  }
}

/**
 * Selectors
 * */

export const categoriesMappingSelector = (state) => state

/**
 * Action Creators
 * */

// Getting categories list
export const getCategoriesMappingData = (url) => {
  return {
    type: REMOTE_CATEGORIES_ITEMS_REQUEST,
    payload: url
  }
}

const categoriesMappingToSuccess = (data) => ({ type: REMOTE_CATEGORIES_ITEMS_SUCCESS, payload: data })

const categoriesMappingWithError = (error) => ({ type: REMOTE_CATEGORIES_ITEMS_ERROR, ...error })


export const updateCategoriesMappingItems = (scraper_id, remote_category_id, shoppe_category_id) => {
  return {
    type: UPDATE_CATEGORIES_ITEMS_REQUEST,
    payload: {
      scraper_id,
      remote_category_id,
      shoppe_category_id
    }
  }
}

const updateCategoriesMappingItemsToSuccess = (data) => ({ type: UPDATE_CATEGORIES_ITEMS_SUCCESS, payload: data })

const updateCategoriesMappingItemsWithError = (error) => ({ type: UPDATE_CATEGORIES_ITEMS_ERROR, ...error })


export const deleteCategoriesMappingItems = (scraper_id, remote_category_id) => {
  return {
    type: DELETE_CATEGORIES_ITEMS_REQUEST,
    payload: {
      scraper_id,
      remote_category_id
    }
  }
}

const deleteCategoriesMappingItemsToSuccess = (data) => ({ type: DELETE_CATEGORIES_ITEMS_SUCCESS, payload: data })

const deleteCategoriesMappingItemsWithError = (error) => ({ type: DELETE_CATEGORIES_ITEMS_ERROR, ...error })


/**
 * Sagas
 **/

function* categoriesMappingData(action) {
  try {
    const result = yield call(() => {
      return getCategoriesMapping(action.payload)
    })

    yield put(categoriesMappingToSuccess(result.data))
  } catch (error) {
    yield put(categoriesMappingWithError(error))
  }
}


function* updateCategoriesMapping(action) {
  try {
    const result = yield call(() => {
      return updateCategoriesMappingDataRequest(action.payload.scraper_id, action.payload)
    })
    yield put(updateCategoriesMappingItemsToSuccess(result.data))
    yield put(getCategoriesMappingData(action.payload.scraper_id))
  } catch (error) {
    yield put(updateCategoriesMappingItemsWithError(error))
  }
}

function* deleteCategoriesMapping(action) {
  try {
    const result = yield call(() => {
      return deleteCategoriesMappingDataRequest(action.payload.scraper_id, action.payload)
    })
    yield put(deleteCategoriesMappingItemsToSuccess(result.data))
    yield put(getCategoriesMappingData(action.payload.scraper_id))
  } catch (error) {
    yield put(deleteCategoriesMappingItemsWithError(error))
  }
}


export function* saga() {
  yield all([
    takeEvery(REMOTE_CATEGORIES_ITEMS_REQUEST, categoriesMappingData),
    takeEvery(UPDATE_CATEGORIES_ITEMS_REQUEST, updateCategoriesMapping),
    takeEvery(DELETE_CATEGORIES_ITEMS_REQUEST, deleteCategoriesMapping)
  ])
}
