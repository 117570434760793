import React from "react"
import PropTypes from "prop-types"

// Import Components
import SwitchStyle from "./style"
import { Field } from "redux-form"
import ReactSwitch from "./Switch"

Switch.propTypes = {
  // from parent component
  name: PropTypes.string.isRequired
}

function Switch({ name, id }) {
  return (
    <SwitchStyle>
      <Field
        name={name}
        component={ReactSwitch}
        roomName={id}
      />
    </SwitchStyle>
  )
}

export default Switch
