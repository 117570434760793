import { all, call, put, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { push } from "react-router-redux"
import { createSelector } from "reselect"
import _set from "lodash/fp/set"
import _get from "lodash/fp/get"
import { appName, DOMAIN_FE_ADMIN, POSTFIX } from "../constants"
import Http from "../api/design-templates"
import { errorFormatter } from "../utils/errorFormatter"
import objectToFormData from "../utils/objectToFormData"

/**
 * Constants
 **/
export const moduleName = "availableTemplates"
const prefix = `@@${appName}/${moduleName}`

const GET_TEMPLATES_REQUEST = `${prefix}/GET_TEMPLATES_REQUEST`
const GET_FLOORPLANS_REQUEST = `${prefix}/GET_FLOORPLANS_REQUEST`
const UPDATE_TEMPLATES_REQUEST = `${prefix}/UPDATE_TEMPLATES_REQUEST`
const UPDATE_ROOM_VARIANT_REQUEST = `${prefix}/UPDATE_ROOM_VARIANT_REQUEST`
const DELETE_TEMPLATE_REQUEST = `${prefix}/DELETE_TEMPLATE_REQUEST`
const DELETE_VARIANT_REQUEST = `${prefix}/DELETE_VARIANT_REQUEST`
const GET_TEMPLATE_BY_ID_REQUEST = `${prefix}/GET_TEMPLATE_BY_ID_REQUEST`
const ADD_NEW_ROOM_VARIANT = `${prefix}/ADD_NEW_ROOM_VARIANT`
const DELETE_NEW_ROOM_VARIANT = `${prefix}/DELETE_NEW_ROOM_VARIANT`
const CLONE_TEMPLATE = `${prefix}/CLONE_TEMPLATE`
const CREATE_ROOM_VARIANT_REQUEST = `${prefix}/CREATE_ROOM_VARIANT_REQUEST`
const CREATE_TEMPLATE_REQUEST = `${prefix}/CREATE_TEMPLATE_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  allTemplates: [],
  template: {},
  floorplans: [],
  isLoading: false,
  error: null
}

export default function(state = initialState, { type, payload, error }) {
  switch (type) {
    case GET_TEMPLATES_REQUEST + POSTFIX.START:
    case CLONE_TEMPLATE + POSTFIX.START:
    case DELETE_NEW_ROOM_VARIANT + POSTFIX.START:
    case UPDATE_ROOM_VARIANT_REQUEST + POSTFIX.START:
    case GET_FLOORPLANS_REQUEST + POSTFIX.START:
    case UPDATE_TEMPLATES_REQUEST + POSTFIX.START:
    case DELETE_TEMPLATE_REQUEST + POSTFIX.START:
    case DELETE_VARIANT_REQUEST + POSTFIX.START:
    case GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.START:
    case CREATE_ROOM_VARIANT_REQUEST + POSTFIX.START:
    case CREATE_TEMPLATE_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_TEMPLATES_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_TEMPLATE_REQUEST + POSTFIX.SUCCESSFUL:
    case DELETE_VARIANT_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        allTemplates: payload.allTemplates
      }

    case GET_FLOORPLANS_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        floorplans: payload.floorplans,
        isLoading: false
      }

    case ADD_NEW_ROOM_VARIANT:
      const roomVariants = _get("room.room_variants", state.template)
      window.localStorage.setItem("variantIndex", JSON.stringify(roomVariants.length + 1))
      roomVariants.push({
        id: roomVariants.length,
        name: `New Room Variant ${roomVariants.length}`,
        cad_price: "0.00",
        usd_price: "0.00",
        active: false,
        default: false,
        sku: "",
        products: null,
        newVariant: true
      })
      return _set("template.room.room_variants", roomVariants, state)

    case DELETE_NEW_ROOM_VARIANT + POSTFIX.SUCCESSFUL:
      const roomVariantsDel = _get("room.room_variants", state.template)
      const newRoomVariants = roomVariantsDel.filter(({ id }) => +id !== +payload.id)
      return {
        ...state,
        isLoading: false,
        template: _set("room.room_variants", newRoomVariants, state.template)
      }

    case GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        template: payload.template
      }

    case UPDATE_TEMPLATES_REQUEST + POSTFIX.SUCCESSFUL:
    case UPDATE_ROOM_VARIANT_REQUEST + POSTFIX.SUCCESSFUL:
    case CLONE_TEMPLATE + POSTFIX.SUCCESSFUL:
    case CREATE_ROOM_VARIANT_REQUEST + POSTFIX.SUCCESSFUL:
    case CREATE_TEMPLATE_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false
      }

    case GET_TEMPLATES_REQUEST + POSTFIX.FAILURE:
    case UPDATE_ROOM_VARIANT_REQUEST + POSTFIX.FAILURE:
    case GET_FLOORPLANS_REQUEST + POSTFIX.FAILURE:
    case UPDATE_TEMPLATES_REQUEST + POSTFIX.FAILURE:
    case DELETE_TEMPLATE_REQUEST + POSTFIX.FAILURE:
    case DELETE_VARIANT_REQUEST + POSTFIX.FAILURE:
    case DELETE_NEW_ROOM_VARIANT + POSTFIX.FAILURE:
    case GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.FAILURE:
    case CLONE_TEMPLATE + POSTFIX.FAILURE:
    case CREATE_ROOM_VARIANT_REQUEST + POSTFIX.FAILURE:
    case CREATE_TEMPLATE_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableTemplatesReducer
 **/
export const getAllAvailableTemplatesSelector = function(state) {
  const allTemplates = state.availableTemplatesReducer.allTemplates

  return allTemplates && allTemplates.data ? allTemplates.data : []
}

export const getAvailableTemplateSelector = function(state) {
  return state.availableTemplatesReducer.template
}

export const getIsLoadingSelector = function(state) {
  return state.availableTemplatesReducer.isLoading
}

export const getRoomVariantsSelector = function(state) {
  return _get("room.room_variants", state.availableTemplatesReducer.template)
}

export const getAllFloorplansSelector = function(state) {
  return state.availableTemplatesReducer.floorplans
}

export const getTemplateIdSelector = (state) => {
  return _get("room.template_id", state.availableTemplatesReducer.template)
}

const getActiveFloorplanId = (state) => {
  return _get("room.floorplan.id", state.availableTemplatesReducer.template)
}

export const makeCurrentFloorplanId = () =>
  createSelector([getActiveFloorplanId, getAllFloorplansSelector], (currentId, florplans) => {
    return florplans.find(({ value }) => +value === +currentId)
  })

export const getRoomVariantSelector = (state) => {
  const roomVariantIndex = window.localStorage.getItem("variantIndex")
    ? JSON.parse(window.localStorage.getItem("variantIndex"))
    : null

  if (roomVariantIndex !== 0 && !roomVariantIndex) return []

  const roomVariants = _get("room.room_variants", state.availableTemplatesReducer.template)

  if (roomVariants) {
    return roomVariants[roomVariantIndex - 1]
  }
}

export const getProductDataToSend = (state) => {
  return {
    default_image: _get("default_image.url", state.availableTemplatesReducer.template),
    image2: _get("image2.url", state.availableTemplatesReducer.template),
    image3: _get("image3.url", state.availableTemplatesReducer.template),
    image4: _get("image4.url", state.availableTemplatesReducer.template),
    template_floor_plan_id: _get("room.floorplan.id", state.availableTemplatesReducer.template)
  }
}

/**
 * Action Creators
 **/
export function getAllAvailableTemplatesAction() {
  return { type: GET_TEMPLATES_REQUEST }
}

export function cloneTemplateAction(templateId) {
  return {
    type: CLONE_TEMPLATE,
    payload: { templateId }
  }
}

export function getAllFloorplansAction() {
  return { type: GET_FLOORPLANS_REQUEST }
}

export function deleteAvailableTemplateAction(templateId) {
  return {
    type: DELETE_TEMPLATE_REQUEST,
    payload: { templateId }
  }
}

export function deleteVariantAction(variantId, templateId) {
  return {
    type: DELETE_VARIANT_REQUEST,
    payload: { variantId, templateId }
  }
}

export function createRoomVariantAction(templateId, data) {
  return {
    type: CREATE_ROOM_VARIANT_REQUEST,
    payload: { templateId, data }
  }
}

export function createTemplateAction(data) {
  return {
    type: CREATE_TEMPLATE_REQUEST,
    payload: { data }
  }
}

export function updateAvailableTemplateAction(templateId, templateData) {
  return {
    type: UPDATE_TEMPLATES_REQUEST,
    payload: { templateId, templateData }
  }
}

export function getAvailableTemplateAction(templateId) {
  return {
    type: GET_TEMPLATE_BY_ID_REQUEST,
    payload: { templateId }
  }
}

export function addNewVariantAction() {
  return { type: ADD_NEW_ROOM_VARIANT }
}

export function deleteNewVariantAction(id) {
  return { type: DELETE_NEW_ROOM_VARIANT, payload: { id } }
}

export function updateRoomVariantAction(id, products, data, templateId) {
  return {
    type: UPDATE_ROOM_VARIANT_REQUEST,
    payload: { variantId: id, products, variantData: data, templateId }
  }
}

/**
 * Sagas
 **/
function* getAvailableTemplatesSaga() {
  yield put({ type: GET_TEMPLATES_REQUEST + POSTFIX.START })
  try {
    const allTemplates = yield call(() => Http.getAllTemplates())
    yield put({
      type: GET_TEMPLATES_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { allTemplates }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_TEMPLATES_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* getAllFloorplansSaga() {
  yield put({ type: GET_FLOORPLANS_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getAllFloorplans())
    yield put({
      type: GET_FLOORPLANS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { floorplans: response.floorplans }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_FLOORPLANS_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* cloneTemplateSaga({ payload: { templateId } }) {
  yield put({ type: CLONE_TEMPLATE + POSTFIX.START })
  try {
    const response = yield call(() => Http.cloneTemplate(templateId))
    yield put({ type: CLONE_TEMPLATE + POSTFIX.SUCCESSFUL })
    const win = window.open(`${DOMAIN_FE_ADMIN}/design-templates/detail/templates/${response.id}`, "_blank")
    win.focus()
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: CLONE_TEMPLATE + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* deleteAvailableTemplateSaga({ payload: { templateId } }) {
  yield put({ type: DELETE_TEMPLATE_REQUEST + POSTFIX.START })
  try {
    const allTemplates = yield call(() => Http.deleteTemplate(templateId))
    yield put({
      type: DELETE_TEMPLATE_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { allTemplates }
    })
    yield put(push("/design-templates/detail/templates"))
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: DELETE_TEMPLATE_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* createAvailableTemplateSaga({ payload: { data } }) {
  yield put({ type: CREATE_TEMPLATE_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.createTemplate(data))
    yield put({ type: CREATE_TEMPLATE_REQUEST + POSTFIX.SUCCESSFUL })
    yield put(push("/design-templates/detail/templates"))
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: CREATE_TEMPLATE_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* deleteVariantSaga({ payload: { variantId, templateId } }) {
  yield put({ type: DELETE_VARIANT_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.deleteRoomVariant(variantId))
    window.localStorage.removeItem("variantIndex")
    const response = yield call(() => Http.getTemplateById(templateId))
    yield put({
      type: GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { template: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: DELETE_VARIANT_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* createRoomVariantSaga({ payload: { templateId, data } }) {
  yield put({ type: CREATE_ROOM_VARIANT_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.createVariant(templateId, data))
    const response = yield call(() => Http.getTemplateById(templateId))
    yield put({
      type: GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { template: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: CREATE_ROOM_VARIANT_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* updateAvailableTemplateSaga({ payload: { templateId, templateData } }) {
  yield put({ type: UPDATE_TEMPLATES_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.updateTemplate(templateId, templateData))
    yield put({
      type: UPDATE_TEMPLATES_REQUEST + POSTFIX.SUCCESSFUL
    })
    yield call(() => toastr.success("Successfully updated"))
    const response = yield call(() => Http.getTemplateById(templateId))
    yield put({
      type: GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { template: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: UPDATE_TEMPLATES_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* updateRoomVariantSaga({ payload: { variantId, products, variantData, templateId } }) {
  yield put({ type: UPDATE_ROOM_VARIANT_REQUEST + POSTFIX.START })
  try {
    yield call(() => Http.addProducts(variantId, objectToFormData(products)))
    yield call(() => Http.updateRoomVariant(variantId, objectToFormData(variantData)))
    yield put({
      type: UPDATE_ROOM_VARIANT_REQUEST + POSTFIX.SUCCESSFUL
    })
    yield call(() => toastr.success("Successfully updated"))
    const response = yield call(() => Http.getTemplateById(templateId))
    yield put({
      type: GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { template: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: UPDATE_ROOM_VARIANT_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* deleteNewRoomVariantSaga({ payload: { id } }) {
  yield put({ type: DELETE_NEW_ROOM_VARIANT + POSTFIX.START })
  try {
    yield put({
      type: DELETE_NEW_ROOM_VARIANT + POSTFIX.SUCCESSFUL,
      payload: { id }
    })
    yield call(() => toastr.success("Successfully deleted"))
    yield put(push("/design-templates/detail/templates"))
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: DELETE_NEW_ROOM_VARIANT + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* getAvailableTemplateByIdSaga({ payload: { templateId } }) {
  yield put({ type: GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getTemplateById(templateId))
    yield put({
      type: GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { template: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_TEMPLATE_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_TEMPLATES_REQUEST, getAvailableTemplatesSaga),
    takeLatest(CREATE_TEMPLATE_REQUEST, createAvailableTemplateSaga),
    takeLatest(DELETE_TEMPLATE_REQUEST, deleteAvailableTemplateSaga),
    takeLatest(DELETE_VARIANT_REQUEST, deleteVariantSaga),
    takeLatest(GET_TEMPLATE_BY_ID_REQUEST, getAvailableTemplateByIdSaga),
    takeLatest(UPDATE_TEMPLATES_REQUEST, updateAvailableTemplateSaga),
    takeLatest(GET_FLOORPLANS_REQUEST, getAllFloorplansSaga),
    takeLatest(UPDATE_ROOM_VARIANT_REQUEST, updateRoomVariantSaga),
    takeLatest(DELETE_NEW_ROOM_VARIANT, deleteNewRoomVariantSaga),
    takeLatest(CLONE_TEMPLATE, cloneTemplateSaga),
    takeLatest(CREATE_ROOM_VARIANT_REQUEST, createRoomVariantSaga)
  ])
}
