import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import ClassNames from "classnames"

import Popup from "../Popup/index"

class ImagesContainer extends Component {
  state = {
    activePopupIndex: 0,
    popupOpened: false
  }

  handleImagesChange = (index, e) => {
    const file = e.target.files && e.target.files[0]
    const url = e.target[0] && e.target[0].value
    this.props.onImagesChange(file, url, index)
    this.setState({ popupOpened: false })
  }

  toggleRemoveBtn = (index, display) => {
    this.refs[`remove-button-${index}`].style.display = display
  }

  togglePopup = (index, e) => {
    const { link, toEditPlan } = this.props
    if (!link){
      e.preventDefault()
      this.setState({
        activePopupIndex: index,
        popupOpened: !this.state.popupOpened
      })
    } else {
      toEditPlan(true)
    }
  }

  getStyles(styles) {
    return ClassNames({
      "space-around": styles === "product",
      "space-between": styles === "room"
    })
  }

  renderPopupContent() {
    const index = this.state.activePopupIndex
    const isLocalImage = this.props.images[index] && this.props.images[index].substring(0, 4) === "blob"
    return (
      <div className="popup-content row justify-content-center">
        <input
          type="file"
          className="upload-file"
          id={`productImageFile`}
          name={`product.image`}
          onChange={this.handleImagesChange.bind(this, index)}
        />
        <label className="upload-file-label col-6 col-md-3 mb-5 mb-md-0" htmlFor={`productImageFile`}>
          Choose File
        </label>
        <form className="upload-form row justify-content-center" onSubmit={this.handleImagesChange.bind(this, index)}>
          <input type="text" className="upload-url col-8 col-md-6 mb-5 mb-md-0" id="uploadUrl" />
          <button type="submit" className="upload-url-label col-8 col-md-4 mb-5 mb-md-0" htmlFor={`uploadUrl`}>
            Upload by URL
          </button>
        </form>
        {!isLocalImage && (
          <div className="popup-content__img-url-container row justify-content-center">
            <div className="col-6 col-lg-12">
              <a href={this.props.images[index]}>
                <span className="popup-content__img-url-text">{this.props.images[index]}</span>
              </a>
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <div className="container-fluid mb-5 images-container">
        <div className={`${this.getStyles(this.props.customStyles)}`}>
          {this.props.images.map((img, i) => (
            <div key={i}>
              <div className={`images-wrapper-${this.props.imageSize} `}>
                {img ? (
                  <div
                    className="d-flex justify-content-center"
                    onMouseEnter={this.toggleRemoveBtn.bind(this, i, "block")}
                    onMouseLeave={this.toggleRemoveBtn.bind(this, i, "none")}
                  >
                    <img src={img} className="photo rounded" alt="..." />
                    {
                      !this.props.link ?
                      <div
                        ref={`remove-button-${i}`}
                        className="remove-button"
                        onClick={this.handleImagesChange.bind(this, i)}
                      >
                        REMOVE
                      </div>
                      :
                        <div
                          ref={`remove-button-${i}`}
                          className="remove-button"
                          onClick={this.handleImagesChange.bind(this, i)}
                        />

                    }
                  </div>
                ) : (
                  <div className={`images-default-container-${this.props.imageSize}`} />
                )}
              </div>
              <div className=" upload-button-wrapper">
                <button className="upload-button" onClick={this.togglePopup.bind(this, i)}>
                  <FontAwesomeIcon icon={faCamera} /> {!this.props.link ? "UPLOAD" : "CHANGE"}
                </button>
              </div>
            </div>
          ))}
          {!this.props.images.length && <div className="col-2">No images</div>}
        </div>
        {
          !this.props.link &&

        <Popup
          title="UPLOAD IMAGE"
          activePopupIndex={this.state.activePopupIndex}
          opened={this.state.popupOpened}
          onClose={this.togglePopup}
          renderContent={this.renderPopupContent.bind(this)}
        />
        }
      </div>
    )
  }
}

export default ImagesContainer

ImagesContainer.propTypes = {
  images: PropTypes.array,
  onImagesChange: PropTypes.func,
  imageSize: PropTypes.oneOf(["small", "large"])
}
