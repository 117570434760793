import { put, call, all, takeEvery } from "redux-saga/effects"
import { appName, } from "../constants"
import { getRoomVariantData, moveProductsDesignRoomVariant, copyProductsDesignRoomVariant } from "../api/index"
import { toastr } from "react-redux-toastr"
import { push } from "react-router-redux"

/**
 * Constants
 **/
export const moduleName = "moveDesignRoom"
const prefix = `${appName}/${moduleName}`

const MOVE_DESIGN_ROOM_REQUEST = `${prefix}/MOVE_DESIGN_ROOM_REQUEST`
const MOVE_DESIGN_ROOM_REQUEST_SUCCESSFUL = `${prefix}/MOVE_DESIGN_ROOM_REQUEST_SUCCESSFUL`
const MOVE_DESIGN_ROOM_REQUEST_FAILURE = `${prefix}/MOVE_DESIGN_ROOM_REQUEST_FAILURE`

const COPY_DESIGN_ROOM_REQUEST = `${prefix}/COPY_DESIGN_ROOM_REQUEST`
const COPY_DESIGN_ROOM_REQUEST_SUCCESSFUL = `${prefix}/COPY_DESIGN_ROOM_REQUEST_SUCCESSFUL`
const COPY_DESIGN_ROOM_REQUEST_FAILURE = `${prefix}/COPY_DESIGN_ROOM_REQUEST_FAILURE`

const ROOM_VARIANT_DATA_REQUEST = `${prefix}/ROOM_VARIANT_DATA_REQUEST`
const ROOM_VARIANT_DATA_SUCCESS = `${prefix}/ROOM_VARIANT_DATA_SUCCESS`
const ROOM_VARIANT_DATA_ERROR = `${prefix}/ROOM_VARIANT_DATA_ERROR`

/**
 * Reducer
 **/
const initialState = {
  data: [],
  currentRoomVariant: { data: null, loading: false, error: null },
  isLoading: false,
  error: null
}

export default function reducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case MOVE_DESIGN_ROOM_REQUEST:
      return {
        ...state,
        isLoading: true,
        currentRoomVariant: { data: null, loading: true, error: null }
      }

    case MOVE_DESIGN_ROOM_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        currentRoomVariant: { data: null, loading: false, error: null }
      }

    case MOVE_DESIGN_ROOM_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        currentRoomVariant: { data: null, loading: false, error: null }
      }

    case COPY_DESIGN_ROOM_REQUEST:
      return {
        ...state,
        isLoading: true,
        currentRoomVariant: { data: null, loading: true, error: null }
      }

    case COPY_DESIGN_ROOM_REQUEST_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        currentRoomVariant: { data: null, loading: false, error: null }
      }

    case COPY_DESIGN_ROOM_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        currentRoomVariant: { data: null, loading: false, error: null }
      }

    case ROOM_VARIANT_DATA_REQUEST:
      return { ...state, currentRoomVariant: { data: null, loading: true, error: null } }

    case ROOM_VARIANT_DATA_SUCCESS: {
      return { ...state, currentRoomVariant: { ...state.currentRoomVariant, loading: false, data: payload } }
    }

    case ROOM_VARIANT_DATA_ERROR:
      return { ...state, currentRoomVariant: { ...state.currentRoomVariant, loading: false, ...payload } }

    default:
      return state
  }
}

/**
 * Selectors
 **/

export const loadingForVariantSelector = (state) => state.room.currentRoomVariant.loading
export const currentRoomVariantSelector = (state) => state.room.currentRoomVariant.data

/**
 * Action Creators
 **/

export const getRoomVariant = (id, variantId) => ({
  type: ROOM_VARIANT_DATA_REQUEST,
  payload: { id, variantId }
})
const getRoomVariantSuccess = (data) => ({ type: ROOM_VARIANT_DATA_SUCCESS, payload: data })
const getRoomVariantError = (error) => ({ type: ROOM_VARIANT_DATA_ERROR, ...error })


export function getMoveDesignRoomRequest(data) {
  return {
    type: MOVE_DESIGN_ROOM_REQUEST,
    payload: { data }
  }
}

export function getCopyDesignRoomRequest(data) {
  return {
    type: COPY_DESIGN_ROOM_REQUEST,
    payload: { data }
  }
}

/**
 * Sagas
 **/

function* roomVariantData(action) {
  const { id, variantId } = action.payload
  try {
    const result = yield call(() => getRoomVariantData(variantId))
    yield put(getRoomVariantSuccess(result.data))
    yield put(push(`/rooms/${id}/variant/${variantId}`))
  } catch (error) {
    yield put(getRoomVariantError(error))
  }
}

function* getMoveDesignRoomRequestSaga({ payload: { data } }) {
  try {
    const room_variant = yield call(moveProductsDesignRoomVariant, data)
    yield put({
      type: MOVE_DESIGN_ROOM_REQUEST_SUCCESSFUL,
      room_variant
    })
    const toastrSuccess = toastr.success
    yield call(toastrSuccess, "Products Successfully moved!")
  } catch (error) {
    const toastrError = toastr.error
    yield call(toastrError, error.message)
    yield put({ type: MOVE_DESIGN_ROOM_REQUEST_FAILURE })
  }
}

function* getCopyDesignRoomRequestSaga({ payload: { data } }) {
  try {
    const room_variant = yield call(copyProductsDesignRoomVariant, data)
    yield put({
      type: COPY_DESIGN_ROOM_REQUEST_SUCCESSFUL,
      room_variant
    })
    const toastrSuccess = toastr.success
    yield call(toastrSuccess, "Products Successfully copied!")
  } catch (error) {
    const toastrError = toastr.error
    yield call(toastrError, error.message)
    yield put({ type: COPY_DESIGN_ROOM_REQUEST_FAILURE })
  }
}

export function* saga() {
  yield all([
    takeEvery(MOVE_DESIGN_ROOM_REQUEST, getMoveDesignRoomRequestSaga),
    takeEvery(COPY_DESIGN_ROOM_REQUEST, getCopyDesignRoomRequestSaga),
    takeEvery(ROOM_VARIANT_DATA_REQUEST, roomVariantData)
  ])
}
