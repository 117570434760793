import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';

// Import Components
import CheckboxTree from 'react-checkbox-tree';
import Multiselect from 'react-select';
import FilterModalStyle from './style';
import { Button } from '../../Shared';
import {
  getCategoriesRequest,
  getCategories,
  getTags,
  setSelectedCategoriesCall,
  setSelectedBudgetFromCall,
  setSelectedBudgetToCall,
  setSelectedTagsCall,
  getSearchRequest,
  getCurrentPage,
  getPerPage,
  getSelectedCategories,
  getSelectedTags,
  getSelectedBudgetFrom,
  getSelectedBudgetTo,
} from '../../../ducks/rooms';

class FilterModal extends Component {
  static propTypes = {
    // from connect
    tags: PropTypes.array,
    categories: PropTypes.array,
    getCategoriesRequest: PropTypes.func,
    page: PropTypes.number,
    entriesPerPage: PropTypes.number,
    inputSearchValue: PropTypes.string,
    selectedCategories: PropTypes.array,
    selectedTags: PropTypes.array,
    selectedBudgetFrom: PropTypes.string,
    selectedBudgetTo: PropTypes.string,
    closeModal: PropTypes.func,
  };

  componentDidMount() {
    this.props.getCategoriesRequest();
  }

  state = {
    checked: this.props.selectedCategories || [],
    selected_tags: this.props.selectedTags || [],
    from_budget: this.props.selectedBudgetFrom || '',
    to_budget: this.props.selectedBudgetTo || '',
    expanded: [],
    room_tag_ids: [],
  };

  filter_checkHandler = (checked) => {
    this.setState({ checked });
    this.props.setSelectedCategoriesCall(checked);
  };

  filter_expandHandler = (expanded) => this.setState({ expanded });

  buttonApply_clickHandler = () => {
    const { getSearchRequest, closeModal } = this.props;
    getSearchRequest({
      tags: this.props.selectedTags,
      categories: this.props.selectedCategories,
      from_budget: this.props.selectedBudgetFrom,
      to_budget: this.props.selectedBudgetTo,
      page: 1,
      per_page: this.props.entriesPerPage,
      q: this.state.searchText,
    });

    closeModal();
  };

  onBudgetFromInputChange = (event) => {
    const budget_from_value = event.target.value;
    this.setState({
      from_budget: budget_from_value,
    });
    this.props.setSelectedBudgetFromCall(budget_from_value);
  };

  onBudgetToInputChange = (event) => {
    const budget_to_value = event.target.value;
    this.setState({
      to_budget: budget_to_value,
    });
    this.props.setSelectedBudgetToCall(budget_to_value);
  };

  handleTagChange = (object) => {
    this.setState({
      selected_tags: object,
    });
    this.props.setSelectedTagsCall(object);
  };

  getSelectedTagsList = (itemsList, itemsIds = []) => {
    const tags_ids = itemsIds.map((tag) => tag.value);
    return itemsList.filter((item) => tags_ids.some((e) => item.value === e));
  };

  render() {
    const {
      checked, expanded, selected_tags, from_budget, to_budget,
    } = this.state;

    return (
      <FilterModalStyle>
        <div className="modal__header">
          <h1 className="modal__title">
            Rooms filter
          </h1>
        </div>
        <div className="row room_filter_content">
          <div className="col-md-4">
            <h3 className="filters__title">
              Budget
            </h3>
            <div className="row">
              <div className="col-md-5">
                <input
                  placeholder="$ Min"
                  className="categories-form__table-input-budget-from"
                  onChange={this.onBudgetFromInputChange}
                  value={from_budget}
                  name="budget-from"
                  type="number"
                />
              </div>
              <div className="col-md-2">
                <span>—</span>
              </div>
              <div className="col-md-5">
                <input
                  placeholder="$ Max"
                  className="categories-form__table-input-budget-to"
                  onChange={this.onBudgetToInputChange}
                  value={to_budget}
                  name="budget-to"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="filters__title">
              Rooms
            </h3>
            <div className="filter__categories">
              <CheckboxTree
                nodes={this.props.categories}
                checked={checked}
                expanded={expanded}
                onCheck={this.filter_checkHandler}
                onExpand={this.filter_expandHandler}
              />
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="filters__title">
              Tags
            </h3>
            <div className="row">
              <div className="col-lg-12">
                <Multiselect
                  isMulti
                  className="select2"
                  value={this.getSelectedTagsList(this.props.tags, selected_tags)}
                  options={this.props.tags}
                  onChange={this.handleTagChange}
                />
                <div className="invalid-feedback">Please choose a tag.</div>
              </div>
            </div>
          </div>
        </div>
        <div className="filter__button-wrapper">
          <Button width={150} onClick={this.buttonApply_clickHandler}>Apply</Button>
        </div>
      </FilterModalStyle>
    );
  }
}

// Enhance
const mapStateToProps = (state) => ({
  categories: getCategories(state),
  tags: getTags(state),
  page: getCurrentPage(state),
  entriesPerPage: getPerPage(state),
  inputSearchValue: state.searchInputReducer.searchRooms,
  selectedCategories: getSelectedCategories(state),
  selectedTags: getSelectedTags(state),
  selectedBudgetFrom: getSelectedBudgetFrom(state),
  selectedBudgetTo: getSelectedBudgetTo(state),
});

const mapDispatchToProps = {
  getCategoriesRequest,
  setSelectedCategoriesCall,
  setSelectedTagsCall,
  setSelectedBudgetFromCall,
  setSelectedBudgetToCall,
  getSearchRequest,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(FilterModal);
