import React from 'react';
import PropTypes from 'prop-types';
// Redux
import { connect } from 'react-redux';
import { saveCategoriesValue } from '../../../../ducks/availableCategories';

function FurnitureTd(props) {
  const openModalWithSaveValues = () => {
    props.saveCategoriesValue(props.categories);
    props.openModal();
  };

  return (
    <td className="table__cell td furniture" colSpan="4" onClick={openModalWithSaveValues}>
      {props.furnitureName}
    </td>
  );
}

FurnitureTd.propTypes = {
  furnitureName: PropTypes.string.isRequired,
  openModal: PropTypes.func,
  saveCategoriesValue: PropTypes.func,
  categories: PropTypes.object,
};

export default connect(null, { saveCategoriesValue })(FurnitureTd);
