import styled from "styled-components"

export default styled.div`
  padding: 50px 100px;
  
  .info-form {
    display: flex;
    flex-wrap: wrap;
    align-items: center; 
  }
  
  .info-form__control.full-size {
    flex-grow: 1;
  }
  
  .info-form__control.switch {
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .info-form__switch-title {
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .info-form__control .redux-form__input,
  .info-form__control .input__field {
    width: 100%;
  }
  
  .info-form__buttons > * {
    margin-right: 25px;
  }
  
  .info-form__buttons > *:last-child {
    margin-right: 0;
  }
  
  @media screen and (max-width: 1440px) {
    padding: 50px;  
  } 
`
