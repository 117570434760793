const ProductTitles = {
  action: 'Action',
  categories: 'Category',
  checkbox: '',
  colors: 'Colors',
  cost_cad: 'Cost CAD',
  cost_usd: 'Cost USD',
  depth: 'Depth',
  errors: 'Errors',
  filename: 'File',
  finish_time: 'Finish time',
  height: 'Height',
  import_status: 'Status',
  last_updated_date: 'Last Updated',
  materials: 'Materials',
  msrp_cad: 'MSRP CAD',
  msrp_usd: 'MSRP USD',
  name: 'Name',
  sku: 'SKU',
  start_time: 'Start time',
  stock_availability: 'Stock',
  stock_number: 'Stock',
  sub_category: 'Subcategory',
  supplier: 'Supplier',
  user: 'User',
  width: 'Width',
};

const DataStatusReportTitles = {
  attribute_error: 'Attribute Error',
  created_at: 'Date',
  product_attribute: 'Attribute',
  sku: 'Product SKU',
  supplier_name: 'Supplier',
};

const DeliveryAgentsSummaryTitles = {
  address: 'Address',
  admin_contact_name: 'Admin Contact Name',
  admin_contact_number: 'Phone Number',
  company_name: 'Company Name',
  delivery_quotes: 'Latest Quotes',
  quality_rating: 'Quality Rating',
  shoppe_orders: 'Linked Orders',
  status: 'Status',
  website: 'Website',
};

export const attributeTagging = [
  { filter: false, sort: '', text: 'checkbox' },
  { filter: false, sort: '', text: 'Preview' },
  { filter: true, sort: 'categories', text: 'Category' },
  { filter: true, sort: 'name', text: 'Name' },
  { filter: true, sort: 'supplier', text: 'Supplier' },
  { filter: true, sort: 'sku', text: 'SKU' },
  { filter: true, sort: 'colors', text: 'Colors' },
  { filter: true, sort: 'materials', text: 'Materials' },
  { filter: false, sort: 'style', text: 'Style' },
  { filter: false, sort: 'value', text: 'Value' },
  { filter: false, sort: 'comfort', text: 'Comfort' },
];

export const categoryRules = [
  { filter: false, sort: '', text: 'checkbox' },
  { filter: true, sort: 'id', text: 'ID' },
  { filter: true, sort: 'categories', text: 'Category' },
  { filter: true, sort: 'name', text: 'Name' },
  { filter: false, sort: 'value', text: 'Value' },
];

export const rooms = [
  { filter: false, sort: '', text: 'checkbox' },
  { filter: false, sort: '', text: 'Permalink' },
  { filter: false, sort: '', text: 'Name' },
  { filter: false, sort: '', text: 'Variants' },
  { filter: false, sort: '', text: 'CAD$' },
  { filter: false, sort: '', text: 'USD$' },
  { filter: false, sort: '', text: 'Active?' },
  { filter: false, sort: '', text: 'Featured?' },
  { filter: false, sort: '', text: 'Room Labels' },
  { filter: false, sort: '', text: 'Tags' },
];

const TagsTitles = {
  active: 'Active?',
  created_at: 'Created Date',
  description: 'Description',
  id: 'ID',
  name: 'Name',
  updated_at: 'Updated Date',
};

const RoomTypesTitles = {
  id: 'ID',
  name: 'Name',
  created_at: 'Created Date',
  updated_at: 'Updated Date',
};

const InventoryTitles = {
  created_at: 'Created Date',
  google_drive_id: 'Google Drive ID',
  id: 'ID',
  inventory_file_type: 'File Type',
  inventory_status: 'Status',
  name: 'Name',
  supplier_name: 'Supplier Name',
  updated_at: 'Updated Date',
  url: 'Google Drive Link',
};

const DeliveryAgentQuoteTitles = {
  id: 'Quote ID',
  quote_total: 'Quote Total',
};

const DeliveryAgentOrdersTitles = {
  first_name: 'First Name',
  id: 'Order ID',
  last_name: 'Last Name',
};

const SuppliersTitle = {
  contact_name: 'Contact Name',
  currency: 'Currency',
  name: 'Supplier Name',
  phone: 'Contact phone',
  view_more: '',
};

export default {
  dataStatus: DataStatusReportTitles,
  deliveryAgentSummary: DeliveryAgentsSummaryTitles,
  deliveryAgentorders: DeliveryAgentOrdersTitles,
  deliveryAgentquotes: DeliveryAgentQuoteTitles,
  inventories: InventoryTitles,
  products: ProductTitles,
  suppliersSummary: SuppliersTitle,
  tags: TagsTitles,
  room_types: RoomTypesTitles
};
