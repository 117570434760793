import styled from "styled-components"

export default styled.div`
  padding-left: 100px;
  padding-right: 100px;
  
  .tabs__navigation-list {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
    overflow-x: auto;
  }
  
  .tabs__content {
    border: 1px solid #ccc;
  }
  
  .tabs__navigation-item {
    min-width: 250px;
    padding: 25px;
    list-style-type: none;
    font-weight: 400;
    color: #595959;
    text-align: center;
    text-transform: uppercase;
    background-color: #f7f7f7;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .tabs__navigation-item.button {
    border: 1px solid #dedede;
  }
  
  .tabs__navigation-item:hover,
  .tabs__navigation-item:focus {
    background-color: #dedede;
  }
  
  .tabs__navigation-item:active {
    opacity: 0.7;
  }
  
  .tabs__navigation-item.active {
    background-color:#ccc;
    cursor: default;
  }
  
  .tabs__navigation-item.empty,
  .tabs__navigation-item.empty:hover,
  .tabs__navigation-item.empty:active,
  .tabs__navigation-item.empty:focus {
    display: none;
  }
 
  @media screen and (max-width: 1440px) {
    padding: 50px;  
  } 
`
