import styled from "styled-components"

export const EditFormStyle = styled.div`
  padding: 50px;
  
  .form { padding: 0; }
  
  .form__header {
    display: flex;
    align-items: center;
   }
   
   .form__switch-wrapper { 
    display: flex; 
    align-items: center; 
     margin-bottom: 25px;
   }
    
  .form-control__wrapper { 
    display: flex;
    flex-wrap: wrap;
    margin-right: 15px;
    margin-bottom: 25px;
    flex-grow: 1;
  }
  
  .form-control__row {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;
  }
  
  .form-control__switch-wrapper {
    text-align: center;
  }
  
  .form-control__file-uploader-wrapper { 
    width: 70%;
  }
  
  .form-control__label.no-padding {
    padding: 0;
  }
  
  .form-control__label {
    margin-bottom: 0;
    padding-top: 11px;
    padding-right: 20px;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
  }
  
  .form__switch-title {
     margin-bottom: 0;
     margin-right: 10px;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
  }
  
  .form-control__input-wrapper { flex-grow: 1; }
  
  .form-control__input {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    padding: 11px;
    font-family: inherit;
    font-weight: 100;
    font-size: 17px;
    line-height: 1;
    text-align: left;
    vertical-align: middle;
    color: #595959;
    border: 1px solid #ccc;
    background-color: #f7f7f7;
    outline: none;
    cursor: text;
    transition: all 0.3s;
  }
  
  .form-control__input:hover,
  .form-control__input:focus { border-color: #8e8e8e; }
  
  .form-control__input.error { border-color: #ff3232; }
  
  .form-control__label.error { color: #ff3232; }
  
  .form-control__error { 
    width: 100%;
    font-size: 14px;
    color:#ff3232; 
  }
  
  .form__button-wrapper { 
    padding-top: 50px;
    text-align: center;
   }
`
