import { appName, POSTFIX } from "../constants"
import { all, call, put, takeLatest } from "redux-saga/effects"
import { createSelector } from "reselect"
import { push } from "react-router-redux"
import Http from "../api/room_type"

import { toastr } from "react-redux-toastr"
import { errorFormatter } from "../utils/errorFormatter"

/**
 * Constants
 **/
export const moduleName = "roomTypeCategories"
const prefix = `${appName}/${moduleName}`

export const LOADING = `${prefix}/LOADING`
export const GET_FURNITURE_CATEGORIES = `${prefix}/GET_FURNITURE_CATEGORIES`
export const CHECKED_FURNITURE_CATEGORIES = `${prefix}/CHECKED_FURNITURE_CATEGORIES`
export const REMOVE_CHECKED_FURNITURE_CATEGORIES = `${prefix}/REMOVE_CHECKED_FURNITURE_CATEGORIES`
export const CREATE_ROOM_TYPE = `${prefix}/CREATE_ROOM_TYPE`
export const GET_ROOM_TYPE = `${prefix}/GET_ROOM_TYPE`
export const UPDATE_ROOM_TYPE = `${prefix}/UPDATE_ROOM_TYPE`
export const ADD_ROOM_TYPE_CATEGORY = `${prefix}/ADD_ROOM_TYPE_CATEGORY`
export const SAVE_CATEGORIES_VALUES = `${prefix}/SAVE_CATEGORIES_VALUES`

/**
 * Reducer
 **/
const initialState = Object.freeze({
  room: {},
  furnitureCategories: [],
  checkedFurnitureCategories: [],
  loading: false,
  categoriesValues: {},
  error: null
})

export default (state = initialState, action) => {
  const { type, payload, error } = action

  switch (type) {
    case LOADING + POSTFIX.START:
      return { ...state, loading: true }

    case LOADING + POSTFIX.STOP:
      return { ...state, loading: false }

    case GET_ROOM_TYPE + POSTFIX.SUCCESSFUL:
      return { ...state, room: payload.room }

    case GET_ROOM_TYPE + POSTFIX.FAILURE:
      return { ...state, error }

    case ADD_ROOM_TYPE_CATEGORY:
      return {
        ...state,
        room: {
          ...state.room,
          style_design_categories_with_parent: payload.categoriesWithParent
        }
      }

    case SAVE_CATEGORIES_VALUES:
      return {
        ...state,
        categoriesValues: payload.categoriesValues
      }

    case GET_FURNITURE_CATEGORIES + POSTFIX.SUCCESSFUL:
      return { ...state, furnitureCategories: payload.furnitureCategories }

    case GET_FURNITURE_CATEGORIES + POSTFIX.FAILURE:
      return { ...state, furnitureCategories: [], error }

    case CHECKED_FURNITURE_CATEGORIES:
      return { ...state, checkedFurnitureCategories: payload.checkedFurnitureCategories }

    case REMOVE_CHECKED_FURNITURE_CATEGORIES:
      return { ...state, checkedFurnitureCategories: [] }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableCategoriesReducer
 **/
export const getLoadingSelector = (state) => state.roomType.loading
export const getFurnitureCategoriesSelector = (state) => state.roomType.furnitureCategories
export const getCheckedFurnitureCategoriesSelector = (state) => state.roomType.checkedFurnitureCategories
export const getRoomTypeSelector = (state) => state.roomType.room
export const getCategoriesValues = (state) => state.roomType.categoriesValues

// Checked categories
export const addFurnitureCategories = (state) => {
  const categories = getFurnitureCategoriesSelector(state)
  const checkedCategories = getCheckedFurnitureCategoriesSelector(state)

  const data = categories.reduce((acc, category) => {
    acc[category.value] = {
      value: category.value,
      label: category.label,
      children: []
    }

    category.children && category.children.forEach((item) => {
      if (checkedCategories.includes(`${item.value}`)) {
        acc[item.parent_id].children.push(item)
      }
    })

    return acc
  }, {})

  return Object.keys(data).map((item) => data[item])
}

export const makeAddFurnitureCategoriesSelector = () => createSelector(
  [addFurnitureCategories],
  (categories) => categories
)

/**
 * Action Creators
 **/

export const startLoadingAction = () => ({ type: LOADING + POSTFIX.START })
export const stopLoadingAction = () => ({ type: LOADING + POSTFIX.STOP })

export const getRoomTypeAction = (roomId) => ({
  type: GET_ROOM_TYPE,
  payload: { roomId }
})
export const getRoomTypeSuccessfulAction = (room) => ({
  type: GET_ROOM_TYPE + POSTFIX.SUCCESSFUL,
  payload: { room }
})
export const getRoomTypeFailureAction = (error) => ({
  type: GET_ROOM_TYPE + POSTFIX.FAILURE,
  payload: { error }
})
export const addRoomTypeCategory = (categoriesWithParent) => ({
  type: ADD_ROOM_TYPE_CATEGORY,
  payload: { categoriesWithParent }
})

export const updateRoomTypeAction = (roomId, roomData) => ({
  type: UPDATE_ROOM_TYPE,
  payload: { roomId, roomData }
})

export const fetchFurnitureCategoriesAction = () => ({ type: GET_FURNITURE_CATEGORIES })
export const fetchFurnitureCategoriesSuccessAction = (furnitureCategories) => ({
  type: GET_FURNITURE_CATEGORIES + POSTFIX.SUCCESSFUL,
  payload: { furnitureCategories }
})
export const fetchFurnitureCategoriesFailureAction = (error) => ({
  type: GET_FURNITURE_CATEGORIES + POSTFIX.FAILURE,
  payload: { error }
})

export const checkedFurnitureCategoriesAction = (checkedFurnitureCategories) => ({
  type: CHECKED_FURNITURE_CATEGORIES,
  payload: { checkedFurnitureCategories }
})

export const removeCheckedFurnitureCategoriesAction = () => ({ type: REMOVE_CHECKED_FURNITURE_CATEGORIES })

export const createRoomTypeAction = (roomData) => ({
  type: CREATE_ROOM_TYPE,
  payload: { roomData }
})

export const saveCategoriesValue = (categoriesValues) => ({
  type: SAVE_CATEGORIES_VALUES,
  payload: { categoriesValues }
})

/**
 * Sagas
 **/

function* fetchFurnitureCategoriesSaga() {
  try {
    yield put(startLoadingAction())
    const getFurnitureCategories = Http.getFurnitureCategories
    const response = yield call(getFurnitureCategories)
    yield put(fetchFurnitureCategoriesSuccessAction(response.categories))
    yield put(stopLoadingAction())
  }
  catch (error) {
    const errorMessage = errorFormatter(error)
    const showError = toastr.error

    yield put(stopLoadingAction())
    yield put(fetchFurnitureCategoriesFailureAction(errorMessage))
    yield call(showError, errorMessage)
  }
}

function* createRoomTypeSaga(action) {
  const { payload: { roomData } } = action

  try {
    const successMessage = "Successfully created"
    const showSuccess = toastr.success

    yield put(startLoadingAction())
    const createRoomType = Http.createRoomType
    yield call(createRoomType, roomData)
    yield put(stopLoadingAction())
    yield call(showSuccess, successMessage)
    yield put(push("/room-types"))
    yield put(removeCheckedFurnitureCategoriesAction())
  }
  catch (error) {
    const errorMessage = errorFormatter(error)
    const showError = toastr.error

    yield put(stopLoadingAction())
    yield call(showError, errorMessage)
  }
}

function* fetchRoomTypeSaga(action) {
  const { payload } = action

  try {
    yield put(startLoadingAction())
    const getFurnitureCategory = Http.getFurnitureCategory
    const response = yield call(getFurnitureCategory, payload.roomId)
    yield put(getRoomTypeSuccessfulAction(response.room))
    yield put(fetchFurnitureCategoriesSuccessAction(response.categories))
    yield put(stopLoadingAction())
  }
  catch (error) {
    const errorMessage = errorFormatter(error)
    const showError = toastr.error

    yield put(stopLoadingAction())
    yield put(getRoomTypeFailureAction(errorMessage))
    yield call(showError, errorMessage)
  }
}

function* updateRoomTypeSaga(action) {
  const { payload: { roomId, roomData } } = action

  try {
    const successMessage = "Successfully updated"
    const showSuccess = toastr.success

    yield put(startLoadingAction())
    const updateRoomType = Http.updateRoomType
    yield call(updateRoomType, roomId, roomData)
    yield put(stopLoadingAction())
    yield call(showSuccess, successMessage)
    yield put(push("/room-types"))
    yield put(removeCheckedFurnitureCategoriesAction())
  }
  catch (error) {
    const errorMessage = errorFormatter(error)
    const showError = toastr.error

    yield put(stopLoadingAction())
    yield call(showError, errorMessage)
  }
}

export function* saga() {
  yield all([
    takeLatest(GET_FURNITURE_CATEGORIES, fetchFurnitureCategoriesSaga),
    takeLatest(CREATE_ROOM_TYPE, createRoomTypeSaga),
    takeLatest(GET_ROOM_TYPE, fetchRoomTypeSaga),
    takeLatest(UPDATE_ROOM_TYPE, updateRoomTypeSaga)
  ])
}
