import styled from "styled-components"

export default styled.section`
  .form {
    max-width: 1655px;
    margin-left:auto;
    margin-right:auto;
    padding: 50px 100px;  
  }
  
  .form__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  
  .form__row.start {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  
  .form__row:last-child {
    margin-bottom: 0;
  }
  
  .form__row-title {
    flex-basis: 150px;
    margin-bottom: 0;
    padding-right: 20px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  .form__control {
    flex-grow: 1;
  }
  
  .form__control.center .input__field {
    width: 100%;
  }
  
  .form__column {
    flex-basis: 350px;
  }
  
  .form__column.floor-plan {
    flex-basis: 450px;
    padding-right: 50px;
  }
  
  .form__column .form__control {
    margin-bottom: 30px;
  }
  
  .form__column .form__control:last-child {
    margin-bottom: 0;
  }
  
  .form__column .redux-form__input {
    justify-content: space-between;
  }
  
  .form__row-images {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .form__submit-button-box {
    text-align: center;
  }
  
  @media screen and (max-width: 1440px) {
    .form {
      padding: 50px;  
    }
  } 
`
