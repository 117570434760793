import React from "react"
import PropTypes from "prop-types"
// Import Components
import { Field } from "redux-form"
import Select from "./Select"

ReduxSelect.propTypes = {
  // from parent component
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  deleteFlag: PropTypes.bool,
  validate: PropTypes.array
}

function ReduxSelect({ name, isMulti, options, deleteFlag = false, validate }) {
  return <Field
    name={name}
    isMulti={isMulti}
    options={options}
    deleteFlag={deleteFlag}
    component={Select}
    validate={validate}
  />
}

export default ReduxSelect

