import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Button } from "../../../Shared"
import Input from "./Input"

const CategoryTd = (props) => {
  const { category, categories, onChangeCategories } = props
  const onButtonDeleteClick = () => props.onButtonDeleteClick(category.value)

  return (
    <Fragment>
      <td className="table__cell td">
        {category.label}
      </td>
      <td className="table__cell td">
        <Input
          id={category.value}
          name="quantity"
          value={categories && categories[category.value] && categories[category.value].quantity !== undefined ? parseFloat(categories[category.value].quantity): 0}
          onChange={onChangeCategories}
        />

      </td>
      <td className="table__cell td small-size">
        <Button width={100} onClick={onButtonDeleteClick} color="danger">Delete</Button>
      </td>
    </Fragment>
  )
}

CategoryTd.propTypes = {
  category: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    quantity: PropTypes.number
  }).isRequired,
  onChangeCategory: PropTypes.func,
  onChangeCategories: PropTypes.func,
  categories: PropTypes.object,
  onChangeSelectCategories: PropTypes.func,
  onButtonDeleteClick: PropTypes.func
}

export default CategoryTd
