import { format } from 'date-fns';

export const formatDate = (oldDateArr, arrKey) => {
  const newDate = oldDateArr.map((item) => {
    let newItem = item;

    arrKey.map((arrItem) => {
      newItem = {
        ...newItem,
        [arrItem]: item[arrItem]
          ? format(new Date(item[arrItem]), 'yyyy-m-d H:MM:ss', { timeZone: 'UTC' })
          : null,
      };
      return arrItem;
    });
    return newItem;
  });
  return newDate;
};
