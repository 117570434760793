import React, { Fragment } from "react"
import PropTypes from "prop-types"

// components
import { Input } from "../../../Shared"

DynamicInputs.propTypes = {
  // from DynamicFormFields component
  onAttributesChange: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  attributeId: PropTypes.string.isRequired
}

DynamicInputs.defaultProps = {
  fields: [],
  onAttributesChange: () => {}
}

function DynamicInputs({ fields, onAttributesChange, attributeId }) {
  const renderFields = fields.map(({ id, name, value, label }, index) => {
    return (
      <Input
        key={id}
        name={name}
        label={label}
        value={value}
        onChange={(event) => onAttributesChange(event, attributeId, index)}
        styleType="primary"
      />
    )
  })

  return <Fragment>{renderFields}</Fragment>
}

export default DynamicInputs
