import React from 'react';
// Redux
import { connect } from 'react-redux';
// Selector
import { Route, Redirect } from 'react-router-dom';
import { getIsAuthenticated } from '../../../ducks/auth';
// Components

function ProtectedRoute({ component: Component, isAuthenticated, ...restProps }) {
  return isAuthenticated ? (
    <Route component={Component} {...restProps} />
  ) : (
    <Redirect to="/login" />
  );
}

// Enhance
const mapStateToProps = (state) => ({
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
