import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from "../Breadcrumbs"
import BreadcrumbsItem from "../Breadcrumbs/BreadcrumbsItem"

class TopInventorySection extends Component {
  render() {
    let {
      pageTitle,
      breadcrumbs
    } = this.props

    return (
      <div className="top-section">
        <div className="top-section-with-breadcrumbs">
          <h1 className="top-section-title">{(pageTitle && pageTitle.toUpperCase()) || ""}</h1>
          <div className="top-section-breadcrumbs-wrapper">
            <Breadcrumbs>
              {breadcrumbs &&
              breadcrumbs.map((item, idx) => (
                <BreadcrumbsItem key={`${item.to}${idx}`} to={item.to} name={item.name} />
              ))}
            </Breadcrumbs>
            <button className="top-section-breadcrumbs-wrapper-button">
              <FontAwesomeIcon icon={faSortDown} alt="profile-options-button-image" />
            </button>
          </div>
        </div>
      </div>
    )
  }
}

TopInventorySection.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string
    })
  ).isRequired
}

export default TopInventorySection
