import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScraperSkuFormStyle from './style';
import { Select } from '../../Shared';

class ScraperSkuForm extends Component {
  static propTypes = {
    remote_products: PropTypes.array,
    remote_product_sku: PropTypes.string,
  };

  state = {
    remote_products: this.props.remote_products,
    remote_product_sku: '',
  };

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      push('/login');
    }
  }

  handleSubmitProductUrl = (event) => {
    event.preventDefault();
  };

  render() {
    const { remote_products, handleChangeRemoteProductSkuSelect } = this.props;

    return (
      <ScraperSkuFormStyle>
        <form className="container-fluid product-scraper-sku-form" onSubmit={this.handleSubmitProductUrl}>
          <div className="row mb-5pl-0 copy-item__box-select">
            <div className="col-lg-9 pl-2 flex copy-item__box-label">
              Select Remote Product SKU:
            </div>
            <div className="col-lg-12 pl-2 flex">
              <Select
                className="select2-single"
                options={remote_products}
                onChange={handleChangeRemoteProductSkuSelect}
              />
            </div>
          </div>
        </form>
      </ScraperSkuFormStyle>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.roomsReducer.isLoading,
});

export default connect(
  mapStateToProps,
)(ScraperSkuForm);
