import { all, call, put, takeLatest } from "redux-saga/effects"
import { toastr } from "react-redux-toastr"
import { appName, POSTFIX } from "../constants"
import Http from "../api/etl-scrapers"
import { errorFormatter } from "../utils/errorFormatter"


/**
 * Constants
 **/
export const moduleName = "availableScrapers"
const prefix = `@@${appName}/${moduleName}`

const GET_SCRAPERS_REQUEST = `${prefix}/GET_SCRAPERS_REQUEST`
const GET_SCRAPER_BY_ID_REQUEST = `${prefix}/GET_SCRAPER_BY_ID_REQUEST`
const GET_SCRAPERS_PRODUCTS_REQUEST = `${prefix}/GET_SCRAPERS_PRODUCTS_REQUEST`
const GET_SCRAPER_PRODUCTS_REQUEST = `${prefix}/GET_SCRAPER_PRODUCTS_REQUEST`
const MOVE_SCRAPERS_PRODUCTS_REQUEST = `${prefix}/MOVE_SCRAPERS_PRODUCTS_REQUEST`

/**
 * Reducer
 **/
const initialState = {
  allScrapers: [],
  scraper: {},
  isLoading: false,
  error: null
}

export default function(state = initialState, { type, payload, error }) {
  switch (type) {
    case GET_SCRAPERS_REQUEST + POSTFIX.START:
    case GET_SCRAPER_BY_ID_REQUEST + POSTFIX.START:
    case GET_SCRAPER_PRODUCTS_REQUEST + POSTFIX.START:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case GET_SCRAPERS_REQUEST + POSTFIX.SUCCESSFUL:
    case GET_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.SUCCESSFUL:
    case MOVE_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.SUCCESSFUL:
    case GET_SCRAPER_PRODUCTS_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        allScrapers: payload.allScrapers
      }

    case GET_SCRAPER_BY_ID_REQUEST + POSTFIX.SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        scraper: payload.scraper
      }

    case GET_SCRAPERS_REQUEST + POSTFIX.FAILURE:
    case GET_SCRAPER_BY_ID_REQUEST + POSTFIX.FAILURE:
    case GET_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.FAILURE:
    case MOVE_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.FAILURE:
    case GET_SCRAPER_PRODUCTS_REQUEST + POSTFIX.FAILURE:
      return {
        ...state,
        isLoading: false,
        error
      }

    default:
      return state
  }
}

/**
 * Selectors
 * reducer name: availableSCRAPERSReducer
 **/
export const getAllAvailableScrapersSelector = function(state) {
  const allScrapers = state.availableScrapersReducer.allScrapers

  return allScrapers && allScrapers.data ? allScrapers.data : []
}

export const getAvailableScraperSelector = function(state) {
  return state.availableScrapersReducer.scraper
}

export const getIsLoadingSelector = function(state) {
  return state.availableScrapersReducer.isLoading
}

/**
 * Action Creators
 **/
export function getAllAvailableScrapersAction() {
  return { type: GET_SCRAPERS_REQUEST }
}

export function getAllAvailableScraperProductsAction(scraperId) {
  return { type: GET_SCRAPERS_PRODUCTS_REQUEST,
           payload: { scraperId }
  }
}

export function getAllScraperProductsAction(scraperId) {
  return { type: GET_SCRAPER_PRODUCTS_REQUEST,
    payload: { scraperId }
  }
}

export function moveAllAvailableScraperProductsAction(scraperId) {
  return { type: MOVE_SCRAPERS_PRODUCTS_REQUEST,
    payload: { scraperId }
  }
}

export function getAvailableScraperAction(scraperId) {
  return {
    type: GET_SCRAPER_BY_ID_REQUEST,
    payload: { scraperId }
  }
}

/**
 * Sagas
 **/
function* getAvailableScrapersSaga() {
  yield put({ type: GET_SCRAPERS_REQUEST + POSTFIX.START })
  try {
    const allScrapers = yield call(() => Http.getAllScrapers())
    yield put({
      type: GET_SCRAPERS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { allScrapers }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_SCRAPERS_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* getAllAvailableScraperProductsSaga({ payload: { scraperId } }) {
  yield put({ type: GET_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.runScraperById(scraperId))
    yield put({
      type: GET_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { scraper: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* getAvailableScraperByIdSaga({ payload: { scraperId } }) {
  yield put({ type: GET_SCRAPER_BY_ID_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getScraperById(scraperId))
    yield put({
      type: GET_SCRAPER_BY_ID_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { scraper: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_SCRAPER_BY_ID_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* moveAllAvailableScraperProductsSaga({ payload: { scraperId } }) {
  yield put({ type: MOVE_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.moveProductsById(scraperId))
    yield put({
      type: MOVE_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { scraper: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: MOVE_SCRAPERS_PRODUCTS_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}

function* getAllScraperProductsSaga({ payload: { scraperId } }) {
  yield put({ type: GET_SCRAPER_PRODUCTS_REQUEST + POSTFIX.START })
  try {
    const response = yield call(() => Http.getOneScraperProducts(scraperId))
    yield put({
      type: GET_SCRAPER_PRODUCTS_REQUEST + POSTFIX.SUCCESSFUL,
      payload: { scraper: response.data }
    })
  } catch (error) {
    yield call(() => toastr.error(errorFormatter(error)))
    yield put({
      type: GET_SCRAPER_PRODUCTS_REQUEST + POSTFIX.FAILURE,
      error: errorFormatter(error)
    })
  }
}


export function* saga() {
  yield all([
    takeLatest(GET_SCRAPERS_REQUEST, getAvailableScrapersSaga),
    takeLatest(GET_SCRAPER_BY_ID_REQUEST, getAvailableScraperByIdSaga),
    takeLatest(GET_SCRAPERS_PRODUCTS_REQUEST, getAllAvailableScraperProductsSaga),
    takeLatest(MOVE_SCRAPERS_PRODUCTS_REQUEST, moveAllAvailableScraperProductsSaga),
    takeLatest(GET_SCRAPER_PRODUCTS_REQUEST, getAllScraperProductsSaga)
  ])
}
