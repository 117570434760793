import styled from "styled-components"

export default styled.section`
  width: 100%;
  min-width: 1030px;
  min-height: 100vh;
  padding: 30px;
  font-family: "Roboto", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Helvetica Neue", "Arial", sans-serif;
  font-weight: 100;
  font-size: 17px;
  letter-spacing: 1px;
  color: #595959;
  background-color:#f7f7f7;
  
  .merge-purge__title {
    margin-bottom: 45px;
    font-weight: 700;
    font-size: 45px;
    line-height: 1.1;
    letter-spacing: 1.5px;
    text-align: center;
    text-transform: uppercase;
  }
  
  .merge-purge-navigate__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
  }
  
  .merge-purge-navigate__item {
    display: block;
    width: 100%;
    margin-right: 25px;
    list-style-type: none;   
    
    &:last-child {
      margin-right: 0;
    }
  } 
  
  .merge-purge-navigate__link {
    position: relative;
    display: block;
    padding: 20px;
    color: #595959;
    font-size: 25px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s;
    
    &:hover::before,
    &:focus::before {
      transform: scale3d(1, 1, 1);
    }, 
    
    &:active {
      opacity: 0.7;
    }
    
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: #595959;
      transform: scale3d(0, 0, 0);
      transition: all 0.3s;
    }
  }
  
  .merge-purge-navigate__link.active {
    background-color: #fff;
    
    &::before {
      transform: scale3d(1, 1, 1);
    }
  }
  
  .merge-purge__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: calc(100% - 170px);
    padding: 50px 30px 30px 30px;
    background-color: #fff;
  }
  
  .merge-purge__item-column {
    &:first-child {
      flex-basis: 60%;
      flex-shrink: 0;
      flex-grow: 0;
    }
    
    &:last-child {
      flex-basis: 40%;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
`
