export const convertStatusValueToNumberValue = (oldObject) => {
  const values = Object.values(oldObject.floorplan_rooms_attributes[0]).map(value => !value ? value = '0' : value = '1')

  const keys = Object.keys(oldObject.floorplan_rooms_attributes[0])

  const formArr = values.reduce(function(acc, cur, i) {
    acc[keys[i]] = cur;
    return acc;
  }, {});

  const transformObj = {
    ...oldObject,
    floorplan_rooms_attributes: [formArr]
  };
  return transformObj
}