import React, { Fragment } from "react"
import PropTypes from "prop-types"
// Components
import { Button } from "../../../../Shared"
import Input from "./Input"
import Select from "react-select"

const CategoryTd = (props) => {
  const { category, categories, onChangeCategories, onChangeSelectCategories } = props

  const onSelectChange = (selected) => {
    onChangeSelectCategories(props.category.value, selected)
  }

  const onButtonDeleteClick = () => props.onButtonDeleteClick(category.value)

  return (
    <Fragment>
      <td className="table__cell td">
        {category.label}
      </td>
      <td className="table__cell td">
        <Select
          options={[
            { value: "not_set", label: "Not set" },
            { value: "essential", label: "Essential" },
            { value: "other", label: "Other" }
          ]}
          onChange={onSelectChange}
        />
      </td>
      <td className="table__cell td">
        <Input
          id={category.value}
          name="quantity"
          value={categories && categories[category.value] && categories[category.value].quantity}
          onChange={onChangeCategories}
        />
      </td>
      <td className="table__cell td">
        <Input
          id={category.value}
          name="average_cost"
          value={categories && categories[category.value] && categories[category.value].average_cost}
          onChange={onChangeCategories}
        />
      </td>
      <td className="table__cell td small-size">
        <Button width={100} onClick={onButtonDeleteClick} color="danger">Delete</Button>
      </td>
    </Fragment>
  )
}

CategoryTd.propTypes = {
  category: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    variation: PropTypes.string,
    quantity: PropTypes.number,
    average_cost: PropTypes.number
  }).isRequired,
  onChangeCategory: PropTypes.func,
  onChangeCategories: PropTypes.func,
  categories: PropTypes.object,
  onChangeSelectCategories: PropTypes.func,
  onButtonDeleteClick: PropTypes.func
}

export default CategoryTd
