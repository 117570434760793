import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSortDown } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from "../Breadcrumbs"
import BreadcrumbsItem from "../Breadcrumbs/BreadcrumbsItem"
import ProductListOptions from "../ProductListOptions"

class TopSection extends Component {
  render() {
    let {
      pageTitle,
      breadcrumbs,
      onRemoveItem,
      isDisabled,
      onViewAll,
      onAssignCategory,
      shouldCreate,
      onCreate,
      onStockAvailability,
      shouldImport,
      shouldInStock,
      shouldDataStatusReport,
      shouldAdd,
      onAddProducts,
      isAddDisabled
    } = this.props

    return (
      <div className="top-section">
        <div className="top-section-with-breadcrumbs">
          <h1 className="top-section-title">{(pageTitle && pageTitle.toUpperCase()) || ""}</h1>
          <div className="top-section-breadcrumbs-wrapper">
            <Breadcrumbs>
              {breadcrumbs &&
                breadcrumbs.map((item, idx) => (
                  <BreadcrumbsItem key={`${item.to}${idx}`} to={item.to} name={item.name} />
                ))}
            </Breadcrumbs>
            <button className="top-section-breadcrumbs-wrapper-button">
              <FontAwesomeIcon icon={faSortDown} alt="profile-options-button-image" />
            </button>
          </div>
        </div>
        <ProductListOptions
          onViewAll={onViewAll}
          onAssignCategory={onAssignCategory}
          onRemoveItem={onRemoveItem}
          isDisabled={isDisabled}
          shouldCreate={shouldCreate}
          onCreate={onCreate}
          onStockAvailability={onStockAvailability}
          shouldImport={shouldImport}
          shouldInStock={shouldInStock}
          shouldDataStatusReport={shouldDataStatusReport}
          shouldAdd={shouldAdd}
          onAddProducts={onAddProducts}
          isAddDisabled={isAddDisabled}
        />
      </div>
    )
  }
}

TopSection.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string
    })
  ).isRequired
}

export default TopSection
